import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BenefitsCardVal } from "../../stories/BenefitsCard.stories";
import Loading from "../../common/Loading";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FamilyDashboardBenefits = (props) => {
  let { uuid } = useParams();
  const [loadingspinner, setLoadingspinner] = useState(true);

  useEffect(() => {
    if (props.allData) {
      setLoadingspinner(false);
    }
  }, [props.allData]);

  return (
    <>
      <div className="mb-3 d-flex">
        <h2 className="me-auto mb-0">Discounts</h2>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={uuid ? `/discounts/${uuid}` : "#"}
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link>
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          {props.allData.discountsData.length === 0 ? (
            <p className="small mb-0">You currently have no discounts!</p>
          ) : (
            <>
              {/* Temp showing one - need to review */}
              {props.allData.discountsData.map(
                ({ benefitText, logoImg, ctaText, benefitUrl }) => (
                  <>
                    <hr className="my-3" />
                    {/* <BenefitsCardVal
                      key={id}
                      variant="basic"
                      benefitTitle={`${benefitText}`}
                      logo={`${logoImg}`}
                      banner={`${bannerImg}`}
                      link={`${benefitUrl}`}
                      linkText={`${ctaText}`}
                      propClassName="card-unstyled"
                    /> */}
                    <a
                      className=""
                      href={benefitUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div
                        className={`card-body card p-0 card-unstyled 
                        `}
                      >
                        <div className="d-flex align-items-center mb-2">
                          <img
                            className="img-third-party-logo img-third-party-logo-xs me-auto rounded-circle "
                            src={logoImg}
                            alt="logo"
                            style={{ border: "1px solid #d9d9d9" }}
                          />
                          {/* <div className="small fw-semibold text-warning">31 March</div> */}
                        </div>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250 }}
                          overlay={
                            <Tooltip id="">
                              <div className="">{benefitText}</div>
                            </Tooltip>
                          }
                        >
                          <div
                            className="h5 mb-1"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 2,
                              cursor: "pointer",
                            }}
                          >
                            {benefitText}
                          </div>
                        </OverlayTrigger>
                        <div
                          className="small text-body"
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 1,
                            cursor: "pointer",
                          }}
                        >
                          {ctaText}
                        </div>
                      </div>
                    </a>
                  </>
                )
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default FamilyDashboardBenefits;
