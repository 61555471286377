import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import Loading from "../../common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const ViewNotificationDetails = (props) => {
  let { uuid, userUUID } = useParams();
  const [Filternewval, setFilternewval] = useState();
  const [sortFilter, setsortFilter] = useState();
  const [title, setTitle] = useState("Event Logs");
  const [subTitle, setSubTitle] = useState(
    "You can view all the event logs in this section."
  );
  const [familyNotificationList, setFamilyNotificationList] = useState([]);
  const [Loadingfilterby, setLoadingfilterby] = useState(true);
  const [familyId, setFamilyId] = useState();
  const [usersDetail, setUsersDetail] = useState([]);
  const [usersId, setUsersId] = useState("all");
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [hasMore, sethasMore] = useState(false);

  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var IdValue = parseInt(localStorage.getItem("userUUIDIdVal"));

  useEffect(() => {
    const userArr = [{ value: "all", label: "All" }];
    UserService.getFamilyAllAu(uuid).then(
      (resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          userArr.push({
            value: resp.data[i].id,
            label: resp.data[i].User.email,
          });
        }
        setUsersDetail(userArr);
      },
      () => {}
    );
  }, []);

  var PrimaryIdval = localStorage.getItem("PrimaryIdval");

  const mainFilter = [
    { value: "date", label: "Sort by date" },
    { value: "module", label: "Filter by module" },
  ];
  const mainFilterAdmin = [
    { value: "date", label: "Sort by date" },
    { value: "user", label: "Filter by user" },
    { value: "module", label: "Filter by module" },
  ];
  const filterOption = [
    { value: "all", label: "All" },
    { value: "Subscriptions", label: "Outgoings" },
    { value: "Warranties", label: "Warranties" },
    { value: "Giftcards", label: "Giftcards" },
    { value: "Tasks", label: "To Do's" },
  ];

  const sortOption = [
    { value: "ASC", label: "Ascending" },
    { value: "DESC", label: "Descending" },
  ];

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        // setParentUser(response.userData);
        setTitle(pUser.firstName + " " + pUser.lastName + "'s Event logs");
        setSubTitle(
          "You can view all the actionable event logs of " +
            pUser.firstName +
            " " +
            pUser.lastName +
            " here"
        );
      });
    } else {
      getFamilyDetails(UserDetails.id);
    }
  }, []);

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setLoadingfilterby(true);
        let familyId = response.FamilyData.Family.uuid;
        //set all the value
        setFamilyId(familyId);
        if (response.FamilyData) {
          if (
            userType === "AO" ||
            userType === "AO + SU" ||
            userType === "AO + AU" ||
            userType === "AO + AU + SU"
          ) {
            let params = {
              fuuid: familyId,
              userId: "all",
              limit: limit,
              offset: offset,
              filterVal: "",
              sortVal: sortFilter,
            };
            if (sortFilter === "ASC") {
              params.sortVal = "ASC";
            } else {
              params.sortVal = "DESC";
            }
            //getting first 50
            UserService.getUserFamilyNotification3(params).then(
              (res) => {
                setFamilyNotificationList(res.data);
                if (res.data.length === 0) {
                  sethasMore(false);
                } else {
                  if (res.data.length >= limit) {
                    setOffset(limit);
                    sethasMore(true);
                  } else {
                    sethasMore(false);
                  }
                }
                setTimeout(() => {
                  setLoadingfilterby(false);
                }, 250);
              },
              () => {}
            );
          } else {
            let params = {
              fuuid: familyId,
              userId:
                props.match.path === "/user_notification/:uuid/:userUUID"
                  ? IdValue
                  : userType === "AU + SU"
                  ? PrimaryIdval
                  : userType === "AU"
                  ? PrimaryIdval
                  : usersId,
              limit: limit,
              offset: offset,
              filterVal: "",
              sortVal: sortFilter,
            };
            if (sortFilter === "ASC") {
              params.sortVal = "ASC";
            } else {
              params.sortVal = "DESC";
            }

            //getting first 50
            UserService.getUserFamilyNotification3(params).then(
              (res) => {
                setFamilyNotificationList(res.data);
                if (res.data.length === 0) {
                  sethasMore(false);
                } else {
                  if (res.data.length >= limit) {
                    setOffset(limit);
                    sethasMore(true);
                  } else {
                    sethasMore(false);
                  }
                }
                setTimeout(() => {
                  setLoadingfilterby(false);
                }, 250);
              },
              () => {}
            );
          }
        }
      },
      () => {
        setLoadingfilterby(false);
      }
    );
  };

  const newBtnfn = (
    FamilyIdVal,
    SubscriptionIdVal,
    WarrantyIdVal,
    TaskIdVal,
    GiftcardIdVal
  ) => {
    if (TaskIdVal) {
      UserService.getTaskDetailsById(TaskIdVal).then((response) => {
        history.push({
          pathname: `/view_todos_details/${uuid}/${response.data.ParentId}`,
          state: TaskIdVal,
        });
      });
    }
    if (SubscriptionIdVal) {
      history.push(`/view_outgoing_details/${uuid}/${SubscriptionIdVal}`);
    }
    if (WarrantyIdVal) {
      history.push(`/view_warranty_details/${uuid}/${WarrantyIdVal}`);
    }
    if (GiftcardIdVal) {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${GiftcardIdVal}`);
    }
  };

  //filter by user
  const EventUserDetails = (e) => {
    setUsersId(e.value);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId: e.value,
      limit: limit,
      offset: offset,
      filterVal: "",
      sortVal: sortFilter,
    };
    if (sortFilter === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }
    getEventLogs(params);
  };

  //filter by in Event Log
  const EventLogFilter = (e) => {
    let filterVal = e.value;
    setFilternewval(filterVal);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: limit,
      offset: offset,
      filterVal: filterVal,
      sortVal: sortFilter,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = filterVal);
    }
    if (sortFilter === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }
    getEventLogs(params);
  };

  //sort by in Event Log
  const EventLogSort = (e) => {
    let sortFil = e.value;
    setsortFilter(sortFil);
    setLoadingfilterby(true);
    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: limit,
      offset: offset,
      filterVal: Filternewval,
      sortVal: sortFil,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = Filternewval);
    }
    if (sortFil === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }
    getEventLogs(params);
  };

  //for more notification in Infinite Scroll
  const getEventLogs = (params) => {
    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setFamilyNotificationList(res.data);
        if (res.data.length === 0) {
          sethasMore(false);
        } else {
          if (res.data.length >= limit) {
            setOffset(limit);
            sethasMore(true);
          } else {
            sethasMore(false);
          }
        }
        setTimeout(() => {
          setLoadingfilterby(false);
        }, 250);
      },
      () => {}
    );
  };

  //for more notification in Infinite Scroll
  const fetchTasks = () => {
    let params = {
      fuuid: familyId,
      userId:
        props.match.path === "/user_notification/:uuid/:userUUID"
          ? IdValue
          : userType === "AU + SU"
          ? PrimaryIdval
          : userType === "AU"
          ? PrimaryIdval
          : usersId,
      limit: limit,
      offset: offset,
      filterVal: Filternewval,
      sortVal: sortFilter,
    };
    // eslint-disable-next-line no-lone-blocks
    {
      params.filterVal === undefined || params.filterVal === "all"
        ? (params.filterVal = "")
        : (params.filterVal = Filternewval);
    }
    if (sortFilter === "ASC") {
      params.sortVal = "ASC";
    } else {
      params.sortVal = "DESC";
    }

    UserService.getUserFamilyNotification3(params).then(
      (res) => {
        setFamilyNotificationList([...familyNotificationList, ...res.data]);
        let nextOffset = Number(offset) + Number(limit);

        if (res.data.length + familyNotificationList.length >= nextOffset) {
          setOffset(nextOffset);
          sethasMore(true);
        } else {
          sethasMore(false);
        }
      },
      () => {}
    );
  };

  var userType = localStorage.getItem("userType");

  const sortRef = useRef(null);
  const sortRef1 = useRef(null);

  const [isSortDropdown, setIsSortDropdown] = useState(false);
  const [isSortDropdown1, setIsSortDropdown1] = useState(false);

  const [selectedFilterType, setSelectedFilterType] = useState(false);

  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedSort1, setSelectedSort1] = useState(null);

  const toggleDropdown = () => setIsSortDropdown((prev) => !prev);

  const closeDropdown = () => setIsSortDropdown(false);
  const closeDropdown1 = () => setIsSortDropdown1(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        closeDropdown();
        closeDropdown1();
      }
      if (sortRef1.current && !sortRef1.current.contains(event.target)) {
        closeDropdown1();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="page-content warranty-pg">
      <Container fluid>
        <>
          <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
            <Col>
              <h3>{title}</h3>
              <p className="sub-title">{subTitle}</p>
            </Col>

            <Col
              xs="auto"
              className="position-relative"
              ref={sortRef} // Ref to track button for outside clicks
            >
              <button
                className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                  isSortDropdown ? "dropdown-arrow" : ""
                }`}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                onClick={toggleDropdown} // Toggle dropdown on click
                aria-expanded={isSortDropdown}
              >
                <i className="fa-solid fa-sm fa-sliders-h lh-1 align-middle"></i>
                <span className="text-dark ms-md-2 d-none d-xl-block">
                  Manage Filters
                </span>
              </button>
              {/* main dropdown  */}
              <ul
                className={`dropdown-menu dropdown-parent mt-2 ${
                  isSortDropdown ? "show" : ""
                }`}
                ref={sortRef}
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  {(userType === "AU" ||
                  userType === "AU + SU" ||
                  userType === "SU"
                    ? mainFilter
                    : mainFilterAdmin
                  ).map((option) => (
                    <div
                      key={option.value}
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        color:
                          selectedSort && selectedSort.value === option.value
                            ? "#020617"
                            : "",
                        backgroundColor:
                          selectedSort && selectedSort.value === option.value
                            ? "#36f1cc"
                            : "",
                      }}
                      onClick={() => {
                        setIsSortDropdown1(true);
                        setSelectedSort(option);
                        setSelectedFilterType(option.value);
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </li>
              </ul>
              {/* dropdown based on main dropdown filter  */}
              {isSortDropdown1 && (
                <ul
                  className={`dropdown-menu dropdown-child mt-2 show`}
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    top:
                      selectedFilterType === "user"
                        ? "5.5rem"
                        : selectedFilterType === "module"
                        ? "7.5rem"
                        : "3.5rem",
                    // left: screenWidth > 1200 ? "16rem" : "",
                    right: "16rem",
                  }}
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    style={{
                      position: "absolute",
                      right: "-13px",
                      width: "0px",
                      height: "0px",
                      borderLeft: "8px solid transparent",
                      borderRight: "10px solid transparent",
                      borderBottom: "15px solid white",
                      transform: "rotate(-30deg)",
                      top: "8px",
                    }}
                  ></div>{" "}
                  <li>
                    {(selectedFilterType === "user"
                      ? usersDetail
                      : selectedFilterType === "module"
                      ? filterOption
                      : sortOption
                    ).map((option) => (
                      <div
                        key={option.value}
                        className="dropdown-item"
                        style={{
                          cursor: "pointer",
                          color:
                            selectedSort1 &&
                            selectedSort1.value === option.value
                              ? "#020617"
                              : "",
                          backgroundColor:
                            selectedSort1 &&
                            selectedSort1.value === option.value
                              ? "#36f1cc"
                              : "",
                        }}
                        onClick={() => {
                          if (selectedFilterType === "user") {
                            EventUserDetails(option);
                          } else if (selectedFilterType === "module") {
                            EventLogFilter(option);
                          } else if (selectedFilterType === "date") {
                            EventLogSort(option);
                          }
                          setSelectedSort1(option);
                          setIsSortDropdown1(false); // Close second dropdown after selection
                          setIsSortDropdown(false); // Close second dropdown after selection
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </li>
                </ul>
              )}
            </Col>
            {/* filter by user  */}
            {/* {props.match.path !== "/user_notification/:uuid/:userUUID" &&
              userType !== "AU" &&
              userType !== "AU + SU" &&
              userType !== "SU" && (
                <Dropdown
                  refProp={sortRef1}
                  isDropdownOpen={isSortDropdown1}
                  toggleDropdown={toggleDropdown1}
                  selectedSort={selectedSort1}
                  setSelectedSort={setSelectedSort1}
                  items={usersDetail}
                  label="Filter by Users"
                  icon="fa-solid fa-users"
                />
              )}
            <Dropdown
              refProp={sortRef2}
              isDropdownOpen={isSortDropdown2}
              toggleDropdown={toggleDropdown2}
              selectedSort={selectedSort2}
              setSelectedSort={setSelectedSort2}
              items={filterOption}
              label="Filter by"
              icon="fa-solid fa-filter"
            />
            <Dropdown
              refProp={sortRef3}
              isDropdownOpen={isSortDropdown3}
              toggleDropdown={toggleDropdown3}
              selectedSort={selectedSort3}
              setSelectedSort={setSelectedSort3}
              items={sortOption}
              label="Sort by"
              icon="fa-solid fa-sort"
            /> */}
          </Row>

          <Row className="viewNotification-row mb-6rem">
            {Loadingfilterby ? (
              <div
                style={{
                  minHeight: "calc(100vh - 20rem)",
                }}
                className="d-flex flex-column"
              >
                <Loading />
              </div>
            ) : (
              <>
                <section className="bg-white p-4 p-md-5 p-lg-6 rounded-4 shadow h-100">
                  <Row className="gx-3 gy-0 gx-md-4 gx-lg-3 gx-xl-2 gx-xxl-3">
                    <Col xs="6" md="6" lg="8" xl="6">
                      <span className="small fw-semibold fs-17">Event</span>
                    </Col>
                    <Col xs="14" md="14" lg="12" xl="10" className="">
                      <span className="small fw-semibold fs-17">
                        Description
                      </span>
                    </Col>
                    <Col xs="4" md="4" lg="4" xl="5">
                      <span className="small fw-semibold fs-17">Module</span>
                    </Col>

                    <Col xs="8" xl="3" className="d-xl-block d-none">
                      <span className="small fw-semibold fs-17">Timestamp</span>
                    </Col>
                  </Row>
                  <hr className="mt-1 mb-3" />
                  <InfiniteScroll
                    dataLength={familyNotificationList.length} // Set the length of the current data
                    next={fetchTasks} // Trigger to load more data
                    hasMore={hasMore} // Check if more data is available
                    style={{
                      overflow: "visible",
                    }}
                    loader={
                      <div className="d-flex align-items-center gap-2 my-5 justify-content-center">
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        ></span>
                        <span>Loading . . .</span>
                      </div>
                    }
                  >
                    {familyNotificationList &&
                    familyNotificationList.length !== 0 ? (
                      familyNotificationList.map((notifyItem, index) => (
                        <>
                          <Row className="align-items-center gx-3 gy-0 gx-md-4 gx-lg-3 gx-xl-2 gx-xxl-3">
                            <Col xs="6" md="6" lg="8" xl="6">
                              <div className="d-inline small  text-dark">
                                {notifyItem.title}
                              </div>
                            </Col>
                            <Col xs="14" md="14" lg="12" xl="10" className="">
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250 }}
                                overlay={
                                  <Tooltip id="">
                                    <div className="">{notifyItem.message}</div>
                                  </Tooltip>
                                }
                              >
                                <div className="d-inline small  text-dark cursor-pointer">
                                  {notifyItem.message}
                                </div>
                              </OverlayTrigger>
                            </Col>
                            <Col xs="4" md="4" lg="4" xl="5">
                              <div className="d-inline small  text-dark">
                                {notifyItem.module}
                              </div>
                            </Col>

                            <Col xs="8" xl="3" className="d-xl-block d-none">
                              <div className="d-inline small  text-dark">
                                {moment(notifyItem.createdAt).format(
                                  "Do MMM, YYYY"
                                )}
                              </div>
                            </Col>
                          </Row>
                          {index !== familyNotificationList.length - 1 && (
                            <hr className="my-3" />
                          )}
                        </>
                      ))
                    ) : (
                      <div style={{ padding: "8rem", textAlign: "center" }}>
                        <p>
                          You have no event logs to catch up in this family.
                        </p>
                      </div>
                    )}
                  </InfiniteScroll>
                </section>
              </>
            )}
          </Row>
        </>
        {/* )} */}
      </Container>
    </div>
  );
};
export default ViewNotificationDetails;
