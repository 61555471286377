import React from "react";
import { useState, useEffect, useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import EL_logo from "../../assets/images/EL_logo-big.svg";
import arrowSmall from "../../assets/images/arrow-small-left.svg";
import Layer from "../../assets/images/Layer 22.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const FamilyCreationConfirmation = (props) => {
  const executeScroll = () => myRef.current.scrollIntoView();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let history = useHistory();
  const [mainUsers, setMainUsers] = useState(true);
  const [supportUsers, setSupportUsers] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const myRef = useRef(null);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [style, setStyle] = useState("wizard-div-sub1-2");
  const [style2, setStyle2] = useState("wizard-div-sub1-2");
  const [style4, setStyle4] = useState("wizard-div-sub1-2");
  const [style7, setStyle7] = useState("wizard-div-sub1-2");
  const [style8, setStyle8] = useState("wizard-div-sub1-2");
  const [style20, setStyle20] = useState("wizard-div-sub1-2");
  const [style21, setStyle21] = useState("wizard-div-sub1-3");
  const [style30, setStyle30] = useState("wizard-div-sub1-2");
  const [style31, setStyle31] = useState("wizard-div-sub1-3");
  const [style1, setStyle1] = useState("wizard-div-sub1-3");
  const [style3, setStyle3] = useState("wizard-div-sub1-3");
  const [style5, setstyle5] = useState("wizard-div-sub1-3");
  const [style6, setstyle6] = useState("wizard-div-sub1-3");
  const [style10, setstyle10] = useState("wizard-div-sub1-3");
  const [MainValue, setMainValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showInviteModel, setShowInviteModel] = useState(false);
  const [showInviteModel11, setShowInviteModel11] = useState(false);
  const [showInviteModel1, setShowInviteModel1] = useState(false);
  const [showInviteModel22, setShowInviteModel22] = useState(false);
  const [NewRes4, setNewRes4] = useState();
  const [mainTab, setMainTab] = useState(true);
  const [supportTab, setSupportTab] = useState(true);
  const [mainTop, setMainTop] = useState(true);
  const [supportTop, setSupportTop] = useState(false);
  const [mainContent, setMainContent] = useState(true);
  const [supportContent, setSupportContent] = useState(false);
  const [mSU, setMSU] = useState(false);
  const [pFurther, setPFurther] = useState(false);
  const [lastStep, setLastStep] = useState(false);
  const [mainShow, setMainShow] = useState(true);
  const [skipBtn, setSkipBtn] = useState(true);
  const [resetBtn, setResetBtn] = useState(false);
  const [firstQ, setFirstQ] = useState(true);
  const [secondQ, setSecondQ] = useState(false);
  const [thirdQ, setThirdQ] = useState(false);
  const [fourthQ, setFourthQ] = useState(false);
  const [suFirstQ, setSuFirstQ] = useState(false);
  const [suSecondQ, setSuSecondQ] = useState(false);
  const [suThirdQ, setSuThirdQ] = useState(false);
  const [suFourthQ, setSuFourthQ] = useState(false);
  const [suFifthQ, setSuFifthQ] = useState(false);
  const [btnState, setBtnState] = useState(false);
  const [checkL, setCheckL] = useState();
  const [mainUser1, setMainUser1] = useState("");

  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var UserDA = localStorage.getItem("UserDA");
  const [newVal, setNewVal] = useState();
  const [newVal1, setNewVal1] = useState();
  const [newVal2, setNewVal2] = useState();
  const [newVal111, setNewVal111] = useState();
  var UserA = localStorage.getItem("UserA");

  useEffect(() => {
    setNewRes4(parseInt(UserA));
  }, []);
  var UserB = localStorage.getItem("UserB");
  const initialValues2 = {
    is_pu: "",
  };
  const [mainList, setMainList] = useState(initialValues2);
  const OpenWizard = (event, key) => {
    setNewVal111();
    setSuSecondQ(false);
    setSuThirdQ(false);
    setSuFourthQ(false);
    setSuFifthQ(false);
    setList([]);
    setPFurther(false);
    setStyle2("wizard-div-sub1-2");
    setstyle10("wizard-div-sub1-3");
    setFourthQ(false);
    if (NewRes4 === 1 || NewRes4 === 4) {
      setThirdQ(false);
      setMSU(true);
    } else {
      setThirdQ(true);
      setMSU(true);
    }
    setShowInviteModel11(false);
    setShowInviteModel(false);
    setShowInviteModel22(false);
    setShowInviteModel1(false);
    setStyle8("wizard-div-sub1-2");
    setFormValues(initialValues);
    setFormValues1(initialValues1);
    if (event.target.value === "true") {
      setMainList({ ...initialValues2, is_pu: event.target.value });
    }
    setMainUsers(false);
    setSupportUsers(true);
    setNewVal1("yes1");
    setNewVal("");
    setNewVal2("");
    setStyle("wizard-div-sub1-2-after");
    setStyle1("wizard-div-sub1-3");
    setStyle4("wizard-div-sub1-2");
    setstyle6("wizard-div-sub1-3");
    setstyle5("wizard-div-sub1-3");
    document.getElementById("welcomeNext1").style.display = "none";
    setMainValue(false);
    setList1([]);
  };
  const OpenWizard1 = (event, key) => {
    setError1("");
    setSUVal1();
    setNewVal111("yes111");
    setSuFirstQ(false);
    setSuSecondQ(false);
    setSuThirdQ(false);
    setStyle20("wizard-div-sub1-2");
    setStyle21("wizard-div-sub1-3");
    setList([]);
    setPFurther(false);
    setSuFifthQ(false);
    setMSU(false);
    setThirdQ(false);
    setFourthQ(false);
    setFormValues(initialValues);
    setFormValues1(initialValues1);
    setShowInviteModel11(false);
    setShowInviteModel(false);
    setShowInviteModel22(false);
    setShowInviteModel1(true);
    setStyle1("wizard-div-sub1-3-after");
    if (event.target.value === "false") {
      setMainList({ ...initialValues2, is_pu: event.target.value });
    }
    setMainUsers(true);
    setSupportUsers(false);
    setStyle("wizard-div-sub1-2");
    setStyle2("wizard-div-sub1-2");
    setStyle4("wizard-div-sub1-2");
    setstyle6("wizard-div-sub1-3");
    setNewVal1("No1");
    setNewVal("");
    setNewVal2("");
    setMainValue(false);
    setList1([]);
  };
  const [valNew, setValNew] = useState();
  const [sUVal1, setSUVal1] = useState();
  const [sUVal2, setSUVal2] = useState();

  const OpenWizard3 = (event) => {
    setError1("");
    setValNew("No");
    setSUVal2("");
    setStyle20("wizard-div-sub1-2");
    if (event.target.value === "44") {
      setMainFn6(event.target.value);
    }
    if (list1.length > 0) {
      if (
        document.querySelectorAll('input[type="checkbox"]:checked').length > 0
      ) {
        setSuFourthQ(true);
        setPFurther(true);
      } else {
        setSuFourthQ(true);
        setPFurther(false);
      }
    } else {
      setSuFourthQ(false);
      setPFurther(true);
    }
    setFormValues(initialValues);
    setShowInviteModel(false);
    setShowInviteModel11(false);
    setMainFn5("");
    setStyle3("wizard-div-sub1-3-after");
    setNewVal2("");
    if (
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(true);
    }
    if (mainFn4 === "22") {
      if (mainFn6 === "") {
        setMainValue(true);
        if (list1.length > 0) {
          setSuFourthQ(true);

          if (isFound === true) {
            setPFurther(true);
          } else {
            setPFurther(false);
          }
        } else {
          setSuFourthQ(false);
          setPFurther(true);
        }
      } else if (mainFn6 === "") {
        setMainValue(false);
      }
    }
    if (
      NewRes4 !== 3 &&
      NewRes4 !== 6 &&
      mainFn4 === "" &&
      (mainFn1 === "No" || mainFn === "yes")
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainFn === "yes" &&
      mainFn4 === "" &&
      mainFn3 === ""
    ) {
      setMainValue(false);
    }

    if ((NewRes4 === 2 || NewRes4 === 5) && mainFn4 === "" && mainFn1 === "") {
      if (style20 === "wizard-div-sub1-2-after") {
      }
    }
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainFn4 === "" &&
      mainFn1 === "" &&
      list.length > 0 &&
      list1.length > 0
    ) {
      setMainValue(true);
      if (style20 === "wizard-div-sub1-2-after") {
      }
    }

    if (
      mainFn === "yes" &&
      mainFn3 === "11" &&
      NewRes4 !== 3 &&
      NewRes4 !== 6
    ) {
      if (mainFn6 === "44") {
        setMainValue(true);
      }
      if (mainFn5 === "33") {
        setMainValue(true);
      }
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2" &&
      style6 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2-after"
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      style6 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style6 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(true);
    }

    if (
      mainList.is_pu === "true" &&
      mainFn === "yes" &&
      mainFn3 === "11" &&
      list1.length === 4 &&
      list.length > 0
    ) {
      setMainValue(true);
    }
    setStyle7("wizard-div-sub1-2");
    setStyle8("wizard-div-sub1-2");
    setStyle21("wizard-div-sub1-3");
  };

  const OpenWizard5 = (event) => {
    setNewVal111("no111");
    setError1("");
    if (event.target.value === "22") {
      setMainFn4(event.target.value);
    }
    setFormValues1(initialValues1);
    setMainFn3("");
    if (NewRes4 === 1 || NewRes4 === 4) {
      if (mainFn6 === "44" || list1.length === 1 || list1.length > 0) {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
    }
    if (NewRes4 === 2 || NewRes4 === 5) {
      if (mainFn4 === "22" || list1.length === 2 || list1.length > 0) {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
    }
    if (NewRes4 === 3 || NewRes4 === 6) {
      if (mainFn4 === "22" || list1.length === 5 || list1.length > 0) {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2" &&
      style10 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
      setMSU(true);
    }
    setstyle5("wizard-div-sub1-3-after");
    if (style1 === "wizard-div-sub1-3-after") {
      setThirdQ(false);
      setFourthQ(false);
      setStyle("wizard-div-sub1-2");
      setStyle1("wizard-div-sub1-3-after");
      setShowInviteModel1(true);
      setMSU(false);
      setList1([]);
    }
    if (style === "wizard-div-sub1-2-after") {
      setThirdQ(true);
      setFourthQ(false);
      setstyle5("wizard-div-sub1-3-after");
      setStyle("wizard-div-sub1-2-after");
      setStyle1("wizard-div-sub1-3");
      setShowInviteModel1(false);
      setMSU(true);
      setList1([]);
    }
    setStyle8("wizard-div-sub1-2");
    setShowInviteModel22(false);

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style4 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 5 &&
      list1.length > 0 &&
      list1.length < 4
    ) {
      setMainValue(false);
      setMSU(false);
    }
  };

  const OpenWizard31 = (event) => {
    setMainUser1("22");
    setError1("");
    if (event.target.value === "22") {
      setMainFn4(event.target.value);
    }
    setMainFn3("");
    if (NewRes4 === 1 || NewRes4 === 4) {
      if (mainFn6 === "44" || list1.length === 1 || list1.length > 0) {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
    }
    if (NewRes4 === 2 || NewRes4 === 5) {
      if (mainFn4 === "22" || list1.length === 2 || list1.length > 0) {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
    }
    if (NewRes4 === 3 || NewRes4 === 6) {
      if (mainFn4 === "22" || list1.length === 5 || list1.length > 0) {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
      if (mainFn === "yes" && mainList.is_pu === "false") {
        setMainValue(true);
        setMSU(true);
        setShowInviteModel22(false);
        setShowInviteModel1(false);
        setFormValues1(initialValues1);
      }
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2" &&
      style10 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
      setMSU(true);
    }
    setStyle31("wizard-div-sub1-3-after");
    setMSU(true);
    setStyle30("wizard-div-sub1-2");
    setShowInviteModel1(false);
    setShowInviteModel22(false);
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style4 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 5 &&
      list1.length > 0 &&
      list1.length < 4
    ) {
      setMainValue(false);
      setMSU(false);
    }
  };
  const modelOpen = (event, key) => {
    const updatedPU = [...list1];
    {
      list1.map((b, key) => (
        <span className="wizard-SU-1" key={key}>
          {((updatedPU[key].pu_supported_by_ao = false), setList1(updatedPU))}
        </span>
      ));
    }
    setStyle20("wizard-div-sub1-2");
    setPFurther(false);
    setShowInviteModel22(false);
    setShowInviteModel1(false);
    if (list.length >= 1) {
      setShowInviteModel(false);
    } else {
      setShowInviteModel(true);
    }
    setSuFifthQ(false);
    setNewVal("yes");
    setNewVal2("");
    setStyle4("wizard-div-sub1-2");
    setstyle6("wizard-div-sub1-3");
    setStyle2("wizard-div-sub1-2-after");
    setStyle7("wizard-div-sub1-2");
    setstyle10("wizard-div-sub1-3");
    setMainFn("");
    setMainFn1("");
    setList([]);
    document.getElementById("welcomeNext1").style.display = "none";
    setMainValue(false);
    setSuSecondQ(true);
    setSuFourthQ(false);
  };
  const [mainFn, setMainFn] = useState();
  const [mainFn1, setMainFn1] = useState();
  const [mainFn3, setMainFn3] = useState();
  const [mainFn4, setMainFn4] = useState();
  const [mainFn5, setMainFn5] = useState();
  const [mainFn6, setMainFn6] = useState();
  const modelOpen1 = (event) => {
    if (event.target.value === "yes") {
      setMainFn(event.target.value);
    }
    setMainFn1("");
    setNewVal2("yes2");
    if (list1.length >= 1) {
      setShowInviteModel1(false);
    } else {
      setShowInviteModel1(true);
      setNewVal2("");
      setMainValue(false);
    }
    setStyle4("wizard-div-sub1-2-after");
    setstyle6("wizard-div-sub1-3");
  };

  const modelOpen11 = (event, key) => {
    setError1("");
    const updatedPU = [...list1];
    {
      list1.map((b, key) => (
        <span className="wizard-SU-1" key={key}>
          {((updatedPU[key].pu_supported_by_ao = false), setList1(updatedPU))}
        </span>
      ));
    }
    setSUVal1("");
    setSUVal2("");
    setShowInviteModel(false);
    setStyle2("wizard-div-sub1-2");
    setList([]);
    setstyle10("wizard-div-sub1-3-after");
    setNewVal("No");
    setSuThirdQ(false);
    setShowInviteModel11(false);
    setShowInviteModel(false);
    if (list1.length > 0) {
      setSuFourthQ(true);
      setPFurther(false);
    } else {
      setSuFourthQ(false);
      setPFurther(true);
    }
    setStyle21("wizard-div-sub1-3");
    setStyle20("wizard-div-sub1-2");

    setFormValues(initialValues);
    setSuFifthQ(false);
  };
  const initialValues = {
    SU_email: "",
  };

  const initialValues1 = {
    PU_email: "",
    pu_supported_by_ao: false,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formValues1, setFormValues1] = useState(initialValues1);
  const [list, setList] = useState([]);
  const [list1, setList1] = useState([]);

  const [error1, setError1] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    // Get the email value from form
    const email = formValues.SU_email.trim();

    // Check if email is empty
    if (!email) {
      setError1("Email cannot be empty.");
      return;
    }

    // Check if the email matches the pattern (simple regex check)
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (!emailPattern.test(email)) {
      setError1("Please enter a valid email address.");
      return;
    }
    setError1("");
    setSUVal1("");
    setSUVal2("");

    setSuSecondQ(true);
    setSUValues(formValues);
    setShowInviteModel(false);
    setFormValues(initialValues);
    setStyle7("wizard-div-sub1-2");
    setList((ls) => [...ls, formValues]);
    if ((NewRes4 === 2 || NewRes4 === 5) && list.length >= 1) {
      setPFurther(true);
      if (mainFn1 === "No" && list1.length === 0) {
        setMainValue(true);

        setSuFourthQ(true);
      } else if (mainFn === "yes" && list1.length > 0) {
        setMainValue(true);
        setSuFourthQ(true);
      } else if (list1.length > 0) {
        setSuThirdQ(false);
        if (isFound === true) {
          setSuFourthQ(true);
        } else {
          setPFurther(true);
          setStyle20("wizard-div-sub1-2");
        }
        if (style21 === "wizard-div-sub1-3-after") {
          setPFurther(true);
        } else {
          if (
            document.querySelectorAll('input[type="checkbox"]:checked').length >
            0
          ) {
            setPFurther(true);
          } else {
            setPFurther(true);
          }
        }
      } else if (list1.length === 0) {
        setSuFourthQ(false);
        setPFurther(true);
        setSuThirdQ(false);
      } else {
        setMainValue(false);
        setSuThirdQ(false);
      }
    } else if ((NewRes4 === 2 || NewRes4 === 5) && list.length === 0) {
      if (list1.length > 0) {
        setPFurther(false);
        setSuSecondQ(true);
      } else {
        setPFurther(false);
        setSuSecondQ(true);
      }
    } else if ((NewRes4 === 1 || NewRes4 === 4) && list.length >= 0) {
      setMainValue(true);
      if (list1.length > 0) {
        setSuFourthQ(true);
        setPFurther(false);
        setSuSecondQ(true);
      } else {
        setSuFourthQ(false);
        setPFurther(true);
        setSuSecondQ(true);
      }
    } else if ((NewRes4 === 3 || NewRes4 === 6) && list.length >= 4) {
      setSuThirdQ(false);
      if (list1.length > 0) {
        setPFurther(true);
        if (style21 === "wizard-div-sub1-3-after") {
          setPFurther(true);
        } else {
          if (isFound === true) {
            setPFurther(true);
          } else {
            setPFurther(true);
          }
        }
        setSuSecondQ(true);
      } else {
        setSuThirdQ(false);
        setSuFourthQ(false);
        setPFurther(true);
        setSuSecondQ(true);
      }
      if (
        mainFn === "yes" &&
        mainFn4 === "22" &&
        mainFn5 === "33" &&
        list1.length > 0
      ) {
        setMainValue(true);
        setSuFourthQ(false);
      }
      if (style6 === "wizard-div-sub1-3-after") {
        setMainValue(true);
        setSuFourthQ(false);
      }
    } else {
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style8 === "wizard-div-sub1-2" &&
      style5 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);

      if (list.length >= 4) {
        setSuThirdQ(false);
      } else {
        setSuThirdQ(true);
      }
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
      setSuThirdQ(true);
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after"
    ) {
      setMainValue(false);
      setSuThirdQ(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style6 === "wizard-div-sub1-3-after" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3"
    ) {
      setMainValue(false);
      setSuThirdQ(true);
    }

    if ((NewRes4 === 2 || NewRes4 === 5) && list.length >= 1) {
      if (mainFn === "yes" && mainFn3 === "11") {
        if (mainFn6 === "44") {
          setMainValue(true);
          setSuFourthQ(true);
        }
        if (mainFn5 === "33") {
          setMainValue(true);
          setSuFourthQ(true);
        }
      }
    } else if ((NewRes4 === 1 || NewRes4 === 4) && list.length >= 0) {
      if (mainFn === "yes" && mainFn3 === "11") {
        if (mainFn6 === "44") {
          setMainValue(true);
          setSuFourthQ(true);
        }
        if (mainFn5 === "33") {
          setMainValue(true);
          setSuFourthQ(true);
        }
      }
    } else if ((NewRes4 === 3 || NewRes4 === 6) && list.length >= 4) {
      if (mainFn === "yes" && mainFn3 === "11") {
        if (mainFn6 === "44") {
          setMainValue(true);
          setSuFourthQ(true);
        }
        if (mainFn5 === "33" && list1.length < 4) {
          setMainValue(false);
          setSuThirdQ(true);
        }
        if (mainFn5 === "33" && list1.length >= 4) {
          setMainValue(true);
          setSuFourthQ(true);
        }
      }
    } else {
      setSuThirdQ(true);
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      list.length > 0 &&
      list.length < 4 &&
      list1.length > 0 &&
      list1.length < 3 &&
      MainValue === true
    ) {
      setMainValue(false);
      setSuThirdQ(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      style4 === "wizard-div-sub1-2-after" &&
      style5 === "wizard-div-sub1-3-after" &&
      list.length > 0 &&
      list.length < 4 &&
      list1.length > 0 &&
      list1.length < 3 &&
      MainValue === false
    ) {
      setMainValue(false);
      setSuThirdQ(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style2 === "wizard-div-sub1-2-after" &&
      style6 === "wizard-div-sub1-3-after" &&
      list.length > 0 &&
      list.length === 4
    ) {
      setMainValue(true);
      setSuFourthQ(true);
    }

    setStyle3("wizard-div-sub1-3");
    setFormValues(initialValues);
    setShowInviteModel11(false);
    setShowInviteModel(false);
  };
  const [SUValues, setSUValues] = useState({});

  const handleSubmit1 = (e) => {
    e.preventDefault();
    // Get the email value from form
    const email = formValues1.PU_email.trim();

    // Check if email is empty
    if (!email) {
      setError1("Email cannot be empty.");
      return;
    }

    // Check if the email matches the pattern (simple regex check)
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (!emailPattern.test(email)) {
      setError1("Please enter a valid email address.");
      return;
    }
    setError1("");
    setMainUser1("");
    setSUValues1(formValues1);

    setShowInviteModel1(false);
    setFormValues1(initialValues1);
    setList1((ls) => [...ls, formValues1]);
    setMainFn4("");
    setSecondQ(true);
    setStyle8("wizard-div-sub1-2");
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      list1.length === 0
    ) {
      setMainValue(true);
      setMSU(true);
      setThirdQ(true);
      setStyle8("wizard-div-sub1-2-after");
    } else if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "false" &&
      list1.length === 1
    ) {
      setMainValue(true);
      setMSU(true);
      setThirdQ(true);
      setFourthQ(false);
      setStyle8("wizard-div-sub1-2-after");
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "true" &&
      list1.length === -1
    ) {
      setMainValue(true);
      setMSU(true);
      setThirdQ(false);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "false" &&
      list1.length === 0
    ) {
      setMainValue(true);
      setMSU(true);
      setThirdQ(true);
      setStyle8("wizard-div-sub1-2-after");
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style10 === "wizard-div-sub1-3" &&
      list.length < 5
    ) {
      setMainValue(false);
      setMSU(true);
      setThirdQ(true);
      setStyle8("wizard-div-sub1-2-after");
      setFourthQ(false);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style10 === "wizard-div-sub1-3-after" &&
      list.length < 5
    ) {
      setMainValue(true);
      setMSU(true);
      setThirdQ(false);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      (style7 === "wizard-div-sub1-2-after" ||
        style3 === "wizard-div-sub1-3-after") &&
      list.length < 5
    ) {
      setStyle8("wizard-div-sub1-2-after");
      setMainValue(true);
      setMSU(true);
      setThirdQ(true);
      setFourthQ(false);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length === 3 &&
      list.length === 5
    ) {
      setMainValue(true);
      setMSU(true);
      setThirdQ(false);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "false" &&
      list1.length === 4
    ) {
      setMainValue(true);
      setMSU(true);
      setThirdQ(true);
      setFourthQ(false);
      setStyle8("wizard-div-sub1-2-after");
    } else {
      setMainValue(false);
      setMSU(true);
      setThirdQ(true);
      setFourthQ(true);
      setStyle8("wizard-div-sub1-2-after");
      setStyle30("wizard-div-sub1-2");
      setStyle31("wizard-div-sub1-3");
    }

    setstyle5("wizard-div-sub1-3");
    setFormValues1(initialValues1);
    setShowInviteModel22(false);
    setShowInviteModel1(false);
  };
  const [SUValues1, setSUValues1] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormValues1({ ...formValues1, [name]: value });
  };

  const FinalValue = async () => {
    try {
      setLoading(true);
      let data = {
        is_pu: mainList.is_pu === "true" ? true : false, // Simplified the assignment
        aoData: UserDetails,
        primary: list1,
        support: list,
        family_uuid: UserB,
        family_id: UserDA,
      };

      const responseVal = await UserService.createWizard(data);
      if (responseVal.status === 400 || responseVal.status === 404) {
        setLoading(false);
        executeScroll();
        window.scrollTo(0, 0);
        setSuccessToast(false);
        setShowToast(true);
        setToastMessage(
          responseVal.data.error
            ? responseVal.data.error
            : responseVal.data.message
        );
        return;
      }
      // Process primary data (list1)
      if (list1.length > 0) {
        for (const element of list1) {
          const supportedByAo = element.pu_supported_by_ao;
          const data2 = {
            firstName: safeValue(element.PU_firstname),
            lastName: safeValue(element.PU_lastname),
            to: element.PU_email,
            from: UserDetails.uuid,
            for: UserB,
            isAU: true,
          };

          const data3 = { SU: true };

          const wizardResponse = await UserService.wizardapi1(data2);
          debugger;
          if (supportedByAo === true) {
            const auUserId = wizardResponse.InviteData.id;
            const data4 = { status: "Onboarded" };
            await UserService.wizardapi2(UserB, auUserId, data3);
          }
        }
      }

      // Process secondary data (list)
      if (data.is_pu === true) {
        const data5 = { isAU: true };
        await UserService.wizardapi3(UserB, data5);

        if (list.length !== 0) {
          for (const element of list) {
            const data1 = {
              firstName: safeValue(element.SU_firstname),
              lastName: safeValue(element.SU_lastname),
              to: element.SU_email,
              from: UserDetails.uuid,
              for: UserB,
              isAU: false,
            };

            await UserService.wizardapi4(data1);
          }
        }
      }

      // After all API calls
      setLoading(false);
      setSuccessToast(true);
      setShowToast(true);
      executeScroll();
      window.scrollTo(0, 0);
      setToastMessage("Successfully added Users to the Family");

      // Fetch updated family data
      const familyResponse = await UserService.getUserSingleFamilyDetails(
        UserB,
        UserDetails.id
      );

      if (familyResponse.FamilyData) {
        const familyData = familyResponse.FamilyData;
        let userType = "";

        if (familyData.isAo && familyData.isAu && familyData.isSu)
          userType = "AO + AU + SU";
        else if (familyData.isAo && familyData.isAu) userType = "AO + AU";
        else if (familyData.isAo && familyData.isSu) userType = "AO + SU";
        else if (familyData.isAu && familyData.isSu) userType = "AU + SU";
        else if (familyData.isAo) userType = "AO";
        else if (familyData.isAu) userType = "AU";
        else if (familyData.isSu) userType = "SU";

        localStorage.setItem("userType", userType);

        // Redirect after a slight delay
        setTimeout(() => {
          window.history.pushState(
            null,
            "",
            history.push(`/dashboard/${UserB}`)
          );
          window.onpopstate = function () {
            window.history.pushState(
              null,
              "",
              history.push(`/dashboard/${UserB}`)
            );
          };
        }, 750);
      }
    } catch (error) {
      console.error("Error during processing: ", error);
      setLoading(false); // Ensure loading state is reset if there is an error
    }
  };

  // Utility function to safely check for undefined or null
  const safeValue = (value) =>
    value === undefined || value == null ? "null" : value;

  const FHS = (event) => {
    if (event.target.value === "No") {
      setMainFn1(event.target.value);
    }
    setMainFn("");
    setNewVal2("No2");
    setstyle6("wizard-div-sub1-3-after");
    setStyle4("wizard-div-sub1-2");
    setMainValue(true);
    setShowInviteModel22(false);
    setShowInviteModel1(false);
    setFormValues1(initialValues1);
    setList1([]);
    setMainFn3("");
    setMainFn4("");
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 5
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 2
    ) {
      setMainValue(false);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length === 2
    ) {
      setMainValue(true);
      setShowInviteModel22(false);
      setShowInviteModel1(false);
      setFormValues1(initialValues1);
    }
  };
  const [newList, setNewList] = useState([]);
  const deleteAll = (event, key) => {
    setShowInviteModel11(false);
    setShowInviteModel(false);
    setFormValues(initialValues);
    const newList = [...list];
    newList.splice(key, 1);
    setList(newList);
    if ((NewRes4 === 2 || NewRes4 === 5) && newList.length < 2) {
      setMainValue(false);
      setStyle7("wizard-div-sub1-2");
      setStyle3("wizard-div-sub1-3");
      setSuThirdQ(true);
      setPFurther(false);
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList.length < 1) {
      setMainValue(false);
      setStyle7("wizard-div-sub1-2");
      setStyle3("wizard-div-sub1-3");
    } else if ((NewRes4 === 3 || NewRes4 === 6) && newList.length < 5) {
      setMainValue(false);
      setSuThirdQ(true);
      setPFurther(false);
      setStyle7("wizard-div-sub1-2");
      setStyle3("wizard-div-sub1-3");
    } else if ((NewRes4 !== 1 || NewRes4 !== 4) && newList.length === 0) {
      document.getElementById("welcomeNext1").style.display = "none";
      setMainValue(false);
      setStyle2("wizard-div-sub1-2");
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList.length === 0) {
      document.getElementById("welcomeNext1").style.display = "none";
      setMainValue(false);
      setStyle2("wizard-div-sub1-2");
    } else {
    }

    if ((NewRes4 !== 1 || NewRes4 !== 4) && newList.length === 0) {
      setNewVal("");
      document.getElementById("welcomeNext1").style.display = "none";
      setStyle2("wizard-div-sub1-2");
      setMainValue(false);
      setPFurther(false);
      setSuFourthQ(false);
      setSuThirdQ(false);
      setSuFifthQ(false);
    }

    if ((NewRes4 === 1 || NewRes4 === 4) && newList.length === 0) {
      setNewVal("");
      document.getElementById("welcomeNext1").style.display = "none";
      setStyle2("wizard-div-sub1-2");
      setMainValue(false);
    }
  };
  const [newList1, setNewList1] = useState([]);
  const deleteAll2 = (event, key) => {
    setShowInviteModel22(false);
    setShowInviteModel1(false);
    setFormValues1(initialValues1);
    const newList1 = [...list1];
    newList1.splice(key, 1);
    setList1(newList1);

    if ((NewRes4 === 2 || NewRes4 === 5) && newList1.length < 1) {
      setMainValue(false);
      if (mainList.is_pu === "false") {
        setMSU(false);
      } else {
        setMSU(true);
      }
      setThirdQ(true);
      setFourthQ(false);
      setStyle("wizard-div-sub1-2");
      setStyle1("wizard-div-sub1-3");
    } else if ((NewRes4 === 2 || NewRes4 === 5) && newList1.length === 1) {
      setMainValue(false);
      setMSU(true);
      setThirdQ(true);
      setStyle30("wizard-div-sub1-2");
      setStyle31("wizard-div-sub1-3");
      setFourthQ(true);
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList1.length < 0) {
      setMainValue(false);
      setMSU(true);
      setThirdQ(true);
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
      setNewVal111();
    } else if ((NewRes4 === 1 || NewRes4 === 4) && newList1.length === 0) {
      setMainValue(false);
      setThirdQ(true);
      setStyle8("wizard-div-sub1-2");
      setstyle5("wizard-div-sub1-3");
      setNewVal111();
      if (
        style === "wizard-div-sub1-2-after" &&
        style1 === "wizard-div-sub1-3"
      ) {
        setMSU(true);
      }
      if (
        style === "wizard-div-sub1-2" &&
        style1 === "wizard-div-sub1-3-after"
      ) {
        setMSU(false);
      }
    } else if ((NewRes4 === 3 || NewRes4 === 6) && newList1.length < 4) {
      setMainValue(false);
      setMSU(true);
      setFourthQ(true);
      setStyle30("wizard-div-sub1-2");
      setStyle31("wizard-div-sub1-3");
      setNewVal1("");
    } else if ((NewRes4 === 3 || NewRes4 === 6) && newList1.length === 4) {
      setMainValue(false);
      setMSU(true);
      setThirdQ(true);
      setStyle8("wizard-div-sub1-2-after");
      setFourthQ(true);
      setstyle5("wizard-div-sub1-3");
      setStyle30("wizard-div-sub1-2");
    } else if (newList1.length === 0) {
      setMainValue(false);
      if (mainList.is_pu === true) {
        setMSU(true);
      } else {
        setMSU(false);
      }
      setThirdQ(true);
      setStyle4("wizard-div-sub1-2");
    } else {
      setMainValue(true);
      setMSU(true);
      setThirdQ(false);
    }
    if (newList1.length === 0) {
      if (style1 === "wizard-div-sub1-3-after") {
        setThirdQ(false);
        setStyle("wizard-div-sub1-2");
        setStyle1("wizard-div-sub1-3-after");
        setShowInviteModel1(true);
      } else {
        setThirdQ(true);
        setStyle("wizard-div-sub1-2-after");
        setStyle1("wizard-div-sub1-3");
      }
      setStyle8("wizard-div-sub1-2");
      setStyle4("wizard-div-sub1-2");
      setMainValue(false);
      setFourthQ(false);
      setSecondQ(false);
      setNewVal2("");
      setNewVal1("");
      setNewVal111();
      setBtnState(false);
    }
  };

  const OpenWizardYes = (event) => {
    setValNew("yes");
    if (event.target.value === "33") {
      setMainFn5(event.target.value);
    }
    setMainFn6("");
    setStyle7("wizard-div-sub1-2-after");
    setStyle3("wizard-div-sub1-3");
    setShowInviteModel22(false);
    setShowInviteModel1(false);
    if (mainFn === "yes" || mainFn1 === "No") {
      setMainValue(false);
    }
    if (mainFn6 === "44") {
      setMainValue(true);
    }
    setNewVal2("");
    if ((NewRes4 === 2 || NewRes4 === 5) && list.length >= 2) {
      setShowInviteModel11(false);
      setShowInviteModel(false);
      setFormValues(initialValues);
    } else if ((NewRes4 === 1 || NewRes4 === 4) && list.length >= 1) {
      setShowInviteModel11(false);
      setShowInviteModel(false);
      setFormValues(initialValues);
    } else if ((NewRes4 === 3 || NewRes4 === 6) && list.length >= 5) {
      setShowInviteModel11(false);
      setShowInviteModel(false);
      setFormValues(initialValues);
    } else {
      setShowInviteModel11(true);
      setShowInviteModel(false);
      setSuFourthQ(false);
      setPFurther(false);
      setSuFifthQ(false);
      const updatedPU = [...list1];

      list1.map((b, key) => (
        <span className="wizard-SU-1" key={key}>
          {((updatedPU[key].pu_supported_by_ao = false), setList1(updatedPU))}
        </span>
      ));
    }
  };
  const [FinalVal, setFinalVal] = useState();
  useEffect(() => {
    if (mainList.is_pu === "true") {
      setFinalVal({
        is_pu: mainList.is_pu,
        primary: list1,
        support: list,
        family_uuid: UserB,
      });
    } else if (mainList.is_pu === "false") {
      setFinalVal({
        is_pu: mainList.is_pu,
        primary: list1,
        support: list,
        family_uuid: UserB,
      });
    } else {
      setFinalVal({
        is_pu: mainList.is_pu,
        primary: list1,
        support: list,
        family_uuid: UserB,
      });
    }
  }, []);

  const OpenWizard10 = (event) => {
    setError1("");
    setNewVal111("yes111");
    if (event.target.value === "11") {
      setMainFn3(event.target.value);
    }
    setMainFn4("");
    setStyle8("wizard-div-sub1-2-after");
    setstyle5("wizard-div-sub1-3");
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      list1.length >= 1
    ) {
      setShowInviteModel1(false);
      setShowInviteModel22(false);
      setMainValue(true);
      setMSU(true);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "true" &&
      list1.length >= 0
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length >= 4
    ) {
      setShowInviteModel1(false);
      setFormValues1(initialValues1);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "false" &&
      list1.length >= 5
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "false" &&
      list1.length >= 1
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "false" &&
      list1.length >= 2
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else {
      setShowInviteModel1(false);
      setShowInviteModel22(true);
      setMainValue(false);
      setMSU(true);
    }
  };

  const OpenWizard30 = (event) => {
    setMainUser1("11");
    setError1("");
    if (event.target.value === "11") {
      setMainFn3(event.target.value);
    }
    setMainFn4("");
    setStyle30("wizard-div-sub1-2-after");
    setStyle31("wizard-div-sub1-3");
    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "true" &&
      list1.length >= 1
    ) {
      setShowInviteModel1(false);
      setShowInviteModel22(false);
      setMainValue(true);
      setMSU(true);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "true" &&
      list1.length >= 0
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      list1.length >= 4
    ) {
      setShowInviteModel1(false);
      setFormValues1(initialValues1);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
    } else if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "false" &&
      list1.length >= 5
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 1 || NewRes4 === 4) &&
      mainList.is_pu === "false" &&
      list1.length >= 1
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      mainList.is_pu === "false" &&
      list1.length >= 2
    ) {
      setShowInviteModel1(false);
      setMainValue(true);
      setMSU(true);
      setShowInviteModel22(false);
      setFormValues1(initialValues1);
    } else {
      setShowInviteModel1(false);
      setShowInviteModel22(true);
      setMainValue(false);
      setMSU(true);
    }
  };

  useEffect(() => {
    window.history.forward();
  }, []);
  let ABC = {
    is_pu: mainList.is_pu,
    aoData: UserDetails,
    primary: list1,
    support: list,
    family_uuid: UserB,
  };
  const [show, setShow] = useState(true);
  const [showChecked, setShowChecked] = useState(false);
  const [checkedVal, setCheckedVal] = useState(false);
  const [arrVal, setArrVal] = useState(0);
  const checked = document.querySelectorAll('input[type="checkbox"]:checked');
  function MainOption() {
    setCheckL(checked.length);
    setMainTop(true);
    setSupportTop(false);
    setMSU(true);
    setShow(false);
    if (list1.length > 0) {
      setThirdQ(true);
      setStyle8("wizard-div-sub1-2-after");
    }
    if (
      style === "wizard-div-sub1-2-after" &&
      (NewRes4 !== 1 || NewRes4 !== 4)
    ) {
      setThirdQ(true);
    }

    if (style8 === "wizard-div-sub1-2-after" && list1.length === 0) {
      setShowInviteModel22(true);
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "true" &&
      style30 === "wizard-div-sub1-2-after" &&
      list1.length > 0 &&
      list1.length < 4
    ) {
      setShowInviteModel22(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      mainList.is_pu === "false" &&
      style30 === "wizard-div-sub1-2-after" &&
      list1.length > 0 &&
      list1.length < 5
    ) {
      setShowInviteModel22(true);
    }

    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      style8 === "wizard-div-sub1-2-after" &&
      list1.length > 0 &&
      list1.length < 4 &&
      mainList.is_pu === "true"
    ) {
      setFourthQ(true);
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      style8 === "wizard-div-sub1-2-after" &&
      list1.length > 0 &&
      list1.length < 5 &&
      mainList.is_pu === "false"
    ) {
      setFourthQ(true);
    }
    if (style30 === "wizard-div-sub1-2" && style31 === "wizard-div-sub1-3") {
      if (style === "wizard-div-sub1-2-after") {
        if (list1.length > 0) {
          setFourthQ(true);
          setThirdQ(true);
          setStyle8("wizard-div-sub1-2-after");
        } else {
          setFourthQ(false);
          if (NewRes4 === 1) {
            setThirdQ(false);
          } else if (NewRes4 === 4) {
            setThirdQ(false);
          } else {
            setThirdQ(true);
          }
          setStyle8("wizard-div-sub1-2");
        }
      }
    }

    if (
      mainList.is_pu === "true" &&
      (NewRes4 === 2 || NewRes4 === 5) &&
      list1.length === 1
    ) {
      setFourthQ(false);
    } else if (
      mainList.is_pu === "true" &&
      (NewRes4 === 1 || NewRes4 === 4) &&
      list1.length === 0
    ) {
      setFourthQ(false);
    } else if (
      mainList.is_pu === "true" &&
      (NewRes4 === 3 || NewRes4 === 6) &&
      list1.length === 4
    ) {
      setFourthQ(false);
    } else if (
      mainList.is_pu === "false" &&
      (NewRes4 === 3 || NewRes4 === 6) &&
      list1.length === 5
    ) {
      setFourthQ(false);
    } else if (
      mainList.is_pu === "false" &&
      (NewRes4 === 2 || NewRes4 === 5) &&
      list1.length === 2
    ) {
      setFourthQ(false);
    } else if (
      mainList.is_pu === "false" &&
      (NewRes4 === 1 || NewRes4 === 4) &&
      list1.length === 1
    ) {
      setFourthQ(false);
    } else if (list.length === 0 && style8 === "wizard-div-sub1-2") {
      setShowInviteModel1(false);
    } else if (list.length === 0 && style8 === "wizard-div-sub1-2-after") {
    }
    if (style5 === "wizard-div-sub1-3-after") {
      setFourthQ(false);
    }
    if (
      style1 === "wizard-div-sub1-3-after" &&
      style8 === "wizard-div-sub1-2-after" &&
      style30 === "wizard-div-sub1-2-after" &&
      NewRes4 === 2 &&
      list1.length === 1
    ) {
      setFourthQ(true);
      setShowInviteModel22(true);
    }

    if (
      style1 === "wizard-div-sub1-3-after" &&
      style8 === "wizard-div-sub1-2-after" &&
      NewRes4 === 2 &&
      list1.length === 1
    ) {
      setFourthQ(true);
    }

    if (
      style === "wizard-div-sub1-2-after" &&
      style8 === "wizard-div-sub1-2-after" &&
      NewRes4 === 2 &&
      list1.length === 0
    ) {
      setShowInviteModel22(true);
    }

    if (
      style1 === "wizard-div-sub1-3-after" &&
      style8 === "wizard-div-sub1-2-after" &&
      style30 === "wizard-div-sub1-2-after" &&
      NewRes4 === 5 &&
      list1.length === 1
    ) {
      setFourthQ(true);
      setShowInviteModel22(true);
    }

    if (
      style1 === "wizard-div-sub1-3-after" &&
      style8 === "wizard-div-sub1-2-after" &&
      NewRes4 === 5 &&
      list1.length === 1
    ) {
      setFourthQ(true);
    }

    setFirstQ(true);
    setSecondQ(true);

    setSuFirstQ(false);
    setSuSecondQ(false);
    setSuThirdQ(false);
    setSuFourthQ(false);
    setSuFifthQ(false);
    setShowInviteModel(false);
    setShowInviteModel11(false);
  }
  const isFound = list1.some((element) => {
    if (element.pu_supported_by_ao === true) {
      return true;
    }
    return false;
  });

  const SupportOption = () => {
    if (isFound === true) {
      setPFurther(true);
    } else {
      setPFurther(false);
    }
    setShowInviteModel1(false);
    setShowInviteModel22(false);
    setMainTop(false);
    setMSU(false);
    setShow(true);
    setSupportTop(true);
    setFirstQ(false);
    setSecondQ(false);
    setThirdQ(false);
    setFourthQ(false);
    if (mainList.is_pu === "true") {
      setSuFirstQ(true);
      setSuFourthQ(false);
    } else {
      setSuFirstQ(false);
      setSuFourthQ(true);
      if (style21 === "wizard-div-sub1-3-after") {
        setPFurther(true);
      }
    }
    if (
      style === "wizard-div-sub1-2-after" &&
      (NewRes4 === 1 || NewRes4 === 4) &&
      list.length > 0
    ) {
      setPFurther(true);
      setSuSecondQ(true);
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      NewRes4 === 2 &&
      list.length === 1
    ) {
      setSuThirdQ(true);
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      NewRes4 === 5 &&
      list.length === 1
    ) {
      setSuThirdQ(true);
    }

    if (
      style2 === "wizard-div-sub1-2" &&
      style10 === "wizard-div-sub1-3" &&
      style20 === "wizard-div-sub1-2-after"
    ) {
      setSuFourthQ(false);
    }

    if (
      style10 === "wizard-div-sub1-3-after" &&
      style21 === "wizard-div-sub1-3-after" &&
      pFurther === true
    ) {
      setSuFourthQ(true);
      setPFurther(true);
    }
    if (style20 === "wizard-div-sub1-2-after") {
      if (list1.length > 0) {
        if (isFound === true) {
          if (style10 === "wizard-div-sub1-3-after") {
            setSuThirdQ(false);
            setPFurther(true);
            setSuFifthQ(true);
            setSuFourthQ(true);
            setSuSecondQ(true);
          } else {
            if (style1 === "wizard-div-sub1-3-after") {
              setSuThirdQ(false);
              setPFurther(true);
              setSuFifthQ(true);
              setSuFourthQ(true);
              setSuSecondQ(true);
            } else {
              setSuThirdQ(true);
              setPFurther(true);
              setSuFifthQ(true);
              setSuFourthQ(true);
              setSuSecondQ(true);
            }
          }
          if (
            style2 === "wizard-div-sub1-2-after" &&
            list.length > 0 &&
            style3 === "wizard-div-sub1-3-after" &&
            style20 === "wizard-div-sub1-2-after"
          ) {
            setPFurther(true);
          }
          if (
            style2 === "wizard-div-sub1-2-after" &&
            list.length === 5 &&
            style20 === "wizard-div-sub1-2-after"
          ) {
            setPFurther(true);
          }
        } else {
          if (
            mainList.is_pu === "false" &&
            style20 === "wizard-div-sub1-2-after"
          ) {
            setSuThirdQ(false);
            setSuFourthQ(true);
            setSuFifthQ(true);
            setPFurther(false);
          }
          if (mainList.is_pu === "true") {
            if (
              style2 === "wizard-div-sub1-2" &&
              style10 === "wizard-div-sub1-3" &&
              style7 === "wizard-div-sub1-2" &&
              style3 === "wizard-div-sub1-3"
            ) {
              setSuFourthQ(false);
              setSuFifthQ(false);
              setSuSecondQ(false);
              setSuThirdQ(false);
              setPFurther(false);
            } else {
              setPFurther(false);
              if (style10 === "wizard-div-sub1-3-after") {
                setSuThirdQ(false);
              } else {
                setSuThirdQ(true);
              }
              setSuFourthQ(true);
              setSuFifthQ(true);
              setSuSecondQ(true);
            }
          }
        }
      } else {
        if (NewRes4 === 1 || NewRes4 === 4) {
          setPFurther(false);
          setSuSecondQ(true);
          setSuFifthQ(false);
          setSuFourthQ(false);
        } else if (
          style2 === "wizard-div-sub1-2" &&
          style10 === "wizard-div-sub1-3"
        ) {
          setPFurther(false);
          setSuSecondQ(true);
          setSuFifthQ(false);
          setSuFourthQ(false);
        } else {
          if (
            (NewRes4 === 3 || NewRes4 === 6) &&
            list.length < 5 &&
            list.length > 0
          ) {
            setPFurther(true);
            setSuThirdQ(true);
            setSuSecondQ(true);
            setSuFifthQ(false);
            setSuFourthQ(false);
          }
          if (
            (NewRes4 === 3 || NewRes4 === 6) &&
            (list.length === 5 || list.length === 0)
          ) {
            setPFurther(true);
            setSuThirdQ(false);
            setSuSecondQ(true);
            setSuFifthQ(false);
            setSuFourthQ(false);
          }
          if ((NewRes4 === 2 || NewRes4 === 5) && list.length === 1) {
            setPFurther(true);
            setSuThirdQ(true);
            setSuSecondQ(true);
            setSuFifthQ(false);
            setSuFourthQ(false);
          }
          if (
            (NewRes4 === 2 || NewRes4 === 5) &&
            (list.length === 2 || list.length === 0)
          ) {
            setPFurther(true);
            setSuThirdQ(false);
            setSuSecondQ(true);
            setSuFifthQ(false);
            setSuFourthQ(false);
          }
        }
      }
    }

    if (style2 === "wizard-div-sub1-2-after" && list.length === 0) {
      setShowInviteModel(true);
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      list.length < 2 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 2 || NewRes4 === 5)
    ) {
      setSuSecondQ(true);

      if (list1.length > 0) {
        if (isFound === true) {
          setPFurther(true);
          setSuFourthQ(true);
        } else {
          setPFurther(false);
          setSuFourthQ(false);
        }
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length === 2 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 2 || NewRes4 === 5)
    ) {
      setSuThirdQ(false);
      if (list1.length > 0) {
        setSuSecondQ(true);
        setSuFourthQ(false);
        setPFurther(true);
      } else {
        setSuSecondQ(true);
        setSuFourthQ(false);
        setPFurther(true);
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 3 || NewRes4 === 6)
    ) {
      setSuSecondQ(true);
      if (list.length === 5) {
        setSuThirdQ(false);
      } else {
        setSuThirdQ(true);
      }

      if (isFound === true) {
        setPFurther(true);
      } else {
        setPFurther(false);
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length > 0 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 1 || NewRes4 === 4) &&
      style1 === "wizard-div-sub1-3-after"
    ) {
      setSuSecondQ(true);
      setSuFourthQ(false);
      if (isFound === true) {
        setPFurther(true);
      } else {
        setPFurther(false);
      }
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length === 2 &&
      style21 === "wizard-div-sub1-3-after"
    ) {
      if (
        style5 === "wizard-div-sub1-3" &&
        style8 === "wizard-div-sub1-2-after"
      ) {
        setSuSecondQ(true);
        setSuFourthQ(true);
        setPFurther(true);
      }
      if (
        style5 === "wizard-div-sub1-3-after" &&
        style8 === "wizard-div-sub1-2"
      )
        setSuSecondQ(true);
      setSuFourthQ(true);
      setPFurther(true);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      style2 === "wizard-div-sub1-2-after" &&
      list.length === 1 &&
      style21 === "wizard-div-sub1-3-after" &&
      style3 === "wizard-div-sub1-3-after"
    ) {
      setSuSecondQ(true);
      if (list1.length > 0) {
        setSuFourthQ(true);
      } else {
        setSuFourthQ(false);
      }
      setPFurther(true);
      setSuThirdQ(true);
    }
    if (
      style2 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      list.length === 1
    ) {
      setSuThirdQ(true);
      if (style21 === "wizard-div-sub1-3-after") {
        setPFurther(true);
        if (list1.length > 0) {
          setSuFourthQ(true);
        } else {
          setSuFourthQ(false);
        }
      } else {
        if (
          list1.length === 0 &&
          style8 === "wizard-div-sub1-2" &&
          style5 === "wizard-div-sub1-3"
        ) {
          setPFurther(true);
          setSuFourthQ(false);
        } else {
          if (list1.length > 0) {
            setSuFourthQ(true);
            if (isFound === true) {
              setPFurther(true);
            } else {
              setPFurther(false);
            }
          } else {
            setSuFourthQ(false);
          }
        }
      }
      setSuSecondQ(true);
      if (
        style20 === "wizard-div-sub1-2" &&
        style21 === "wizard-div-sub1-3" &&
        style5 === "wizard-div-sub1-3-after"
      ) {
        setSuFourthQ(false);
        setPFurther(true);
      }
      if (
        style20 === "wizard-div-sub1-2" &&
        style21 === "wizard-div-sub1-3" &&
        style8 === "wizard-div-sub1-2-after"
      ) {
        setSuFourthQ(true);
        setPFurther(false);
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      list.length > 1
    ) {
      setSuThirdQ(true);
      if (style21 === "wizard-div-sub1-3-after") {
        setPFurther(true);
        if (list1.length > 0) {
          setSuFourthQ(true);
        } else {
          setSuFourthQ(false);
        }
      } else {
        if (
          list1.length === 0 &&
          style8 === "wizard-div-sub1-2" &&
          style5 === "wizard-div-sub1-3"
        ) {
          setPFurther(true);
          setSuFourthQ(false);
        } else {
          if (style5 === "wizard-div-sub1-3-after") {
            setSuFourthQ(false);
            setPFurther(true);
          } else {
            setSuFourthQ(true);
            setPFurther(false);
          }
        }
      }
      setSuSecondQ(true);
      if (
        style20 === "wizard-div-sub1-2" &&
        style21 === "wizard-div-sub1-3" &&
        style5 === "wizard-div-sub1-3-after"
      ) {
        setSuFourthQ(false);
        setPFurther(true);
      }
      if (
        style20 === "wizard-div-sub1-2" &&
        style21 === "wizard-div-sub1-3" &&
        style8 === "wizard-div-sub1-2-after"
      ) {
        setSuFourthQ(true);
        setPFurther(false);
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      style7 === "wizard-div-sub1-2-after" &&
      list.length === 1
    ) {
      setSuThirdQ(true);
      setShowInviteModel11(true);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      style2 === "wizard-div-sub1-2-after" &&
      style3 === "wizard-div-sub1-3-after" &&
      style20 === "wizard-div-sub1-2-after" &&
      list.length === 1
    ) {
      if (list1.length > 0) {
        if (isFound === true) {
          setSuThirdQ(true);
          setPFurther(true);
          setSuFifthQ(true);
          setSuFourthQ(true);
        } else {
          setSuThirdQ(true);
          setPFurther(false);
          setSuFifthQ(true);
          setSuFourthQ(true);
        }
      } else {
        setSuThirdQ(true);
        setSuFourthQ(false);
        setPFurther(true);
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length === 5 &&
      (NewRes4 === 3 || NewRes4 === 6)
    ) {
      if (list1.length > 0) {
        setPFurther(true);
        setSuSecondQ(true);
        setSuFourthQ(false);
        setSuFifthQ(false);
      } else {
        setSuFourthQ(false);
        setPFurther(true);
        setSuSecondQ(true);
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length === 2 &&
      (NewRes4 === 2 || NewRes4 === 5) &&
      style20 === "wizard-div-sub1-2" &&
      style21 === "wizard-div-sub1-3"
    ) {
      if (list1.length > 0) {
        if (isFound === true) {
          setPFurther(true);
          setSuFourthQ(true);
          setSuSecondQ(true);
        } else {
          setPFurther(true);
          setSuFourthQ(false);
          setSuSecondQ(true);
        }
      } else {
        setSuFourthQ(false);
        setPFurther(true);
        setSuSecondQ(true);
      }
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length === 1 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      NewRes4 !== 1 &&
      NewRes4 !== 4
    ) {
      setSuThirdQ(true);
      setPFurther(false);
    }

    if (
      style2 === "wizard-div-sub1-2-after" &&
      list.length === 1 &&
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      style20 === "wizard-div-sub1-2" &&
      style21 === "wizard-div-sub1-3-after"
    ) {
      setSuThirdQ(true);
      setPFurther(false);
      setSuFourthQ(true);
    }

    if (
      style10 === "wizard-div-sub1-3-after" &&
      style20 === "wizard-div-sub1-2" &&
      style21 === "wizard-div-sub1-3" &&
      list1.length > 0
    ) {
      setSuFourthQ(true);
      setPFurther(false);
    }
    if (
      style10 === "wizard-div-sub1-3-after" &&
      (style20 === "wizard-div-sub1-2" || style21 === "wizard-div-sub1-3") &&
      list1.length === 0
    ) {
      setSuFourthQ(false);
      setPFurther(true);
    }

    if (
      (NewRes4 === 2 || NewRes4 === 5) &&
      list.length === 2 &&
      (style20 === "wizard-div-sub1-2" || style21 === "wizard-div-sub1-3") &&
      pFurther === false
    ) {
      if (list1.length > 0) {
        if (isFound === true) {
          setPFurther(true);
          setSuFourthQ(false);
        } else {
          setPFurther(true);
          setSuFourthQ(false);
        }
      } else {
        setSuFourthQ(false);
        setPFurther(true);
      }
    }
    if (
      (NewRes4 === 3 || NewRes4 === 6) &&
      list.length === 5 &&
      (style20 === "wizard-div-sub1-2" || style21 === "wizard-div-sub1-3")
    ) {
      if (list1.length > 0) {
        setPFurther(true);
        setSuFourthQ(false);
      } else {
        setSuFourthQ(false);
        setPFurther(true);
      }
    }
  };
  const FinalOption = () => {
    setMainTab(false);
    setSupportTab(false);
    setMainTop(false);
    setMainContent(false);
    setSupportContent(false);
    setSupportTop(false);
    setMSU(false);
    setPFurther(false);
    setLastStep(true);
    setResetBtn(true);
    setMainShow(false);
    setSkipBtn(false);
  };
  const ResetBtn = () => {
    window.location.reload();
  };
  const SU1Yes = () => {
    setSUVal1("yes");
    setSUVal2("");
    setStyle20("wizard-div-sub1-2-after");
    setStyle21("wizard-div-sub1-3");
    setSuFifthQ(true);
    setPFurther(false);
  };

  const SU1No = (event, key) => {
    setSUVal2("no");
    setSUVal1("");
    setStyle20("wizard-div-sub1-2");
    setStyle21("wizard-div-sub1-3-after");
    setSuFifthQ(false);
    if (
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 2 || NewRes4 === 5) &&
      list.length < 2 &&
      style10 === "wizard-div-sub1-3-after"
    ) {
      setPFurther(true);
    } else if (
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 2 || NewRes4 === 5) &&
      list.length < 2 &&
      list.length > 0 &&
      style2 === "wizard-div-sub1-2-after"
    ) {
      setPFurther(false);
    } else if (
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 3 || NewRes4 === 6) &&
      list.length < 5 &&
      style10 === "wizard-div-sub1-3-after"
    ) {
      setPFurther(true);
    } else if (
      style7 === "wizard-div-sub1-2" &&
      style3 === "wizard-div-sub1-3" &&
      (NewRes4 === 3 || NewRes4 === 6) &&
      list.length < 5 &&
      list.length > 0 &&
      style2 === "wizard-div-sub1-2-after"
    ) {
      setPFurther(false);
    } else {
      setPFurther(true);
    }
    const updatedPU = [...list1];
    {
      list1.map((b, key) => (
        <span className="wizard-SU-1" key={key}>
          {((updatedPU[key].pu_supported_by_ao = false), setList1(updatedPU))}
        </span>
      ));
    }
  };

  const newBtnWZ = (event, key) => {
    setArrVal(
      document.querySelectorAll('input[type="checkbox"]:checked').length
    );
    if (
      document.querySelectorAll('input[type="checkbox"]:checked').length > 0 &&
      style7 === "wizard-div-sub1-2-after"
    ) {
      setPFurther(true);
    } else if (
      document.querySelectorAll('input[type="checkbox"]:checked').length > 0 &&
      style3 === "wizard-div-sub1-3-after"
    ) {
      setPFurther(true);
    } else if (
      document.querySelectorAll('input[type="checkbox"]:checked').length > 0 &&
      (NewRes4 === 2 || NewRes4 === 5) &&
      list.length === 2
    ) {
      setPFurther(true);
    } else if (
      document.querySelectorAll('input[type="checkbox"]:checked').length > 0 &&
      (NewRes4 === 3 || NewRes4 === 6) &&
      list.length === 5
    ) {
      setPFurther(true);
    } else if (
      document.querySelectorAll('input[type="checkbox"]:checked').length > 0 &&
      mainList.is_pu === "false" &&
      style20 === "wizard-div-sub1-2-after"
    ) {
      setPFurther(true);
    } else if (
      document.querySelectorAll('input[type="checkbox"]:checked').length > 0 &&
      style10 === "wizard-div-sub1-3-after" &&
      style20 === "wizard-div-sub1-2-after"
    ) {
      setPFurther(true);
    } else {
      setPFurther(false);
    }
    if (event.target.checked === true) {
      setBtnState(true);
      setCheckedVal(true);
      setShowChecked(true);
      const updatedPU = [...list1];
      updatedPU[key].pu_supported_by_ao = true;
      setList1(updatedPU);
    } else {
      setBtnState(false);
      setCheckedVal(false);
      setShowChecked(false);
      const updatedPU = [...list1];
      updatedPU[key].pu_supported_by_ao = false;
      setList1(updatedPU);
    }
  };

  if (props.location.key) {
    return (
      <Container ref={myRef} className="mt-7">
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        <Row className="justify-content-center gy-0 mb-6rem">
          <Col xl="21" xxl="18">
            <div className="text-center confrm-div">
              <Image
                className="hello-text-img new-img mb-7"
                src={EL_logo}
                alt="Check mark"
              />
            </div>
            <section
              className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4"
              style={{ minHeight: "24rem" }}
            >
              {mainTop && <h2 className="mb-3">Main users</h2>}
              {supportTop && <h2 className="mb-3">Support users</h2>}
              {mainTop && (
                <>
                  <div className="">
                    Main Users will have access to the complete platform and all
                    the features. They are users the platform is intended for
                    and will house all their information.
                  </div>
                  <hr className="mt-2 mb-4"></hr>
                </>
              )}
              {supportTop && (
                <div>
                  <div className="">
                    Support Users will have access to the main user’s platform.
                    They will be able to add details to outgoings, warranties,
                    gift cards and vouchers on behalf of the main user. This
                    will ensure the main user always has someone to support
                    them.
                  </div>
                  <hr className="mt-2 mb-4"></hr>
                </div>
              )}
              {lastStep && (
                <div>
                  <h2 className="mb-3 ">You're all set !!!</h2>
                  <div className="">
                    You are now ready to launch the Elderly Life Platform. All
                    users will have to accept the Elderly Life email invitations
                    before they can start using the platform. As the Admin you
                    can always add new users on the platform through the main
                    dashboard.
                  </div>
                  <hr className="mt-2 mb-4"></hr>
                </div>
              )}

              {/* Main content starts from here  */}
              {mainContent && (
                <div style={{}}>
                  <div className="wizard-main-div">
                    {firstQ && (
                      <div className="wizard-div-sub mb-6">
                        <div className="wizard-div-sub1-1 mb-2">
                          <div className="fw-semibold mb-1">
                            Have you purchased this for yourself?
                          </div>
                          <div className="">
                            Based on your account plan you can add upto{" "}
                            {NewRes4 === 2 || NewRes4 === 5 ? (
                              <span> 2 Main Users</span>
                            ) : NewRes4 === 1 || NewRes4 === 4 ? (
                              <span> 1 Main User</span>
                            ) : (
                              <span> 5 Main Users</span>
                            )}{" "}
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <button
                            className={`btn w-12 ${
                              newVal1 === "yes1" ? "btn-primary" : "btn-white"
                            }`}
                            value="true"
                            onClick={(event) => OpenWizard(event)}
                            // disabled={newVal1 === "yes1" && true}
                          >
                            Yes
                          </button>
                          <button
                            className={`btn w-12 ${
                              newVal1 === "No1" ? "btn-primary" : "btn-white"
                            }`}
                            value="false"
                            onClick={(event) => OpenWizard1(event)}
                            // disabled={newVal1 === "No1" && true}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    )}
                    {suFirstQ && (
                      <div style={{}}>
                        <div className="wizard-div-sub mb-6">
                          <div className="wizard-div-sub1-1 mb-2">
                            <div className="fw-semibold mb-1">
                              Will you need a support user to assist you?
                            </div>
                            <div className="">
                              Based on your account plan you can add upto{" "}
                              {NewRes4 === 2 || NewRes4 === 5 ? (
                                <span> 2 Support users</span>
                              ) : NewRes4 === 1 || NewRes4 === 4 ? (
                                <span> 1 Support user</span>
                              ) : (
                                <span> 5 Support users</span>
                              )}{" "}
                            </div>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <button
                              className={`btn w-12 ${
                                newVal === "yes" ? "btn-primary" : "btn-white"
                              }`}
                              onClick={(event) => modelOpen(event)}
                              // disabled={newVal === "yes" && true}
                            >
                              Yes
                            </button>
                            <button
                              className={`btn w-12 ${
                                newVal === "No" ? "btn-primary" : "btn-white"
                              }`}
                              onClick={(event) => modelOpen11(event)}
                              // disabled={newVal === "No" && true}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {suSecondQ && (
                      <div className="wizard-Container" style={{}}>
                        {list.map((a, key) => (
                          <div className="wizard-SU" key={key}>
                            <div className="Wizard-SU-dsn">
                              <div className="PU-dsn-sub">
                                <div>
                                  <div className="">{a.SU_email}</div>
                                </div>
                              </div>
                            </div>
                            <Image
                              className="m-1 vertical-dot-icon"
                              src={Layer}
                              alt="Layer"
                              onClick={(event) => deleteAll(event, key)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {showInviteModel && (
                      <div className="wizardEmail">
                        <form className="invite-form" onSubmit={handleSubmit}>
                          <Row className="g-2 align-items-center">
                            <Col className="m-0">
                              <input
                                type="email"
                                placeholder="Enter the email of the user you would like to add in the group"
                                name="SU_email"
                                value={formValues.SU_email}
                                onChange={handleChange}
                                // required
                                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                title="This is not a valid email format ! "
                                className={`form-control m-0 ${
                                  error1 && "border-reddanger"
                                }`}
                              />
                            </Col>
                            <Col xs="auto" className="m-0">
                              <button className="btn btn-primary" type="submit">
                                Add Support User
                              </button>
                            </Col>
                          </Row>
                          {error1 && (
                            <div className="mt-2 text-danger fs-14">
                              {error1}
                            </div>
                          )}
                        </form>
                      </div>
                    )}
                    {suThirdQ && (
                      <div style={{}}>
                        <div className="wizard-div-sub mb-6">
                          <div className="wizard-div-sub1-1 mb-2">
                            <div className="mb-1 fw-semibold mt-7">
                              Would you need another Support user to assist you?
                            </div>
                            <div className="">
                              You still have{" "}
                              {NewRes4 === 2 || NewRes4 === 5
                                ? 2 - list.length
                                : NewRes4 === 1 || NewRes4 === 4
                                ? 1 - list.length
                                : 5 - list.length}{" "}
                              Support users you can add
                            </div>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            {" "}
                            <button
                              className={`btn w-12 ${
                                valNew === "yes" ? "btn-primary" : "btn-white"
                              }`}
                              value="33"
                              onClick={(event) => OpenWizardYes(event)}
                            >
                              Yes
                            </button>
                            <button
                              className={`btn w-12 ${
                                valNew === "No" ? "btn-primary" : "btn-white"
                              }`}
                              value="44"
                              onClick={(event) => OpenWizard3(event)}
                              // disabled={valNew === "No" && true}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {showInviteModel11 && (
                      <div className="wizardEmail">
                        <form className="invite-form" onSubmit={handleSubmit}>
                          <Row className="g-2 align-items-center">
                            <Col className="m-0">
                              <input
                                type="email"
                                placeholder="Enter the email of the user you would like to add in the group"
                                name="SU_email"
                                value={formValues.SU_email}
                                onChange={handleChange}
                                // required
                                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                title="This is not a valid email format ! "
                                className={`form-control m-0 ${
                                  error1 && "border-reddanger"
                                }`}
                              />
                            </Col>
                            <Col xs="auto " className="m-0">
                              <button className="btn btn-primary" type="submit">
                                Add Support user
                              </button>
                            </Col>
                          </Row>
                          {error1 && (
                            <div className="mt-2 text-danger fs-14">
                              {error1}
                            </div>
                          )}
                        </form>
                      </div>
                    )}
                    {suFourthQ && (
                      <div style={{}}>
                        <div className="wizard-div-sub mb-6">
                          <div className="wizard-div-sub1-1 mb-2">
                            <div className="fw-semibold mb-1">
                              Would you like to be a Support user yourself?
                            </div>
                            <div className="">
                              Supporting the Main users added in the previous
                              step
                            </div>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <button
                              className={`btn w-12 ${
                                sUVal1 === "yes" ? "btn-primary" : "btn-white"
                              }`}
                              value=""
                              onClick={() => SU1Yes()}
                              // disabled={sUVal1 === "yes" && true}
                            >
                              Yes
                            </button>
                            <button
                              className={`btn w-12 ${
                                sUVal2 === "no" ? "btn-primary" : "btn-white"
                              }`}
                              value=""
                              onClick={(event) => SU1No(event)}
                              // disabled={sUVal2 === "no" && true}
                            >
                              No
                            </button>
                          </div>
                        </div>{" "}
                      </div>
                    )}
                    {suFifthQ && (
                      <div style={{}}>
                        <div className="wizard-div-sub mb-6">
                          <div className="wizard-div-sub1-1 mb-2">
                            <div className="fw-semibold mb-1">
                              Select the Main users listed below that you would
                              like to support
                            </div>
                            <div className="">
                              <form>
                                {list1.map((b, key) => (
                                  <div
                                    className="wizard-SU justify-content-start"
                                    key={key}
                                  >
                                    <div className="d-flex me-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        className="newBtnWZ cursor-pointer"
                                        id="keyValue"
                                        onClick={(event) =>
                                          newBtnWZ(event, key)
                                        }
                                        checked={
                                          b.pu_supported_by_ao === true
                                            ? true
                                            : false
                                        }
                                      ></input>
                                    </div>
                                    <div>
                                      <div className="main-dsgn-text">
                                        <div className="PU-dsn-txt">
                                          {b.PU_firstname} {b.PU_lastname}
                                        </div>
                                      </div>
                                      <div className="">{b.PU_email}</div>
                                    </div>
                                  </div>
                                ))}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{ display: "none" }} id="welcomeNext1">
                      <div className="wizard-div-sub">
                        <div className="wizard-div-sub1-1">
                          <div style={{ "font-size": "16px" }}>
                            Would you like to add a Main user now?
                          </div>
                          <div
                            style={{
                              "font-size": "13px",
                              "font-weight": "200",
                            }}
                          >
                            You can add{" "}
                            {mainList.is_pu === "true" &&
                            (NewRes4 === 2 || NewRes4 === 5) ? (
                              <span>1</span>
                            ) : mainList.is_pu === "true" &&
                              (NewRes4 === 1 || NewRes4 === 4) ? (
                              <span>0</span>
                            ) : mainList.is_pu === "true" &&
                              (NewRes4 === 3 || NewRes4 === 6) ? (
                              <span>4</span>
                            ) : mainList.is_pu === "false" &&
                              (NewRes4 === 2 || NewRes4 === 5) ? (
                              <span>2</span>
                            ) : mainList.is_pu === "false" &&
                              (NewRes4 === 1 || NewRes4 === 4) ? (
                              <span>1</span>
                            ) : (
                              <span>5</span>
                            )}{" "}
                            more Main users
                          </div>
                        </div>
                        <div>
                          <button
                            className={`btn w-12 ${
                              newVal2 === "yes2" ? "btn-primary" : "btn-white"
                            }`}
                            value="yes"
                            onClick={(event) => modelOpen1(event)}
                            // disabled={newVal2 === "yes2" && true}
                          >
                            Yes
                          </button>
                          <button
                            className={`btn w-12 ${
                              newVal2 === "No2" ? "btn-primary" : "btn-white"
                            }`}
                            value="No"
                            onClick={(event) => FHS(event)}
                            // disabled={newVal2 === "No2" && true}
                          >
                            No
                          </button>
                        </div>
                      </div>
                    </div>

                    {showInviteModel1 && (
                      <div className="wizardEmail email-add">
                        <form className="invite-form" onSubmit={handleSubmit1}>
                          <Row className="g-2 align-items-center">
                            <Col className="m-0">
                              <input
                                type="email"
                                placeholder="Enter the email of the user you would like to add in the group"
                                name="PU_email"
                                value={formValues1.PU_email}
                                onChange={handleChange1}
                                // required
                                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                title="This is not a valid email format ! "
                                className={`form-control m-0 ${
                                  error1 && "border-reddanger"
                                }`}
                              />
                            </Col>
                            <Col xs="auto" className="m-0">
                              <button className="btn btn-primary" type="submit">
                                Add Main user
                              </button>
                            </Col>
                          </Row>
                          {error1 && (
                            <div className="mt-2 text-danger fs-14">
                              {error1}
                            </div>
                          )}
                        </form>
                      </div>
                    )}
                    {thirdQ && (
                      <div style={{}}>
                        <div className="wizard-div-sub mb-6">
                          <div className="wizard-div-sub1-1 mb-2">
                            <div className="mb-1 fw-semibold mt-7">
                              {" "}
                              Would you like to add another Main User?
                            </div>
                            <div className="">
                              Based on your account plan{" "}
                              {mainList.is_pu === "true" &&
                              (NewRes4 === 2 || NewRes4 === 5) ? (
                                <span>
                                  you still have 1 Main Users you can add
                                </span>
                              ) : mainList.is_pu === "true" &&
                                (NewRes4 === 1 || NewRes4 === 4) ? (
                                <span>
                                  you still have 0 Main Users you can add
                                </span>
                              ) : mainList.is_pu === "true" &&
                                (NewRes4 === 3 || NewRes4 === 6) ? (
                                <span>
                                  you still have 4 Main Users you can add
                                </span>
                              ) : mainList.is_pu === "false" &&
                                (NewRes4 === 2 || NewRes4 === 5) ? (
                                <span>you can add upto 2 Main Users </span>
                              ) : mainList.is_pu === "false" &&
                                (NewRes4 === 1 || NewRes4 === 4) ? (
                                <span>you can add upto 1 Main Users </span>
                              ) : (
                                <span>you can add upto 5 Main Users </span>
                              )}{" "}
                            </div>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <button
                              className={`btn w-12 ${
                                newVal111 === "yes111"
                                  ? "btn-primary"
                                  : "btn-white"
                              }`}
                              onClick={(event) => OpenWizard10(event)}
                              value="11"
                              // disabled={newVal111 === "yes111" && true}
                            >
                              Yes
                            </button>
                            <button
                              className={`btn w-12 ${
                                newVal111 === "no111"
                                  ? "btn-primary"
                                  : "btn-white"
                              }`}
                              onClick={(event) => OpenWizard5(event)}
                              value="22"
                              // disabled={newVal111 === "no111" && true}
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {secondQ && (
                      <div className="wizard-Container" style={{}}>
                        {list1.map((b, key) => (
                          <div className="wizard-SU" key={key}>
                            <div className="Wizard-SU-dsn">
                              <div className="PU-dsn-sub">
                                <div>
                                  <div className="">{b.PU_email}</div>
                                </div>
                              </div>
                            </div>
                            <Image
                              className="m-1 vertical-dot-icon"
                              src={Layer}
                              alt="Layer"
                              onClick={(event) => deleteAll2(event, key)}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    {fourthQ && (
                      <div style={{}}>
                        <div className="wizard-div-sub mb-6">
                          <div className="wizard-div-sub1-1 mb-2">
                            <div className="mb-1 fw-semibold mt-7">
                              {" "}
                              Would you like to add another Main User?
                            </div>
                            <div className="">
                              Based on your account plan You still have{" "}
                              {mainList.is_pu === "true" &&
                              (NewRes4 === 2 || NewRes4 === 5)
                                ? 1 - list1.length
                                : mainList.is_pu === "true" &&
                                  (NewRes4 === 1 || NewRes4 === 4)
                                ? 0 - list1.length
                                : mainList.is_pu === "true" &&
                                  (NewRes4 === 3 || NewRes4 === 6)
                                ? 4 - list1.length
                                : mainList.is_pu === "false" &&
                                  (NewRes4 === 2 || NewRes4 === 5)
                                ? 2 - list1.length
                                : mainList.is_pu === "false" &&
                                  (NewRes4 === 1 || NewRes4 === 4)
                                ? 1 - list1.length
                                : 5 - list1.length}{" "}
                              Main Users you can add
                            </div>
                          </div>
                          <div className="d-flex gap-3 align-items-center">
                            <button
                              className={`btn w-12 ${
                                mainUser1 === "11" ? "btn-primary" : "btn-white"
                              }`}
                              onClick={(event) => OpenWizard30(event)}
                              value="11"
                            >
                              Yes
                            </button>
                            <button
                              className={`btn w-12 ${
                                mainUser1 === "22" ? "btn-primary" : "btn-white"
                              }`}
                              onClick={(event) => OpenWizard31(event)}
                              value="22"
                            >
                              No
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {showInviteModel22 && (
                      <div className="wizardEmail">
                        <form className="invite-form" onSubmit={handleSubmit1}>
                          <Row className="g-2 align-items-center">
                            <Col className="m-0">
                              <input
                                type="email"
                                placeholder="Enter the email of the user you would like to add in the group"
                                name="PU_email"
                                value={formValues1.PU_email}
                                onChange={handleChange1}
                                // required
                                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                                title="This is not a valid email format ! "
                                className={`form-control m-0 ${
                                  error1 && "border-reddanger"
                                }`}
                              />
                            </Col>
                            <Col xs="auto" className="m-0">
                              <button className="btn btn-primary" type="submit">
                                Add Main user
                              </button>
                            </Col>
                          </Row>
                          {error1 && (
                            <div className="mt-2 text-danger fs-14">
                              {error1}
                            </div>
                          )}
                        </form>
                      </div>
                    )}
                  </div>
                  <p></p>
                </div>
              )}
              {mSU && (
                <button
                  className="btn btn-primary mt-7 d-flex"
                  style={{ margin: "auto 0 auto auto" }}
                  onClick={SupportOption}
                >
                  Next: Add Support Users
                </button>
              )}
              {supportTop && (
                <div className="d-flex align-items-center justify-content-between mt-7">
                  {
                    <button
                      className="btn btn-primary d-flex align-items-center gap-2 "
                      onClick={MainOption}
                    >
                      <i className="fa-solid fa-arrow-left text-dark"></i>
                      <div>Go to previous step</div>
                    </button>
                  }
                  {pFurther && (
                    <button
                      className="btn btn-primary"
                      onClick={FinalOption}
                      style={{ display: show ? "inline" : "none" }}
                      id="pFur"
                    >
                      Proceed further
                    </button>
                  )}
                </div>
              )}
              {lastStep && (
                <div className="d-flex align-items-center justify-content-between mt-7">
                  {resetBtn && (
                    <div>
                      <span
                        onClick={() => ResetBtn()}
                        href="aaa"
                        style={{
                          color: "#007EF2",
                          cursor: "pointer",
                          "text-decoration": "underline",
                        }}
                      >
                        Reset and Start Over
                      </span>{" "}
                    </div>
                  )}

                  {
                    <button
                      className="btn btn-primary"
                      onClick={FinalValue}
                      disabled={loading && true}
                    >
                      Let's begin{" "}
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                    </button>
                  }
                </div>
              )}
            </section>
          </Col>
        </Row>
      </Container>
    );
  } else {
    window.location.href = "/404";
  }
};
export default FamilyCreationConfirmation;
