/*---------------------------
 * All route are here
 *--------------------------*/

import Dashboard from "../components/templates/Dashboard";
import SignIn from "../components/Login/SignIn";
import SignUp from "../components/Login/SignUp";
import ResetPassword from "../components/Login/ResetPassword";
import PublicLayout from "../components/Layout/PublicLayout";
import AuthLayout from "../components/Layout/AuthLayout";
import Profile from "../components/templates/Profile";
import Subscription from "../components/templates/Subscription";
import AccountSubscriptions from "../components/templates/AccountSubscriptions";
import AccountDowngradeSubscription from "../components/templates/AccountDowngradeSubscription";
import AccountDowngradeSubscriptionNewImplementation from "../components/templates/AccountDowngradeSubscriptionNewImplementation";
import FamilyCreationConfirmation from "../components/templates/FamilyCreationConfirmation";
import WizardPage from "../components/templates/WizardPage";
import FamilyUpdateConfirmation from "../components/templates/FamilyUpdateConfirmation";
import CheckoutForm from "../components/templates/CheckoutForm";
import FamilyDashboard from "../components/templates/FamilyDashboard";
import InviteHistory from "../components/templates/InviteHistory";
import InviteHistorySu from "../components/templates/InviteHistorySu";
import Warranties from "../components/templates/Warranties";
import ViewWarrantyDetails from "../components/templates/ViewWarrantyDetails";
import ManageSubscription from "../components/templates/ManageSubscription";
import WarrantyAdd from "../components/templates/WarrantyAdd";
import WarrantyEdit from "../components/templates/WarrantyEdit";
import CompareAndSave from "../components/templates/CompareAndSave";
import MySubscriptions from "../components/templates/MySubscriptions";
import AddSubscription from "../components/templates/AddSubscription";
import EditSubscription from "../components/templates/EditSubscription";
import ViewSubscriptionDetails from "../components/templates/ViewSubscriptionDetails";
import GiftCards from "../components/templates/GiftCards";
import AddGiftCard from "../components/templates/GiftCardAdd";
import EditGiftCard from "../components/templates/GiftCardEdit";
import ViewGiftCardsDetails from "../components/templates/ViewGiftCardsDetails";
import Tasks from "../components/templates/Tasks";
import TasksAdd from "../components/templates/TasksAdd";
import TasksEdit from "../components/templates/TasksEdit";
import ViewTaskDetails from "../components/templates/ViewTaskDetails";
import Benefits from "../components/templates/Benefits";
import Pagination from "../components/templates/Pagination";
import SupportingUserDashboard from "../components/templates/SupportingUserDashboard";
import ViewNotificationDetails from "../components/templates/ViewNotificationDetails";
import NotFound from "../components/templates/NotFound";
import ElAccountPlan from "../components/templates/ElAccountPlan";
import ReminderSetting from "../components/templates/ReminderSetting";
import Reminders from "../components/templates/Reminders";
import MyInvites from "../components/templates/MyInvites";
import ManageUsers from "../components/templates/ManageUsers";
import NewBillingPage from "../components/templates/NewBillingPage";
import FamilyDashboardSup from "../components/templates/FamilyDashboardSup";

const routes = [
  {
    path: "/",
    component: SignIn,
    layout: PublicLayout,
  },

  {
    path: "/forgetPassword",
    component: ResetPassword,
    layout: PublicLayout,
  },
  {
    path: "/signup",
    component: SignUp,
    layout: PublicLayout,
  },
  {
    path: "/family_selection",
    component: Dashboard,
    layout: AuthLayout,
  },
  {
    path: "/myInvites",
    component: MyInvites,
    layout: AuthLayout,
  },
  {
    path: "/manage_users/:uuid",
    component: ManageUsers,
    layout: AuthLayout,
  },
  {
    path: "/profile/:uuid",
    component: Profile,
    layout: AuthLayout,
  },
  {
    path: "/subscription",
    component: Subscription,
    layout: AuthLayout,
  },
  {
    path: "/subscription/:uuid",
    component: Subscription,
    layout: AuthLayout,
  },
  {
    path: "/manageSubscription/:uuid",
    component: ManageSubscription,
    layout: AuthLayout,
  },
  {
    path: "/accountSubscriptions/:uuid",
    component: AccountSubscriptions,
    layout: AuthLayout,
  },
  {
    path: "/downgradeSubscription/:uuid",
    component: AccountDowngradeSubscription,
    layout: AuthLayout,
  },
  {
    path: "/downgrade_Subscription/:uuid",
    component: AccountDowngradeSubscriptionNewImplementation,
    layout: AuthLayout,
  },
  {
    path: "/myoutgoings/:uuid",
    component: MySubscriptions,
    layout: AuthLayout,
  },
  {
    path: "/user_myoutgoings/:uuid/:userUUID",
    component: MySubscriptions,
    layout: AuthLayout,
  },
  {
    path: "/add_outgoing/:uuid",
    component: AddSubscription,
    layout: AuthLayout,
  },
  {
    path: "/add_user_outgoing/:uuid/:userUUID",
    component: AddSubscription,
    layout: AuthLayout,
  },
  {
    path: "/view_outgoing_details/:uuid/:subId",
    component: ViewSubscriptionDetails,
    layout: AuthLayout,
  },
  {
    path: "/view_user_outgoing_details/:uuid/:subId/:userUUID",
    component: ViewSubscriptionDetails,
    layout: AuthLayout,
  },
  {
    path: "/edit_outgoing/:uuid/:subId",
    component: EditSubscription,
    layout: AuthLayout,
  },
  {
    path: "/edit_user_outgoing/:uuid/:subId/:userUUID",
    component: EditSubscription,
    layout: AuthLayout,
  },
  {
    path: "/confirmation",
    component: FamilyCreationConfirmation,
    layout: AuthLayout,
  },
  {
    path: "/onboardUser",
    component: WizardPage,
    layout: AuthLayout,
  },
  {
    path: "/reminders/:uuid",
    component: Reminders,
    layout: AuthLayout,
  },
  {
    path: "/updateConfirmation",
    component: FamilyUpdateConfirmation,
    layout: AuthLayout,
  },
  {
    path: "/payment",
    component: CheckoutForm,
    layout: AuthLayout,
  },
  {
    path: "/dashboard/:uuid",
    component: FamilyDashboard,
    layout: AuthLayout,
  },
  {
    path: "/support_dashboard/:uuid",
    component: FamilyDashboardSup,
    layout: AuthLayout,
  },
  {
    path: "/family_seat_history/:uuid",
    component: InviteHistory,
    layout: AuthLayout,
  },
  {
    path: "/su_invite_history/:uuid",
    component: InviteHistorySu,
    layout: AuthLayout,
  },
  {
    path: "/warranties/:uuid",
    component: Warranties,
    layout: AuthLayout,
  },
  {
    path: "/user_warranties/:uuid/:userUUID",
    component: Warranties,
    layout: AuthLayout,
  },
  {
    path: "/view_warranty_details/:uuid/:warId",
    component: ViewWarrantyDetails,
    layout: AuthLayout,
  },
  {
    path: "/view_user_warranty_details/:uuid/:warId/:userUUID",
    component: ViewWarrantyDetails,
    layout: AuthLayout,
  },
  {
    path: "/add_warranty/:uuid",
    component: WarrantyAdd,
    layout: AuthLayout,
  },
  {
    path: "/add_user_warranty/:uuid/:userUUID",
    component: WarrantyAdd,
    layout: AuthLayout,
  },
  {
    path: "/edit_warranty/:uuid/:warId",
    component: WarrantyEdit,
    layout: AuthLayout,
  },
  {
    path: "/edit_user_warranty/:uuid/:warId/:userUUID",
    component: WarrantyEdit,
    layout: AuthLayout,
  },
  {
    path: "/discounts/:uuid",
    component: Benefits,
    layout: AuthLayout,
  },
  {
    path: "/user_discounts/:uuid/:userUUID",
    component: Benefits,
    layout: AuthLayout,
  },
  {
    path: "/compare_and_save/:uuid",
    component: CompareAndSave,
    layout: AuthLayout,
  },
  {
    path: "/vouchers_&_gift_cards/:uuid",
    component: GiftCards,
    layout: AuthLayout,
  },
  {
    path: "/user_vouchers_&_gift_cards/:uuid/:userUUID",
    component: GiftCards,
    layout: AuthLayout,
  },
  {
    path: "/add_vouchers_&_gift_cards/:uuid",
    component: AddGiftCard,
    layout: AuthLayout,
  },
  {
    path: "/add_user_vouchers_&_gift_cards/:uuid/:userUUID",
    component: AddGiftCard,
    layout: AuthLayout,
  },
  {
    path: "/view__vouchers_&_gift_cards/:uuid/:giftId",
    component: ViewGiftCardsDetails,
    layout: AuthLayout,
  },
  {
    path: "/view_user_vouchers_&_gift_cards/:uuid/:giftId/:userUUID",
    component: ViewGiftCardsDetails,
    layout: AuthLayout,
  },
  {
    path: "/edit_vouchers_&_gift_cards/:uuid/:giftId",
    component: EditGiftCard,
    layout: AuthLayout,
  },
  {
    path: "/edit_user_vouchers_&_gift_cards/:uuid/:giftId/:userUUID",
    component: EditGiftCard,
    layout: AuthLayout,
  },
  {
    path: "/todos/:uuid",
    component: Tasks,
    layout: AuthLayout,
  },
  {
    path: "/user_todos/:uuid/:userUUID",
    component: Tasks,
    layout: AuthLayout,
  },
  {
    path: "/add_todos/:uuid",
    component: TasksAdd,
    layout: AuthLayout,
  },
  {
    path: "/add_user_todos/:uuid/:userUUID",
    component: TasksAdd,
    layout: AuthLayout,
  },
  {
    path: "/edit_todos/:uuid/:taskId",
    component: TasksEdit,
    layout: AuthLayout,
  },
  {
    path: "/edit_user_todos/:uuid/:taskId/:userUUID",
    component: TasksEdit,
    layout: AuthLayout,
  },
  {
    path: "/view_todos_details/:uuid/:taskId",
    component: ViewTaskDetails,
    layout: AuthLayout,
  },
  {
    path: "/view_user_todos_details/:uuid/:taskId/:userUUID",
    component: ViewTaskDetails,
    layout: AuthLayout,
  },
  {
    path: "/supporting_user_dashboard/:uuid/:userUUID",
    component: SupportingUserDashboard,
    layout: AuthLayout,
  },
  {
    path: "/notification/",
    component: ViewNotificationDetails,
    layout: AuthLayout,
  },
  {
    path: "/family_notification/:uuid",
    component: ViewNotificationDetails,
    layout: AuthLayout,
  },
  {
    path: "/user_notification/:uuid/:userUUID",
    component: ViewNotificationDetails,
    layout: AuthLayout,
  },
  {
    path: "/pagination",
    component: Pagination,
    layout: AuthLayout,
  },
  {
    path: "/404",
    component: NotFound,
    layout: AuthLayout,
  },
  {
    path: "/el_account_plan",
    component: ElAccountPlan,
    layout: AuthLayout,
  },
  {
    path: "/group_settings/:uuid",
    component: ReminderSetting,
    layout: AuthLayout,
  },
  {
    path: "/user_group_settings/:uuid/:userUUID",
    component: ReminderSetting,
    layout: AuthLayout,
  },
  {
    path: "/newcard_billing_details/:uuid",
    component: NewBillingPage,
    layout: AuthLayout,
  },
  {
    component: NotFound,
    layout: AuthLayout,
  },
];

export default routes;
