import React, { useState, useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import moment from "moment";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import { FamilyList } from "../../stories/FamilyListCard.stories";
import CheckGreen from "../../assets/images/check-green.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";

const Dashboard = (props) => {
  localStorage.removeItem("parentUser.uuid");
  localStorage.removeItem("userType");
  localStorage.removeItem("RedFamDash");
  localStorage.removeItem("userType");
  let history = useHistory();
  var initialFamilyList = props.location.familyList
    ? props.location.familyList
    : [];
  const [familyList, setFamilyList] = useState(initialFamilyList);
  const [loading, setLoading] = useState(true);
  const [familyDashLink] = useState("");
  // Get login user details from localStorage
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  // invite acceptance model
  const [showInviteModel, setShowInviteModel] = useState(false);
  const [successToast] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage] = useState("");
  const [hasMore, sethasMore] = useState(false);
  const [currentItems, setcurrentItems] = useState([]);
  const [limit, setlimit] = useState(50);
  const [offset, setoffset] = useState(0);
  const handleShowInviteModelClose = () => setShowInviteModel(false);
  useEffect(() => {
    // api call
    UserService.getUserFamily(UserDetails.uuid).then(
      (response) => {
        let newArr = [];
        for (let i = 0; i < response.usersFamilyData.length; i++) {
          if (
            response.usersFamilyData[i].status === "Active" &&
            response.usersFamilyData[i].inviteStatus === "Onboarded"
          ) {
            newArr.push(response.usersFamilyData[i]);
          }
        }
        setFamilyList(newArr);

        setcurrentItems(newArr.slice(0, limit)); // Set the first batch of items

        if (newArr.slice(0, limit).length < newArr.length) {
          sethasMore(true);
        } else {
          sethasMore(false);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
    UserService.getUserAccountPlan(UserDetails.uuid).then(
      (response) => {
        var newData = [];
        for (var i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].Family.status !== "InActive") {
            newData.push(response.usersFamilyData[i]);
          }
        }
        if (newData.length === 0) {
          history.push(`/subscription`);
        }

        setTimeout(() => {
          setLoading(false);
        }, 500);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const manageSubscriptionRoute = (fuuid) => {
    history.push(`/manageSubscription/${fuuid}`);
  };
  const manageDashboardRoute = (fuuid, plan) => {
    if (plan.isSu) {
      history.push(`/support_dashboard/${fuuid}`);
    } else {
      history.push(`/dashboard/${fuuid}`);
    }
  };

  const Capitalize = (str) => {
    if (!str) {
      return ""; // Return an empty string if str is null or undefined
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const renderRole = (param) => {
    if (param.isAo && param.isAu && param.isSu) {
      return "Admin + Main user + Support user";
    } else if (param.isAo && param.isAu) {
      return "Admin + Main user";
    } else if (param.isAo && param.isSu) {
      return "Admin + Support user";
    } else if (param.isAu && param.isSu) {
      return "Main user + Support user";
    } else if (param.isAo) {
      return "Admin";
    } else if (param.isAu) {
      return "Main user";
    } else if (param.isSu) {
      return "Support user";
    }
  };

  const renderRole1 = (param) => {
    if (param.isAo && param.isAu && param.isSu) {
      return "AO + AU + SU";
    } else if (param.isAo && param.isAu) {
      return "AO + AU";
    } else if (param.isAo && param.isSu) {
      return "AO + SU";
    } else if (param.isAu && param.isSu) {
      return "AU + SU";
    } else if (param.isAo) {
      return "AO";
    } else if (param.isAu) {
      return "AU";
    } else if (param.isSu) {
      return "SU";
    }
  };

  useEffect(() => {
    if (currentItems.length >= familyList.length) {
      sethasMore(false); // No more data to load
    }
  }, [currentItems, familyList]);

  const fetchMoreData = () => {
    if (currentItems.length < familyList.length) {
      setTimeout(() => {
        const newOffset = offset + limit; // Increase the offset
        const start = newOffset;
        const end = start + limit;

        // Slice the next batch of data
        const nextBatch = familyList.slice(start, end);

        setcurrentItems((prevList) => [...prevList, ...nextBatch]); // Append new data to the list
        setoffset(newOffset); // Update the offset
      }, 1000);
    } else {
      sethasMore(false); // No more data to load
    }
  };

  return (
    <div className="page-content">
      <Container
        fluid
        style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
        className="d-flex flex-column"
      >
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        {loading ? (
          <Loading />
        ) : (
          <Row>
            <div className="text-left">
              <div className="family-invitation-leftSection">
                <h2 className="title-heading">
                  {UserDetails.firstname === "null" ||
                  UserDetails.lastname === "null"
                    ? UserDetails.email
                    : Capitalize(UserDetails.firstname) +
                      " " +
                      Capitalize(UserDetails.lastname)}
                </h2>
                {familyList.length > 0 ? (
                  <>
                    {familyList.length > 1 ? (
                      <p
                        className="sub-text"
                        style={{ "margin-bottom": "2rem" }}
                      >
                        {/* You are part of the following Families on Elderly Life. */}
                        You have multiple groups for your EL account. Pick your
                        group to view details.
                      </p>
                    ) : (
                      <p
                        className="sub-text"
                        style={{ "margin-bottom": "2rem" }}
                      >
                        {/* You are part of the following Families on Elderly Life. */}
                        You have single group for your EL account. Pick your
                        group to view details.
                      </p>
                    )}

                    {currentItems && currentItems.length > 0 ? (
                      <InfiniteScroll
                        dataLength={currentItems.length} // Set the length of the current data
                        next={fetchMoreData} // Trigger to load more data
                        hasMore={hasMore} // Check if more data is available
                        style={{
                          overflow: "visible",
                        }}
                        loader={
                          <div className="d-flex align-items-center gap-2 my-5 justify-content-center">
                            <span
                              className="spinner-border spinner-border-sm mr-2"
                              role="status"
                            ></span>
                            <span>Loading . . .</span>
                          </div>
                        }
                      >
                        <Row
                          className="pl-0 pr-4 fmly-div card-scroll-element mb-4"
                          style={{ display: "flex", flexWrap: "wrap" }}
                        >
                          {currentItems.map((planDetail, index) => (
                            <Col xxl="8" xl="12" lg="12" key={index}>
                              <FamilyList
                                role={renderRole(planDetail)}
                                title={Capitalize(planDetail.Family.name)}
                                membershipDate={moment(
                                  planDetail.Family.createdAt
                                ).format("MMM Do, YYYY")}
                                planName={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.name
                                }
                                planPrice=""
                                planDuration={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.renewalTyp
                                }
                                auSeats={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.availableS
                                }
                                suSeats={
                                  planDetail.Family.Family_Subscription
                                    .Account_Subscription_Plan.availableS
                                }
                                variant="basic"
                                familyName={planDetail.Family.name}
                                familyId={planDetail.Family.displayId}
                                firstLetter={Capitalize(
                                  planDetail.Family.name
                                ).charAt(0)}
                                isAo={planDetail.isAo ? "true" : "false"}
                                buttonClick={() => {
                                  manageSubscriptionRoute(
                                    planDetail.Family.uuid
                                  );
                                }}
                                buttonClick1={() => {
                                  manageDashboardRoute(
                                    planDetail.Family.uuid,
                                    planDetail
                                  );
                                  localStorage.setItem(
                                    "familyName",
                                    planDetail.Family.name
                                  );
                                  localStorage.setItem(
                                    "userType",
                                    renderRole1(planDetail)
                                  );
                                }}
                                aoDetails={planDetail.Family.aoDetails}
                              />
                            </Col>
                          ))}
                        </Row>
                      </InfiniteScroll>
                    ) : (
                      "hello"
                    )}
                  </>
                ) : (
                  <>
                    <p className="sub-text">
                      Thank You! for choosing Elderly Life.
                    </p>
                    <p className="sub-text mb-4" style={{ minHeight: "226px" }}>
                      You are not associated with any of the families yet. You
                      can either wait for someone to invite you in a Group or
                      create a new Group on Elderly Life.
                    </p>
                  </>
                )}
              </div>
            </div>

            {/* invite acceptance modal */}
            <Modal
              show={showInviteModel}
              onHide={handleShowInviteModelClose}
              className="invite_accpt_modal"
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body className="text-center">
                <h3>Congratulations !!</h3>
                <Image
                  className="hello-text-img my-4"
                  src={CheckGreen}
                  alt="Check mark"
                  width="18%"
                />
                <p>You are Successfully Onboarded into the Group</p>

                <Link to={familyDashLink}>
                  <Large
                    children="Visit your Group page"
                    size="largebtn"
                    variant="primary"
                    type=""
                    buttonWidth="260px"
                    font_Weight="600"
                  />
                </Link>
              </Modal.Body>
            </Modal>
          </Row>
        )}
      </Container>
    </div>
  );
};
export default Dashboard;
