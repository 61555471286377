import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import userService from "../../services/user.service";
import moment from "moment";

const FamilyDashboardUsers = () => {
  let { uuid } = useParams();
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const [accountUsers, setAccountUsers] = useState("");
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [familyDetails, setfamilyDetails] = useState({});
  const [audata, setaudata] = useState([]);
  const [sudata, setsudata] = useState([]);

  useEffect(() => {
    setAccountUsers("/manage_users/" + uuid);

    userService.getUserAccountPlan(UserDetails.uuid).then((res) => {
      for (let i = 0; i < res.usersFamilyData.length; i++) {
        if (res.usersFamilyData[i].Family.uuid === uuid) {
          setfamilyDetails(res.usersFamilyData[i]);
        }
      }
      userService.updateSUAURel(uuid).then((response) => {
        let auarray = [];
        let suarray = [];
        for (let i = 0; i < response.data.length; i++) {
          if (
            response.data[i].isAu &&
            response.data[i].inviteStatus !== "Removed" &&
            response.data[i].inviteStatus !== "Cancelled"
          ) {
            auarray.push(response.data[i]);
          }
          if (
            response.data[i].isSu &&
            response.data[i].inviteStatus !== "Removed" &&
            response.data[i].inviteStatus !== "Cancelled"
          ) {
            suarray.push(response.data[i]);
          }
        }
        setaudata(auarray);
        setsudata(suarray);

        setTimeout(() => {
          setLoadingspinner(false);
        }, 500);
      });
    });
  }, []);

  return (
    <div>
      <div className="d-flex mb-7">
        <h2 className="me-auto mb-0">Users</h2>
        {/* <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={accountUsers}
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link> */}
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          <Row>
            <Col lg="12" className="mb-4 pb-4 custom-line pe-7">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-2">Main Users</h4>
                <Link
                  className="btn btn-white btn-sm btn-circle flex-shrink-0"
                  to={accountUsers}
                >
                  <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
                </Link>
              </div>
              <div className="d-flex align-items-center bold-colour fw-medium mb-5 gap-3">
                You can add{" "}
                {familyDetails.Family.Family_Subscription
                  .Account_Subscription_Plan.availableS - audata.length}{" "}
                more{" "}
                {familyDetails.Family.Family_Subscription
                  .Account_Subscription_Plan.availableS -
                  audata.length ===
                1
                  ? "user"
                  : "users"}{" "}
              </div>

              {audata.length > 0 ? (
                audata.map((userList, index) => (
                  <span key={index}>
                    <div className="fs-14 manage-users-card">
                      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-2">
                        <div className="d-flex align-items-center gap-2">
                          <img
                            src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                            alt="Profile Avatar"
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                            }}
                          />
                          <div className="text-break">
                            <div>
                              <div className="PU-dsn-txt">
                                <span>
                                  {userList.User.firstName === "null" ? (
                                    <span></span>
                                  ) : (
                                    userList.User.firstName
                                  )}{" "}
                                  {userList.User.lastName === "null" ? (
                                    <span></span>
                                  ) : (
                                    userList.User.lastName
                                  )}
                                </span>
                                <span>
                                  {userList.User.firstName !== "null" ? (
                                    <div className="PU-dsn-txt-1">
                                      {userList.User.email}
                                    </div>
                                  ) : (
                                    <div className="PU-dsn-txt-1">
                                      {userList.User.email}
                                    </div>
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex align-items-center gap-2 ms-7 ms-sm-0">
                          <div className="text-break ">
                            {userList.inviteStatus}
                          </div>
                        </div>
                      </div>

                      <div className="mt-2" style={{ marginLeft: "3rem" }}>
                        Onboarded on{" "}
                        <b>
                          {moment(userList.createdAt).format("Do MMMM, YYYY")}
                        </b>
                      </div>
                    </div>
                  </span>
                ))
              ) : (
                <div className="new-Txt-Chng">No Main Users available.</div>
              )}
            </Col>

            <Col lg="12" className="ps-lg-7">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-2">Support Users</h4>
                <Link
                  className="btn btn-white btn-sm btn-circle flex-shrink-0"
                  to={accountUsers}
                >
                  <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
                </Link>
              </div>

              <div className="d-flex bold-colour fw-medium mb-5 gap-3">
                You can add{"  "}
                {familyDetails.Family.Family_Subscription
                  .Account_Subscription_Plan.availableS - sudata.length}{" "}
                more{" "}
                {familyDetails.Family.Family_Subscription
                  .Account_Subscription_Plan.availableS -
                  sudata.length ===
                1
                  ? "user"
                  : "users"}{" "}
              </div>
              {sudata.length > 0 ? (
                sudata.map((userList, index) => (
                  <span key={index}>
                    <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between gap-2 fs-14 manage-users-card">
                      <div className="d-flex align-items-center gap-2">
                        <img
                          src="https://el-publicassets.s3.eu-west-2.amazonaws.com/images/default_user_image.png"
                          alt="Profile Avatar"
                          style={{
                            width: "2.5rem",
                            height: "2.5rem",
                          }}
                        />
                        <div className="text-break">
                          <div>
                            <div className="PU-dsn-txt">
                              <span>
                                {userList.User.firstName === "null" ? (
                                  <span></span>
                                ) : (
                                  userList.User.firstName
                                )}{" "}
                                {userList.User.lastName === "null" ? (
                                  <span></span>
                                ) : (
                                  userList.User.lastName
                                )}
                              </span>
                              <span>
                                {userList.User.firstName !== "null" ? (
                                  <div className="PU-dsn-txt-1">
                                    {userList.User.email}
                                  </div>
                                ) : (
                                  <div className="PU-dsn-txt-1">
                                    {userList.User.email}
                                  </div>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 ms-7 ms-sm-0">
                        <div className="text-break ">
                          {userList.inviteStatus}
                        </div>
                      </div>
                    </div>
                  </span>
                ))
              ) : (
                <div className="new-Txt-Chng">No Support Users available.</div>
              )}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default FamilyDashboardUsers;
