import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import InputGroup from "react-bootstrap/InputGroup";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Select from "react-select";
import Loading from "../../common/Loading";
import imageX from "../../assets/images/x.svg";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css"; // Import styles
import "react-calendar/dist/Calendar.css"; // Required for calendar display

const AddGiftCard = (props) => {
  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const initialValues = {
    name: "",
    cardType: "",
    supplier: "",
    total_amt: "",
    used_amt: "",
    expiry_date: "",
    gift_card_doc: "",
    comments: "",
    otherSupplierName: "",
  };
  const initialSupplierOption = [{ value: "", label: "Select a supplier" }];
  const [supplierOption, setSupplierOption] = useState(initialSupplierOption);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [filtervalue, setfiltervalue] = useState("Gift Card");
  // State to manage uploaded files
  const [uploadedDocArray, setUploadedDocArray] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [browserErrMsg, setBrowserErrMsg] = useState("");
  const [giftcardsUrl, setGiftcardsUrl] = useState("");
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [fileLoader, setFileLoader] = useState(false);
  const [otherModal, setOtherModal] = useState(false);
  const [familyUserId, setFamilyUserId] = useState();
  const [dateValStart, setDateValStart] = useState(); // Default start date
  const [mounted, setMounted] = useState(false); // Default selected date
  const [Loadingdata, setLoadingdata] = useState(true);

  const executeScroll = () => myRef.current.scrollIntoView();
  const myRef = useRef(null);
  const { uuid, userUUID } = useParams();

  // select error style
  const customStylesError = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "16px",
      borderRadius: " 0.75rem",
      borderStyle: "none",
      cursor: "pointer",
      padding: "2px 0",
      border: "0.0625rem solid red",
      marginBottom: "4px",
      color: "#475569",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0.75rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "4px 0",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "16px",
      width: "100%",
      cursor: "pointer",
      color: "#475569",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#475569",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af",
      fontSize: "16px",
      fontStyle: "italic",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#007bff" : "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#dfe8f1",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
  };

  // select style
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
      fontSize: "16px",
      borderRadius: " 0.75rem",
      borderStyle: "none",
      cursor: "pointer",
      padding: "2px 0",
      border: "0.0625rem solid #aabccf",
      marginBottom: "4px",
      color: "#475569",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "0.75rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "4px 0",
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "0.5rem",
      fontSize: "16px",
      width: "100%",
      cursor: "pointer",
      color: "#475569",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#475569",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#9ca3af",
      fontSize: "16px",
      fontStyle: "italic",
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#007bff" : "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#dfe8f1",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      color: "#9ca3af",
      "&:hover": {
        color: "#007bff",
      },
    }),
  };

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleChange = (e, action) => {
    let targetData = {};
    if (action) {
      targetData = {
        name: action.name,
        value: e.value,
      };
      if (action.name === "supplier") {
        setSelectedSupplier(e);

        if (e.label === "Others") {
          setOtherModal(true); // Open the modal
        }
      }
    } else if (e.target.name === "total_amt") {
      targetData = {
        name: e.target.name,
        value:
          e.target.value.indexOf(".") >= 0
            ? e.target.value.substr(0, e.target.value.indexOf(".")) +
              e.target.value.substr(e.target.value.indexOf("."), 3)
            : e.target.value,
      };
    } else if (e.target.name === "used_amt") {
      targetData = {
        name: e.target.name,
        value:
          e.target.value.indexOf(".") >= 0
            ? e.target.value.substr(0, e.target.value.indexOf(".")) +
              e.target.value.substr(e.target.value.indexOf("."), 3)
            : e.target.value,
      };
    } else {
      targetData = {
        name: e.target.name,
        value: e.target.value,
      };
    }
    const { name, value } = targetData;

    setFormValues({ ...formValues, [name]: value });

    // upload img
    if (targetData.name === "gift_card_doc") {
      setFileLoader(true);
      setBrowserErrMsg("");
      // upload file
      var documentFile = e.target.files[0];

      var reader = new FileReader();
      reader.onload = function (e) {
        var MAX_FILE_SIZE = 2; // 2MB

        if (
          [
            "image/png",
            "image/jpg",
            "image/jpeg",
            "application/pdf",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document", //docx
            "application/msword", // .doc
          ].includes(documentFile.type)
        ) {
          let fileSize = documentFile.size / 1024 / 1024;
          if (fileSize > MAX_FILE_SIZE) {
            setBrowserErrMsg("OOPS! File size is above 2MB.");
            setFileLoader(false);
          } else {
            uploadFile(documentFile);
          }
        } else {
          setBrowserErrMsg("OOPS! The file format doesn't support");
          setFileLoader(false);
        }
      };
      if (documentFile !== undefined) {
        reader.readAsDataURL(documentFile);
      }
    }

    if (targetData.name === "used_amt") {
      setFormErrors({ ...formErrors, used_amt: "" });
    }
  };

  const getFamilyDetails = (userId) => {
    // AU Family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);

        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            setUserCreateUpdate(response.FamilyData.UserId);
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);

        setUserCreateUpdate(response.FamilyData.UserId);
        setUserCreateUpdateFor(response.FamilyData.id);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    UserService.relationData(uuid).then((response) => {
      // main user checking
      const mainUser = response.data.au.find(
        (obj) => obj.User.email === UserDetails.email
      );

      const mainArr = [];
      if (mainUser) {
        mainArr.push(mainUser);
      }

      // support user checking
      const suppUser = response.data.au.find((obj) =>
        obj.su.find(
          (family) =>
            family.Family_User &&
            family.Family_User.User.email === UserDetails.email
        )
      );

      const suppArr = [];
      if (suppUser) {
        suppArr.push(suppUser);
      }

      let newArray = [];
      newArray = [...mainArr, ...suppArr];
      const uuidFind = newArray.find((obj) => obj.User.uuid === userUUID);

      userUUID && setFamilyUserId(uuidFind.id);
    });

    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setGiftcardsUrl("/user_vouchers_&_gift_cards/" + uuid + "/" + userUUID);
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setGiftcardsUrl("/vouchers_&_gift_cards/" + uuid);
    }

    // get supplier listing
    UserService.getSupplier({ moduleName: "Giftcards" }).then(
      (response) => {
        setSupplierOption(response.data.data);
      },
      (error) => {}
    );

    setFormValues({ ...formValues, cardType: "Voucher" });

    setTimeout(() => {
      setLoadingdata(false);
      setMounted(true);
    }, 500);
  }, []);

  useEffect(() => {
    // add gift card
    if (
      Object.keys(formErrors).length === 0 &&
      formValues.total_amt > 0 &&
      isSubmit
    ) {
      setLoading(true);

      UserService.getCatfromSup({
        supplierId: formValues.supplier,
      }).then((resp) => {
        if (formValues.used_amt === "" || formValues.used_amt < 0) {
          formValues.used_amt = 0;
        }

        // Convert to string
        // Convert array to a single string (comma-separated)
        const urlsAsString =
          uploadedDocArray.length > 0 ? JSON.stringify(uploadedDocArray) : "";

        let data = {
          CategoryId: resp.data.data[0].CategoryId,
          SubCategoryId: resp.data.data[0].sub_category_id,
          cardType: filtervalue,
          SupplierId: formValues.supplier,
          totalAmount: formValues.total_amt,
          usedAmount: formValues.used_amt,
          expiryDate: formValues.expiry_date,
          documentLink: urlsAsString,
          reminder: isSwitchOn ? 1 : 0,
          updatedBy: userCreateUpdate,
          createdUpdatedFor: userUUID
            ? familyUserId
            : familyDetails.FamilyData.id,
          FamilyId: familyDetails.FamilyData.FamilyId,
          FamilyUserId: userUUID ? familyUserId : familyDetails.FamilyData.id,
          name: formValues.name,
          comments: formValues.comments,
          otherSupplierName: formValues.otherSupplierName,
        };
        // api call
        UserService.createGiftcard(data).then(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              setLoading(false);
              setShowToast(true);
              setSuccessToast(true);
              executeScroll();
              window.scrollTo(0, 0);
              // setToastMessage("Voucher & Gift Card created successfully");
              setToastMessage(
                data.cardType === "Gift Card"
                  ? "Gift Card created successfully"
                  : "Voucher created successfully"
              );
              setTimeout(() => {
                history.push(giftcardsUrl);
                setFormValues(initialValues);
              }, 1000);
            } else {
              setLoading(false);
              setShowToast(true);
              setSuccessToast(false);
              executeScroll();
              window.scrollTo(0, 0);
              setToastMessage(response.data.message);
            }
          },
          (error) => {}
        );
      });
      // end api call
      setIsSwitchOn(true);
    } else {
      setLoading(false);
      // window.scrollTo(0, 0);
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.msg = "Please enter the name";
      errors.name = "Yes";
    }
    if (!values.supplier) {
      errors.msg = "Please select the supplier";
      errors.supplier = "Yes";
    }
    if (!values.total_amt || values.total_amt === 0 || values.total_amt < 0) {
      errors.msg = "Please enter the total amount";
      errors.total_amt = "Yes";
    }
    if (!values.expiry_date) {
      errors.msg = "Please enter the expiry date";
      errors.expiry_date = "Yes";
    }
    if (values.total_amt) {
      if (parseInt(values.used_amt) > parseInt(values.total_amt)) {
        errors.used_amt_msg = "Please enter Used amount less then total amount";
        errors.used_amt = "Yes";
      }
    }
    return errors;
  };

  useEffect(() => {
    if (mounted) {
      if (dateValStart) {
        setFormValues((prevValues) => ({
          ...prevValues,
          expiry_date: dateValStart,
        }));
        setFormErrors((prevErrors) => ({ ...prevErrors, expiry_date: "" }));
      }
    }
  }, [dateValStart]);

  // file upload
  const uploadFile = (data) => {
    setBrowserErrMsg("");

    const formData = new FormData();
    formData.append("file", data, data.name.replace(/ /g, ""));

    UserService.uploadUserDocuments(formData).then(
      (response) => {
        setUploadedDocArray((prevArray) => [...prevArray, response.link[0]]); // Push to the array
        setFileLoader(false);
      },
      (error) => {
        setBrowserErrMsg("Something went error");
        setFileLoader(false);
      }
    );
  };
  // Function to delete a file
  const deleteFile = (fileUrl) => {
    setUploadedDocArray((prevFiles) =>
      prevFiles.filter((file) => file !== fileUrl)
    );
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const [activeButton, setActiveButton] = useState("Gift Card"); // Initial state set to 'voucher'

  // Function to handle button click
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType); // Set active button based on click
    setfiltervalue(buttonType);
    setFormValues({
      ...formValues,
      cardType: buttonType,
      comments: "",
      expiry_date: "",
      gift_card_doc: "",
      name: "",
      supplier: "",
      total_amt: "",
      used_amt: "",
    });
    setSelectedSupplier({ value: "", label: "Select a supplier" });
  };

  const [otherSupErr, setOtherSupErr] = useState("");
  const [otherSupplier, setOtherSupplier] = useState("");

  // Reset dropdown when modal closes
  const handleCloseModal = () => {
    setOtherModal(false); // Close the modal
    setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
    setFormValues((prevValues) => ({
      ...prevValues,
      supplier: "",
      otherSupplierName: "",
    }));
    setOtherSupErr("");
  };

  const submitModal = () => {
    // Check if the input field is empty
    if (!formValues.otherSupplierName) {
      setOtherSupErr("otherSupErr");
      return; // Do not proceed if the input field is empty
    }
    setOtherSupErr("");
    setOtherSupplier(formValues.otherSupplierName);
    setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
    setTimeout(() => {
      setOtherModal(false); // Close the modal
    }, 100);
  };

  return (
    <Container ref={myRef}>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem new-datepicker">
        <Col xl="21" xxl="18">
          <h1 className="lh-base mb-4">Add a new Voucher or Gift card</h1>
          <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
            {Loadingdata ? (
              <>
                <Loading />
              </>
            ) : (
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <section>
                  <h2 className="mb-1 mb-md-2">Details</h2>
                  <p className="mb-4">
                    {activeButton === "Gift Card"
                      ? "Add a gift card here"
                      : "Add a voucher here"}
                  </p>
                  <Row className="gy-0 mb-4">
                    <Form.Label>Type</Form.Label>
                    <Col className="d-flex">
                      <div
                        className={`btn btn-sm ${
                          activeButton === "Gift Card" ? "bg-cayn" : "btn-white"
                        }`}
                        onClick={() => handleButtonClick("Gift Card")}
                        style={{
                          borderRadius: "8px 0 0 8px",
                        }}
                      >
                        Gift card
                      </div>

                      <div
                        className={`btn btn-sm ${
                          activeButton === "Voucher" ? "bg-cayn" : "btn-white"
                        }`}
                        onClick={() => handleButtonClick("Voucher")}
                        style={{
                          borderRadius: "0 8px 8px 0",
                        }}
                      >
                        Voucher
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label
                        className={formErrors.name ? "text-danger" : " "}
                      >
                        Name
                      </Form.Label>
                      <Form.Control
                        className={formErrors.name ? "is-invalid" : ""}
                        type="text"
                        placeholder={`e.g. Tesco clubcard ${
                          filtervalue === "Voucher" ? "voucher" : "gift card"
                        }`}
                        name="name"
                        value={formValues.name}
                        onChange={handleChange}
                        maxLength={255}
                      />
                      <Form.Text
                        className={formErrors.name ? "text-danger" : " "}
                      >
                        Please enter the{" "}
                        {activeButton === "Voucher" ? "voucher" : "gift card"}{" "}
                        name
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label
                        className={formErrors.supplier ? "invalid-text" : " "}
                      >
                        Supplier
                      </Form.Label>
                      {otherSupplier ? (
                        <div className="form-control d-flex align-items-center gap-3 justify-content-between">
                          {otherSupplier}{" "}
                          <button
                            className="rounded-circle bg-white border-1 p-1 border-black d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={() => {
                              setOtherSupplier("");
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                otherSupplierName: "",
                              }));
                              setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
                            }}
                          >
                            <img
                              src={imageX}
                              alt=""
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>
                        </div>
                      ) : (
                        <Select
                          styles={
                            formErrors.supplier
                              ? customStylesError
                              : customStyles
                          }
                          options={supplierOption}
                          defaultValue={{
                            label: "Select a supplier",
                            value: "",
                          }}
                          value={selectedSupplier}
                          onChange={handleChange}
                          name="supplier"
                        />
                      )}
                      <Form.Text
                        className={formErrors.supplier ? "text-danger" : " "}
                      >
                        Please select your supplier name.
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label
                        className={formErrors.total_amt ? "text-danger" : ""}
                      >
                        Total
                      </Form.Label>
                      <InputGroup
                        className={formErrors.total_amt ? "is-invalid" : ""}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <i className="fa-solid fa-pound-sign fa-sm"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className={
                            formErrors.total_amt
                              ? "is-invalid invalid-border-right"
                              : ""
                          }
                          type="number"
                          onWheel={() => document.activeElement.blur()}
                          name="total_amt"
                          step="any"
                          value={
                            formValues.total_amt < 0
                              ? ""
                              : formValues.total_amt === 0
                              ? formValues.total_amt
                              : formValues.total_amt === 0.0
                              ? formValues.total_amt
                              : formValues.total_amt > 0.0
                              ? formValues.total_amt
                              : formValues.total_amt
                          }
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <Form.Text
                        className={formErrors.total_amt ? "text-danger" : ""}
                      >
                        Please enter the total amount
                      </Form.Text>
                    </Col>
                    <Col
                      xs="18"
                      sm="14"
                      md="12"
                      lg="10"
                      className={filtervalue === "Voucher" ? "d-none" : ""}
                    >
                      <Form.Label>
                        Remamining
                        <span className="small text-muted fw-normal ms-1">
                          Optional
                        </span>
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          <i className="fa-solid fa-pound-sign fa-sm"></i>
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          onWheel={() => document.activeElement.blur()}
                          name="used_amt"
                          step="any"
                          value={
                            filtervalue === "Voucher"
                              ? "0.00"
                              : formValues.used_amt < 0
                              ? ""
                              : formValues.used_amt === 0
                              ? formValues.used_amt
                              : formValues.used_amt === 0.0
                              ? formValues.used_amt
                              : formValues.used_amt > 0.0
                              ? formValues.used_amt
                              : formValues.used_amt
                          }
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <Form.Text>Please enter the remaining amount</Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="10">
                      <Form.Label
                        className={formErrors.expiry_date ? "text-danger" : ""}
                      >
                        Expiry date
                      </Form.Label>
                      <InputGroup
                        className={formErrors.expiry_date ? "is-invalid" : ""}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <i className="fa-solid fa-calendar fa-sm"></i>
                        </InputGroup.Text>
                        <DatePicker
                          onChange={setDateValStart}
                          name="expiry_date"
                          value={dateValStart ? new Date(dateValStart) : null} // Ensure Date object
                          format="dd-MM-yyyy" // Format for display
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          clearIcon={null} // Hide clear button
                          className={`${
                            formErrors.date ? "invalid-border-right" : ""
                          } form-control`}
                          // view="month" // Month selection
                          // maxDetail="year" // Show Year > Month > Date
                        />
                      </InputGroup>
                      <Form.Text
                        className={formErrors.expiry_date ? "text-danger" : " "}
                      >
                        Expiry date must be greater than today
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="24">
                      <Form.Label>
                        Comments
                        <span className="small text-muted fw-normal ms-1">
                          Optional
                        </span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        cols={5}
                        name="comments"
                        placeholder="Add any extra details or special notes here​"
                        value={formValues.comments}
                        onChange={handleChange}
                        maxLength={255}
                      />
                      <Form.Text>
                        You can provide any extra details or special notes here​
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0">
                    <Col md="auto">
                      <Form.Label className="mb-0">Reminder</Form.Label>
                      <p className="mb-2">
                        Standard reminder settings will be applied to this{" "}
                        {filtervalue === "Voucher" ? "voucher" : "giftcard"}.
                      </p>
                      <Form.Switch
                        onChange={onSwitchAction}
                        id="custom-switch"
                        checked={isSwitchOn}
                      />
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section class="mb-4">
                  <h3 className="mb-1 mb-md-2">
                    Upload attachment
                    <span className="small text-muted fw-normal ff-body ms-2">
                      Optional
                    </span>
                  </h3>
                  <p className="mb-4">
                    Upload your{" "}
                    {filtervalue === "Voucher" ? "voucher" : "giftcard"} here
                    –include key details like barcodes or scannable codes.​
                  </p>
                  <Row className="gy-0">
                    <Col xl="21" xxl="24" className="mb-3">
                      <Form.Control
                        className="d-none"
                        type="file"
                        id="upload"
                        name="gift_card_doc"
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                        // value={formValues.gift_card_doc}
                        onChange={handleChange}
                        accept="image/png, image/jpg, image/jpeg, application/pdf,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                      />
                      {fileLoader ? (
                        <div
                          className=" btn-upload mb-0 form-label"
                          style={{ cursor: "auto" }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <Form.Label
                          className="btn btn-upload mb-0"
                          for="upload"
                        >
                          <i className="fa-solid fa-upload fa-2xl lh-1"></i>
                          <div className="h6 lh-base mt-4 mb-1">
                            Upload an attachment
                          </div>
                          <p className="small fw-normal text-muted mb-0">
                            We accept PNG, JPG, JPEG, PDF & DOC. (Maximum file
                            size 2MB)
                          </p>
                          <p className="mt-3 text-danger">{browserErrMsg}</p>
                        </Form.Label>
                      )}
                    </Col>

                    {/* uploaded files  */}
                    {uploadedDocArray && uploadedDocArray.length > 0 && (
                      <Col xl="21" xxl="24">
                        <div className="fs-18 bold-colour mb-2 fw-medium">
                          Files selected for upload
                        </div>
                        <div>
                          {uploadedDocArray.map((file, index) => (
                            <div
                              key={index}
                              className="file-item p-3 mb-3 d-flex align-items-center justify-content-between"
                              style={{
                                boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px",
                                borderRadius: "12px",
                              }}
                            >
                              <div>{file.split("/").pop()}</div>
                              <button
                                className="btn btn-danger py-1 px-2"
                                onClick={() => deleteFile(file)} // This function will handle image removal
                              >
                                <i className="fa-solid fa-trash text-white"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </Col>
                    )}
                  </Row>
                </section>
                <div className="d-flex justify-content-end">
                  <Button
                    className="d-flex align-items-center"
                    variant="primary"
                    type="submit"
                    isLoading={loading ? "true" : "false"}
                    isDisabled={loading ? "true" : "false"}
                  >
                    <i className="fa-solid fa-sm fa-add lh-1 align-middle me-2"></i>
                    Add {filtervalue === "Voucher" ? "voucher" : "giftcard"}
                  </Button>
                </div>
              </Form>
            )}
          </section>
        </Col>
      </Row>

      {/* uploaded document   */}
      <Modal show={otherModal} onHide={() => handleCloseModal()} className="">
        <Modal.Header closeButton>
          <Modal.Title className="mx-1">
            <h3 className="mb-0">Add other Supplier name</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Form.Label className={otherSupErr ? "text-danger" : ""}>
              Supplier name
            </Form.Label>
            <Form.Control
              className={`mb-2 ${
                otherSupErr ? "is-invalid invalid-border-right" : ""
              }`}
              type="text"
              maxlength="100"
              placeholder="e.g. Starbucks"
              name="otherSupplierName"
              value={formValues.otherSupplierName}
              onChange={handleChange}
            />
            <Form.Text className={otherSupErr ? "text-danger mb-4" : "mb-4"}>
              Please enter the name of the supplier if you haven't found their
              name in the dropdown
            </Form.Text>
          </div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => submitModal()}
          >
            + Add
          </button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default AddGiftCard;
