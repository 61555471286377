// common Alert block with any action button
import React from "react";
import { Card } from "react-bootstrap";
import EmailVerificationIcon from "../../assets/images/verfication_envelop.svg";
import smartphn from "../../assets/images/smartphone.svg";

function ActionAlert(props) {
  const { message, variant, onButtonClick, ...rest } = props;

  return (
    <div {...rest}>
      <Card.Body className="p-3 d-block d-md-flex align-items-center gap-2 bg-white br-12 fs-14">
        <div className="d-flex align-items-center">
          <span className="action-left me-3">
            {variant === "info" ? (
              <img src={EmailVerificationIcon} alt="Email verification icon" />
            ) : (
              <div className="p-2 rounded-circle lb-background">
                <img src={smartphn} alt="smartphn" style={{ width: "20px" }} />
              </div>
            )}
          </span>
          <span className="action-right">
            <button
              className="btn btn-primary"
              onClick={onButtonClick}
              style={{ width: "max-content" }}
            >
              Send verification Code
            </button>
          </span>
        </div>
        <span className="action-text">{message}</span>
      </Card.Body>
    </div>
  );
}

export default ActionAlert;
