import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const Warranties = (props) => {
  const myRef = useRef(null);
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const filterOption = [
    { value: "all", label: "All Warranties" },
    { value: "warrantyTenure", label: "By Expiration time" },
    { value: "status", label: "By Warranty status" },
  ];

  const sortOption = [
    { value: "In Warranty", label: "In Warranty" },
    { value: "Out Warranty", label: "Out of Warranty" },
  ];
  const sortOption1 = [
    { value: "6M", label: "6 months" },
    { value: "12M", label: "12 months" },
    { value: "24M", label: "24 months" },
    { value: "36M", label: "36 months" },
    { value: "48M", label: "48 months" },
  ];

  const [familyDetails, setFamilyDetails] = useState([]);
  const [successToast, setSuccessToast] = useState(false);
  const [userWarrantyList, setUserWarrantyList] = useState([]);
  const [InWarrantyTotalPrice, setInWarrantyTotalPrice] = useState("£ 0");
  const [OutWarrantyTotalPrice, setOutWarrantyTotalPrice] = useState("£ 0");
  const [searchStr, setSearchStr] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [addWarrantyUrl, setAddWarrantyUrl] = useState("");
  const [title, setTitle] = useState("Warranties");
  const [isEmpty, setIsEmpty] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [limit, setlimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [familyUserId, setFamilyUserId] = useState();
  const [monthType, setMonthType] = useState("all");
  const [checkAll, setCheckAll] = useState(true);
  const splitUrl = window.location.href.split("/");

  const viewDetailsRoute = (warId) => {
    if (userUUID) {
      history.push(`/view_user_warranty_details/${uuid}/${warId}/${userUUID}`);
    } else {
      history.push(`/view_warranty_details/${uuid}/${warId}`);
    }
  };
  const editWarrantyRoute = (warId) => {
    if (userUUID) {
      history.push(`/edit_user_warranty/${uuid}/${warId}/${userUUID}`);
    } else {
      history.push(`/edit_warranty/${uuid}/${warId}`);
    }
  };

  const [filtValue, setfiltValue] = useState("all"); // filter by
  const [expValue, setexpValue] = useState(""); // status by
  const [searchValue, setsearchValue] = useState(""); // search by
  const [sortValue, setsortValue] = useState("createdAt"); //sort by
  const [orderValue, setorderValue] = useState("DESC"); //order by

  const [subTitle, setSubTitle] = useState(
    "You can track all your warranties on Elderly Life platform here."
  );

  const [loadingspinner, setLoadingspinner] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total items, needed to calculate total pages
  const [mounted, setMounted] = useState(false); // Tracks if the component has fully loaded

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedOption1, setSelectedOption1] = useState({
    value: "all",
    label: "All Warranties",
  }); // Selected option for first dropdown
  const [selectedOption2, setSelectedOption2] = useState(null); // Selected option for second dropdown

  useEffect(() => {
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        setAddWarrantyUrl("/add_user_warranty/" + uuid + "/" + userUUID);
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        const abc =
          pUser.firstName === "null"
            ? pUser.email
            : pUser.firstName + " " + pUser.lastName === "null"
            ? ""
            : pUser.lastName;
        setTitle(` Warranties`);
        setSubTitle(`You can track all Warranties of ${abc} here`);
      });
    } else {
      setAddWarrantyUrl("/add_warranty/" + uuid);
      getFamilyDetails(UserDetails.id);
    }
  }, []);

  const getFamilyDetails = (userId) => {
    // get family details
    // family relation
    UserService.relationData(uuid).then((respo) => {
      // main user checking
      const mainUser = respo.data.au.find(
        (obj) => obj.User.email === UserDetails.email
      );

      const mainArr = [];
      if (mainUser) {
        mainArr.push(mainUser);
      }

      // support user checking
      const suppUser = respo.data.au.find((obj) =>
        obj.su.find(
          (family) =>
            family.Family_User &&
            family.Family_User.User.email === UserDetails.email
        )
      );

      const suppArr = [];
      if (suppUser) {
        suppArr.push(suppUser);
      }

      let newArray = [];
      newArray = [...mainArr, ...suppArr];

      const uuidFind = newArray.find((obj) => obj.User.uuid === userUUID);

      UserService.getUserSingleFamilyDetails(uuid, userId).then(
        (response) => {
          setFamilyDetails(response);
          let famUserID = userUUID ? uuidFind.id : response.FamilyData.id;
          if (userUUID) {
            setFamilyUserId(uuidFind.id);
          } else {
            setFamilyUserId(famUserID);
          }

          let params = {
            userId: famUserID,
            familyId: response.FamilyData.Family.id,
            searchStr: searchValue,
            sort: sortValue,
            order: orderValue,
            limit: limit,
            offset: offset,
            filtBy: filtValue,
            status: expValue,
            monthType: monthType,
          };

          let Max = { ...params }; // Clone params to avoid modifying it directly
          Max.limit = 9007199254740991; // Update the limit property

          // get warranty list
          UserService.getUserWarrantyListNew(Max).then(
            (inner_response) => {
              setTotalItems(inner_response.data.rows);
              inner_response.data.rows.length === 0
                ? setIsEmpty(true)
                : setIsEmpty(false);
            },
            (error) => {}
          );

          // get warranty list
          UserService.getUserWarrantyListNew(params).then(
            (inner_response) => {
              setUserWarrantyList(inner_response.data.rows);
              setTimeout(() => {
                setLoadingspinner(false);
                setMounted(true); // Set mounted to true after the initial load
              }, 250);
            },
            (error) => {}
          );

          getStatsInOut(response.FamilyData.Family.id, famUserID);
        },
        (error) => {}
      );
    });
  };

  // Get warranty List
  const getWarrantyList = (params) => {
    setLoadingspinner(true);

    let Max = { ...params }; // Clone params to avoid modifying it directly
    Max.limit = 9007199254740991; // Update the limit property

    // get all data
    UserService.getUserWarrantyListNew(Max).then(
      (response) => {
        setTotalItems(response.data.rows); // Store the total number of items for pagination
      },
      (error) => {}
    );

    UserService.getUserWarrantyListNew(params).then(
      (response) => {
        setUserWarrantyList(response.data.rows);
        setTimeout(() => {
          setLoadingspinner(false);
        }, 250);
      },
      (error) => {}
    );
  };

  // Get warranty List
  const fetchTasks = (page, limitVal) => {
    setLoadingspinner(true);
    const offset1 = (page - 1) * 50;
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limitVal,
      offset: offset1,
      filtBy: filtValue,
      status: expValue,
      monthType: monthType,
    };

    // get all data
    UserService.getUserWarrantyListNew(params).then(
      (response) => {
        setUserWarrantyList(response.data.rows);
        setLoadingspinner(false);
      },
      (error) => {}
    );
  };

  const getStatsInOut = (familyId, userId) => {
    UserService.getUserStatsInOut(familyId, userId).then(
      (response) => {
        var numWithZeroes = response.OutWarrantyTotalPrice.toLocaleString(
          "en",
          {
            useGrouping: false,
            minimumFractionDigits: 2,
          }
        );

        setOutWarrantyTotalPrice(numWithZeroes);

        var numWithZeroes1 = response.InWarrantyTotalPrice.toLocaleString(
          "en",
          {
            useGrouping: false,
            minimumFractionDigits: 2,
          }
        );

        setInWarrantyTotalPrice(numWithZeroes1);
      },
      (error) => {}
    );
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setSearchStr("");
    setsearchValue("");
    setLoadingspinner(true);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: "",
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: filtValue,
      status: expValue,
      monthType: monthType,
    };
    getWarrantyList(params);
  };

  // search box
  useEffect(() => {
    if (familyDetails.FamilyData !== undefined) {
      if (searchStr.length === 0) {
        // clearSearch();
        setShowSearchText(false);
        setLoadingspinner(true);
        setSearchStr("");
        setsearchValue("");
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: "",
          sort: sortValue,
          order: orderValue,
          limit: limit,
          offset: offset,
          filtBy: filtValue,
          status: expValue,
          monthType: monthType,
        };
        getWarrantyList(params);
      }
    }
  }, [searchStr]);

  // Search warranties
  const warrantySearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoadingspinner(true);
      let srchStr = e.target.value;
      setSearchStr(e.target.value);
      setsearchValue(srchStr);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: srchStr,
          sort: sortValue,
          order: orderValue,
          limit: limit,
          offset: offset,
          filtBy: filtValue,
          status: expValue,
          monthType: monthType,
        };
        getWarrantyList(params);
      }
    }
  };

  // Search modal
  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  //onchange in filter by & filter by value--
  const handleChange = (val) => {
    if (val === "all") {
      setCheckAll(true);
      setMonthType("all");
      setActiveButton("all");
      setexpValue("");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchValue,
        sort: sortValue,
        order: orderValue,
        limit: limit,
        offset: offset,
        filtBy: val,
        status: "",
        monthType: "all",
      };
      getWarrantyList(params);
    } else if (val === "In Warranty" || val === "Out Warranty") {
      setCheckAll(false);
      setMonthType("");
      setexpValue(val);
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchValue,
        sort: sortValue,
        order: orderValue,
        limit: limit,
        offset: offset,
        filtBy: "all",
        status: val,
        monthType: "",
      };
      getWarrantyList(params);
    } else {
      setCheckAll(false);
      setMonthType("");
      setfiltValue(val);
      setexpValue("");
      let params = {
        familyId: familyDetails.FamilyData.Family.id,
        userId: familyUserId,
        searchStr: searchValue,
        sort: sortValue,
        order: orderValue,
        limit: limit,
        offset: offset,
        filtBy: val,
        status: "",
        monthType: "",
      };
      getWarrantyList(params);
    }
  };

  const [arrowExpired, setArrowExpired] = useState(true);
  const [arrowPrice, setArrowPrice] = useState(true);

  // expired date onclick function
  const handleExpired = () => {
    setArrowExpired(!arrowExpired);

    if (arrowExpired) {
      // Sort based on expiry date, from highest to lowest
      const sortedList = userWarrantyList.sort(
        (a, b) => new Date(b.expiryDate) - new Date(a.expiryDate)
      );

      setUserWarrantyList([...sortedList]);
    } else {
      // Sort based on expiry date, from lowest to highest
      const sortedList = userWarrantyList.sort(
        (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate)
      );

      setUserWarrantyList([...sortedList]);
    }
  };

  // price onclick function
  const handlePrice = () => {
    setArrowPrice(!arrowPrice);

    if (arrowPrice) {
      // Sort based on price, from highest to lowest
      const sortedList = userWarrantyList.sort((a, b) => b.price - a.price);

      setUserWarrantyList([...sortedList]);
    } else {
      // Sort based on price, from lowest to highest
      const sortedList = userWarrantyList.sort((a, b) => a.price - b.price);

      setUserWarrantyList([...sortedList]);
    }
  };

  const dropdownRef = useRef(null); // Ref for dropdown
  const buttonRef = useRef(null); // Ref for the dropdown button

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle the dropdown state
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false); // Close the main dropdown
    setIsDropdownOpen1(false); // Close second dropdown
  };

  useEffect(() => {
    // Handler to detect clicks outside of the dropdown
    const handleClickOutside = (event) => {
      // handle filter dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        closeDropdown();
      }
    };

    // Bind the event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update screen width on resize
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [pageSize, setPageSize] = useState(50); // Default value is 50

  const handlePageSizeChange = (event) => {
    const selectedValue = parseInt(event.target.value, 50); // Get the selected value as a number
    setPageSize(selectedValue); // Update the state
    setlimit(selectedValue);

    fetchTasks(currentPage, selectedValue);
    setCurrentPage(1); // Reset to page 1 after limit change
  };

  useEffect(() => {
    if (mounted) {
      fetchTasks(currentPage, limit);
    }
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems.length / limit);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [activeButton, setActiveButton] = useState("all"); // Initial state set to 'voucher'
  // Function to handle button click
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType); // Set active button based on click
    setMonthType(buttonType);
    let params = {
      familyId: familyDetails.FamilyData.Family.id,
      userId: familyUserId,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limit,
      offset: offset,
      filtBy: "all",
      status: "",
      monthType: buttonType,
    };
    getWarrantyList(params);
  };

  return (
    <>
      <Container
        fluid
        ref={myRef}
        style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
        className="d-flex flex-column"
      >
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        <>
          <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
            <Col>
              <h1 className="lh-base mb-0">{title}</h1>
            </Col>

            {/* filter  */}
            <Col
              xs="auto"
              className="position-relative"
              ref={buttonRef} // Ref to track button for outside clicks
            >
              <button
                className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                  isDropdownOpen ? "dropdown-arrow" : ""
                }`}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                onClick={toggleDropdown} // Toggle dropdown on click
                aria-expanded={isDropdownOpen}
              >
                <i className="fa-solid fa-sm fa-sliders-h lh-1 align-middle"></i>
                <span className="text-dark ms-md-2 d-none d-xl-block">
                  Filters
                </span>
              </button>
              {/* main dropdown  */}
              <ul
                className={`dropdown-menu dropdown-parent mt-2 ${
                  isDropdownOpen ? "show" : ""
                }`}
                ref={dropdownRef}
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  {filterOption.map((option) => (
                    <div
                      key={option.value}
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        color:
                          selectedOption1 &&
                          selectedOption1.value === option.value
                            ? "#020617"
                            : "",
                        backgroundColor:
                          selectedOption1 &&
                          selectedOption1.value === option.value
                            ? "#36f1cc"
                            : "",
                      }}
                      onClick={() => {
                        setSelectedOption1(option);
                        if (option.value === "all") {
                          closeDropdown(); // Close dropdown after selecting an option}
                          handleChange(option.value);
                        } else {
                          setIsDropdownOpen1(true);
                          setSelectedFilterType(option.value);
                        }
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </li>
              </ul>
              {/* dropdown based on main dropdown filter  */}
              {isDropdownOpen1 && (
                <ul
                  className={`dropdown-menu dropdown-child mt-2 show`}
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    top:
                      screenWidth > 767
                        ? selectedFilterType === "warrantyTenure"
                          ? "5.5rem"
                          : "7.5rem"
                        : "12.75rem",
                    left: screenWidth > 1200 ? "16rem" : "",
                    right:
                      screenWidth > 1200
                        ? ""
                        : screenWidth < 1200 && screenWidth > 767
                        ? "4rem"
                        : "0",
                  }}
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    style={{
                      position: "absolute",
                      left: screenWidth > 1200 ? "-13px" : "",
                      right: screenWidth < 1200 ? "-13px" : "",
                      width: "0px",
                      height: "0px",
                      borderLeft: "8px solid transparent",
                      borderRight: "10px solid transparent",
                      borderBottom: "15px solid white",
                      transform:
                        screenWidth < 1200
                          ? "rotate(-30deg)"
                          : "rotate(-210deg)",
                      top: screenWidth < 1200 ? "8px" : "16px",
                      display: screenWidth > 767 ? "" : "none",
                    }}
                  ></div>{" "}
                  {/* Arrow */}
                  <li>
                    {(selectedFilterType === "warrantyTenure"
                      ? sortOption1
                      : sortOption
                    ).map((option) => (
                      <div
                        key={option.value}
                        className="dropdown-item"
                        style={{
                          cursor: "pointer",
                          color:
                            selectedOption2 &&
                            selectedOption2.value === option.value
                              ? "#020617"
                              : "",
                          backgroundColor:
                            selectedOption2 &&
                            selectedOption2.value === option.value
                              ? "#36f1cc"
                              : "",
                        }}
                        onClick={() => {
                          handleChange(option.value);
                          setSelectedOption2(option);
                          setIsDropdownOpen(false); // Close second dropdown after selection
                          setIsDropdownOpen1(false); // Close second dropdown after selection
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </li>
                </ul>
              )}
            </Col>

            <Col xs="auto">
              <Button
                variant="white"
                className="d-xl-none border-white shadow"
                onClick={handleShowSearch}
              >
                <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
              </Button>
              <InputGroup className="shadow rounded d-none d-xl-flex">
                <Form.Control
                  type="text"
                  placeholder="Search warranties"
                  value={searchStr}
                  onChange={(e) => setSearchStr(e.target.value)}
                  onKeyDown={warrantySearch}
                  className="border-white"
                />
                {!searchStr && (
                  <Button
                    variant="white"
                    className="border-white"
                    type="submit"
                  >
                    <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
                  </Button>
                )}
                {searchStr && (
                  <Button
                    variant="white"
                    className="border-white"
                    type="submit"
                    onClick={clearSearch}
                  >
                    <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
                  </Button>
                )}
              </InputGroup>
            </Col>
            <Col xs="auto">
              <Link
                to={addWarrantyUrl}
                className="btn btn-primary d-md-flex align-items-md-center"
              >
                <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                <span className="ms-md-2 d-none d-md-block">Add new</span>
              </Link>
            </Col>
          </Row>
          {loadingspinner ? (
            <>
              <Loading />
            </>
          ) : (
            <section className="bg-white p-4 p-md-5 p-lg-6 rounded-4 shadow h-100">
              <Row className="gx-3 gy-2 gx-md-5 gx-lg-7 mb-8">
                <Col xs="12" md="auto">
                  <div className="small fw-medium">In warranty</div>
                  <div className="h1 text-success mb-0">
                    £{" "}
                    {Number.isInteger(InWarrantyTotalPrice)
                      ? InWarrantyTotalPrice.toLocaleString("en-US")
                      : Math.trunc(InWarrantyTotalPrice).toLocaleString(
                          "en-US"
                        )}
                  </div>
                </Col>
                <Col xs="12" md="auto">
                  <div className="small fw-medium">Out of warranty</div>
                  <div className="h1 text-danger mb-0">
                    £{" "}
                    {Number.isInteger(OutWarrantyTotalPrice)
                      ? OutWarrantyTotalPrice.toLocaleString("en-US")
                      : Math.trunc(OutWarrantyTotalPrice).toLocaleString(
                          "en-US"
                        )}
                  </div>
                </Col>
                <Col className="d-flex justify-content-end align-items-end">
                  <div
                    className={`btn btn-sm ${
                      activeButton === "all" ? "bg-cayn" : "btn-white"
                    }`}
                    onClick={() => handleButtonClick("all")}
                    style={{
                      borderRadius: "8px 0 0 8px",
                      ...(!checkAll && {
                        pointerEvents: "none", // Disable click events
                        opacity: 0.5, // Visually indicate it's disabled
                      }),
                    }}
                  >
                    All
                  </div>
                  <div
                    className={`btn btn-sm ${
                      activeButton === "upcoming" ? "bg-cayn" : "btn-white"
                    }`}
                    onClick={() => handleButtonClick("upcoming")}
                    style={{
                      borderRadius: "0",
                      ...(!checkAll && {
                        pointerEvents: "none", // Disable click events
                        opacity: 0.5, // Visually indicate it's disabled
                      }),
                    }}
                  >
                    Upcoming
                  </div>
                  <div
                    className={`btn btn-sm ${
                      activeButton === "next_month" ? "bg-cayn" : "btn-white"
                    }`}
                    onClick={() => handleButtonClick("next_month")}
                    style={{
                      borderRadius: "0 8px 8px 0",
                      ...(!checkAll && {
                        pointerEvents: "none", // Disable click events
                        opacity: 0.5, // Visually indicate it's disabled
                      }),
                    }}
                  >
                    Next
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center gx-3 gy-0 gx-md-4 gx-xl-3 gx-xxl-4">
                <Col xs="13" md="10" xl="5" xxl="6" className="order-xl-first">
                  <span className="small fw-semibold fs-17">Product</span>
                </Col>
                <Col xs="auto" className="order-md-last ms-auto">
                  <Button
                    className="btn-sm btn-square invisible h-100"
                    variant="link"
                  >
                    {/* maintain alignment */}
                  </Button>
                  <Button
                    className="btn-sm btn-square invisible h-100"
                    variant="link"
                  >
                    {/* maintain alignment */}
                  </Button>
                </Col>
                {/* if md breakpoint */}
                <Col
                  md="5"
                  lg="4"
                  xl="3"
                  className="order-lg-5 d-none d-md-block"
                  style={{ cursor: "pointer" }}
                  onClick={handleExpired}
                >
                  <span className="small fw-semibold fs-17">Expires on</span>
                  <i
                    className={`fa-solid fa-xs ms-1 ${
                      arrowExpired ? "fa-arrow-up " : "fa-arrow-down"
                    }`}
                  ></i>
                </Col>
                {/* if lg breakpoint */}
                <Col
                  md="3"
                  xl="3"
                  className=" d-none d-md-block"
                  style={{ cursor: "pointer" }}
                  onClick={handlePrice}
                >
                  <span className="small fw-semibold fs-17">Price</span>
                  <i
                    className={`fa-solid fa-xs ms-1 ${
                      arrowPrice ? "fa-arrow-up " : "fa-arrow-down"
                    }`}
                  ></i>
                </Col>

                {/* if xl breakpoint */}
                <Col xl="4" className="order-xl-first d-none d-xl-block">
                  <span className="small fw-semibold fs-17">Supplier</span>
                </Col>

                {/* if xl breakpoint */}
                <Col xl="3" className="order-xl-first  d-none d-xl-block">
                  <span className="small fw-semibold fs-17">Category</span>
                </Col>
              </Row>
              <hr className="mt-1 mb-3" />
              {isEmpty ? (
                <div className="text-center">
                  <p className="mt-4">
                    You currently have no warranties set up!
                  </p>
                  <Link
                    to={addWarrantyUrl}
                    className="btn btn-primary btn-lg d-inline-flex align-items-center"
                  >
                    <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                    <span className="ms-md-2 d-none d-md-block">Add new</span>
                  </Link>
                </div>
              ) : (
                <>
                  {userWarrantyList.map((warData, index) => (
                    <>
                      <Row
                        className="align-items-center gx-3 gy-0 gx-md-4 gx-xl-3 gx-xxl-4"
                        onClick={() => viewDetailsRoute(warData.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <Col
                          xs="13"
                          md="10"
                          xl="5"
                          xxl="6"
                          className="order-xl-first"
                        >
                          <div className="d-flex align-items-center">
                            <div className="text-truncate">
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250 }}
                                overlay={
                                  <Tooltip id="">
                                    <div className="">
                                      {warData.productName
                                        ? warData.productName
                                        : "--"}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <div className="small text-dark lh-sm text-truncate">
                                  {warData.productName
                                    ? warData.productName
                                    : "--"}
                                </div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs="auto"
                          className="order-md-last ms-auto d-flex align-items-center gap-2"
                        >
                          {warData.reminder && (
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250 }}
                              overlay={
                                <Tooltip id="">
                                  <div className="">
                                    Reminder set for this warranty
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div className="d-none d-lg-flex me-2">
                                <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light rounded-circle">
                                  <i className="fa-solid fa-md fa-bell"></i>
                                </span>
                              </div>
                            </OverlayTrigger>
                          )}
                          {/* comment  */}
                          {warData.invoiceDocument ? (
                            <div className="d-none d-lg-flex">
                              <span className="badge badge-xs d-inline-flex align-items-center bg-warning d-flex align-items-center">
                                <i className="fa fa-file"></i>{" "}
                                <span
                                  className="ms-1 fs-12"
                                  style={{ marginTop: "3px", minWidth: "55px" }}
                                >
                                  {JSON.parse(warData.invoiceDocument).length}{" "}
                                  {JSON.parse(warData.invoiceDocument).length >
                                  1
                                    ? "Files"
                                    : "File"}
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{ width: "87px" }}
                              className="d-none d-lg-flex"
                            ></div>
                          )}
                          <Button
                            className="btn-sm btn-square btn-interactive"
                            variant="link"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents event from bubbling up
                              editWarrantyRoute(warData.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-edit"></i>
                          </Button>
                          <Button
                            className="btn-sm btn-square btn-interactive"
                            variant="link"
                            onClick={() => viewDetailsRoute(warData.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-chevron-right"></i>
                          </Button>
                        </Col>
                        {/* if md breakpoint */}
                        <Col
                          md="5"
                          lg="4"
                          xl="3"
                          className="order-lg-5 d-none d-md-block"
                        >
                          <span className="text-danger d-inline small">
                            {moment(warData.expiryDate).format("MMM Do, YYYY")}
                          </span>
                        </Col>
                        {/* if lg breakpoint */}
                        <Col md="3" xl="3" className=" d-none d-md-block">
                          <div className="d-inline small">£{warData.price}</div>
                        </Col>

                        {/* if xl breakpoint */}
                        <Col
                          xl="4"
                          className="order-xl-first d-none d-xl-block"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <div class="img-wrapper img-wrapper-warranty-product flex-shrink-0 d-none d-md-flex">
                              <img
                                src={
                                  warData.Supplier && warData.Supplier.logo
                                    ? warData.Supplier.logo
                                    : "--"
                                }
                                alt="logo"
                                className="img-fluid w-100 h-100"
                              />
                            </div>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250 }}
                              overlay={
                                <Tooltip id="">
                                  <div className="">
                                    {warData.Supplier && warData.Supplier.name
                                      ? warData.Supplier.name === "Others"
                                        ? warData.otherSupplierName
                                        : warData.Supplier.name
                                      : "--"}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div className="small  text-dark lh-sm text-truncate">
                                {warData.Supplier && warData.Supplier.name
                                  ? warData.Supplier.name === "Others"
                                    ? warData.otherSupplierName
                                    : warData.Supplier.name
                                  : "--"}
                              </div>
                            </OverlayTrigger>
                          </div>
                        </Col>

                        {/* if xl breakpoint */}
                        <Col
                          xl="3"
                          className="order-xl-first  d-none d-xl-block"
                        >
                          <div className="d-flex align-items-center gap-2">
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250 }}
                              overlay={
                                <Tooltip id="">
                                  <div className="">
                                    {warData.Category === null ||
                                    warData.Category === undefined
                                      ? ""
                                      : warData.Category.CategoryName}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <div className="small text-dark lh-sm text-truncate">
                                {warData.Category === null ||
                                warData.Category === undefined
                                  ? ""
                                  : warData.Category.CategoryName}
                              </div>
                            </OverlayTrigger>
                          </div>
                        </Col>
                      </Row>
                      {index !== userWarrantyList.length - 1 && (
                        <hr className="my-3" />
                      )}
                    </>
                  ))}
                </>
              )}

              {userWarrantyList.length === 0 && !isEmpty && (
                <>
                  <p className="text-center mt-4">
                    There are no results for the query
                  </p>
                </>
              )}

              {/* pagination  */}
              <div className="d-sm-flex align-items-center gap-2 justify-content-end mt-7 fs-14 bold-colour pt-3">
                {/* Page Size Dropdown */}
                <div className="d-flex align-items-center gap-2 me-3  mb-3 mb-sm-0">
                  <label htmlFor="pageSize">Page Size:</label>
                  <select
                    id="pageSize"
                    value={pageSize} // Bind the state to the select element
                    onChange={handlePageSizeChange}
                    className="form-select p-2 me-2 fs-14"
                    style={{ width: "75px", display: "inline-block" }}
                  >
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                  </select>
                  <div>
                    Showing <strong>{userWarrantyList.length}</strong> of{" "}
                    <strong>{totalItems.length}</strong>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  {/* Previous Button */}
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <i class="fas fa-chevron-left "></i>
                  </button>
                  <div>
                    Page <strong>{totalPages === 0 ? 0 : currentPage}</strong>{" "}
                    of <strong>{totalPages}</strong>
                  </div>
                  {/* Next Button */}
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage * limit >= totalItems.length}
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </section>
          )}
        </>

        {/* Search for mobile */}
        <Modal show={showSearch} onHide={handleCloseSearch}>
          <Modal.Header closeButton>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="rounded shadow">
              <Form.Control
                type="text"
                placeholder="Search warranties"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                onKeyDown={warrantySearch}
                className="border-white"
              />
              {!searchStr && (
                <Button variant="white" className="border-white" type="submit">
                  <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
                </Button>
              )}
              {searchStr && (
                <Button
                  variant="white"
                  className="border-white"
                  type="submit"
                  onClick={clearSearch}
                >
                  <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
                </Button>
              )}
            </InputGroup>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default Warranties;
