import React from "react";
import { Row, Col, Modal } from "react-bootstrap";
import DownLoadIcon from "../../assets/images/download-icon.svg";
import { useState } from "react";
import PDFViewer from "pdf-viewer-reactjs";
import axios from "axios";

function RenewalHistoryCard({ invoice }) {
  const [showDoc, setshowDoc] = useState(false);
  const [docLink, setdocLink] = useState("");
  const downloadFile = (file) => {
    window.location.href = file;
  };
  const viewFile = (file) => {
    setshowDoc(true);
    setdocLink(file);

    const header = {
      Accept: "application/json, text/plain, /",
      "Content-Type": "multipart/form-data",
    };
    //get invoice s3 data
    axios
      .get(file, header)
      .then((response) => {})
      .catch((error) => {});
  };
  const closeDoc = () => {
    setshowDoc(false);
    setdocLink("");
  };

  return (
    <div className="renewal-history-card my-2">
      <Row className="space-between align-items-center">
        <div className="">
          {/* <Row className="">
            <Col className="col-lg-3 py-3">
              <p className="mb-0 text-horizonblue font-small">Payment Date</p>
              <p className="mb-0 medium-text bold">{invoice.payment_date}</p>
            </Col>
            <Col className="col-lg-3 py-3">
              <p className="mb-0 text-horizonblue font-small">Amount</p>
              <p className="mb-0 medium-text  bold">
                {invoice.currency}
                {invoice.amount === ".0" ? "0.00" : invoice.amount}
              </p>
            </Col>
            <Col className="col-lg-3 py-3">
              <p className="mb-0 text-horizonblue font-small">Invoice Number</p>
              <p className="mb-0 medium-text  bold">{invoice.inv_number}</p>
            </Col>
            <Col className="col-lg-3  p-0 ">
              <button
                className="download-invoice-btn my-3 mx-1"
                onClick={() => downloadFile(invoice.file)}
              >
                Download Invoice
                <img src={DownLoadIcon} alt="Download" />
              </button>
            </Col>
          </Row> */}

          <hr class="mb-3"></hr>
          <Row class="gx-3 gy-0">
            <Col xs="9" md="12" lg="14" xl="14" xxl="14">
              <div className="bold-colour fw-bold">{invoice.payment_date}</div>
            </Col>
            <Col xs="6" md="5" lg="4" xl="4" xxl="4">
              <div className="bold-colour fw-bold">
                {" "}
                {invoice.currency}
                {invoice.amount === ".0" ? "0.00" : invoice.amount}
              </div>
            </Col>
            <Col
              xs="9"
              md="7"
              lg="6"
              xl="6"
              xxl="6"
              className="d-flex align-items-center"
            >
              <div className="bold-colour fw-bold me-2">
                {invoice.inv_number}
              </div>
              <div>
                <i
                  class="fa fa-download"
                  style={{ cursor: "pointer" }}
                  onClick={() => downloadFile(invoice.file)}
                ></i>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <Modal
        show={showDoc}
        onHide={closeDoc}
        className="invite_au_modal"
        scrollable={true}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title className="mx-1">
            <h3 className="mb-0">View Invoice Document</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-1">
          <PDFViewer
            document={{
              url:
                // "https://stripe-upload-api.s3.us-west-1.amazonaws.com/uploads/file_1N7zhcHPjiF3hUjmV3YlNRdI?response-content-disposition=attachment%3B%20filename%3D%22Invoice-ELINV-11312.pdf%22%3B%20filename%2A%3DUTF-8%27%27Invoice-ELINV-11312.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQOF7EIWLDVW4XLTG%2F20230515%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230515T113633Z&X-Amz-Expires=600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEOv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCRujKap00bORs9A7ge7tTDb2S568Hh2yqrCqtuV82f7AIgFk7dLw%2BtSMzh74Gf2HtehpSGDdVj9n9MGHyoaP5qfdoqsgUIFBAAGgwwMzA0NjU2MDcwNjIiDMi3LCKdtVlnf%2Fwk1CqPBepeP1M6fy4lCsc0Miw5mxqZ1wAF%2BfySnjFaR8OrUBEbzbbyZGyPzd7m%2BdmCWfWGBaOVncvZXoadomqnd3RRNqOHXTPGFzdfZVPSHs3M8faob5cjvJALc9PkftWbnMbmw0%2Fg7t70ULSPsUigtsJvZrMX1o2aT9RTt7AOZTdE3rWMPd8h%2FLikiWP3ctpQoc%2BXelhAmkmht06pXTAcOM6t4gbW6nPcgCCSGHXTDWDe0afUGLfBQ0Z2vND%2B60qFUEBuQ1mDVX%2FLdEdxjbbG93U76T%2F9n%2BFjDU5wOz3Tng9Vji3OqckxgCs3EAzg2659%2BG2RA0h6zspCpt4r%2FbDmmBQkRUsaKsoISyi1YHQGuolkQgZalMx8ttynSkFP4KNM%2F99UB43FsN%2BK13%2FpftWFSR3BQ%2Fzq0CajPvZeA7TU2k2tjJgHX4LquT30tOdkn4p7ZEruilrJDn4X10pLztqPKYjO%2F3Uvtg4lMX%2B3xYdYSUy4tkVCNK5xV6bjPc0rMZu7oTgo0L4mvpfSumSiX%2FSGz222y4b4KFtDRpk%2BUEgl3C2YcA63Qbt5HK5ePk%2F%2FhZ4FJ0SvVsSfEIEBNIYXS23cSDTsX4I%2FoDU8acqNoHmo8a%2B807FXlOCl%2BXzHQEGtPc1918K732m9n2IEAcDx1F2%2FZkKAJct3KygAJuWCgOwl8JuVLTjNRmhJ1Ou8tJ60mJ%2BR7XYod%2BTkYl%2Fh5Sgyl0dYmRWwkhr%2FcjBh8BHlMhSgYtJNuqkgRqKU2LDCOPBRbgtZpYToFaWOQxkY0QaIe7l0kiKgRvoyzqU9O%2Flz%2FuysdPoqZds3iZyz5z7G5R6TAGrvuVqhULSnNeXToHlPV%2BhxhOePP96PEXjO9%2FwJXhPjJ1QG3J8w%2BqiIowY6sQGtG36HJ2MaGhRb%2FetUC%2FyArEzyedz8qgA3Yj7beGXQgpe0H8snrTUPbopYpf9ei3bT7%2BajMgkB8fc5lciZlycyJewEhCFMXvhcmGRne8sGQ%2BwT3qQyIrk%2FZtWXKqMiKKtyRDvRbuhwuDCJF3q5ozcx4gpARmMYvEvT0kYGbmgGdcsaLIK6rZzb8MvnKA6oTo1RYgsqQaZRq2BGFlxbafkGtISyGyBK25l4iy3u%2FmEWTiI%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=d8e88ec22760439fd81955b1cf5f5aaa2c6ae46f202aa34dd530f1ebb789ba84",
                docLink,
            }}
          />
          {/* <FileViewer
            fileType="pdf"
            filePath="https://stripe-upload-api.s3.us-west-1.amazonaws.com/uploads/file_1N7zhcHPjiF3hUjmV3YlNRdI?response-content-disposition=attachment%3B%20filename%3D%22Invoice-ELINV-11312.pdf%22%3B%20filename%2A%3DUTF-8%27%27Invoice-ELINV-11312.pdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAQOF7EIWLDVW4XLTG%2F20230515%2Fus-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230515T113633Z&X-Amz-Expires=600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEOv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQCRujKap00bORs9A7ge7tTDb2S568Hh2yqrCqtuV82f7AIgFk7dLw%2BtSMzh74Gf2HtehpSGDdVj9n9MGHyoaP5qfdoqsgUIFBAAGgwwMzA0NjU2MDcwNjIiDMi3LCKdtVlnf%2Fwk1CqPBepeP1M6fy4lCsc0Miw5mxqZ1wAF%2BfySnjFaR8OrUBEbzbbyZGyPzd7m%2BdmCWfWGBaOVncvZXoadomqnd3RRNqOHXTPGFzdfZVPSHs3M8faob5cjvJALc9PkftWbnMbmw0%2Fg7t70ULSPsUigtsJvZrMX1o2aT9RTt7AOZTdE3rWMPd8h%2FLikiWP3ctpQoc%2BXelhAmkmht06pXTAcOM6t4gbW6nPcgCCSGHXTDWDe0afUGLfBQ0Z2vND%2B60qFUEBuQ1mDVX%2FLdEdxjbbG93U76T%2F9n%2BFjDU5wOz3Tng9Vji3OqckxgCs3EAzg2659%2BG2RA0h6zspCpt4r%2FbDmmBQkRUsaKsoISyi1YHQGuolkQgZalMx8ttynSkFP4KNM%2F99UB43FsN%2BK13%2FpftWFSR3BQ%2Fzq0CajPvZeA7TU2k2tjJgHX4LquT30tOdkn4p7ZEruilrJDn4X10pLztqPKYjO%2F3Uvtg4lMX%2B3xYdYSUy4tkVCNK5xV6bjPc0rMZu7oTgo0L4mvpfSumSiX%2FSGz222y4b4KFtDRpk%2BUEgl3C2YcA63Qbt5HK5ePk%2F%2FhZ4FJ0SvVsSfEIEBNIYXS23cSDTsX4I%2FoDU8acqNoHmo8a%2B807FXlOCl%2BXzHQEGtPc1918K732m9n2IEAcDx1F2%2FZkKAJct3KygAJuWCgOwl8JuVLTjNRmhJ1Ou8tJ60mJ%2BR7XYod%2BTkYl%2Fh5Sgyl0dYmRWwkhr%2FcjBh8BHlMhSgYtJNuqkgRqKU2LDCOPBRbgtZpYToFaWOQxkY0QaIe7l0kiKgRvoyzqU9O%2Flz%2FuysdPoqZds3iZyz5z7G5R6TAGrvuVqhULSnNeXToHlPV%2BhxhOePP96PEXjO9%2FwJXhPjJ1QG3J8w%2BqiIowY6sQGtG36HJ2MaGhRb%2FetUC%2FyArEzyedz8qgA3Yj7beGXQgpe0H8snrTUPbopYpf9ei3bT7%2BajMgkB8fc5lciZlycyJewEhCFMXvhcmGRne8sGQ%2BwT3qQyIrk%2FZtWXKqMiKKtyRDvRbuhwuDCJF3q5ozcx4gpARmMYvEvT0kYGbmgGdcsaLIK6rZzb8MvnKA6oTo1RYgsqQaZRq2BGFlxbafkGtISyGyBK25l4iy3u%2FmEWTiI%3D&X-Amz-SignedHeaders=host&X-Amz-Signature=d8e88ec22760439fd81955b1cf5f5aaa2c6ae46f202aa34dd530f1ebb789ba84"
          /> */}
          {/* <iframe
            srcDoc="file:///C:/Users/LENOVO/Downloads/Invoice-ELINV-10877.pdf"
            width="100%"
            height="90%"
          /> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default RenewalHistoryCard;
