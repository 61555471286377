import React from "react";
import { useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import EL_logo from "../../assets/images/EL_logo-big.svg";

const FamilyCreationConfirmation = (props) => {
  let history = useHistory();
  const myRef = useRef(null);

  function openWizardBtn() {
    history.push("/onboardUser");
    history.push({
      pathname: "/onboardUser",
      fromWizardtSuccsess: true,
    });
  }

  if (props.location.key) {
    return (
      <Container ref={myRef} className="mt-7">
        <Row className="justify-content-center gy-0 mb-6rem">
          <Col xl="21" xxl="18">
            <div className="text-center">
              <Image
                className="hello-text-img new-img mb-7"
                src={EL_logo}
                alt="Check mark"
              />
              {/* <h3 style={{ "font-size": "initial" }}>Congratulations !!</h3> */}
            </div>
            <section
              className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4"
              style={{ minHeight: "24rem" }}
            >
              <h1 className="mb-5 text-center">Welcome to Elderly Life.</h1>
              <div className="mb-5 fs-20 fw-semibold text-center px-md-7">
                We will now ask you a series of questions to get you and all the
                other users set up. 
              </div>
              <div className="mb-7 fs-16  text-center px-md-7">
                Remember, we are always here for you; you can click the chat
                icon at the bottom of each screen and drop us a message whenever
                you need support. 
              </div>
              <button
                className="btn btn-primary m-auto d-flex justify-content-center"
                onClick={openWizardBtn}
              >
                <b>Let’s begin</b>
              </button>
            </section>
          </Col>
        </Row>
      </Container>
    );
  } else {
    window.location.href = "/404";
  }
};

export default FamilyCreationConfirmation;
