import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { Info, Sms } from "../../stories/ActionAlert.stories";
import UserService from "../../services/user.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import { Secondary } from "../../stories/Button.stories";
import Modal from "react-bootstrap/Modal";
import ReactCodeInput from "react-verification-code-input";
import { Medium } from "../../stories/Input.stories";
import { Primary } from "../../stories/Button.stories";

const AuthHeader = (props) => {
  const splitUrl = window.location.href.split("/");
  let { uuid } = useParams();
  let history = useHistory();
  let items = JSON.parse(localStorage.getItem("UserDetails"));
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [formCodeValues, setFormCodeValues] = useState("");
  const [formCodeErrors, setFormCodeErrors] = useState({});
  const [smsErr, setSmsErr] = useState({});
  const [isCodeSubmit, setIsCodeSubmit] = useState(false);
  const [isSMSSubmit, setIsSMSSubmit] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const [showMModel, setShowMModel] = useState(false);
  const [FamilyDataList, setFamilyDataList] = useState();
  const [showUpdateFmlyModal, setShowUpdateFmlyModal] = useState(false);
  const handleUpdateFmlyClose = () => setShowUpdateFmlyModal(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [updateFmlyFormErrors, setUpdateFmlyFormErrors] = useState("");
  const [isUpdateFmlySubmit, setIsUpdateFmlySubmit] = useState(false);
  const initialFmlyNameValues = {
    family_name: "",
  };
  const [updateFmlyFormValues, setUpdateFmlyFormValues] = useState(
    initialFmlyNameValues
  );

  const handleUpdateFmlyChange = (e) => {
    const { name, value } = e.target;
    setUpdateFmlyFormValues({ ...updateFmlyFormValues, [name]: value });
  };

  // code verification model
  const [showVerificationModel, setShowVerificationModel] = useState(false);
  const [smsmodal, setSmsModal] = useState(false);

  const handleShowVerificationModelClose = () => {
    setShowVerificationModel(false);
  };
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  if (!items) {
    history.push("/");
  }

  const handleVerificationChange = (e) => {
    let codeValue = e;
    setFormCodeValues(codeValue);
  };
  const [first, setfirst] = useState("");
  const handleVerificationSubmit = (e) => {
    setfirst("");
    e.preventDefault();
    setFormCodeErrors(validateEmailCode(formCodeValues));
    setIsCodeSubmit(true);
    setLoadingCode(true);
  };
  const validateEmailCode = (values) => {
    setLoadingCode(false);
    const c_errors = {};
    if (values.length !== 6) {
      c_errors.msg = "Please enter valid verification code";
      c_errors.code = "Yes";
      setLoadingCode(false);
      if (first === "") {
        setSuccessToast(false);
        setShowToast(true);
        setToastMessage(c_errors.msg);
      }
    }
    return c_errors;
  };
  const handleResendEmailCode = (userUUID) => {
    setfirst("first");
    setLoadingCode(true);
    UserService.resendUserEmailCode(userUUID).then(
      (response) => {
        setSuccessToast(true);
        setShowToast(true);
        setToastMessage("Verification code sent successfully");
        setFormCodeValues("");
        setLoadingCode(false);
        setShowMModel(false);
        setTimeout(() => {
          setShowVerificationModel(true);
        }, 2000);
      },
      (error) => {}
    );
  };

  const handleResendEmailCodeFirst = (userUUID) => {
    setfirst("");
    setLoadingCode(true);
    UserService.resendUserEmailCode(userUUID).then(
      (response) => {
        setSuccessToast(true);
        setShowToast(true);
        setToastMessage("Verification code sent successfully");
        setFormCodeValues("");
        setLoadingCode(false);
        setShowMModel(false);
        setTimeout(() => {
          setShowVerificationModel(true);
        }, 3000);
      },
      (error) => {}
    );
  };

  const handleSMS = (userUUID) => {
    setfirst("");
    // setLoadingCode(true);
    UserService.resendUserSMSCode(userUUID, {}).then(
      (response) => {
        if (response.status === 200) {
          setSuccessToast(true);
          setShowToast(true);
          setToastMessage("Verification code sent via SMS successfully");
          setFormCodeValues("");
          setTimeout(() => {
            setSmsModal(true);
          }, 3000);
        } else {
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(
            "Contact number not found ! Please update in your profile section"
          );
        }
      },
      (error) => {}
    );
  };

  const handleSMSAgain = (userUUID) => {
    setfirst("");
    // setLoadingCode(true);
    UserService.resendUserSMSCode(userUUID, {}).then(
      (response) => {
        if (response.status === 200) {
          setSuccessToast(true);
          setShowToast(true);
          setToastMessage("Verification code sent via SMS successfully");
        }
      },
      (error) => {}
    );
  };

  const handleVerificationSubmitSMS = (e) => {
    setfirst("");
    e.preventDefault();
    setSmsErr(validateSmsCode(formCodeValues));
    setIsSMSSubmit(true);
  };

  const validateSmsCode = (values) => {
    const c_errors = {};
    setLoadingCode(true);
    if (values.length !== 6) {
      c_errors.msg = "Please enter valid verification code";
      c_errors.code = "Yes";
      setLoadingCode(false);
      setSuccessToast(false);
      setShowToast(true);
      setToastMessage(c_errors.msg);
    } else {
      let data = {
        code: formCodeValues,
      };

      // api call
      UserService.smscheck(items.uuid, data).then(
        (response) => {
          if (response && response.status === 200) {
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Contact number verified");
            const newObj = {
              ...items,
              isContactVerified: true,
            };
            localStorage.setItem("UserDetails", JSON.stringify(newObj));
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            setLoadingCode(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage("Invalid verifiaction code !");
          }
        },
        (error) => {
          if (error.response.status === 400) {
            setLoadingCode(false);
          }
        }
      );
    }
    return c_errors;
  };

  useEffect(() => {
    // email verification form submit
    if (Object.keys(formCodeErrors).length === 0 && isCodeSubmit) {
      let data = {
        code: formCodeValues,
      };
      setLoadingCode(true);
      // api call
      AuthService.verifyUserEmail(data, items.uuid).then(
        (res) => {
          if (res.status === 400) {
            setFormCodeValues("");
            setLoadingCode(false);
            setShowVerificationModel(true);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.error);
          } else {
            setLoadingCode(false);
            setShowVerificationModel(false);
            items["isEmailVerified"] = true;
            localStorage.setItem("UserDetails", JSON.stringify(items));
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Email verified successfully");
          }
        },
        (error) => {
          const errors = {};

          errors.msg = error.response;
          if (errors.msg) {
            setFormCodeErrors(errors);
          }
          setLoadingCode(false);
        }
      );
    } else {
      setLoadingCode(false);
    }
  }, [formCodeErrors]);

  useEffect(() => {
    UserService.userProfile(UserDetails.uuid).then(
      (response) => {
        setFamilyDataList(response.userData.createdAt);
      },
      (error) => {}
    );
  }, []);

  var myData = JSON.parse(localStorage.getItem("UserDetails"));
  if (myData === null || items === null) {
    window.location.href = "/";
  }
  var CreationDate = FamilyDataList;
  var CD = new Date(Date.parse(CreationDate) + 6 * 24 * 60 * 60 * 1000);
  var dd1 = String(CD.getDate()).padStart(2, "0");
  var mm1 = String(CD.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy1 = CD.getFullYear();
  CD = dd1 + "/" + mm1 + "/" + yyyy1;
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + "/" + mm + "/" + yyyy;
  const date = new Date(Date.parse(CreationDate));
  date.setDate(date.getDate() + 6);
  var date1 = date;
  var date2 = new Date();
  useEffect(() => {
    if (date2 > date1) {
      if (items.isEmailVerified === false && items.isActive === true) {
        if (showVerificationModel === true) {
          setShowMModel(false);
        } else {
          setShowMModel(true);
        }
      }
      if (items.isEmailVerified === true && items.isActive === true) {
        if (showVerificationModel === true) {
          setShowMModel(false);
        } else {
          setShowMModel(true);
        }
      }
    } else {
      setShowMModel(false);
    }
  }, [items]);

  const handleFamilyNameSubmit = (e) => {
    e.preventDefault();
    setUpdateFmlyFormErrors(validateFamilyName(updateFmlyFormValues));
    setIsUpdateFmlySubmit(true);
  };

  const validateFamilyName = (values) => {
    const errors = {};
    if (!values.family_name) {
      errors.msg = "Please enter group name";
      errors.family_name = "Yes";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(updateFmlyFormErrors).length === 0 && isUpdateFmlySubmit) {
      setFormLoading(true);
      let data = {
        name: updateFmlyFormValues.family_name,
      };
      // api call
      UserService.updateFamilyName(uuid, data).then(
        (res) => {
          if (res.status === 400) {
            setFormLoading(false);
            setShowUpdateFmlyModal(false);
            setSuccessToast(false);
            setShowToast(true);
            setToastMessage(res.data.message);
          } else {
            setFormLoading(false);
            setShowUpdateFmlyModal(false);
            //executeScroll();
            window.scrollTo(0, 0);
            setSuccessToast(true);
            setShowToast(true);
            setToastMessage("Group Name have been successfully updated");
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        },
        (error) => {
          setFormLoading(false);
        }
      );
    }
  }, [updateFmlyFormErrors]);

  if (
    splitUrl[3] !== "confirmation" &&
    splitUrl[3] !== "onboardUser" &&
    splitUrl[3] !== "newcard_billing_details"
  ) {
    return (
      <div className="header">
        <Container fluid>
          <ToastBlockContainer
            successToast={successToast}
            showToast={showToast}
            setShowToast={setShowToast}
            toastMessage={toastMessage}
          />
          <Row className="justify-content-center gy-0">
            <Col md="24" lg="20" xl="18" xxl="16">
              {splitUrl[3] === "profile" ? (
                items.isEmailVerified && items.isContactVerified ? (
                  ""
                ) : (
                  <Card
                    className={`action-alert-card info-alert mb-7 gap-3`}
                    style={{ background: "transparent", boxShadow: "none" }}
                  >
                    {items.isEmailVerified === false &&
                      items.isActive === true && (
                        <Info
                          variant="info"
                          message="Your email is not verified yet. Please verify your email to continue using our services"
                          onButtonClick={() =>
                            handleResendEmailCodeFirst(items.uuid)
                          }
                        />
                      )}
                    {items.isContactVerified === false &&
                      items.isActive === true && (
                        <Sms
                          variant="sms"
                          message="Your contact number is not verified yet. Please verify your number to continue using our services"
                          onButtonClick={() => handleSMS(items.uuid)}
                        />
                      )}
                  </Card>
                )
              ) : (
                ""
              )}
            </Col>

            {items.isEmailVerified === false && items.isActive === true && (
              <Modal
                show={showVerificationModel}
                onHide={handleShowVerificationModelClose}
                className="verification_modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <h3>Verify Email</h3>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p className="mb-4">
                    We have sent a 6 digit verification code to your email{" "}
                    <strong>{items.email}</strong>.
                  </p>
                  <Form
                    className="verification-form"
                    onSubmit={handleVerificationSubmit}
                  >
                    <div className="mb-2 fs-18 fw-semibold">
                      Verification Code
                    </div>
                    <ReactCodeInput
                      value={formCodeValues}
                      name="code"
                      fields={6}
                      onChange={handleVerificationChange}
                      className={formCodeErrors.code ? "invalid-text" : ""}
                    />
                    <input type="hidden" name="regUser" value={items.uuid} />

                    <div className="mt-5 mb-5">
                      <Secondary
                        children="Verify email"
                        size="mediumbtn"
                        variant="secondary"
                        type=""
                        className="btn btn-primary"
                        font_Weight="600"
                        isLoading={loadingCode ? "true" : "false"}
                        isDisabled={loadingCode ? "true" : "false"}
                      />
                      {/* {loadingCode && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )} */}
                    </div>
                    <div className="">
                      <button
                        onClick={() => handleResendEmailCode(items.uuid)}
                        className="btn btn-white"
                      >
                        {" "}
                        Resend the Code
                      </button>
                    </div>
                  </Form>
                </Modal.Body>
              </Modal>
            )}
          </Row>
          <Modal
            show={showUpdateFmlyModal}
            onHide={handleUpdateFmlyClose}
            className="choose_plan_modal"
          >
            <Modal.Header closeButton>
              {/* <Modal.Title><h3>Confirm Account plan</h3></Modal.Title>*/}
              <Modal.Title>
                <h3>Update Group Name</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form className="family-form" onSubmit={handleFamilyNameSubmit}>
                {/* <p className="mb-1 subtext">Verify the chosen plan and proceed to payment.</p>
                        <p className="subtext">Changed your mind? <Link to="/subscription" className="modal-link" onClick={handleSwitchPlan}> Choose another plan </Link></p> */}
                {Object.keys(formErrors).length !== 0 && isUpdateFmlySubmit ? (
                  <p className="error-txt-msg">{formErrors.msg} </p>
                ) : (
                  ""
                )}
                <Form.Group className="mb-3" controlId="">
                  <Form.Label
                    className={
                      formErrors.familyName
                        ? "invalid-text mt-3 mb-0"
                        : "mt-3 mb-0"
                    }
                  >
                    Group Name
                  </Form.Label>
                  <Medium
                    type="test"
                    errorClass={
                      updateFmlyFormErrors.family_name ? "invalid-input" : ""
                    }
                    placeholder="Enter Group Name"
                    size="medium"
                    name="family_name"
                    value={updateFmlyFormValues.family_name}
                    onChange={handleUpdateFmlyChange}
                    maxlength="40"
                  />
                  <p
                    className={
                      updateFmlyFormErrors.family_name
                        ? "invalid-text subtext mt-1"
                        : "subtext mt-1"
                    }
                  >
                    Add a name to your group on EL platform. It should be under
                    40 characters.
                  </p>
                </Form.Group>
                <div className="text-center mt-5">
                  <Primary
                    children="Update"
                    variant="primary"
                    size="mediumbtn"
                    type="submit"
                    buttonWidth="230px"
                    isLoading={formLoading ? "true" : "false"}
                    isDisabled={formLoading ? "true" : "false"}
                  />
                  {/* {formLoading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} */}
                </div>
              </Form>
            </Modal.Body>
          </Modal>

          <Modal
            show={smsmodal}
            onHide={() => setSmsModal(false)}
            className="choose_plan_modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <h3 className="mb-0">Verify Contact Number</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-4">
                We have sent a 6 digit verification code to verify the contact
                number mentioned against your Elderly Life account .
              </p>
              <Form
                className="verification-form"
                onSubmit={handleVerificationSubmitSMS}
              >
                <div className="mb-2 fs-18 fw-semibold">Verification Code</div>
                <ReactCodeInput
                  value={formCodeValues}
                  name="code"
                  fields={6}
                  onChange={handleVerificationChange}
                  className={formCodeErrors.code ? "invalid-text" : ""}
                />
                <input type="hidden" name="regUser" value={items.uuid} />

                <div className="mt-5 mb-5">
                  <Secondary
                    children="Verify contact number"
                    size="mediumbtn"
                    variant="primary"
                    type=""
                    font_Weight="600"
                    className="btn btn-primary"
                    isLoading={loadingCode ? "true" : "false"}
                    isDisabled={loadingCode ? "true" : "false"}
                  />
                </div>
                <div className="">
                  <button
                    onClick={() => handleSMSAgain(items.uuid)}
                    className="btn btn-white"
                  >
                    {" "}
                    Resend the Code
                  </button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    );
  } else {
    return "";
  }
};
export default AuthHeader;
