/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import UserService from "../../services/user.service";
import InputGroup from "react-bootstrap/InputGroup";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";
import Select from "react-select";
import imageX from "../../assets/images/x.svg";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css"; // Import styles
import "react-calendar/dist/Calendar.css"; // Required for calendar display

// select error style
const customStylesError = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid red",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

// select style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid #aabccf",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

const EditSubscription = (props) => {
  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const myRef = useRef(null);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [Loadingdata, setLoadingdata] = useState(true);
  const [userCreateUpdate, setUserCreateUpdate] = useState();
  const [userCreateUpdateFor, setUserCreateUpdateFor] = useState();
  const [subscriptionsUrl, setSubscriptionsUrl] = useState("");
  const [minEndDate, setMinEndDate] = useState("");
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [browserMsg, setBrowserMsg] = useState("");
  const [browserErrMsg, setBrowserErrMsg] = useState("");
  const [uploadedDocUrl, setUploadedDocUrl] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [response1, setResponse1] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [fileLoader, setFileLoader] = useState(false);
  const executeScroll = () => myRef.current.scrollIntoView();
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  let { uuid, subId, userUUID } = useParams();
  const [otherSupplier, setOtherSupplier] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState();
  const [selectedFrequency, setSelectedFrequency] = useState();
  const [parsedDoc, setParsedDoc] = useState(false);
  // State to manage uploaded files
  const [uploadedDocArray, setUploadedDocArray] = useState([]);
  const [otherModal, setOtherModal] = useState(false);
  const [familyUserId, setFamilyUserId] = useState();
  const splitUrl = window.location.href.split("/");

  const [dateValStart, setDateValStart] = useState(); // Default start date
  const [dateValEnd, setDateValEnd] = useState(); // Default end date

  const [mounted, setMounted] = useState(false); // Default selected date

  //new state add
  const [selectedNewFrequency, setSelectedNewFrequency] = useState();
  const [selectedCategory, setSelectedCategory] = useState();

  const [CategoryData, setCategoryData] = useState([]);

  const initialCategoryOption = [{ label: "Select a category", value: "" }];
  const [CategoryOption, setCategoryOption] = useState(initialCategoryOption);

  const initialSupplierOption = [
    { label: "Select a supplier", value: "", subCat: "" },
  ];
  const [supplierOption, setSupplierOption] = useState(initialSupplierOption);
  const [showInvoiceFile, setShowInvoiceFile] = useState("");

  const initialFrequencyOption = [
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi Annually" },
    { value: "Yearly", label: "Yearly" },
  ];
  const [frequencyOption, setFrequencyOption] = useState(
    initialFrequencyOption
  );
  const [AmountVal, setAmountVal] = useState();

  const handleChange = (e, action) => {
    setValueChk("ValueChk");

    let targetData = {};

    if (action) {
      targetData = {
        name: action.name,
        value: e.value,
      };

      if (action.name === "category") {
        setSelectedCategory(e);

        // find the supplier related to category
        const result = CategoryData.find((product) => product.id === e.value);

        const supData = result.subcategories.map((item) =>
          item.suppliers.map((val) => ({
            value: val.id,
            label: val.name,
            subCat: val.SubCategoryId,
          }))
        );

        setSupplierOption(supData[0]);
        setOtherSupplier("");
        setSelectedSupplier(initialSupplierOption); // Reset the selected supplier
        setFormValues((prevValues) => ({
          ...prevValues,
          supplier: "",
        }));
      }

      if (action.name === "supplier") {
        if (e.label === "Others") {
          setOtherModal(true); // Open the modal
        }
        setSelectedSupplier(e); // Set selected supplier
        setFormErrors((prevErrors) => ({ ...prevErrors, supplier: "" })); // Clear form errors
        setFormValues((prevValues) => ({
          ...prevValues,
          subCategory: e.subCat,
        }));
      }

      if (action.name === "frequency") {
        setSelectedFrequency(e);
        setFormErrors((prevErrors) => ({ ...prevErrors, frequency: "" }));
      }
    } else {
      const { name, value } = e.target;

      if (name === "amount") {
        targetData = {
          name,
          value: value.includes(".")
            ? value.slice(0, value.indexOf(".")) +
              value.slice(value.indexOf("."), value.indexOf(".") + 3)
            : value,
        };

        setAmountVal(targetData.value);
      } else {
        targetData = { name, value };
      }
    }

    const { name, value } = targetData;
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));

    if (name === "start_date" || name === "frequency") {
      const frequencyMapping = {
        Monthly: "1",
        Quarterly: "3",
        "Bi-Annually": "6",
        Yearly: "12",
      };

      const freqMonth =
        frequencyMapping[name === "frequency" ? value : formValues.frequency] ||
        "";
      debugger;
      if (freqMonth) {
        const startDate = name === "start_date" ? value : formValues.start_date;
        setMinEndDate(addMonth(startDate, freqMonth));
      }
    }

    if (name === "uploaded_doc") {
      handleFileUpload(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (mounted) {
      if (dateValStart) {
        setFormValues((prevValues) => ({
          ...prevValues,
          start_date: dateValStart,
        }));
        setFormErrors((prevErrors) => ({ ...prevErrors, start_date: "" }));
      }
    }
  }, [dateValStart]);

  useEffect(() => {
    if (mounted) {
      if (dateValEnd) {
        setFormValues((prevValues) => ({
          ...prevValues,
          end_date: dateValEnd,
        }));
        setFormErrors((prevErrors) => ({ ...prevErrors, end_date: "" }));
      }
    }
  }, [dateValEnd]);

  // Helper Functions
  const handleFileUpload = (documentFile) => {
    if (!documentFile) {
      setFileLoader(false);
      return;
    }

    const MAX_FILE_SIZE = 2; // 2MB
    const allowedFileTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
      "application/msword", // .doc
    ];

    const fileReader = new FileReader();
    setFileLoader(true);
    fileReader.onload = () => {
      if (allowedFileTypes.includes(documentFile.type)) {
        const fileSize = documentFile.size / 1024 / 1024; // Convert to MB
        if (fileSize > MAX_FILE_SIZE) {
          setBrowserErrMsg("OOPS! File size is above 2MB.");
          setFileLoader(false);
        } else {
          uploadFile(documentFile);
        }
      } else {
        setBrowserErrMsg("OOPS! The file format doesn't support.");
        setFileLoader(false);
      }
    };

    fileReader.readAsDataURL(documentFile);
  };
  // file upload
  const uploadFile = (data) => {
    setBrowserErrMsg("");
    setFileLoader(true);

    const formData = new FormData();
    formData.append("file", data, data.name.replace(/ /g, ""));

    UserService.uploadUserDocuments(formData).then(
      (response) => {
        setShowInvoiceFile(data.name.substr(0, 65));
        setBrowserMsg("Successfully uploaded");
        setUploadedDocUrl(response.link);
        setUploadedDocArray((prevArray) => [...prevArray, response.link[0]]); // Push to the array
        setFileLoader(false);
      },
      (error) => {
        setBrowserErrMsg("Something went error");
        setFileLoader(false);
      }
    );
  };

  // Function to delete a file
  const deleteFile = (fileUrl) => {
    setUploadedDocArray((prevFiles) =>
      prevFiles.filter((file) => file !== fileUrl)
    );
  };
  // end
  const [valueChk, setValueChk] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setValueChk("ValueChk");
    setIsSubmit(true);
    setLoading(true);
  };
  const validate = (values) => {
    const errors = {};
    if (!values.product_name) {
      errors.msg = "Please enter the product name";
      errors.product_name = "Yes";
    }
    if (!values.category) {
      errors.msg = "Please select the category";
      errors.category = "Yes";
    }
    if (!values.amount || values.amount === 0 || values.amount < 0) {
      errors.msg = "Please enter the amount";
      errors.amount = "Yes";
    }
    if (!values.supplier) {
      errors.msg = "Please enter the supplier";
      errors.supplier = "Yes";
    }
    if (!values.frequency) {
      errors.msg = "Please enter the frequency";
      errors.frequency = "Yes";
    }
    if (!values.start_date) {
      errors.msg = "Please enter the start date";
      errors.start_date = "Yes";
    }
    if (!values.end_date) {
      errors.msg = "Please enter the end date";
      errors.end_date = "Yes";
    }
    return errors;
  };
  // d - is a moment() call
  const addMonth = (d, n) => {
    debugger;
    return moment(d).add(n, "M").toDate(); // Keep as Date object
  };
  const getFamilyDetails = (userId) => {
    // AU Family details
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdateFor(response.FamilyData.id);

        // SU Family details
        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            setUserCreateUpdate(response.FamilyData.UserId);
          },
          (error) => {}
        );
      },
      (error) => {}
    );
  };

  const getAUFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        setUserCreateUpdate(response.FamilyData.UserId);
        setUserCreateUpdateFor(response.FamilyData.id);
      },
      (error) => {}
    );
  };
  useEffect(() => {
    UserService.relationData(uuid).then((response) => {
      // main user checking
      const mainUser = response.data.au.find(
        (obj) => obj.User.email === UserDetails.email
      );

      const mainArr = [];
      if (mainUser) {
        mainArr.push(mainUser);
      }

      // support user checking
      const suppUser = response.data.au.find((obj) =>
        obj.su.find(
          (family) =>
            family.Family_User &&
            family.Family_User.User.email === UserDetails.email
        )
      );

      const suppArr = [];
      if (suppUser) {
        suppArr.push(suppUser);
      }

      let newArray = [];
      newArray = [...mainArr, ...suppArr];

      const uuidFind = newArray.find((obj) => obj.User.uuid === userUUID);

      userUUID && setFamilyUserId(uuidFind.id);
    });

    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setSubscriptionsUrl("/user_myoutgoings/" + uuid + "/" + userUUID);
      });
    } else {
      getAUFamilyDetails(UserDetails.id);
      setSubscriptionsUrl("/myoutgoings/" + uuid);
    }
    // get single subscription details by ID
    UserService.getSubscriptionDetailsById(subId).then(
      (response) => {
        // Convert back to array
        const parsedUrls = response.data.rows[0].documentUrl
          ? JSON.parse(response.data.rows[0].documentUrl)
          : response.data.rows[0].documentUrl;

        setParsedDoc(parsedUrls);
        setUploadedDocArray((prevArray) => [...prevArray, ...parsedUrls]); // Push to the array
        setLoadingdata(true);

        setOtherSupplier(response.data.rows[0].otherSupplierName);

        const initialValues = {
          product_name: response.data.rows[0].productName,
          supplier: response.data.rows[0].SupplierId,
          category: response.data.rows[0].CategoryId,
          additional_details: response.data.rows[0].productDescription,
          amount: !formErrors.amount
            ? response.data.rows[0].premiumAmount
            : AmountVal,
          frequency: response.data.rows[0].renewalFrequency,
          start_date: moment(response.data.rows[0].startDate).toDate(),
          otherSupplierName: response.data.rows[0].otherSupplierName,
          end_date: response.data.rows[0].endDate
            ? moment(response.data.rows[0].endDate).toDate()
            : null,

          contract_doc: "",
          uploaded_doc: response.data.rows[0].documentUrl,
        };
        setFormValues(initialValues);
        setDateValStart(moment(response.data.rows[0].startDate).toDate());
        setDateValEnd(moment(response.data.rows[0].endDate).toDate());

        let docParams = response.data.rows[0].documentUrl
          ? response.data.rows[0].documentUrl.split("/")
          : "";
        setShowInvoiceFile(docParams[docParams.length - 1]);
        setUploadedDocUrl(response.data.rows[0].documentUrl);
        setIsSwitchOn(response.data.rows[0].reminder);

        if (valueChk === "") {
          // get supplier data
          UserService.getCatbyMod(1).then((resp) => {
            setCategoryData(resp.data.data);
            const selectedData = resp.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            }));
            setCategoryOption(selectedData);
          });

          setSelectedCategory({
            value: response.data.rows[0].CategoryId,
            label: response.data.rows[0].Category
              ? response.data.rows[0].Category.name
              : "",
          });

          setSelectedSupplier({
            value: response.data.rows[0].Supplier
              ? response.data.rows[0].Supplier.id
              : "",
            label: response.data.rows[0].Supplier
              ? response.data.rows[0].Supplier.name
              : "",
            subCat: response.data.rows[0].SubCategoryId,
          });

          setResponse1(response.data.rows[0].renewalFrequency);
          setSelectedFrequency({
            value: response.data.rows[0].renewalFrequency,
            label: response.data.rows[0].renewalFrequency,
          });
          setSelectedNewFrequency(response.data.rows[0].renewalFrequency);
        }
        setTimeout(() => {
          setLoadingdata(false);
          setMounted(true);
        }, 500);
      },
      (error) => {
        setLoadingdata(false);
      }
    );
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && formValues.amount > 0) {
      setLoading(true);
      const urlsAsString =
        uploadedDocArray.length > 0 ? JSON.stringify(uploadedDocArray) : "";
      let data = {
        CategoryId: formValues.category,
        SubCategoryId: formValues.subCategory,
        SupplierId: formValues.supplier,
        productName: formValues.product_name,
        premiumAmount: formValues.amount,
        renewalFrequency: formValues.frequency,
        startDate: formValues.start_date,
        otherSupplierName: formValues.otherSupplierName,
        endDate: formValues.end_date,
        documentUrl: urlsAsString,
        productDescription: formValues.additional_details,
        country: 1,
        currency: 1,
        reminder: isSwitchOn ? 1 : 0,
        familyId: familyDetails.FamilyData.FamilyId,
        FamilyUserId: userUUID ? familyUserId : familyDetails.FamilyData.id,
        updatedBy: userCreateUpdate,
        createdUpdatedFor: userUUID
          ? familyUserId
          : familyDetails.FamilyData.id,
        createdBy: userCreateUpdate,
      };

      const date = new Date();
      if (moment(date).format("YYYY-MM-DD") >= formValues.end_date) {
        setFormErrors({
          end_date: "end_date",
          newErr: "newErr",
        });
      } else {
        // api call
        UserService.updateSubscritpion(data, subId).then(
          (response) => {
            if (response.status === 200 || response.status === 201) {
              setLoading(false);
              setSuccessToast(true);
              setShowToast(true);
              executeScroll();
              window.scrollTo(0, 0);
              setToastMessage("Outgoing updated successfully");
              setTimeout(() => {
                history.push(subscriptionsUrl);
              }, 2000);
            } else {
              setLoading(false);
              setSuccessToast(false);
              setShowToast(true);
              executeScroll();
              window.scrollTo(0, 0);
              setToastMessage("Error");
            }
          },
          (error) => {}
        );
      }
    } else {
      setLoading(false);
      // window.scrollTo(0, 0);
    }
  }, [formErrors]);

  const [otherSupErr, setOtherSupErr] = useState("");
  // Reset dropdown when modal closes
  const handleCloseModal = () => {
    setOtherModal(false); // Close the modal
    setSelectedSupplier({
      label: "Select a supplier",
      value: "",
    }); // Reset the selected supplier
    setFormValues((prevValues) => ({
      ...prevValues,
      supplier: "",
      otherSupplierName: "",
    }));
    setOtherSupErr("");
  };

  const submitModal = () => {
    // Check if the input field is empty
    if (!formValues.otherSupplierName) {
      setOtherSupErr("otherSupErr");
      return; // Do not proceed if the input field is empty
    }
    setOtherSupErr("");
    setOtherSupplier(formValues.otherSupplierName);
    setSelectedSupplier({
      label: "Select a supplier",
      value: "",
    }); // Reset the selected supplier
    setTimeout(() => {
      setOtherModal(false); // Close the modal
    }, 100);
  };

  useEffect(() => {
    if (formValues.start_date && formValues.frequency) {
      const startDate = new Date(formValues.start_date);
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set today's time to midnight for accurate comparison

      let monthsToAdd = 0;

      // Determine the number of months to add based on the frequency
      switch (formValues.frequency) {
        case "Monthly":
          monthsToAdd = 1;
          break;
        case "Quarterly":
          monthsToAdd = 3;
          break;
        case "Bi-Annually":
          monthsToAdd = 6;
          break;
        case "Yearly":
          monthsToAdd = 12;
          break;
        default:
          monthsToAdd = 0; // Default case if no frequency is selected
      }

      const newEndDate = new Date(
        startDate.setMonth(startDate.getMonth() + monthsToAdd)
      );

      setFormValues((prevValues) => ({
        ...prevValues,
        end_date: newEndDate,
      }));

      setDateValEnd(newEndDate);

      setMinEndDate(formValues.start_date); // Update minEndDate
    }
  }, [formValues.start_date, formValues.frequency]);

  console.log(formValues);

  return (
    <Container
      ref={myRef}
      style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
      className="d-flex flex-column"
    >
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      {Loadingdata ? (
        <>
          <Loading />
        </>
      ) : (
        <Row className="justify-content-center gy-0 mb-6rem new-datepicker">
          <Col xl="21" xxl="18">
            <h1 className="lh-base mb-4">Edit outgoing</h1>
            <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
              <Form onSubmit={handleSubmit} encType="multipart/form-data">
                <section>
                  <h2 className="mb-1 mb-md-2">Details</h2>
                  <p className="mb-4">
                    Edit your outgoings – from subscriptions to recurring
                    payments for products and services​
                  </p>
                  <Row className="gy-0 mb-4">
                    <Col md="12" lg="12">
                      <Form.Label
                        className={
                          formErrors.product_name ? "text-danger" : " "
                        }
                      >
                        Name
                      </Form.Label>
                      <Form.Control
                        className={formErrors.product_name ? "is-invalid" : ""}
                        type="text"
                        placeholder="e.g. Car insurance"
                        name="product_name"
                        value={formValues.product_name}
                        onChange={handleChange}
                        maxLength={255}
                      />
                      <Form.Text
                        className={
                          formErrors.product_name ? "text-danger" : " "
                        }
                      >
                        Please enter the product name
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    {
                      <Col md="12" lg="12">
                        <Form.Label
                          className={formErrors.category ? "text-danger" : " "}
                        >
                          Category
                        </Form.Label>
                        <Select
                          styles={
                            formErrors.category
                              ? customStylesError
                              : customStyles
                          }
                          options={CategoryOption}
                          defaultValue={{
                            label: "Select a category",
                            value: "",
                          }}
                          value={selectedCategory}
                          onChange={handleChange}
                          name="category"
                        />
                        <Form.Text
                          className={formErrors.category ? "text-danger" : " "}
                        >
                          Please select your category
                        </Form.Text>
                      </Col>
                    }
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col md="12" lg="12">
                      <Form.Label
                        className={formErrors.supplier ? "text-danger" : " "}
                      >
                        Supplier
                      </Form.Label>
                      {otherSupplier ? (
                        <div className="form-control d-flex align-items-center gap-3 justify-content-between">
                          {otherSupplier}{" "}
                          <button
                            className="rounded-circle bg-white border-1 p-1 border-black d-flex align-items-center justify-content-center"
                            type="button"
                            onClick={() => {
                              setOtherSupplier("");
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                otherSupplierName: "",
                                supplier: "",
                              }));
                              setSelectedSupplier({
                                label: "Select a supplier",
                                value: "",
                              }); // Reset the selected supplier
                            }}
                          >
                            <img
                              src={imageX}
                              alt=""
                              style={{ width: "16px", height: "16px" }}
                            />
                          </button>
                        </div>
                      ) : (
                        <Select
                          styles={
                            formErrors.supplier
                              ? customStylesError
                              : customStyles
                          }
                          options={supplierOption}
                          defaultValue={{
                            label: "Select a supplier",
                            value: "",
                          }}
                          value={selectedSupplier}
                          onChange={handleChange}
                          name="supplier"
                        />
                      )}
                      <Form.Text
                        className={formErrors.supplier ? "text-danger" : " "}
                      >
                        Please select your supplier
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0">
                    <Col xs="24">
                      <Form.Label>
                        Comments
                        <span className="small text-muted fw-normal ms-1">
                          Optional
                        </span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        cols={5}
                        name="additional_details"
                        placeholder="Add any extra details or special notes here​"
                        value={formValues.additional_details}
                        onChange={handleChange}
                        maxLength={255}
                      />
                      <Form.Text>
                        Provide any extra information or specific notes about
                        the outgoing.
                      </Form.Text>
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section>
                  <h3 className="mb-1 mb-md-2">Payment</h3>
                  <p className="mb-4">
                    Set up your payment plan – let’s capture how much and how
                    often you pay for these products or services​
                  </p>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="8">
                      <Form.Label
                        className={formErrors.amount ? "invalid-text" : ""}
                      >
                        Amount
                      </Form.Label>
                      <InputGroup
                        className={formErrors.amount ? "is-invalid" : ""}
                      >
                        <InputGroup.Text>
                          <i className="fa-solid fa-pound-sign fa-sm"></i>
                        </InputGroup.Text>
                        <Form.Control
                          className={
                            formErrors.amount
                              ? "is-invalid invalid-border-right"
                              : ""
                          }
                          type="number"
                          onWheel={(event) => event.currentTarget.blur()}
                          name="amount"
                          value={formValues.amount < 0 ? "" : formValues.amount}
                          onBlur={() => {
                            if (formValues.amount && formValues.amount > 0) {
                              const formattedValue = parseFloat(
                                formValues.amount
                              ).toFixed(2); // Format to 2 decimal places
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                amount: formattedValue, // Update the price with the formatted value
                              }));
                            }
                          }}
                          onChange={handleChange}
                        />
                      </InputGroup>
                      <Form.Text
                        className={formErrors.amount ? "text-danger" : " "}
                      >
                        Please enter the amount
                      </Form.Text>
                    </Col>
                  </Row>
                  <Row className="gy-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="8">
                      <Form.Label>Billing term</Form.Label>
                      <Select
                        styles={
                          formErrors.frequency
                            ? customStylesError
                            : customStyles
                        }
                        options={frequencyOption}
                        defaultValue={{
                          label: "Select a billing frequency",
                          value: "",
                        }}
                        value={selectedFrequency}
                        onChange={handleChange}
                        name="frequency"
                      />
                      <Form.Text
                        className={formErrors.frequency ? "text-danger" : " "}
                      >
                        Please select the billing term
                      </Form.Text>
                    </Col>
                  </Row>
                  {/* <Row className="gy-0 mb-4">
                    <Col md="24">
                      <Form.Label>Has no end date?</Form.Label>
                      <Form.Switch
                        type="checkbox"
                        onChange={(event) => newBtnWZ(event)}
                        value={valNew}
                        id="custom-switch"
                        checked={valNew} // Use 'checked' to control the checkbox state
                      />
                    </Col>
                  </Row> */}
                  <Row className="gy-4 gx-md-4 gy-md-0 mb-4">
                    <Col xs="18" sm="14" md="12" lg="8">
                      <Form.Label
                        className={formErrors.start_date ? "invalid-text" : ""}
                      >
                        Start date
                      </Form.Label>
                      <InputGroup
                        className={formErrors.start_date ? "is-invalid" : ""}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <i className="fa-solid fa-calendar fa-sm"></i>
                        </InputGroup.Text>
                        <DatePicker
                          onChange={setDateValStart}
                          name="start_date"
                          value={dateValStart ? new Date(dateValStart) : null} // Ensure Date object
                          format="dd-MM-yyyy" // Format for display
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          clearIcon={null} // Hide clear button
                          className={`${
                            formErrors.start_date
                              ? "is-invalid invalid-border-right"
                              : ""
                          } form-control`}
                          // view="month" // Month selection
                          // maxDetail="year" // Show Year > Month > Date
                        />
                      </InputGroup>
                      <Form.Text
                        className={formErrors.start_date ? "text-danger" : " "}
                      >
                        Please select the start date
                      </Form.Text>
                    </Col>
                    <Col xs="18" sm="14" md="12" lg="8">
                      <Form.Label
                        className={formErrors.end_date ? "text-danger" : " "}
                      >
                        End date
                      </Form.Label>
                      <InputGroup
                        className={formErrors.end_date ? "is-invalid " : ""}
                      >
                        <InputGroup.Text id="basic-addon1">
                          <i className="fa-solid fa-calendar fa-sm"></i>
                        </InputGroup.Text>
                        <DatePicker
                          onChange={setDateValEnd}
                          name="end_date"
                          value={dateValEnd ? new Date(dateValEnd) : null}
                          format="dd-MM-yyyy" // Format for display
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          minDate={
                            minEndDate ? minEndDate : formValues.start_date
                          } // Prevent past dates
                          clearIcon={null} // Hide clear button
                          className={`${
                            formErrors.end_date
                              ? "is-invalid invalid-border-right"
                              : ""
                          } form-control`}
                        />
                      </InputGroup>
                      <Form.Text
                        className={formErrors.end_date ? "text-danger" : " "}
                      >
                        {formErrors.end_date && !formErrors.newErr
                          ? "Please select the end date"
                          : formErrors.end_date && formErrors.newErr
                          ? "End date must be greater than Today's date"
                          : "Please select the end date"}
                      </Form.Text>
                    </Col>
                  </Row>

                  <Row className="gy-0">
                    <Col md="auto">
                      <Form.Label className="mb-0">Reminder</Form.Label>
                      <p className="mb-2">
                        Standard reminder settings will be applied to this
                        outgoing.
                      </p>
                      <Form.Switch
                        onChange={onSwitchAction}
                        id="custom-switch"
                        checked={isSwitchOn}
                      />
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section class="mb-4">
                  <h3 className="mb-1 mb-md-2">
                    Upload attachment
                    <span className="small text-muted fw-normal ff-body ms-2">
                      Optional
                    </span>
                  </h3>
                  <p className="mb-4">
                    Upload any important documents related to your outgoings –
                    this could be contracts, receipts, or payment
                    confirmations.​
                  </p>
                  <Row className="gy-0">
                    <Col xl="21" xxl="24">
                      <Form.Control
                        className="d-none"
                        type="file"
                        id="upload"
                        name="uploaded_doc"
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                        // value={formValues.uploaded_doc}
                        onChange={handleChange}
                        accept="image/png, image/jpg,  image/jpeg, application/pdf,  application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
                      />
                      {/* {fileLoader ? (
                        <div
                          className=" btn-upload mb-0 form-label"
                          style={{ cursor: "auto" }}
                        >
                          <Loading />
                        </div>
                      ) : showInvoiceFile ? (
                        <div className="btn btn-upload mb-0 form-label justify-content-center d-flex align-items-center gap-2 m-auto">
                          <div>{showInvoiceFile}</div>
                          <button
                            className="btn btn-danger py-1 px-2"
                            onClick={removeUploadedFile} // This function will handle image removal
                          >
                            <i className="fa-solid fa-trash text-white"></i>
                          </button>
                        </div>
                      ) : (
                        <Form.Label
                          className="btn btn-upload mb-0"
                          for="upload"
                        >
                          <i className="fa-solid fa-upload fa-2xl lh-1"></i>
                          <div className="h6 lh-base mt-4 mb-1">
                            Upload an attachment
                          </div>
                          <p className="small fw-normal text-muted mb-0">
                            We accept PNG, JPG, JPEG, PDF & DOC. (Maximum file
                            size 2MB)
                          </p>
                          <p className="mt-3 text-danger">{browserErrMsg}</p>
                        </Form.Label>
                      )} */}
                      {fileLoader ? (
                        <div
                          className=" btn-upload mb-0 form-label"
                          style={{ cursor: "auto" }}
                        >
                          <Loading />
                        </div>
                      ) : (
                        <Form.Label
                          className="btn btn-upload mb-0"
                          for="upload"
                        >
                          <i className="fa-solid fa-upload fa-2xl lh-1"></i>
                          <div className="h6 lh-base mt-4 mb-1">
                            Upload an attachment
                          </div>
                          <p className="small fw-normal text-muted mb-0">
                            We accept PNG, JPG, JPEG, PDF & DOC. (Maximum file
                            size 2MB)
                          </p>
                          <p className="mt-3 text-danger">{browserErrMsg}</p>
                        </Form.Label>
                      )}
                    </Col>
                    {/* uploaded files  */}
                    {uploadedDocArray && uploadedDocArray.length > 0 && (
                      <Col xl="21" xxl="24">
                        <div className="fs-18 bold-colour mb-2 fw-medium mt-3">
                          Files selected for upload
                        </div>
                        <div>
                          {uploadedDocArray.map((file, index) => (
                            <div
                              key={index}
                              className="file-item p-3 mb-3 d-flex align-items-center justify-content-between"
                              style={{
                                boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px",
                                borderRadius: "12px",
                              }}
                            >
                              <div>{file.split("/").pop()}</div>
                              <button
                                type="button" // Prevents the button from submitting the form
                                className="btn btn-danger py-1 px-2"
                                onClick={() => deleteFile(file)} // This function will handle image removal
                              >
                                <i className="fa-solid fa-trash text-white"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </Col>
                    )}
                  </Row>
                </section>
                <div className="d-flex justify-content-end">
                  <Button
                    className="d-flex align-items-center"
                    variant="primary"
                    type="submit"
                    isLoading={loading ? "true" : "false"}
                    isDisabled={loading ? "true" : "false"}
                  >
                    <i className="fa-solid fa-sm fa-save lh-1 align-middle me-2"></i>
                    Save outgoing
                  </Button>
                </div>
              </Form>
            </section>
          </Col>
        </Row>
      )}

      {/* uploaded document   */}
      <Modal show={otherModal} onHide={() => handleCloseModal()} className="">
        <Modal.Header closeButton>
          <Modal.Title className="mx-1">
            <h3 className="mb-0">Add other Supplier name</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-5">
            <Form.Label className={otherSupErr ? "text-danger" : ""}>
              Supplier name
            </Form.Label>
            <Form.Control
              className={`mb-2 ${
                otherSupErr ? "is-invalid invalid-border-right" : ""
              }`}
              type="text"
              maxlength="100"
              placeholder="e.g. Starbucks"
              name="otherSupplierName"
              value={formValues.otherSupplierName}
              onChange={handleChange}
            />
            <Form.Text className={otherSupErr ? "text-danger mb-4" : "mb-4"}>
              Please enter the name of the supplier if you haven't found their
              name in the dropdown
            </Form.Text>
          </div>
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => submitModal()}
          >
            + Add
          </button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default EditSubscription;
