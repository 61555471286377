import React from "react";
import { Card } from "react-bootstrap";
import { Primary } from "../../stories/Button.stories";

function SubscriptionCard(props) {
  const {
    planName,
    planPrice,
    planDuration,
    planImage,
    benefits,
    priceSymbol,
    currentPlan,
    variant,
    buttonClick,
    planType,
    buttonShow,
    loadingModal,
    index,
    fullData,
    isLoading,
    ...rest
  } = props;

  return (
    <div>
      <Card
        className={`story-card subscription-cards ${variant}-cards mb-3`}
        style={{ boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px" }}
      >
        <Card.Body className="p-6">
          <div className="">
            <div className="mb-5">
              <h1 className="mb-3">{planName}</h1>
              <div className="text-info fs-36 fw-bold">
                <strong>
                  {priceSymbol} {planPrice}
                </strong>
              </div>
              <div className="fw-semibold bold-colour">{planDuration}</div>
            </div>

            <p className="fw-semibold bold-colour mb-2">
              <span class="text-success me-2">&#10004;</span>
              {benefits === 1 ? (
                <span>{benefits} Main User</span>
              ) : (
                <span>{benefits} Main Users</span>
              )}
            </p>
            <p className="fw-semibold bold-colour mb-2">
              <span class="text-success me-2">&#10004;</span>
              {benefits === 1 ? (
                <span>{benefits} Support User</span>
              ) : (
                <span>{benefits} Support Users</span>
              )}
            </p>
            <p className="fw-semibold bold-colour mb-2">
              <span class="text-success me-2">&#10004;</span>
              <span>Cancel any time</span>
            </p>
            <p className="fw-semibold bold-colour mb-2">
              <span class="text-success me-2">&#10004;</span>
              <span>No hidden costs</span>
            </p>
          </div>
          {currentPlan && (
            <span className="subscription-btn">
              <Primary
                children="Current Plan"
                variant="info"
                size="outlineMedium"
                font_Weight="600"
              />
            </span>
          )}
          {!currentPlan && buttonShow && (
            <span className="subscription-btn">
              {planType ? (
                planType.length > 0 ? (
                  <div
                    className="btn btn-primary mt-3 d-flex align-items-center gap-2 w-fit"
                    onClick={buttonClick}
                  >
                    Choose Plan{" "}
                    {isLoading && (
                      <div className="page-loader">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className="btn btn-primary mt-3 d-flex align-items-center gap-2 w-fit"
                    onClick={buttonClick}
                  >
                    Try free for 7 days{" "}
                    {isLoading && (
                      <div className="page-loader">
                        <span className="spinner-border spinner-border-sm"></span>
                      </div>
                    )}
                  </div>
                )
              ) : (
                <div
                  className="btn btn-primary mt-3 d-flex align-items-center gap-2 w-fit"
                  onClick={buttonClick}
                >
                  Choose Plan{" "}
                  {isLoading && (
                    <div className="page-loader">
                      <span className="spinner-border spinner-border-sm"></span>
                    </div>
                  )}
                </div>
              )}
            </span>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export default SubscriptionCard;
