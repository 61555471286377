import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Medium } from "../../stories/Input.stories";
import ImagePicker from "../ImagePicker/ImagePicker";
import ProfileModal from "../ProfileModal/ProfileModal";
import VerifyEmailCheck from "../../assets/images/verify-email-check.svg";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import AddPayMtdModal from "../ProfileModal/AddPayMtdModal";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import AuthService from "../../services/auth.service";
import copy from "copy-to-clipboard";
import Loading from "../../common/Loading";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

// Custom hook to track the previous value of a state/prop
function usePrevious(value) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Profile = (props) => {
  const [loader, setloader] = useState(true);
  var uuid = localStorage.getItem("uuid");
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  useEffect(() => {
    UserService.userProfile(UserDetails.uuid).then((response) => {
      setFormValues(response.userData);
      setiniVal(response.userData);
    });
  }, []);
  const [iniVal, setiniVal] = useState({});
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [successToast, setSuccessToast] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChangePhone = (value, data) => {
    setPhoneNum(value);

    const dialCode = data.dialCode;
    const format = "+";
    const result = format.concat(dialCode);
    setCountrycode(result);

    const phone = value.replace(data.dialCode, "");

    setPhoneNumber(phone);

    setFormValues({ ...formValues, countryCode: result, contactNumber: phone });
  };

  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cusDetails, setCusDetails] = useState();
  const [newProPicImg, setNewProPicImg] = useState("");
  const [isProfileSubmit, setIsProfileSubmit] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  // payment modal
  const [showPayment, setShowPayment] = useState(false);
  const handlePaymentClose = () => setShowPayment(false);

  // close update photo modal
  const handleUploadClose = () => setProfileModalOpen(false);

  // Initialize a boolean state
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleProfileUpdateSubmit = (e) => {
    e.preventDefault();
    validate(formValues);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;

    if (!values.firstName) {
      errors.msg = "Please enter the required fields";
      errors.firstName = "Yes";
      isValid = false;
    }

    if (!values.lastName) {
      errors.msg = "Please enter the required fields";
      errors.lastName = "Yes";
      isValid = false;
    }
    if (!values.email) {
      errors.msg = "Please enter the required fields";
      errors.email = "Yes";
      isValid = false;
    } else if (!regex.test(values.email)) {
      errors.msg = "This is not a valid email format!";
      errors.email = "Yes";
      isValid = false;
    }

    setFormErrors(errors);

    if (isValid) {
      updateProfile();
    }
  };
  // handle reset password
  const initialResetValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const [formResetValues, setFormResetValues] = useState(initialResetValues);
  const [formResetErrors, setFormResetErrors] = useState({});
  const [cmPasswordShown, setCmPasswordShown] = useState(false);
  const [oldPasswordShown, setOldPasswordShown] = useState(false);
  const [isResetSubmit, setIsResetSubmit] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const toggleCmPassword = () => {
    setCmPasswordShown(!cmPasswordShown);
  };
  const toggleOldPassword = () => {
    setOldPasswordShown(!oldPasswordShown);
  };

  const [passchk, setPassChk] = useState(false);
  const handleResetChange = (e) => {
    const { name, value } = e.target;
    setFormResetValues({ ...formResetValues, [name]: value });
    //setFormCodeValues(codeValue);
    setPassChk(true);
  };
  const handleResetSubmit = (e) => {
    e.preventDefault();
    setFormResetErrors(validateReset(formResetValues));
    setIsResetSubmit(true);
    setResetLoading(true);
  };
  const validateReset = (values) => {
    const errors = {};
    if (!values.oldPassword) {
      errors.msg = "Please enter the required fields";
      errors.oldPassword = "Yes";
    }
    if (!values.confirmPassword) {
      errors.msg = "Please enter the required fields";
      errors.confirmPassword = "Yes";
    }
    if (!values.newPassword) {
      errors.msg = "Please enter the required fields";
      errors.password = "Yes";
    } else {
      var alphanumerics = /^.{8,40}$/;
      if (!values.newPassword.match(alphanumerics)) {
        errors.msg = "Please enter a valid password";
        errors.password = "Yes";
        setSuccessToast(false);
        executeScroll();
        window.scrollTo(0, 0);
        setShowToast(true);
        setToastMessage(
          "Please enter a valid password with min 8 characters and max 40 characters"
        );
      }
    }

    if (!errors.password) {
      if (values.confirmPassword !== values.newPassword) {
        errors.msg = "Please enter the same password you've entered before";
        errors.confirmPassword = "Yes";
        setSuccessToast(false);
        executeScroll();
        window.scrollTo(0, 0);
        setShowToast(true);
        setToastMessage("Please enter the same password you've entered before");
      }
    }
    return errors;
  };

  const [checkEdit, setCheckEdit] = useState(false);

  // Track the previous values of form fields using the custom hook
  const prevFormValues = usePrevious(formValues);
  const prevAvatar = usePrevious(newProPicImg);

  useEffect(() => {
    // Compare each field strictly with its previous value
    if (
      prevFormValues.firstName &&
      (prevFormValues.firstName !== formValues.firstName ||
        prevFormValues.lastName !== formValues.lastName ||
        prevFormValues.email !== formValues.email ||
        prevFormValues.contactNumber !== formValues.contactNumber)
    ) {
      setCheckEdit(true);
    }
  }, [formValues, newProPicImg, prevFormValues, prevAvatar]);

  const updateProfile = (e) => {
    let formData = {};
    let isEdited = false;

    if (iniVal.firstName !== formValues.firstName) {
      formData.firstName = formValues.firstName;
      UserDetails.firstname = formValues.firstName;
      isEdited = true;
    }
    if (iniVal.lastName !== formValues.lastName) {
      formData.lastName = formValues.lastName;
      UserDetails.lastname = formValues.lastName;
      isEdited = true;
    }
    if (iniVal.email !== formValues.email) {
      formData.email = formValues.email;
      UserDetails.email = formValues.email;
      UserDetails.isEmailVerified = false;
      isEdited = true;
    }
    if (iniVal.contactNumber !== formValues.contactNumber) {
      formData.contactNumber = formValues.contactNumber;
      UserDetails.contactNumber = formValues.contactNumber;
      isEdited = true;
    }
    if (iniVal.countryCode !== formValues.countryCode) {
      formData.countryCode = formValues.countryCode;
      UserDetails.countryCode = formValues.countryCode;
      isEdited = true;
    }
    if (UserDetails.avatar !== newProPicImg) {
      formData.avatar = newProPicImg;
      UserDetails.avatar = newProPicImg;
      isEdited = true;
    }

    if (isEdited) {
      // api call
      setLoading(true);

      UserService.updateUserProfile(formValues.uuid, formData).then((res) => {
        if (res.status === 400 || res.status === 401) {
          window.scrollTo(0, 0);
          setSuccessToast(false);
          setShowToast(true);
          setLoading(false);
          setToastMessage(res.data.message);
        } else {
          window.scrollTo(0, 0);
          setSuccessToast(true);
          setShowToast(true);
          setLoading(false);
          setToastMessage("Profile updated successfully");
          localStorage.setItem("UserDetails", JSON.stringify(UserDetails));
          setTimeout(function () {
            window.location.reload();
          }, 1000);
        }
      });
    } else {
      setLoading(false);
      setFormErrors({ msg: "Please edit the profile details" });
    }
  };

  let history = useHistory();
  let items = localStorage.getItem("UserDetails");
  if (!items) {
    //redirect to Login
    history.push("/");
  }

  useEffect(() => {
    var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
    setNewProPicImg(UserDetails.avatar);
    // reset password submit
    if (Object.keys(formResetErrors).length === 0 && isResetSubmit) {
      let data = {
        password: formResetValues.oldPassword,
        newPassword: formResetValues.newPassword,
        confNewPassword: formResetValues.confirmPassword,
      };
      UserService.resetUserProfilePassword(UserDetails.uuid, data).then(
        (res) => {
          if (res.status === 401) {
            setLoading(false);
            setResetLoading(false);
            setSuccessToast(false);
            executeScroll();
            window.scrollTo(0, 0);
            setShowToast(true);
            setToastMessage(res.data.error);
          } else {
            setLoading(false);
            setResetLoading(false);
            setSuccessToast(true);
            executeScroll();
            window.scrollTo(0, 0);
            setShowToast(true);
            setToastMessage("Password updated successfully");
            // logout
            setTimeout(() => {
              AuthService.logout();
              history.push("/");
            }, 2000);
          }
        },
        (error) => {
          const errors = {};
          if (error.response === undefined) {
            errors.msg = "Something went wrong. Please try again later";
            formResetErrors(errors);
          } else {
            errors.msg = error.response.data.error;
            if (errors.msg) {
              formResetErrors(errors);
            }
          }
          setResetLoading(false);
        }
      );
    } else {
      setResetLoading(false);
    }
  }, [formResetErrors]);
  const [displayIdShow, setdisplayIdShow] = useState("");
  useEffect(() => {
    const result =
      UserDetails.countryCode | UserDetails.contactNumber
        ? UserDetails.countryCode.concat(UserDetails.contactNumber)
        : "";
    setPhoneNum(result);
    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setdisplayIdShow(response.FamilyData.Family.displayId);
        setTimeout(() => {
          setloader(false);
        }, 250);
      }
    );
  }, []);

  return (
    <div className="page-content subscription-pg">
      <Container
        fluid
        ref={myRef}
        style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
        className="d-flex flex-column"
      >
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />
        {loader ? (
          <Loading />
        ) : (
          <>
            <Row className="justify-content-center gy-0 mb-6rem">
              <Col md="24" lg="20" xl="18" xxl="16">
                <h3 className="p-0 mb-3">Edit Profile</h3>
                <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 mb-4 mb-md-6">
                  <h4 className="mb-2">Details</h4>
                  <span className="">Details text will come here</span>

                  {/* Avatar  */}
                  <h5 className="mb-2 pt-5">Avatar</h5>
                  <div>
                    <p className="mb-1 title-heading">Basic Profile</p>

                    {Object.keys(formErrors).length !== 0 && isProfileSubmit ? (
                      <p className="error-txt-msg">{formErrors.msg} </p>
                    ) : (
                      ""
                    )}
                    <ImagePicker
                      newProPicImg={newProPicImg}
                      setProfileModalOpen={setProfileModalOpen}
                    />

                    <Form>
                      <Row>
                        <Col md="12">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label
                              className={
                                formErrors.firstName
                                  ? "invalid-text bold-colour"
                                  : "mb-1 bold-colour"
                              }
                            >
                              First name
                            </Form.Label>
                            <Medium
                              type="text"
                              errorClass={
                                formErrors.firstName ? "invalid-input" : ""
                              }
                              placeholder="Tiffany"
                              size="medium"
                              name="firstName"
                              value={formValues.firstName}
                              className="form-control mb-2"
                              onChange={handleChange}
                              //(event) => this.handleUserInput(event)
                            />
                          </Form.Group>
                        </Col>

                        <Col md="12">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label
                              className={
                                formErrors.lastName
                                  ? "invalid-text bold-colour"
                                  : "mb-1 bold-colour"
                              }
                            >
                              Last name
                            </Form.Label>
                            <Medium
                              type="text"
                              errorClass={
                                formErrors.lastName ? "invalid-input" : ""
                              }
                              placeholder="Walker"
                              size="medium"
                              name="lastName"
                              value={formValues.lastName}
                              className="form-control mb-2"
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <Form.Group className="mb-3" controlId="">
                            <Form.Label
                              className={
                                formErrors.email
                                  ? "invalid-text d-flex justify-content-between bold-colour"
                                  : "mb-1 d-flex justify-content-between bold-colour"
                              }
                            >
                              Email
                              {formValues.isEmailVerified ? (
                                <div className="d-flex gap-1 align-items-center">
                                  <img
                                    src={VerifyEmailCheck}
                                    alt="VerifyEmailCheck"
                                  />
                                  <span>
                                    <small>Verified</small>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </Form.Label>
                            {/* <Form.Control type="email" placeholder="John@gmail.com" /> */}
                            <Medium
                              type="email"
                              errorClass={
                                formErrors.email ? "invalid-input" : ""
                              }
                              placeholder="tiffanyw@gmail.com"
                              size="medium"
                              name="email"
                              value={formValues.email}
                              onChange={handleChange}
                              className="form-control mb-2"
                            />
                            {formErrors.email && (
                              <p className="invalid-text subtext mt-1">
                                Invalid Email
                              </p>
                            )}
                          </Form.Group>
                        </Col>

                        <Col md="12">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label
                              className={
                                "mb-1 d-flex justify-content-between bold-colour"
                              }
                            >
                              Contact Number
                              {formValues.isContactVerified ? (
                                <div className="d-flex gap-1 align-items-center">
                                  <img
                                    src={VerifyEmailCheck}
                                    alt="VerifyEmailCheck"
                                  />
                                  <span>
                                    <small>Verified</small>
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </Form.Label>
                            <div className="profileContact-row">
                              <PhoneInput
                                country="gb"
                                value={phoneNum}
                                onChange={handleChangePhone}
                                countryCodeEditable={false}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>

                  <hr class="my-5"></hr>

                  <h5 className="mb-2">Password</h5>
                  <span className="">Details text will come here</span>
                  <Form onSubmit={handleResetSubmit} className="pt-5">
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label
                            className={
                              formResetErrors.password
                                ? "invalid-text bold-colour"
                                : "mb-1 bold-colour"
                            }
                          >
                            New Password
                          </Form.Label>

                          <InputGroup className="mb-0">
                            <FormControl
                              type={passwordShown ? "text" : "password"}
                              className={
                                formResetErrors.password ? "invalid-input" : ""
                              }
                              size="medium"
                              name="newPassword"
                              value={formResetValues.newPassword}
                              onChange={handleResetChange}
                            />
                            <InputGroup.Text
                              className={
                                formResetErrors.password
                                  ? "invalid-input"
                                  : "pwd-icon"
                              }
                            >
                              <i
                                className={`fa ${
                                  passwordShown ? "fa-eye" : "fa-eye-slash"
                                } password-icon cursor-pointer`}
                                onClick={togglePassword}
                              />
                            </InputGroup.Text>
                          </InputGroup>

                          <small className="form-text">
                            {" "}
                            Enter new password.
                          </small>
                        </Form.Group>
                      </Col>
                      <Col md="12" className="">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label
                            className={
                              formResetErrors.confirmPassword
                                ? "invalid-text bold-colour"
                                : "mb-1 bold-colour"
                            }
                          >
                            New password confirmation
                          </Form.Label>

                          <InputGroup className="mb-0">
                            <FormControl
                              type={cmPasswordShown ? "text" : "password"}
                              className={
                                formResetErrors.confirmPassword
                                  ? "invalid-input"
                                  : ""
                              }
                              size="medium"
                              name="confirmPassword"
                              value={formResetValues.confirmPassword}
                              onChange={handleResetChange}
                            />
                            <InputGroup.Text
                              className={
                                formResetErrors.confirmPassword
                                  ? "invalid-input"
                                  : "pwd-icon"
                              }
                            >
                              <i
                                className={`fa ${
                                  cmPasswordShown ? "fa-eye" : "fa-eye-slash"
                                } password-icon cursor-pointer`}
                                onClick={toggleCmPassword}
                              />
                            </InputGroup.Text>
                          </InputGroup>

                          <small className="form-text">
                            {" "}
                            Enter new password again for confirmation.
                          </small>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="">
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label
                            className={
                              formResetErrors.oldPassword
                                ? "invalid-text bold-colour"
                                : "mb-1 bold-colour"
                            }
                          >
                            Current Password
                          </Form.Label>

                          <InputGroup className="mb-0">
                            <FormControl
                              type={oldPasswordShown ? "text" : "password"}
                              className={
                                formResetErrors.oldPassword
                                  ? "invalid-input"
                                  : ""
                              }
                              size="medium"
                              name="oldPassword"
                              value={formResetValues.oldPassword}
                              onChange={handleResetChange}
                            />
                            <InputGroup.Text
                              className={
                                formResetErrors.oldPassword
                                  ? "invalid-input"
                                  : "pwd-icon"
                              }
                            >
                              <i
                                className={`fa ${
                                  oldPasswordShown ? "fa-eye" : "fa-eye-slash"
                                } password-icon cursor-pointer`}
                                onClick={toggleOldPassword}
                              />
                            </InputGroup.Text>
                          </InputGroup>

                          <small className="form-text">
                            {" "}
                            Enter current password.
                          </small>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Large
                      children="Save"
                      size="mediumbtn"
                      variant="primary"
                      type="submit"
                      className="btn btn-primary "
                      // buttonWidth="259px"
                      isLoading={resetLoading ? "true" : "false"}
                      isDisabled={!passchk ? "true" : "false"}
                    />
                  </Form>

                  <div className="mt-5 d-flex align-items-center justify-content-end">
                    <Large
                      children="Save"
                      className="btn btn-primary "
                      size="mediumbtn"
                      variant="primary"
                      type=""
                      onClick={handleProfileUpdateSubmit}
                      // buttonWidth="259px"
                      isLoading={loading ? "true" : "false"}
                      isDisabled={checkEdit ? "false" : "true"}
                    />
                    {/* {loading && (
                  <span className="mr-4 spinner-border spinner-border-sm"></span>
                )} */}
                  </div>
                </section>
              </Col>
            </Row>
          </>
        )}

        <Modal
          show={profileModalOpen}
          onHide={handleUploadClose}
          className="profile_modal"
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Update Profile Photo</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProfileModal
              modalOpen={profileModalOpen}
              setModalOpen={setProfileModalOpen}
              newProPicImg={newProPicImg}
              setNewProPicImg={setNewProPicImg}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={showPayment}
          onHide={handlePaymentClose}
          className="payment_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Add New Card</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-3 subtext">
              Add the details below to save a new card
            </p>
            <AddPayMtdModal customerId={cusDetails} />
          </Modal.Body>
        </Modal>
      </Container>
    </div>
  );
};
export default Profile;
