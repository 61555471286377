import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Image,
  InputGroup,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import Select from "react-select";
import Loading from "../../common/Loading";
import Avatar from "../../assets/images/avatar.svg";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import InfiniteScroll from "react-infinite-scroll-component";

const Tasks = (props) => {
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const filterOption = [
    { value: "all", label: "All Todo's" },
    { value: "status", label: "By Status" },
    { value: "recurrence", label: "By Recurring status" },
    { value: "frequency", label: "By Frequency Status" },
  ];
  const filterOption1 = [
    { value: "Active", label: "Active" },
    { value: "Expired", label: "Expired" },
    { value: "Completed", label: "Completed" },
  ];
  const filterOption2 = [
    { value: "true", label: "Recurring" },
    { value: "false", label: "Non-Recurring" },
  ];
  const filterOption3 = [
    { value: "Daily", label: "Daily" },
    { value: "Weekly", label: "Weekly" },
    { value: "Monthly", label: "Monthly" },
    { value: "Quarterly", label: "Quarterly" },
    { value: "Bi-Annually", label: "Bi-Annually" },
    { value: "Yearly", label: "Yearly" },
  ];
  const sortOption = [
    //Expired, Completed and Active
    { value: "eta", label: "Activity Date" },
    { value: "createdAt", label: "Creation date" },
  ];

  const [familyDetails, setFamilyDetails] = useState([]);
  const [userTaskList, setUserTaskList] = useState([]);
  const [addTaskUrl, setAddTaskUrl] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [title, setTitle] = useState("To-do");
  const [isEmpty, setIsEmpty] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [filtervalue, setfiltervalue] = useState("all");
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [familyUserId, setFamilyUserId] = useState();
  const [sortBy, setSortBy] = useState("eta");
  const [orderBy, setOrderBy] = useState("DESC");
  const [freqVal, setFreqVal] = useState("");
  const [statusVal, setStatusVal] = useState("");
  const [reqVal, setReqVal] = useState("");
  const [showMarkTodo, setShowMarkTodo] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [hasMore, sethasMore] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedOption1, setSelectedOption1] = useState(null); // Selected option for first dropdown
  const [selectedOption2, setSelectedOption2] = useState(null); // Selected option for second dropdown

  const [selectedSort, setSelectedSort] = useState(null); // Selected option for sort dropdown
  const [isSortDropdown, setIsSortDropdown] = useState(false);

  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        setAddTaskUrl("/add_user_todos/" + uuid + "/" + userUUID);
      });
    } else {
      setAddTaskUrl("/add_todos/" + uuid);
      getFamilyDetails(UserDetails.id);
    }
  }, []);

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.relationData(uuid).then((response) => {
      // main user checking
      const mainUser = response.data.au.find(
        (obj) => obj.User.email === UserDetails.email
      );

      const mainArr = [];
      if (mainUser) {
        mainArr.push(mainUser);
      }

      // support user checking
      const suppUser = response.data.au.find((obj) =>
        obj.su.find(
          (family) =>
            family.Family_User &&
            family.Family_User.User.email === UserDetails.email
        )
      );

      const suppArr = [];
      if (suppUser) {
        suppArr.push(suppUser);
      }

      let newArray = [];
      newArray = [...mainArr, ...suppArr];
      const uuidFind = newArray.find((obj) => obj.User.uuid === userUUID);

      UserService.getUserSingleFamilyDetails(uuid, userId).then(
        (response) => {
          setFamilyDetails(response);
          let familyUserId = userUUID ? uuidFind.id : response.FamilyData.id;

          if (userUUID) {
            setFamilyUserId(uuidFind.id);
          } else {
            setFamilyUserId(familyUserId);
          }

          let params = {
            userId: familyUserId,
            familyId: response.FamilyData.Family.id,
            searchStr: searchStr,
            sort: sortBy,
            order: orderBy,
            limit: limit,
            offset: offset,
            freq: freqVal,
            statusVal: statusVal,
            req: reqVal,
          };
          UserService.getUserTaskList(params).then(
            (response) => {
              setUserTaskList(response.data);
              if (response.data.length === 0) {
                setIsEmpty(true);

                sethasMore(false);
              } else {
                setIsEmpty(false);
                if (response.data.length >= limit) {
                  setOffset(limit);
                  sethasMore(true);
                } else {
                  sethasMore(false);
                }
              }
              setTimeout(() => {
                setLoadingspinner(false);
              }, 500);
            },
            (error) => {}
          );
        },
        (error) => {}
      );
    });
  };

  // more Family details based on Pagination
  const fetchTasks = () => {
    setTimeout(() => {
      const params = {
        userId: familyDetails.FamilyData.id,
        familyId: familyDetails.FamilyData.Family.id,
        searchStr: searchStr,
        sort: sortBy,
        order: orderBy,
        limit: limit,
        offset: offset,
        freq: freqVal,
        statusVal: statusVal,
        req: reqVal,
      };

      UserService.getUserTaskList(params)
        .then((response) => {
          setUserTaskList((prevList) => [...prevList, ...response.data]); // Adjust based on actual data structure
          setOffset(offset); // Update the offset
          let nextOffset = Number(offset) + Number(limit);

          if (response.data.length + userTaskList.length >= nextOffset) {
            setOffset(nextOffset);
            sethasMore(true);
          } else {
            sethasMore(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }, 500);
  };

  // Get custom list based on filter, search , etc.
  const getCustomData = (params) => {
    UserService.getUserTaskList(params).then(
      (response) => {
        setUserTaskList(response.data);
        setLoadingspinner(false);
      },
      (error) => {}
    );
  };

  const viewDetailsRoute = (ParentId, TaskId) => {
    localStorage.setItem("taskID", TaskId);
    if (userUUID) {
      history.push(`/view_user_todos_details/${uuid}/${TaskId}/${userUUID}`);
    } else {
      history.push(`/view_todos_details/${uuid}/${TaskId}`);
    }
  };

  const editSubscriptionRoute = (taskID) => {
    if (userUUID) {
      history.push(`/edit_user_todos/${uuid}/${taskID}/${userUUID}`);
    } else {
      history.push(`/edit_todos/${uuid}/${taskID}`);
    }
  };

  const noImgError = (e) => {
    e.target.setAttribute("src", Avatar);
  };

  //API function on Filter click
  const FilterData = (option) => {
    setfiltervalue(option);
    setLoadingspinner(true);
    //api call
    if (option === "all") {
      setStatusVal("");
      setFreqVal("");
      setReqVal("");
      let params = {
        userId: familyUserId,
        familyId: familyDetails.FamilyData.Family.id,
        searchStr: searchStr,
        sort: sortBy,
        order: "DESC",
        limit: 50,
        offset: 0,
        freq: "",
        statusVal: "",
        req: "",
      };
      getCustomData(params);
    } else if (selectedFilterType === "status") {
      setStatusVal(option);
      setFreqVal("");
      setReqVal("");
      let params = {
        userId: familyUserId,
        familyId: familyDetails.FamilyData.Family.id,
        searchStr: searchStr,
        sort: sortBy,
        order: "DESC",
        limit: 50,
        offset: 0,
        freq: "", // frequency
        statusVal: option, // status
        req: "", // recurring
      };
      getCustomData(params);
    } else if (selectedFilterType === "recurrence") {
      setStatusVal("");
      setFreqVal("");
      setReqVal(option);
      let params = {
        userId: familyUserId,
        familyId: familyDetails.FamilyData.Family.id,
        searchStr: searchStr,
        sort: sortBy,
        order: "DESC",
        limit: 50,
        offset: 0,
        freq: "", // frequency
        statusVal: "", // status
        req: option, // recurring
      };
      getCustomData(params);
    } else if (selectedFilterType === "frequency") {
      setStatusVal("");
      setFreqVal(option);
      setReqVal("");
      let params = {
        userId: familyUserId,
        familyId: familyDetails.FamilyData.Family.id,
        searchStr: searchStr,
        sort: sortBy,
        order: "DESC",
        limit: 50,
        offset: 0,
        freq: option, // frequency
        statusVal: "", // status
        req: "", // recurring
      };
      getCustomData(params);
    }
  };

  // Sort tasks
  const taskSort = (val) => {
    let _sortBy = val;
    setSortBy(val);
    setLoading(true);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchStr,
      sort: _sortBy,
      order: "DESC",
      limit: 50,
      offset: 0,
      freq: freqVal,
      statusVal: statusVal,
      req: reqVal,
    };
    getCustomData(params);
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setLoading(true);
    setSearchStr("");
    setOffset(0);
    setLimit(50);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: "",
      sort: sortBy,
      order: orderBy,
      limit: 50,
      offset: 0,
      freq: freqVal,
      statusVal: statusVal,
      req: reqVal,
    };
    getCustomData(params);
  };

  //search box
  useEffect(() => {
    if (familyDetails.FamilyData !== undefined) {
      if (searchStr.length === 0) {
        // clearSearch();
        setShowSearchText(false);
        setLoading(true);
        setSearchStr("");
        setOffset(0);
        setLimit(50);
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: "",
          sort: sortBy,
          order: orderBy,
          limit: 50,
          offset: 0,
          freq: freqVal,
          statusVal: statusVal,
          req: reqVal,
        };
        getCustomData(params);
      }
    }
  }, [searchStr]);

  // Search task
  const taskSearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoading(true);
      let srchStr = e.target.value;
      setSearchStr(e.target.value);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: srchStr,
          sort: sortBy,
          order: orderBy,
          limit: 50,
          offset: 0,
          freq: freqVal,
          statusVal: statusVal,
          req: reqVal,
        };
        getCustomData(params);
      }
    }
  };

  // Search modal
  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  const validateDate = (date) => {
    // Get today's date
    const today = new Date();

    // Create the target date from the ISO string
    const targetDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  const [markTodoObj, setMarkTodoObj] = useState({});
  // show the mark to as completed modal
  const showTodo = (aaa) => {
    setMarkTodoObj(aaa);
    setShowMarkTodo(true);
  };

  // hide mark to do as completed modal
  const handleMarkTodoHide = () => {
    setShowMarkTodo(false);
  };

  // update task status to complete
  const updateTaskStatus = () => {
    let data = {
      status: "Completed",
    };
    UserService.updateTaskAsComplete(data, markTodoObj.id).then(
      (response) => {
        setShowMarkTodo(false);
        window.scrollTo(0, 0);

        if (response.status === 400) {
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(response.data.message);
        } else {
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage("To Do mark as complete successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        }
      },
      (error) => {}
    );
  };

  const dropdownRef = useRef(null); // Ref for dropdown
  const buttonRef = useRef(null); // Ref for the dropdown button

  const sortRef = useRef(null); // Ref for the dropdown button

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle the dropdown state
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false); // Close the main dropdown
    setIsDropdownOpen1(false); // Close second dropdown
  };

  const toggleDropdownSort = () => {
    setIsSortDropdown((prev) => !prev); // Toggle the dropdown state
  };

  const closeDropdownSort = () => {
    setIsSortDropdown(false);
  };

  useEffect(() => {
    // Handler to detect clicks outside of the dropdown
    const handleClickOutside = (event) => {
      // handle filter dropdown
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        closeDropdown();
      }

      // handle sort dropdown
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        closeDropdownSort();
      }
    };

    // Bind the event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update screen width on resize
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const etaDateFn = (val) => {
    // Find the latest date
    const latestDate = new Date(
      Math.min(...val.map((item) => new Date(item.eta)))
    );

    return moment(latestDate).format("Do MMM, YYYY");
  };

  return (
    <>
      <Container
        fluid
        style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
        className="d-flex flex-column"
      >
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        <>
          <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
            <Col>
              <h1 className="lh-base mb-0">{title}</h1>
            </Col>

            {/* sort  */}
            <Col
              xs="auto"
              className="position-relative"
              ref={sortRef} // Ref to track button for outside clicks
            >
              <button
                className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                  isSortDropdown ? "dropdown-arrow" : ""
                }`}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                onClick={toggleDropdownSort} // Toggle dropdown on click
                aria-expanded={isSortDropdown}
              >
                <i className="fa-solid fa-sm fa-sort lh-1 align-middle"></i>
                <span className="text-dark ms-md-2 d-none d-xl-block">
                  Sort by
                </span>
              </button>
              {/* main dropdown  */}
              <ul
                className={`dropdown-menu dropdown-parent mt-2 ${
                  isSortDropdown ? "show" : ""
                }`}
                ref={dropdownRef}
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  {sortOption.map((option) => (
                    <div
                      key={option.value}
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        color:
                          selectedSort && selectedSort.value === option.value
                            ? "#020617"
                            : "",
                        backgroundColor:
                          selectedSort && selectedSort.value === option.value
                            ? "#36f1cc"
                            : "",
                      }}
                      onClick={() => {
                        setSelectedSort(option);
                        taskSort(option.value);
                        closeDropdownSort();
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </li>
              </ul>
            </Col>

            {/* filter  */}
            <Col
              xs="auto"
              className="position-relative"
              ref={buttonRef} // Ref to track button for outside clicks
            >
              <button
                className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                  isDropdownOpen ? "dropdown-arrow" : ""
                }`}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                onClick={toggleDropdown} // Toggle dropdown on click
                aria-expanded={isDropdownOpen}
              >
                <i className="fa-solid fa-sm fa-sliders-h lh-1 align-middle"></i>
                <span className="text-dark ms-md-2 d-none d-xl-block">
                  Filters
                </span>
              </button>
              {/* main dropdown  */}
              <ul
                className={`dropdown-menu dropdown-parent mt-2 ${
                  isDropdownOpen ? "show" : ""
                }`}
                ref={dropdownRef}
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  {filterOption.map((option) => (
                    <div
                      key={option.value}
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        color:
                          selectedOption1 &&
                          selectedOption1.value === option.value
                            ? "#020617"
                            : "",
                        backgroundColor:
                          selectedOption1 &&
                          selectedOption1.value === option.value
                            ? "#36f1cc"
                            : "",
                      }}
                      onClick={() => {
                        setSelectedOption1(option);
                        if (option.value === "all") {
                          closeDropdown(); // Close dropdown after selecting an option}
                          FilterData(option.value);
                        } else {
                          setIsDropdownOpen1(true);
                          setSelectedFilterType(option.value);
                        }
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </li>
              </ul>
              {/* dropdown based on main dropdown filter  */}
              {isDropdownOpen1 && (
                <ul
                  className={`dropdown-menu dropdown-child mt-2 show`}
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    top:
                      screenWidth > 767
                        ? selectedFilterType === "status"
                          ? "5.5rem"
                          : selectedFilterType === "recurrence"
                          ? "8rem"
                          : "10rem"
                        : "12.75rem",
                    left: screenWidth > 1200 ? "16rem" : "",
                    right:
                      screenWidth > 1200
                        ? ""
                        : screenWidth < 1200 && screenWidth > 767
                        ? "4rem"
                        : "0",
                  }}
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    style={{
                      position: "absolute",
                      left: screenWidth > 1200 ? "-14px" : "",
                      right: screenWidth < 1200 ? "-14px" : "",
                      width: "0px",
                      height: "0px",
                      borderLeft: "10px solid transparent",
                      borderRight: "10px solid transparent",
                      borderBottom: "15px solid white",
                      transform:
                        screenWidth < 1200
                          ? "rotate(-30deg)"
                          : "rotate(-210deg)",
                      top: "10px",
                      display: screenWidth > 767 ? "" : "none",
                    }}
                  ></div>{" "}
                  {/* Arrow */}
                  <li>
                    {(selectedFilterType === "status"
                      ? filterOption1
                      : selectedFilterType === "recurrence"
                      ? filterOption2
                      : filterOption3
                    ).map((option) => (
                      <div
                        key={option.value}
                        className="dropdown-item"
                        style={{
                          cursor: "pointer",
                          color:
                            selectedOption2 &&
                            selectedOption2.value === option.value
                              ? "#020617"
                              : "",
                          backgroundColor:
                            selectedOption2 &&
                            selectedOption2.value === option.value
                              ? "#36f1cc"
                              : "",
                        }}
                        onClick={() => {
                          FilterData(option.value);
                          setSelectedOption2(option);
                          setIsDropdownOpen(false); // Close second dropdown after selection
                          setIsDropdownOpen1(false); // Close second dropdown after selection
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </li>
                </ul>
              )}
            </Col>

            {/* search field  */}
            <Col xs="auto">
              <Button
                variant="white"
                className="shadow border-white d-xl-none"
                onClick={handleShowSearch}
              >
                <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
              </Button>
              <InputGroup className="shadow rounded d-none d-xl-flex">
                <Form.Control
                  type="text"
                  placeholder="Search To do's"
                  value={searchStr}
                  onChange={(e) => setSearchStr(e.target.value)}
                  onKeyDown={taskSearch}
                  className="border-white"
                />
                {!searchStr && (
                  <Button
                    variant="white"
                    type="submit"
                    className="border-white"
                  >
                    <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
                  </Button>
                )}
                {searchStr && (
                  <Button
                    variant="white"
                    type="submit"
                    className="border-white"
                    onClick={clearSearch}
                  >
                    <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
                  </Button>
                )}
              </InputGroup>
            </Col>

            {/* Add button */}
            <Col xs="auto">
              <Link
                to={addTaskUrl}
                className="btn btn-primary d-md-flex align-items-md-center"
              >
                <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                <span className="ms-md-2 d-none d-md-block">Add new</span>
              </Link>
            </Col>
          </Row>
          {loadingspinner ? (
            <>
              <Loading />
            </>
          ) : (
            <section className="bg-white p-4 p-md-5 p-lg-6 rounded-4 shadow h-100">
              <div className="d-none align-items-center ms-8">
                <span>Sort by:</span>
                <Select
                  className="ms-3"
                  options={sortOption}
                  defaultValue={{
                    label: "Created",
                    value: "created",
                  }}
                  onChange={taskSort}
                />
              </div>
              {isEmpty ? (
                <div className="text-center">
                  <p className="mt-4">You currently have no To Do's set up!</p>
                  <Link
                    to={addTaskUrl}
                    className="btn btn-primary btn-lg d-inline-flex align-items-center"
                  >
                    <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                    <span className="ms-md-2 d-none d-md-block">Add new</span>
                  </Link>
                </div>
              ) : (
                <>
                  <InfiniteScroll
                    dataLength={userTaskList.length} // Set the length of the current data
                    next={fetchTasks} // Trigger to load more data
                    hasMore={hasMore} // Check if more data is available
                    style={{
                      overflow: "visible",
                    }}
                    loader={
                      <div className="d-flex align-items-center gap-2 my-5 justify-content-center">
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                        ></span>
                        <span>Loading . . .</span>
                      </div>
                    }
                  >
                    <section class="mb-6">
                      <h3 className="mb-4">This week</h3>
                      <hr className="mb-3" />
                      {userTaskList
                        .filter((item) => item.status !== "Completed")
                        .filter((item) => {
                          const today = new Date();
                          const etaDate = new Date(item.eta);
                          const sevenDaysFromToday = new Date();
                          sevenDaysFromToday.setDate(today.getDate() + 7); // Calculate 7 days from today

                          // Check if etaDate is within the next 7 days and not expired
                          return (
                            etaDate >= today && etaDate <= sevenDaysFromToday
                          );
                        })
                        .sort((a, b) => new Date(a.eta) - new Date(b.eta))
                        .length === 0 ? (
                        <p>No tasks are available for this week</p>
                      ) : (
                        userTaskList
                          .filter((item) => item.status !== "Completed")
                          .filter((item) => {
                            const today = new Date();
                            const etaDate = new Date(item.eta);
                            const sevenDaysFromToday = new Date();
                            sevenDaysFromToday.setDate(today.getDate() + 7);

                            return (
                              etaDate >= today && etaDate <= sevenDaysFromToday
                            );
                          })
                          .sort((a, b) => new Date(a.eta) - new Date(b.eta))
                          .map((taskcard, index) => (
                            <>
                              <Row
                                className="align-items-center gx-3 gy-0 gx-md-4"
                                onClick={() =>
                                  viewDetailsRoute(
                                    taskcard.ParentId,
                                    taskcard.id
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Col
                                  xs="auto"
                                  className={`${
                                    taskcard.status === "Completed"
                                      ? "opacity-25"
                                      : ""
                                  }`}
                                >
                                  <button
                                    className="btn btn-white btn-sm btn-circle flex-shrink-0"
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevents event from bubbling up

                                      showTodo(taskcard);
                                    }}
                                  ></button>
                                </Col>
                                <Col
                                  xs="17"
                                  md="10"
                                  xl="10"
                                  xxl="11"
                                  className="me-auto"
                                >
                                  <div
                                    className={`d-flex align-items-center ${
                                      taskcard.status === "Completed"
                                        ? "opacity-25"
                                        : ""
                                    }`}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250 }}
                                      overlay={
                                        <Tooltip id="">
                                          <div className="">
                                            {taskcard.name}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="small fw-semibold text-dark d-md-none text-truncate">
                                        {taskcard.name}
                                      </div>
                                    </OverlayTrigger>
                                    <i
                                      className={`fa-solid fa-xs ${
                                        taskcard.taskRecurrence === true
                                          ? "fa-arrows-rotate text-primary ms-2"
                                          : "d-none"
                                      } d-md-none`}
                                    ></i>
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250 }}
                                      overlay={
                                        <Tooltip id="">
                                          <div className="">
                                            {taskcard.name}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="fw-semibold text-truncate text-dark d-none d-md-block">
                                        {taskcard.name}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250 }}
                                    overlay={
                                      <Tooltip id="">
                                        <div className="">{taskcard.name}</div>
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className={`small text-truncate d-md-none ${
                                        taskcard.status === "Completed"
                                          ? "opacity-25"
                                          : ""
                                      }`}
                                    >
                                      {taskcard.details}
                                    </div>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250 }}
                                    overlay={
                                      <Tooltip id="">
                                        <div className="">{taskcard.name}</div>
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className={`text-truncate d-none d-md-block ${
                                        taskcard.status === "Completed"
                                          ? "opacity-25"
                                          : ""
                                      }`}
                                    >
                                      {taskcard.details}
                                    </div>
                                  </OverlayTrigger>
                                  <div
                                    className={`small d-md-none ${
                                      validateDate(taskcard.eta) <= 30
                                        ? "text-danger"
                                        : validateDate(taskcard.eta) <= 60 &&
                                          validateDate(taskcard.eta) > 30
                                        ? "text-orange"
                                        : validateDate(taskcard.eta) <= 90 &&
                                          validateDate(taskcard.eta) > 60
                                        ? "text-lightgreen"
                                        : "text-success"
                                    } fw-semibold text-truncate`}
                                  >
                                    {taskcard.eta !== null &&
                                    taskcard.eta !== undefined &&
                                    moment(taskcard.eta).isValid()
                                      ? moment(taskcard.eta).format(
                                          "Do MMM, YYYY"
                                        )
                                      : etaDateFn(taskcard.childTasks)}
                                  </div>
                                  <div
                                    className={`fw-semibold ${
                                      taskcard.status === "Completed"
                                        ? "text-success"
                                        : "text-danger"
                                    } d-inline d-md-none small`}
                                  >
                                    {taskcard.status}
                                  </div>
                                </Col>
                                <Col
                                  md="4"
                                  xl="3"
                                  className={`text-end order-md-last d-none d-md-block ${
                                    taskcard.status === "Completed"
                                      ? "opacity-25"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`small ${
                                      validateDate(taskcard.eta) <= 30
                                        ? "text-danger"
                                        : validateDate(taskcard.eta) <= 60 &&
                                          validateDate(taskcard.eta) > 30
                                        ? "text-orange"
                                        : validateDate(taskcard.eta) <= 90 &&
                                          validateDate(taskcard.eta) > 60
                                        ? "text-lightgreen"
                                        : "text-success"
                                    } fw-semibold text-truncate`}
                                  >
                                    {taskcard.eta !== null &&
                                    taskcard.eta !== undefined &&
                                    moment(taskcard.eta).isValid()
                                      ? moment(taskcard.eta).format(
                                          "Do MMM, YYYY"
                                        )
                                      : etaDateFn(taskcard.childTasks)}
                                  </div>
                                </Col>
                                <Col
                                  xs="auto"
                                  className="order-md-last ms-auto"
                                >
                                  {
                                    <Button
                                      className={`btn-sm btn-square btn-interactive d-none d-md-inline-block ${
                                        taskcard.taskRecurrence === true
                                          ? "invisible"
                                          : "visible"
                                      }`}
                                      variant="link"
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevents event from bubbling up
                                        editSubscriptionRoute(taskcard.id);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fa-solid fa-edit"></i>
                                    </Button>
                                  }
                                  <Button
                                    className="btn-sm btn-square btn-interactive"
                                    variant="link"
                                    onClick={() =>
                                      viewDetailsRoute(
                                        taskcard.ParentId,
                                        taskcard.id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="fa-solid fa-chevron-right"></i>
                                  </Button>
                                </Col>
                                <Col
                                  md="5"
                                  lg="4"
                                  xl="3"
                                  className="d-none d-md-block"
                                >
                                  <div
                                    className={`fw-semibold ${
                                      taskcard.status === "Completed"
                                        ? "text-success"
                                        : "text-danger"
                                    } d-inline small`}
                                  >
                                    {taskcard.status}
                                  </div>
                                  <div
                                    class={`${
                                      taskcard.taskRecurrence === true
                                        ? "d-inline-flex align-items-center"
                                        : "d-none"
                                    } ${
                                      taskcard.status === "Completed"
                                        ? "d-md-none"
                                        : ""
                                    } d-xl-none`}
                                  >
                                    <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light">
                                      <i className="fa-solid fa-sm fa-arrows-rotate"></i>
                                      <span class="ms-1">Recurring task</span>
                                    </span>
                                  </div>
                                </Col>
                                <Col xs="3" className="d-none d-xl-block">
                                  <div
                                    class={`${
                                      taskcard.taskRecurrence === true
                                        ? "d-none d-md-inline-flex align-items-center"
                                        : "d-none"
                                    } ${
                                      taskcard.status === "Completed"
                                        ? "opacity-25"
                                        : ""
                                    }`}
                                  >
                                    <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light">
                                      <i className="fa-solid fa-sm fa-arrows-rotate"></i>
                                      <span class="ms-1">Recurring task</span>
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  xs="auto"
                                  className={`d-none d-lg-block ms-lg-auto ${
                                    taskcard.status === "Completed"
                                      ? "opacity-25"
                                      : ""
                                  }`}
                                >
                                  <div className="avatar avatar-sm flex-shrink-0">
                                    <Image
                                      src={taskcard.assigneeId.User}
                                      onError={noImgError}
                                      alt="Profile Avatar"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {index !== userTaskList.length - 1 && (
                                <hr className="my-3" />
                              )}
                            </>
                          ))
                      )}
                    </section>
                    <section>
                      <h3 className="mb-4">Others</h3>
                      <hr className="mb-3" />
                      {userTaskList

                        .filter((item) => {
                          const today = new Date();
                          const etaDate = new Date(item.eta);
                          const sevenDaysFromToday = new Date();
                          sevenDaysFromToday.setDate(today.getDate() + 7); // Calculate 7 days from today

                          // Include tasks that are:
                          // - Completed
                          // - Expired (eta is less than today)
                          // - Or have eta greater than 7 days from today
                          return (
                            item.status === "Completed" ||
                            etaDate < today ||
                            etaDate > sevenDaysFromToday
                          );
                        })
                        .sort((a, b) => new Date(a.eta) - new Date(b.eta))
                        .length === 0 ? (
                        <p>No other tasks are available.</p>
                      ) : (
                        userTaskList

                          .filter((item) => {
                            const today = new Date();
                            const etaDate = new Date(item.eta);
                            const sevenDaysFromToday = new Date();
                            sevenDaysFromToday.setDate(today.getDate() + 7); // Calculate 7 days from today

                            // Include tasks that are:
                            // - Completed
                            // - Expired (eta is less than today)
                            // - Or have eta greater than 7 days from today
                            return (
                              item.status === "Completed" ||
                              etaDate < today ||
                              etaDate > sevenDaysFromToday
                            );
                          })
                          .sort((a, b) => new Date(a.eta) - new Date(b.eta))
                          .map((taskcard, index) => (
                            <>
                              <Row
                                className="align-items-center gx-3 gy-0 gx-md-4"
                                onClick={() =>
                                  viewDetailsRoute(
                                    taskcard.ParentId,
                                    taskcard.id
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <Col
                                  xs="auto"
                                  className={`${
                                    taskcard.status === "Completed"
                                      ? "opacity-25"
                                      : ""
                                  }`}
                                >
                                  <button
                                    className="btn btn-white btn-sm btn-circle flex-shrink-0"
                                    onClick={(event) => {
                                      event.stopPropagation(); // Prevents event from bubbling up

                                      showTodo(taskcard);
                                    }}
                                  ></button>
                                </Col>
                                <Col
                                  xs="17"
                                  md="10"
                                  xl="10"
                                  xxl="11"
                                  className="me-auto"
                                >
                                  <div
                                    className={`d-flex align-items-center ${
                                      taskcard.status === "Completed"
                                        ? "opacity-25"
                                        : ""
                                    }`}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250 }}
                                      overlay={
                                        <Tooltip id="">
                                          <div className="">
                                            {taskcard.name}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="small fw-semibold text-truncate text-dark d-md-none">
                                        {taskcard.name}
                                      </div>
                                    </OverlayTrigger>
                                    <i
                                      className={`fa-solid fa-xs ${
                                        taskcard.taskRecurrence === true
                                          ? "fa-arrows-rotate text-primary ms-2"
                                          : "d-none"
                                      } d-md-none`}
                                    ></i>
                                    <OverlayTrigger
                                      placement="bottom"
                                      delay={{ show: 250 }}
                                      overlay={
                                        <Tooltip id="">
                                          <div className="">
                                            {taskcard.name}
                                          </div>
                                        </Tooltip>
                                      }
                                    >
                                      <div className="fw-semibold text-truncate text-dark d-none d-md-block">
                                        {taskcard.name}
                                      </div>
                                    </OverlayTrigger>
                                  </div>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250 }}
                                    overlay={
                                      <Tooltip id="">
                                        <div className="">{taskcard.name}</div>
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className={`small fw-medium text-truncate d-md-none ${
                                        taskcard.status === "Completed"
                                          ? "opacity-25"
                                          : ""
                                      }`}
                                    >
                                      {taskcard.details}
                                    </div>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    delay={{ show: 250 }}
                                    overlay={
                                      <Tooltip id="">
                                        <div className="">{taskcard.name}</div>
                                      </Tooltip>
                                    }
                                  >
                                    <div
                                      className={`text-truncate fw-medium d-none d-md-block ${
                                        taskcard.status === "Completed"
                                          ? "opacity-25"
                                          : ""
                                      }`}
                                    >
                                      {taskcard.details}
                                    </div>
                                  </OverlayTrigger>
                                  <div
                                    className={`small d-md-none ${
                                      validateDate(taskcard.eta) <= 30
                                        ? "text-danger"
                                        : validateDate(taskcard.eta) <= 60 &&
                                          validateDate(taskcard.eta) > 30
                                        ? "text-orange"
                                        : validateDate(taskcard.eta) <= 90 &&
                                          validateDate(taskcard.eta) > 60
                                        ? "text-lightgreen"
                                        : "text-success"
                                    } fw-semibold text-truncate`}
                                  >
                                    {taskcard.eta !== null &&
                                    taskcard.eta !== undefined &&
                                    moment(taskcard.eta).isValid()
                                      ? moment(taskcard.eta).format(
                                          "Do MMM, YYYY"
                                        )
                                      : etaDateFn(taskcard.childTasks)}
                                  </div>
                                  <div
                                    className={`fw-semibold ${
                                      taskcard.status === "Completed"
                                        ? "text-success"
                                        : "text-danger"
                                    } d-inline d-md-none small`}
                                  >
                                    {taskcard.status}
                                  </div>
                                </Col>
                                <Col
                                  md="4"
                                  xl="3"
                                  className={`text-end order-md-last d-none d-md-block ${
                                    taskcard.status === "Completed"
                                      ? "opacity-25"
                                      : ""
                                  }`}
                                >
                                  <div
                                    className={`small  ${
                                      validateDate(taskcard.eta) <= 30
                                        ? "text-danger"
                                        : validateDate(taskcard.eta) <= 60 &&
                                          validateDate(taskcard.eta) > 30
                                        ? "text-orange"
                                        : validateDate(taskcard.eta) <= 90 &&
                                          validateDate(taskcard.eta) > 60
                                        ? "text-lightgreen"
                                        : "text-success"
                                    } fw-semibold text-truncate`}
                                  >
                                    {taskcard.eta !== null &&
                                    taskcard.eta !== undefined &&
                                    moment(taskcard.eta).isValid()
                                      ? moment(taskcard.eta).format(
                                          "Do MMM, YYYY"
                                        )
                                      : etaDateFn(taskcard.childTasks)}
                                  </div>
                                </Col>
                                <Col
                                  xs="auto"
                                  className="order-md-last ms-auto"
                                >
                                  {
                                    <Button
                                      className={`btn-sm btn-square btn-interactive d-none d-md-inline-block ${
                                        taskcard.taskRecurrence === true
                                          ? "invisible"
                                          : "visible"
                                      }`}
                                      variant="link"
                                      onClick={(event) => {
                                        event.stopPropagation(); // Prevents event from bubbling up
                                        editSubscriptionRoute(taskcard.id);
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fa-solid fa-edit"></i>
                                    </Button>
                                  }
                                  <Button
                                    className="btn-sm btn-square btn-interactive"
                                    variant="link"
                                    onClick={() =>
                                      viewDetailsRoute(
                                        taskcard.ParentId,
                                        taskcard.id
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <i className="fa-solid fa-chevron-right"></i>
                                  </Button>
                                </Col>
                                <Col
                                  md="5"
                                  lg="4"
                                  xl="3"
                                  className="d-none d-md-block"
                                >
                                  <div
                                    className={`fw-semibold ${
                                      taskcard.status === "Completed"
                                        ? "text-success"
                                        : "text-danger"
                                    } d-inline small`}
                                  >
                                    {taskcard.status}
                                  </div>
                                  <div
                                    class={`${
                                      taskcard.taskRecurrence === true
                                        ? "d-inline-flex align-items-center"
                                        : "d-none"
                                    } ${
                                      taskcard.status === "Completed"
                                        ? "d-md-none"
                                        : ""
                                    } d-xl-none`}
                                  >
                                    <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light">
                                      <i className="fa-solid fa-sm fa-arrows-rotate"></i>
                                      <span class="ms-1">Recurring task</span>
                                    </span>
                                  </div>
                                </Col>
                                <Col xs="3" className="d-none d-xl-block">
                                  <div
                                    class={`${
                                      taskcard.taskRecurrence === true
                                        ? "d-none d-md-inline-flex align-items-center"
                                        : "d-none"
                                    } ${
                                      taskcard.status === "Completed"
                                        ? "opacity-25"
                                        : ""
                                    }`}
                                  >
                                    <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light">
                                      <i className="fa-solid fa-sm fa-arrows-rotate"></i>
                                      <span class="ms-1">Recurring task</span>
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  xs="auto"
                                  className={`d-none d-lg-block ms-lg-auto ${
                                    taskcard.status === "Completed"
                                      ? "opacity-25"
                                      : ""
                                  }`}
                                >
                                  <div className="avatar avatar-sm flex-shrink-0">
                                    <Image
                                      src={taskcard.assigneeId.User}
                                      onError={noImgError}
                                      alt="Profile Avatar"
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {index !== userTaskList.length - 1 && (
                                <hr className="my-3" />
                              )}
                            </>
                          ))
                      )}
                    </section>

                    {/* {userTaskList.length === 0 && !loading && !showSearchText && (
                    <p className="text-center mb-0">No data found</p>
                  )} */}
                    {userTaskList.length === 0 &&
                      !loading &&
                      showSearchText &&
                      searchStr && (
                        <>
                          <p className="text-center">
                            There are no results for the search query
                          </p>
                          <p className="text-center mb-0">
                            Try searching To do's with modified terms
                          </p>
                        </>
                      )}
                  </InfiniteScroll>
                </>
              )}
            </section>
          )}
        </>

        {/* Search for mobile */}
        <Modal show={showSearch} onHide={handleCloseSearch}>
          <Modal.Header closeButton>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="rounded shadow">
              <Form.Control
                type="text"
                placeholder="Search To do's"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                onKeyDown={taskSearch}
                className="border-white"
              />
              {!searchStr && (
                <Button variant="white" type="submit" className="border-white">
                  <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
                </Button>
              )}
              {searchStr && (
                <Button
                  variant="white"
                  type="submit"
                  className="border-white"
                  onClick={clearSearch}
                >
                  <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
                </Button>
              )}
            </InputGroup>
          </Modal.Body>
        </Modal>

        {/* // Mark Todo as completed */}
        <Modal show={showMarkTodo} onHide={handleMarkTodoHide} className="">
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className="h3 mb-0">Mark Todo as completed</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Mark the Todo to be completed from the family</p>
            <p className="mb-5">Do you want to continue?</p>
            <Button variant="primary" onClick={handleMarkTodoHide}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => updateTaskStatus()}
              className="ms-4"
            >
              Yes
            </Button>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};
export default Tasks;
