import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import Loading from "../../common/Loading";
import userService from "../../services/user.service";

const NewBillingPage = () => {
  const location = useLocation();
  const { customerId, planData, paymentList, accountPlanList } =
    location.state || {};

  const [isLoad, setIsLoad] = useState(true);
  const [activeTab, setActiveTab] = useState();
  const [activeTab1, setActiveTab1] = useState();

  const [customer, setCustomer] = useState();
  const [planFullData, setPlanFullData] = useState();
  const [payment, setPayment] = useState();
  const [subData, setSubData] = useState([]);

  useEffect(() => {
    setActiveTab(planData.renewalType);
    setActiveTab1(planData.name);
    setCustomer(customerId);
    setPlanFullData(planData);
    setPayment(paymentList);

    userService.planList().then(
      (response) => {
        setSubData(response);
      },
      () => {}
    );
    setTimeout(() => {
      setIsLoad(false);
    }, 500);
  }, []);

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const handleSelect1 = (key) => {
    setActiveTab1(key);
  };

  useEffect(() => {
    for (let i = 0; i < subData.length; i++) {
      if (
        subData[i].name === activeTab1 &&
        subData[i].renewalType === activeTab
      ) {
        setPlanFullData(subData[i]);
      }
    }
  }, [activeTab, activeTab1, subData]);

  return (
    <div>
      {isLoad ? (
        <div
          style={{ minHeight: "calc(100vh - 150px)" }}
          className="d-flex flex-column"
        >
          <Loading />
        </div>
      ) : (
        <>
          <div className="container-fluid px-0 position-fixed top-0 w-100 h-100 overflow-auto">
            {/* Main Container  */}
            <Row className="mx-auto">
              {/* Left Container */}
              <Col lg="12" className="bg-white ">
                <div className="my-5 my-lg-0 d-lg-flex align-items-center justify-content-center text-white flex-column text-center  mx-1666 h-100vh">
                  <div className="fs-36 fw-bold bold-colour mb-3">
                    Add your billing details
                  </div>

                  <div className="d-flex align-items-center justify-content-between fs-14 mb-2 w-100">
                    <p className="fw-semibold bold-colour ">
                      <span class="text-success me-2">&#10004;</span>
                      <span>Cancel any time</span>
                    </p>
                    <p className="fw-semibold bold-colour ">
                      <span class="text-success me-2">&#10004;</span>
                      <span>No hidden costs</span>
                    </p>
                  </div>

                  <CheckoutForm
                    customerId={customer}
                    priceId={planFullData.stripePriceId}
                    planId={planFullData.id}
                    // familyName={familyName}
                    paymentList={paymentList}
                    planType={accountPlanList}
                  />
                </div>
              </Col>

              {/* Right Container */}
              <Col lg="12" className="p-0 ">
                <div className="d-flex align-items-center justify-content-center cont mx-1666 h-100vh">
                  <div className=" mtb-6rem w-100">
                    <div
                      className={`story-card subscription-cards mb-3 bg-white br-12`}
                      style={{
                        boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px",
                      }}
                    >
                      <div className="p-5">
                        <div className="mb-5 fw-semibold bold-colour d-flex align-items-center justify-content-between new-billing-page">
                          <Tabs
                            defaultActiveKey={activeTab}
                            id="uncontrolled-tab-example"
                            className="tab-container d-flex align-items-center fs-14"
                            style={{ border: "none" }}
                            onSelect={handleSelect}
                          >
                            <Tab
                              eventKey="yearly"
                              title="Annual"
                              className="tab-annual mb-0 "
                            ></Tab>
                            <Tab
                              eventKey="monthly"
                              title="Monthly"
                              className="tab-monthly mb-0 "
                            ></Tab>
                          </Tabs>

                          <Tabs
                            defaultActiveKey={activeTab1}
                            id="uncontrolled-tab-example"
                            className="tab-container d-flex align-items-center fs-14"
                            style={{ border: "none" }}
                            onSelect={handleSelect1}
                          >
                            <Tab
                              eventKey="Single"
                              title="Single"
                              className="tab-annual mb-0 "
                            ></Tab>
                            <Tab
                              eventKey="Couple"
                              title="Couple"
                              className="tab-annual mb-0 "
                            ></Tab>
                            <Tab
                              eventKey="Family"
                              title="Family"
                              className="tab-monthly mb-0 "
                            ></Tab>
                          </Tabs>
                        </div>
                        <div className="mb-5">
                          <h1 className="mb-3">{planFullData.name}</h1>
                          <div className="text-info fs-36 fw-bold">
                            <strong>
                              {planFullData.Country.symbol} {planFullData.price}
                            </strong>
                          </div>
                          <div className="fw-semibold bold-colour">
                            {planFullData.renewalType}
                          </div>
                        </div>

                        <p className="fw-semibold bold-colour mb-2">
                          <span class="text-success me-2">&#10004;</span>
                          {planFullData.availableSeats === 1 ? (
                            <span>{planFullData.availableSeats} Main User</span>
                          ) : (
                            <span>
                              {planFullData.availableSeats} Main Users
                            </span>
                          )}
                        </p>
                        <p className="fw-semibold bold-colour mb-2">
                          <span class="text-success me-2">&#10004;</span>
                          {planFullData.availableSeats === 1 ? (
                            <span>
                              {planFullData.availableSeats} Support User
                            </span>
                          ) : (
                            <span>
                              {planFullData.availableSeats} Support Users
                            </span>
                          )}
                        </p>
                        <p className="fw-semibold bold-colour mb-2">
                          <span class="text-success me-2">&#10004;</span>
                          <span>Cancel any time</span>
                        </p>
                        <p className="fw-semibold bold-colour mb-2">
                          <span class="text-success me-2">&#10004;</span>
                          <span>No hidden costs</span>
                        </p>
                      </div>
                    </div>

                    <div className="text-center">
                      <i
                        className="fas fa-smile"
                        style={{ color: "#d8d838" }}
                      ></i>
                      <div className="text-info fw-bold fs-14">
                        We want you happy - cancel any time with no stress
                      </div>
                      <div className="mt-1 fs-14">
                        You will not charged until your free trial comes to an
                        end. Your free trial ends at the end of the 7th day. You
                        will be charged in the first hour on the 8th day. This
                        is a subscription, a recurring payment monthly/annually.
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
};

export default NewBillingPage;
