import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Dropdown from "react-bootstrap/Dropdown";
import Loading from "../../common/Loading";
import FamilyDashboardSubscriptions from "./FamilyDashboardSubscriptions";
import FamilyDashboardCalendar from "./FamilyDashboardCalendar";
import FamilyDashboardTasks from "./FamilyDashboardTasks";
import FamilyDashboardBenefits from "./FamilyDashboardBenefits";
import FamilyDashboardGiftCards from "./FamilyDashboardGiftCards";
import FamilyDashboardEvent from "./FamilyDashboardEvent";
import FamilyDashboardAccount from "./FamilyDashboardAccount";
import FamilyDashboardUsers from "./FamilyDashboardUsers";
import moment from "moment";

const FamilyDashboard = (props) => {
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  localStorage.removeItem("parentUser.uuid");
  let { uuid } = useParams();
  let { userUUID } = useParams();
  const [loading, setLoading] = useState(true);
  const [userType, setUserType] = useState("");
  const [addSubscriptionUrl, setAddSubscriptionUrl] = useState("");
  const [addTaskUrl, setAddTaskUrl] = useState("");
  const [addGiftcardUrl, setAddGiftcardUrl] = useState("");
  const [addWarrantyUrl, setAddWarrantyUrl] = useState("");
  const [nameFam, setNameFam] = useState("");
  const [allData, setAllData] = useState();
  const [allDataRem, setAllDataRem] = useState();

  const [childData, setChildData] = useState(new Date());

  const [familyUserId, setfamilyUserId] = useState();
  const [familyId, setfamilyId] = useState();

  const pathArray = props.location.pathname.split("/");

  // Function to handle data from the child
  const handleChildData = (data) => {
    setChildData(data);

    // Format the date manually to account for local timezone
    const year = data.getFullYear();
    const month = String(data.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const date = String(data.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${date}`;
    const NewMonth = moment(formattedDate).format("YYYY-MM");
    debugger;
    UserService.getDashboard({
      fid: familyId,
      uid: familyUserId,
      dateChosen: formattedDate,
      // monthChosen: NewMonth,
    }).then(
      (response) => {
        setAllDataRem(response);
      },
      (error) => {}
    );
  };

  // url checking
  useEffect(() => {
    setLoading(true);

    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        setAddSubscriptionUrl("/add_user_outgoing/" + uuid + "/" + userUUID);
        setAddTaskUrl("/add_user_todos/" + uuid + "/" + userUUID);
        setAddGiftcardUrl(
          "/add_user_vouchers_&_gift_cards/" + uuid + "/" + userUUID
        );
        setAddWarrantyUrl("/add_user_warranty/" + uuid + "/" + userUUID);
      });
    } else {
      setAddSubscriptionUrl("/add_outgoing/" + uuid);
      setAddTaskUrl("/add_todos/" + uuid);
      setAddGiftcardUrl("/add_vouchers_&_gift_cards/" + uuid);
      setAddWarrantyUrl("/add_warranty/" + uuid);
    }

    const today = new Date();

    const formattedDate = today.toISOString().split("T")[0]; // Extracts only the date part
    const month = moment(formattedDate).format("YYYY-MM");

    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        if (response.FamilyData) {
          let familyUserId = response.FamilyData.id;
          let familyId = response.FamilyData.Family.id;

          setfamilyUserId(familyUserId);
          setfamilyId(familyId);
          debugger;
          UserService.getDashboard({
            fid: familyId,
            uid: familyUserId,
            dateChosen: formattedDate,
            // monthChosen: month,
          }).then(
            (response) => {
              setAllData(response);
              setAllDataRem(response);
            },
            (error) => {}
          );

          localStorage.setItem("FamilyId", response.FamilyData.FamilyId);
          localStorage.setItem("id", response.FamilyData.id);
          localStorage.setItem("PrimaryIdval", response.FamilyData.id);
          localStorage.setItem("uuid", response.FamilyData.Family.uuid);
          setNameFam(response.FamilyData.Family.name);

          // user type set
          if (response.FamilyData) {
            if (
              response.FamilyData.isAo &&
              response.FamilyData.isAu &&
              response.FamilyData.isSu
            ) {
              setUserType("AO + AU + SU");
              localStorage.setItem("userType", "AO + AU + SU");
            } else if (response.FamilyData.isAo && response.FamilyData.isAu) {
              setUserType("AO + AU");
              localStorage.setItem("userType", "AO + AU");
            } else if (response.FamilyData.isAo && response.FamilyData.isSu) {
              setUserType("AO + SU");
              localStorage.setItem("userType", "AO + SU");
            } else if (response.FamilyData.isAu && response.FamilyData.isSu) {
              setUserType("AU + SU");
              localStorage.setItem("userType", "AU + SU");
            } else if (response.FamilyData.isAo) {
              setUserType("AO");
              localStorage.setItem("userType", "AO");
            } else if (response.FamilyData.isAu) {
              setUserType("AU");
              localStorage.setItem("userType", "AU");
            } else if (response.FamilyData.isSu) {
              setUserType("SU");
              localStorage.setItem("userType", "SU");
            }
          }
          setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);
  return (
    <Container
      fluid
      style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
      className="d-flex flex-column"
    >
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row className="align-items-center mb-3 ">
            <Col>
              <h1 className="h2 mb-0">
                <span>
                  <span>Hey </span>
                </span>
                <span>
                  {userType !== "SU"
                    ? UserDetails.firstname === "null"
                      ? UserDetails.email
                      : `${UserDetails.firstname},`
                    : nameFam}
                </span>
              </h1>
            </Col>
            {userType !== "AO" && (
              <Col className="col-auto">
                <Dropdown>
                  <Dropdown.Toggle
                    className="d-md-flex align-items-md-center"
                    variant="primary"
                    id="dropdown-add-new"
                  >
                    <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                    <span className="ms-md-2 d-none d-md-block">Add new</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Link className="dropdown-item" to={addSubscriptionUrl}>
                      Outgoings
                    </Link>
                    <Link className="dropdown-item" to={addTaskUrl}>
                      To Do
                    </Link>
                    <Link className="dropdown-item" to={addGiftcardUrl}>
                      Vouchers & Gift Cards
                    </Link>
                    <Link className="dropdown-item" to={addWarrantyUrl}>
                      Warranty
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
          </Row>
          <div className="fam-dashboard">
            {userType === "AO" ? (
              <Row className="g-3 g-md-4 g-xxl-5">
                <Col xl="12" xxl="13">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    {/* Event Logs */}
                    <FamilyDashboardEvent></FamilyDashboardEvent>
                  </section>
                </Col>
                <Col xl="12" xxl="11">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    {/* Account */}
                    <FamilyDashboardAccount></FamilyDashboardAccount>
                  </section>
                </Col>
                <Col xl="24" xxl="24">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    {/* Users */}
                    <FamilyDashboardUsers></FamilyDashboardUsers>
                  </section>
                </Col>
              </Row>
            ) : (
              <Row className="g-3 g-md-4 g-xxl-5">
                <Col xl="12" xxl="13">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    <FamilyDashboardSubscriptions allData={allData} />
                  </section>
                </Col>
                <Col xl="12" xxl="11">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    <FamilyDashboardCalendar
                      allData={allDataRem}
                      sendDataToParent={handleChildData}
                    />
                  </section>
                </Col>
                <Col md="15" lg="16" xl="8" xxl="8">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    <FamilyDashboardTasks allData={allData} />
                  </section>
                </Col>
                <Col md="9" lg="8" xl="5" xxl="5">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    <FamilyDashboardBenefits allData={allData} />
                  </section>
                </Col>
                <Col xl="11" xxl="11">
                  <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 h-100">
                    <FamilyDashboardGiftCards allData={allData} />
                  </section>
                </Col>
              </Row>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default FamilyDashboard;
