import React, { useState, useEffect } from "react";
import { Container, Col, Image, Card } from "react-bootstrap";
import { Large } from "../../stories/Button.stories";
import { Link, useHistory } from "react-router-dom";
import Envelop from "../../assets/images/envelop.svg";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import { Invite } from "../../stories/InvitationCards.stories";
import CheckGreen from "../../assets/images/check-green.svg";
import Loading from "../../common/Loading";

const MyInvites = (props) => {
  const [loading, setLoading] = useState(true);
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var initialFamilyList = props.location.familyList
    ? props.location.familyList
    : [];
  const [familyList, setFamilyList] = useState(initialFamilyList);
  var initialFamilyInviteList = props.location.inviteList
    ? props.location.inviteList
    : [];
  const [inviteList, setInviteList] = useState(initialFamilyInviteList);
  const [showInviteModel, setShowInviteModel] = useState(false);
  const [showInviteModel1, setShowInviteModel1] = useState(false);
  const [familyDashLink, setFamilyDashLink] = useState();
  const handleShowInviteModelClose = () => setShowInviteModel(false);
  const [accountPlanList, setAccountPlanList] = useState([]);

  let history = useHistory();
  var RedFamDash = localStorage.getItem("RedFamDash");

  useEffect(() => {
    // invite list
    UserService.getUserFamilyInvite(UserDetails.uuid).then((response) => {
      setInviteList(response.InviteData);
      setLoading(false);
    });

    UserService.getUserAccountPlan(UserDetails.uuid).then((response) => {
      var newData = [];
      for (var i = 0; i < response.usersFamilyData.length; i++) {
        if (response.usersFamilyData[i].Family.status !== "InActive") {
          newData.push(response.usersFamilyData[i]);
        }
      }
      // newData.forEach((row, ind) => (row.id = ind));
      setAccountPlanList(newData);
    });
  }, []);

  const handleInviteStatus = (inviteId, status, familyUUID, userInvitedAs) => {
    let updateData = {
      status: status,
    };
    if (userInvitedAs === true) {
      UserService.updateFamilyInviteStatus(inviteId, updateData).then(
        (response) => {
          //refresh family list
          setLoading(true);
          UserService.getUserFamily(UserDetails.uuid).then(
            (response) => {
              setFamilyList(response.usersFamilyData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          //refresh family invite list
          UserService.getUserFamilyInvite(UserDetails.uuid).then(
            (response) => {
              setInviteList(response.InviteData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          if (status === "Onboarded") {
            if (RedFamDash !== "RedFamDash" && inviteList.length === 1) {
              setShowInviteModel1(true);
            } else {
              setShowInviteModel(true);
            }

            setFamilyDashLink(familyUUID);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      UserService.update_SU_FamilyInviteStatus(inviteId, updateData).then(
        (response) => {
          //refresh family list
          setLoading(true);
          UserService.getUserFamily(UserDetails.uuid).then(
            (response) => {
              setFamilyList(response.usersFamilyData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          //refresh family invite list
          UserService.getUserFamilyInvite(UserDetails.uuid).then(
            (response) => {
              setInviteList(response.InviteData);

              setLoading(false);
            },
            (error) => {
              setLoading(false);
            }
          );
          if (status === "Onboarded") {
            if (RedFamDash !== "RedFamDash" && inviteList.length === 1) {
              setShowInviteModel1(true);
            } else {
              setShowInviteModel(true);
            }

            setFamilyDashLink(familyUUID);
          }
        },
        (error) => {
          setLoading(false);
        }
      );
    }
  };

  const famDashboardLink = () => {
    UserService.getUserSingleFamilyDetails(familyDashLink, UserDetails.id).then(
      (response) => {
        if (
          response.FamilyData.isAo &&
          response.FamilyData.isAu &&
          response.FamilyData.isSu
        ) {
          localStorage.setItem("userType", "AO + AU + SU");
        } else if (response.FamilyData.isAo && response.FamilyData.isAu) {
          localStorage.setItem("userType", "AO + AU");
        } else if (response.FamilyData.isAo && response.FamilyData.isSu) {
          localStorage.setItem("userType", "AO + SU");
        } else if (response.FamilyData.isAu && response.FamilyData.isSu) {
          localStorage.setItem("userType", "AU + SU");
        } else if (response.FamilyData.isAo) {
          localStorage.setItem("userType", "AO");
        } else if (response.FamilyData.isAu) {
          localStorage.setItem("userType", "AU");
        } else if (response.FamilyData.isSu) {
          localStorage.setItem("userType", "SU");
        }

        if (response.FamilyData.isSu === true) {
          history.push(`/support_dashboard/${familyDashLink}`);
        } else {
          history.push(`/dashboard/${familyDashLink}`);
        }
      },
      (error) => {}
    );
  };

  return (
    <>
      <Container
        fluid
        style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
        className="d-flex flex-column"
      >
        {loading ? (
          <Loading />
        ) : (
          <>
            <Col className="text-left" style={{ margin: "auto" }}>
              <Card className="family-invitation-card my-1 sizing-properly">
                <Card.Body className="p-4 family-invitation-cardBody">
                  <h3 className="title-heading">Group Invitations</h3>
                  <p className="small-text">
                    Find all the onboarding invitations that you have received
                    on the Elderly Life platform
                  </p>
                  <div className="fmly-invite-div card-scroll-element">
                    {inviteList.length !== 0 &&
                    inviteList.some(
                      (el) => el.inviteStatus === "Acceptance Pending"
                    ) ? (
                      inviteList.map((userInvite, index) =>
                        userInvite.inviteStatus === "Acceptance Pending" ? (
                          <div className="mb-4" key={index}>
                            <Invite
                              familyName={userInvite.Family.name}
                              userRole={
                                userInvite.isAu === true
                                  ? "Main user"
                                  : "Support User"
                              }
                              variant="invite"
                              invitedBy={
                                userInvite.invitedBy
                                  ? userInvite.invitedBy.firstName +
                                    " " +
                                    userInvite.invitedBy.lastName
                                  : ""
                              }
                              acceptClick={() =>
                                handleInviteStatus(
                                  userInvite.id,
                                  "Onboarded",
                                  userInvite.Family.uuid,
                                  userInvite.isAu
                                )
                              }
                              rejectClick={() =>
                                handleInviteStatus(
                                  userInvite.id,
                                  "Cancelled",
                                  userInvite.Family.uuid,
                                  userInvite.isAu
                                )
                              }
                            />
                          </div>
                        ) : (
                          " "
                        )
                      )
                    ) : (
                      <div className="text-center mt-3">
                        <Image
                          className="envelop"
                          src={Envelop}
                          alt="Envelop image"
                        />
                        <p className="small-blue-text">
                          you have no invitations to act upon
                        </p>
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Modal
              show={showInviteModel}
              onHide={handleShowInviteModelClose}
              className="invite_accpt_modal"
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <h3>Congratulations !!</h3>
                <Image
                  className="hello-text-img my-4"
                  src={CheckGreen}
                  alt="Check mark"
                  width="18%"
                />
                <p>You are Successfully Onboarded into the Group</p>

                <button
                  onClick={() => famDashboardLink()}
                  type="button"
                  className="btn btn-primary"
                >
                  Visit your group page
                </button>
              </Modal.Body>
            </Modal>

            <Modal show={showInviteModel1} className="invite_accpt_modal">
              <Modal.Header>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <h3>Congratulations !!</h3>
                <Image
                  className="hello-text-img my-4"
                  src={CheckGreen}
                  alt="Check mark"
                  width="18%"
                />
                <p>You are Successfully Onboarded into the Group</p>

                <button
                  onClick={() => famDashboardLink()}
                  type="button"
                  className="btn btn-primary"
                >
                  Visit your group page
                </button>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default MyInvites;
