import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import PDFViewer from "pdf-viewer-reactjs";
import FileViewer from "react-file-viewer";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const ViewWarrantyDetails = (props) => {
  let history = useHistory();
  const [warrantyDetails, setWarrantyDetails] = useState([]);
  let { uuid, warId, userUUID } = useParams();
  const [warrantyEditUrl, setWarrantyEditUrl] = useState("");
  const [backText, setBackText] = useState("Back to My Warranties");
  const [showDocSub, setShowDocSub] = useState(false);
  const [docLink, setDocLink] = useState("");
  const [loader, setLoader] = useState(true);
  const [title, setTitle] = useState("Warranties");
  const [parsedDoc, setParsedDoc] = useState(false);
  const [fileName, setFileName] = useState("");
  const [showDeleteSub, setShowDeleteSub] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successToast, setSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        let pUser = response.userData;

        const abc =
          pUser.firstName === "null"
            ? pUser.email
            : pUser.firstName + " " + pUser.lastName === "null"
            ? ""
            : pUser.lastName;
        setTitle(`${abc}'s Warranties`);
      });

      UserService.userProfile(userUUID).then((response) => {
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    }

    // get single warranty details by ID
    UserService.getWarrantyDetailsById(warId).then(
      (response) => {
        setWarrantyDetails(response.data);

        // Convert back to array
        const parsedUrls = response.data.rows[0].invoiceDocument
          ? JSON.parse(response.data.rows[0].invoiceDocument)
          : response.data.rows[0].invoiceDocument;

        setParsedDoc(parsedUrls);

        if (
          response.data.rows &&
          response.data.rows[0] &&
          response.data.rows[0].id
        ) {
          if (userUUID) {
            setWarrantyEditUrl(
              "/edit_user_warranty/" +
                uuid +
                "/" +
                response.data.rows[0].id +
                "/" +
                userUUID
            );
          } else {
            setWarrantyEditUrl(
              "/edit_warranty/" + uuid + "/" + response.data.rows[0].id
            );
          }
        } else {
          setWarrantyEditUrl("#");
        }
        setLoader(false);
      },
      (error) => {}
    );
  }, []);
  const handleFrequency = (freq) => {
    switch (freq) {
      case "6M":
        return "6 Months";
      case "12M":
        return "12 Months";
      case "24M":
        return "24 Months";
      case "36M":
        return "36 Months";
      case "48M":
        return "48 Months";
      default:
        return "";
    }
  };

  // Close doc model
  const handleShowDocSubClose = () => {
    setShowDocSub(false);
  };

  // Show doc modal
  const handleShowDocSubShow = (data) => {
    setShowDocSub(true);
    setFileName(data);
  };

  // Get the file extension from the URL
  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  };

  // Close delete confirmation
  const handleShowDeleteModal = () => {
    setShowDeleteSub(true);
  };

  // Close delete confirmation
  const handleShowDeleteSubClose = () => {
    setShowDeleteSub(false);
  };

  // Delete subscription
  const handleDeleteWarranty = (subId) => {
    setShowDeleteSub(false);
    UserService.deleteWarranty(subId).then(
      (response) => {
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("Warranty deleted successfully");
        window.scrollTo(0, 0);

        setTimeout(() => {
          history.push(`/warranties/${uuid}`);
        }, 2500);
      },
      (error) => {}
    );
  };

  const validateDate = (date) => {
    // Get today's date
    const today = new Date();

    // Create the target date from the ISO string
    const targetDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  return (
    <Container>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem">
        <Col xl="21" xxl="18">
          <h1 className="lh-base mb-4">{title}</h1>
          <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
            {loader ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <section>
                  <Row className="gy-3 gy-md-4 gy-lg-0 mb-3 mb-md-4">
                    <Col xs lg="15">
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250 }}
                        overlay={
                          <Tooltip id="">
                            <div className="">
                              {warrantyDetails.rows[0] &&
                              warrantyDetails.rows[0].productName
                                ? warrantyDetails.rows[0].productName
                                : "--"}
                            </div>
                          </Tooltip>
                        }
                      >
                        <h2
                          className={`${
                            warrantyDetails.rows[0] &&
                            warrantyDetails.rows[0].additionalDetails
                              ? "mb-1 mb-md-2 text-truncate "
                              : "mb-0 text-truncate "
                          }`}
                        >
                          {warrantyDetails.rows[0] &&
                          warrantyDetails.rows[0].productName
                            ? warrantyDetails.rows[0].productName
                            : "--"}
                        </h2>
                      </OverlayTrigger>
                      {warrantyDetails.rows[0] &&
                        warrantyDetails.rows[0].additionalDetails !== "" && (
                          <p className="mb-0">
                            {warrantyDetails.rows[0].additionalDetails}
                          </p>
                        )}
                    </Col>
                    <Col
                      xs="auto"
                      className="order-lg-last ms-auto"
                      style={{ height: "0" }}
                    >
                      <Link
                        to={warrantyEditUrl}
                        className="btn btn-white btn-sm btn-square d-inline-flex d-md-none"
                      >
                        <i className="fa-solid fa-edit"></i>
                      </Link>
                      <Link
                        to={warrantyEditUrl}
                        className="btn btn-white btn-sm d-none d-md-inline-block"
                      >
                        <i className="fa-solid fa-edit me-2"></i>Edit
                      </Link>
                      <button
                        className="btn btn-danger btn-sm btn-square d-inline-flex ms-2"
                        onClick={() => handleShowDeleteModal()}
                      >
                        <i className="fa-solid fa-trash text-white"></i>
                      </button>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0 mb-3 mb-md-4">
                    <Col xs="16" md="8">
                      <div className="small fw-medium">Category</div>
                      <div className="d-flex align-items-center gap-2 mt-1">
                        <p className="text-dark fw-medium mb-0">
                          {warrantyDetails.rows[0] &&
                          warrantyDetails.rows[0].Category &&
                          warrantyDetails.rows[0].Category.name
                            ? warrantyDetails.rows[0].Category.name
                            : "--"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0 mb-3 mb-md-4">
                    <Col xs="16" md="8">
                      <div className="small fw-medium">Supplier</div>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <img
                          className="img-third-party-logo"
                          src={
                            warrantyDetails.rows[0]
                              ? warrantyDetails.rows[0].Supplier.logo
                              : "https://el-publicassets.s3.eu-west-2.amazonaws.com/images/Supplier+default+logo.png"
                          }
                          alt="supplier-logo"
                          style={{
                            borderRadius: "50%",
                            border: "1px solid #a09e9e",
                          }}
                        />
                        <p className="text-dark fw-medium mb-0">
                          {warrantyDetails.rows[0].Supplier &&
                          warrantyDetails.rows[0].Supplier.name
                            ? warrantyDetails.rows[0].Supplier.name === "Others"
                              ? warrantyDetails.rows[0].otherSupplierName
                              : warrantyDetails.rows[0].Supplier.name
                            : "--"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0">
                    <Col md="8">
                      <div className="small fw-medium">Item code</div>
                      <p className="text-dark fw-medium mb-0">
                        {warrantyDetails.rows[0] &&
                        warrantyDetails.rows[0].itemCode
                          ? warrantyDetails.rows[0].itemCode
                          : "--"}
                      </p>
                    </Col>
                    <Col md="8">
                      <div className="small fw-medium">Serial number</div>
                      <p className="text-dark fw-medium mb-0">
                        {warrantyDetails.rows[0] &&
                        warrantyDetails.rows[0].serialNumber
                          ? warrantyDetails.rows[0].serialNumber
                          : "--"}
                      </p>
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section>
                  <h3 className="mb-3 mb-md-4">Warranty</h3>
                  <Row className="gy-3 gy-md-0 mb-3 mb-md-4">
                    <Col md="8">
                      <div className="small fw-medium">Status</div>
                      <p
                        className={` fw-semibold mb-0 ${
                          warrantyDetails.rows[0] &&
                          warrantyDetails.rows[0].status === "In Warranty"
                            ? "text-success"
                            : "text-danger"
                        }`}
                      >
                        {warrantyDetails.rows[0]
                          ? warrantyDetails.rows[0].status
                          : "--"}
                      </p>
                    </Col>
                    <Col md="12">
                      <div className="small fw-medium">Expires on</div>
                      <div className="d-flex align-items-center">
                        <p
                          className={`${
                            validateDate(
                              warrantyDetails.rows &&
                                warrantyDetails.rows[0].expiryDate
                            ) <= 30
                              ? "text-danger"
                              : validateDate(
                                  warrantyDetails.rows &&
                                    warrantyDetails.rows[0].expiryDate
                                ) <= 60 &&
                                validateDate(
                                  warrantyDetails.rows &&
                                    warrantyDetails.rows[0].expiryDate
                                ) > 30
                              ? "text-orange"
                              : validateDate(
                                  warrantyDetails.rows &&
                                    warrantyDetails.rows[0].expiryDate
                                ) <= 90 &&
                                validateDate(
                                  warrantyDetails.rows &&
                                    warrantyDetails.rows[0].expiryDate
                                ) > 60
                              ? "text-lightgreen"
                              : "text-success"
                          } fw-semibold mb-0`}
                        >
                          {warrantyDetails.rows &&
                          warrantyDetails.rows[0].expiryDate
                            ? moment(warrantyDetails.rows[0].expiryDate).format(
                                "DD/MM/YYYY"
                              )
                            : "--"}
                        </p>
                        {warrantyDetails.rows[0] &&
                          warrantyDetails.rows[0].reminder && (
                            <div
                              className="d-flex ms-2 align-items-start"
                              style={{ marginTop: "-2px" }}
                            >
                              <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light">
                                <i className="fa-solid fa-sm fa-bell"></i>
                                <span className="ms-1">Reminder</span>
                              </span>
                            </div>
                          )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0">
                    <Col md="8">
                      <div className="small fw-medium">Price</div>
                      <p className="text-dark fw-medium mb-0">
                        £
                        {warrantyDetails.rows[0]
                          ? warrantyDetails.rows[0].price
                          : "--"}
                      </p>
                    </Col>
                    <Col md="8">
                      <div className="small fw-medium">Purchase date</div>
                      <p className="text-dark fw-semibold mb-0">
                        {warrantyDetails.rows[0]
                          ? moment(warrantyDetails.rows[0].purchaseDate).format(
                              "DD/MM/YYYY"
                            )
                          : ""}
                      </p>
                    </Col>
                    <Col md="8">
                      <div className="small fw-medium">Warranty term</div>
                      <p className="text-dark fw-medium mb-0">
                        {warrantyDetails.rows[0]
                          ? handleFrequency(
                              warrantyDetails.rows[0].warrantyTenure
                            )
                          : ""}
                      </p>
                    </Col>
                  </Row>
                </section>
                <hr className="my-6 my-md-7" />
                <section>
                  <h3 className="mb-3 mb-md-4">
                    {parsedDoc && parsedDoc.length > 1
                      ? "Uploaded attachments"
                      : "Uploaded attachment"}
                  </h3>
                  <Row className="gy-0">
                    {parsedDoc && parsedDoc.length > 0 ? (
                      parsedDoc.map((data, index) => (
                        <Col md="12" lg="8">
                          <p
                            className="btn btn-white btn-sm d-flex p-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleShowDocSubShow(data)}
                          >
                            <span className="fw-medium text-black">
                              <i className="fa-solid fa-file me-1"></i>{" "}
                              {data.split("/").pop().substring(0, 20) + "..."}
                            </span>
                          </p>
                        </Col>
                      ))
                    ) : (
                      <p className="view-txt fw-medium">
                        No documents available
                      </p>
                    )}
                  </Row>
                </section>
              </>
            )}
          </section>
        </Col>
      </Row>
      {/* uploaded document   */}
      <Modal
        show={showDocSub}
        onHide={handleShowDocSubClose}
        className=""
        size="lg"
      >
        <Modal.Header
          closeButton
          className="d-flex align-items-center justify-content-between"
        >
          <Modal.Title className="mx-1">
            <h3 className="mb-0">View Attachment</h3>
          </Modal.Title>

          <Link
            to={{
              pathname: fileName,
            }}
            target="_blank"
            className="ms-auto position-absolute"
            style={{
              right: "4rem",
              color: "black",
              height: "20px",
            }}
          >
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id="button-tooltip">Download this file</Tooltip>
              }
            >
              <i class="fa-solid fa-download"></i>
            </OverlayTrigger>
          </Link>
        </Modal.Header>
        <Modal.Body
          className="mx-1 overflow-auto"
          style={{ maxHeight: "75vh" }}
        >
          <div className="document-container mb-5">
            {get_url_extension(fileName) === "pdf" ? (
              <PDFViewer
                document={{
                  url: fileName,
                }}
                className="w-100 h-100"
              />
            ) : get_url_extension(fileName) === "png" ||
              get_url_extension(fileName) === "jpg" ||
              get_url_extension(fileName) === "jpeg" ? (
              <img src={fileName} alt={`Document`} className="w-100 h-100" />
            ) : get_url_extension(fileName) === "doc" ||
              get_url_extension(fileName) === "docx" ? (
              <FileViewer
                fileType={get_url_extension(fileName)}
                filePath={fileName}
                className="w-100 h-100"
              />
            ) : (
              <p>Unsupported file type: {get_url_extension(fileName)}</p>
            )}
          </div>
        </Modal.Body>
      </Modal>

      {/* // Delete subscription */}
      <Modal
        show={showDeleteSub}
        onHide={handleShowDeleteSubClose}
        className="invite_au_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="h3 mb-0">Delete Warranty</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            All the Warranty related details will be deleted permanently from
            the system.
          </p>
          <p className="mb-5">Do you want to continue?</p>
          <Button variant="white" onClick={handleShowDeleteSubClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleDeleteWarranty(warId)}
            className="ms-3"
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ViewWarrantyDetails;
