import React from "react";
import { Card } from "react-bootstrap";

function FamilyListCard(props) {
  const {
    firstLetter,
    familyId,
    title,
    membershipDate,
    role,
    planname,
    planDetail,
    buttonClick,
    buttonClick1,
    aoDetails,
    Family,
    uuid,
    variant,
    familyName,
    planName,
    planPrice,
    planDuration,
    planImage,
    auSeats,
    suSeats,
    isAo,
    ...rest
  } = props;

  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  return (
    <div {...rest}>
      <div
        className="position-relative p-2 border rounded bg-white mb-5"
        style={{ boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px" }}
      >
        <h5 className="mb-2 mx-2 dashboard-header fw-bold">
          {title ? title : "--"}
        </h5>
        <div
          className="dashboard-details my-2 mx-2 dashboard-subheader"
          style={{ fontSize: "14px" }}
        >
          <div className="d-flex justify-content-between pb-3">
            <div className="font-weight-bold py-1" style={{ marginTop: "2px" }}>
              Member since:{" "}
              <strong className="mx-1 bold-colour">{membershipDate}</strong>
            </div>
            <div
              className=" fw-semibold py-1 px-2"
              style={{
                fontSize: "12px",
                border: "2px solid #36f1cc",
                borderRadius: "5px",
              }}
            >
              Group ID:
              <span className="mx-1 bold-colour fw-bold">{familyId}</span>
            </div>
          </div>

          <div className="d-flex flex-column pb-4">
            <div className="">Your role in this group</div>
            <div className="fw-semibold bold-colour">{role}</div>
          </div>

          <div className="d-flex align-items-center justify-content-between pb-1 mb-2">
            <div className="d-flex flex-column">
              <div className="">Admin for this Group</div>
              <div className="d-flex justify-content-between fw-semibold">
                <div className="fw-semibold bold-colour">
                  {UserDetails.email === aoDetails.User.email
                    ? "You are the admin"
                    : (aoDetails.User.firstName !== "null"
                        ? aoDetails.User.firstName
                        : aoDetails.User.email) +
                      " " +
                      (aoDetails.User.lastName !== "null" &&
                        aoDetails.User.lastName)}
                </div>
              </div>
            </div>
            <div className="btn btn-primary w-25" onClick={buttonClick1}>
              Login
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FamilyListCard;
