import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import userService from "../../services/user.service";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import { eventBus } from "./EventBus";

const FamilyDashboardAccount = () => {
  let { uuid } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const currentYear = moment(new Date()).format("YYYY");

  const [familyDetails, setFamilyDetails] = useState("");
  const [currentPlanDetails, setCurrentPlanDetails] = useState("");
  const [currentPlanLoader, setCurrentPlanLoader] = useState(true);
  const [custId, setCustId] = useState("");
  const [accountPlanURL, setAccountPlanURL] = useState("");
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [boughtDate, setBoughtDate] = useState();
  const [upcomingDate, setUpcomingDate] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setAccountPlanURL("/manageSubscription/" + uuid);

    // get family details
    userService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        setFamilyDetails(response);
        setCustId(response.FamilyData.Family.stripeCustomerId);
        setCurrentPlanDetails(
          response.FamilyData.Family.Family_Subscription
            .Account_Subscription_Plan
        );

        // // Get Customer stripe details
        // getCustomerStripeDetails(response.FamilyData.Family.stripeCustomerId);
        let st = new Date("01-01-" + currentYear).getTime();
        userService
          .getCustomerInvoiceList(
            response.FamilyData.Family.stripeCustomerId,
            response.FamilyData.Family.Family_Subscription.stripeSubscriptionId,
            st
          )
          .then((res) => {
            var items = [];
            if (res.length > 0) {
              for (let i = 0; i < res.length; i++) {
                items.push({
                  payment_date: moment
                    .unix(res[i].created)
                    .format("MMMM Do, YYYY"),
                  currency: res[i].currency === "gbp" ? "£" : res[i].currency,
                  amount:
                    String(res[i].amount_paid).slice(0, -2) +
                    "." +
                    String(res[i].amount_paid).slice(-2),
                  inv_number: res[i].number,
                  billing_reason: res[i].billing_reason,
                  file: res[i].invoice_pdf,
                  currYear: moment.unix(res[i].created).format("YYYY"),
                  hostURL: res[i].hosted_invoice_url,
                  renewalType: res[i].lines.data[0].plan.interval,
                });
              }
            }

            // Calculate the next renewal date
            const currentDate = items[0].payment_date; // Example current date
            const renewalType = items[0].renewalType; // Example renewal type
            const date = moment(currentDate, "MMMM Do, YYYY");
            setBoughtDate(items[0].payment_date);
            setUpcomingDate(date.add(1, renewalType).format("MMMM Do, YYYY"));
          });
        setCurrentPlanLoader(false);

        setTimeout(() => {
          setLoadingspinner(false);
        }, 250);
      },
      (error) => {}
    );

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div className="d-flex mb-3">
        <h2 className="me-auto mb-0">Account</h2>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={accountPlanURL}
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link>
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          <Row className="m-0 g-3">
            {/* your plan  */}
            <Col
              md="12"
              lg="12"
              xl="12"
              xxl="10"
              className="p-4 border rounded bg-white mt-0"
              style={{ boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px" }}
            >
              {!currentPlanLoader && (
                <div className="">
                  <div className="">
                    <div className="mb-5">
                      <h3 bold-colour className="mb-3">
                        {currentPlanDetails.name}
                      </h3>
                      <div className="text-info fs-36 fw-bold">
                        {" "}
                        £{currentPlanDetails.price}
                      </div>
                      <div className="fw-semibold bold-colour">
                        {currentPlanDetails.renewalTyp}
                      </div>
                    </div>
                    <p className="fw-semibold bold-colour mb-2">
                      <span class="text-success me-2">&#10004;</span>
                      {currentPlanDetails.availableS === 1 ? (
                        <span>{currentPlanDetails.availableS} Main user</span>
                      ) : (
                        <span>{currentPlanDetails.availableS} Main users</span>
                      )}
                    </p>
                    <p className="fw-semibold bold-colour mb-2">
                      <span class="text-success me-2">&#10004;</span>
                      {currentPlanDetails.availableS === 1 ? (
                        <span>
                          {currentPlanDetails.availableS} support user
                        </span>
                      ) : (
                        <span>
                          {currentPlanDetails.availableS} support users
                        </span>
                      )}
                    </p>
                    <p className="fw-semibold bold-colour mb-2">
                      <span class="text-success me-2">&#10004;</span>
                      Cancel any time
                    </p>
                    <p className="fw-semibold bold-colour mb-2">
                      <span class="text-success me-2">&#10004;</span>
                      No hidden costs
                    </p>
                  </div>
                </div>
              )}
              {currentPlanLoader && (
                <div>
                  <div className="text-center">
                    <button className="btn loading-btn p-0" type="button">
                      <span
                        className="spinner-border spinner-border-sm me-2"
                        role="status"
                      ></span>
                      Loading Your Plan..
                    </button>
                  </div>
                </div>
              )}
            </Col>

            {/* other details */}
            <Col
              md="12"
              lg="12"
              xl="12"
              xxl="14"
              className={`d-flex flex-column ${
                windowWidth < 768 ? "mt-3 p-0" : "mt-0 ps-3"
              }`}
            >
              <div
                className=" mb-3 p-4 border rounded bg-white"
                style={{ boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px" }}
              >
                <div className="fs-12">Group name</div>
                <div className="d-flex align-items-center fw-semibold bold-colour justify-content-between">
                  <span
                    style={{ marginTop: "6px" }}
                    className="fs-17 text-truncate"
                  >
                    {familyDetails.FamilyData.Family.name}
                  </span>
                  <div
                    className="btn btn-white btn-sm btn-circle flex-shrink-0"
                    onClick={() => eventBus.emit("triggerGroupNameChange")}
                  >
                    <i className="fa-solid fa-sm fa-edit text-dark"></i>
                  </div>
                </div>
              </div>

              <div
                className="p-4 border rounded bg-white d-flex flex-column justify-content-center"
                style={{
                  boxShadow: "rgba(0, 123, 255, 0.2) 0px 3px 8px",
                  flex: 1,
                }}
              >
                <span className="fs-12 mb-3">Renewal history </span>
                <div className=" mb-2">
                  <span className="fs-14">You purchased this plan on </span>
                  <b className="bold-colour">{boughtDate}</b>
                </div>
                <div className=" mb-2">
                  <span className="fs-14">Plan is renewal </span>
                  <b className="bold-colour">{currentPlanDetails.renewalTyp}</b>
                </div>
                <div className=" ">
                  <span className="fs-14">Next renewal date will be on </span>
                  <b className="bold-colour">{upcomingDate}</b>
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default FamilyDashboardAccount;
