import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Loading from "../../common/Loading";
import moment from "moment";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";

// select error style
const customStylesError = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid red",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

// select style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid #aabccf",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

const Reminders = (props) => {
  let { uuid } = useParams();
  let history = useHistory();
  const sortRef = useRef(null); // Ref for the dropdown button

  const [loadingspinner, setloadingspinner] = useState(true);
  const [loading, setloading] = useState(false);

  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const uuidDetails = localStorage.getItem("uuid");
  const userUuidDetails = JSON.parse(localStorage.getItem("userUUID"));
  const parentUserUUId = localStorage.getItem("parentUser.uuid");
  const PrimaryIdval = localStorage.getItem("PrimaryIdval");
  const [filtAll, setfiltAll] = useState([]);
  const [selectedSort, setSelectedSort] = useState(); // Selected option for sort dropdown
  const [isSortDropdown, setIsSortDropdown] = useState(false);
  const [filterVal, setfilterVal] = useState("all");
  const [tabVal, settabVal] = useState("other");
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [activeButton, setActiveButton] = useState("other"); // Initial state set to 'voucher'
  const [allData, setAllData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [familyUserId, setfamilyUserId] = useState();
  const [familyId, setfamilyId] = useState();
  const [hasMore, sethasMore] = useState(false);

  useEffect(() => {
    var userType = localStorage.getItem("userType");
    const userArr = [
      { value: "all", label: "All" },
      { value: "expired", label: "Expired" },
    ];
    const primaryArr = [{ value: "expired", label: "Expired" }];
    const primaryArrVal = [];

    UserService.getFamilyAllAu(uuid).then(
      (resp) => {
        for (let i = 0; i < resp.data.length; i++) {
          userArr.push({
            value: resp.data[i].id,
            label: resp.data[i].User.email,
          });

          if (parseInt(PrimaryIdval) === resp.data[i].id) {
            primaryArr.push({
              value: resp.data[i].id,
              label: resp.data[i].User.email,
            });
            primaryArrVal.push({
              value: resp.data[i].id,
              label: resp.data[i].User.email,
            });
          }
          if (
            userType === "AO" ||
            userType === "AO + SU" ||
            userType === "AO + AU" ||
            userType === "AO + AU + SU"
          ) {
            setfiltAll(userArr);
            setSelectedSort({
              value: "all",
              label: "All",
            }); // Selected option for sort dropdown
          } else {
            setfiltAll(primaryArr);
            primaryArrVal[0] &&
              setSelectedSort({
                value: primaryArrVal[0].value,
                label: primaryArrVal[0].label,
              }); // Selected option for sort dropdown
          }
        }

        UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
          (response) => {
            let familyUserId = response.FamilyData.id;
            let familyId = response.FamilyData.Family.id;
            setfamilyUserId(familyUserId);
            setfamilyId(familyId);
            setfilterVal(
              userType === "AO" ||
                userType === "AO + SU" ||
                userType === "AO + AU" ||
                userType === "AO + AU + SU"
                ? filterVal
                : primaryArrVal[0]
                ? primaryArrVal[0].value
                : parseInt(PrimaryIdval)
            );
            const data = {
              fid: familyId,
              uid: familyUserId,
              filterValue:
                userType === "AO" ||
                userType === "AO + SU" ||
                userType === "AO + AU" ||
                userType === "AO + AU + SU"
                  ? filterVal
                  : primaryArrVal[0]
                  ? primaryArrVal[0].value
                  : parseInt(PrimaryIdval),
              limit: limit,
              offset: offset,
            };
            UserService.getRemData(tabVal, data).then(
              (resp) => {
                setAllData(resp.otherData);

                if (resp.otherData.length === 0) {
                  setIsEmpty(true);

                  sethasMore(false);
                } else {
                  setIsEmpty(false);
                  if (resp.otherData.length >= limit) {
                    setOffset(limit);
                    sethasMore(true);
                  } else {
                    sethasMore(false);
                  }
                }
                setTimeout(() => {
                  setloadingspinner(false);
                }, 500);
              },
              (error) => {}
            );
          }
        );
      },
      (error) => {}
    );
  }, []);

  const handleremindersett = () => {
    if (parentUserUUId === null) {
      history.push({
        pathname: `/group_settings/${uuidDetails}`,
      });
    } else {
      history.push({
        pathname: `/user_group_settings/${uuidDetails}/${userUuidDetails}`,
      });
    }
  };

  const closeDropdownSort = () => {
    setIsSortDropdown(false);
  };

  const toggleDropdownSort = () => {
    setIsSortDropdown((prev) => !prev); // Toggle the dropdown state
  };

  useEffect(() => {
    // Handler to detect clicks outside of the dropdown
    const handleClickOutside = (event) => {
      // handle filter dropdown

      // handle sort dropdown
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        closeDropdownSort();
      }
    };

    // Bind the event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to handle button click
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType); // Set active button based on click
    settabVal(buttonType);
    setLimit(50);
    setOffset(0);
    const data = {
      fid: familyId,
      uid: familyUserId,
      filterValue: filterVal,
      limit: 50,
      offset: 0,
    };

    getReminder(buttonType, data);
  };

  // Function to handle button click
  const taskSort = (val) => {
    setfilterVal(val);
    setLimit(50);
    setOffset(0);
    const data = {
      fid: familyId,
      uid: familyUserId,
      filterValue: val,
      limit: 50,
      offset: 0,
    };

    getReminder(tabVal, data);
  };

  const getReminder = (val, data) => {
    setloading(true);
    UserService.getRemData(val, data).then(
      (resp) => {
        setAllData(resp.otherData);
        if (resp.otherData.length === 0) {
          setIsEmpty(true);

          sethasMore(false);
        } else {
          setIsEmpty(false);
          if (resp.otherData.length >= limit) {
            setOffset(limit);
            sethasMore(true);
          } else {
            sethasMore(false);
          }
        }
        setTimeout(() => {
          setloading(false);
        }, 500);
      },
      (error) => {}
    );
  };

  const fetchMoreData = () => {
    const data = {
      fid: familyId,
      uid: familyUserId,
      filterValue: filterVal,
      limit: limit,
      offset: offset,
    };
    setTimeout(() => {
      UserService.getRemData(tabVal, data).then(
        (response) => {
          setAllData([...allData, ...response.otherData]);
          let nextOffset = Number(offset) + Number(limit);

          if (response.otherData.length + allData.length >= nextOffset) {
            setOffset(nextOffset);
            sethasMore(true);
          } else {
            sethasMore(false);
          }
        },
        (error) => {}
      );
    }, 500);
  };
  return (
    <>
      {/* old code  */}
      <>
        {/* {parentUserUUId === null &&
              userType !== "SU" &&
              userType !== "AO" &&
              userType !== "AO + SU" ? (
                <div className="Reminder-AllRem-Btn" onClick={() => hello()}>
                  <span className="Reminder-AllRem-Btn-Txt">
                    Change reminder settings
                  </span>
                </div>
              ) : (
                ""
              )} */}
        {/* <div className="Reminder-AllRem-Main-1 mb-4 d-flex align-items-center">
              <div className="Reminder-Table-1 me-3">Products & Services</div>
              {userType !== "AU" &&
              userType !== "AU + SU" &&
              userType !== "SU" &&
              !parentUserUUId ? (
                <div className="Filter-rem d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{ label: "All", value: "all" }}
                      options={filData}
                      onChange={subscriptionFilter}
                    />
                  </div>
                </div>
              ) : (
                <div className="Filter-rem d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{
                        label: filData1[0].label,
                        value: filData1[0].value,
                      }}
                      options={filData1}
                      onChange={subscriptionFilter}
                    />
                  </div>
                </div>
              )}
            </div> */}
        {/* <div
              className="ag-theme-alpine"
              style={{ height: "309px", width: "50.6rem" }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                pagination={true}
                paginationPageSize={5}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px;">You currently have no reminders</span>'
                }
                defaultColDef={{
                  editable: true,
                  enableRowGroup: true,
                  enablePivot: true,
                  enableValue: true,
                  sortable: true,
                  resizable: true,
                }}
              ></AgGridReact>
            </div> */}

        {/* <div className="Reminder-AllRem-Main-1 mb-4 mt-5 d-flex align-items-center">
              <div className="Reminder-Table-1 me-3">TO DO's</div>
              {userType !== "AU" &&
              userType !== "AU + SU" &&
              userType !== "SU" &&
              !parentUserUUId ? (
                <div className="Filter-rem-2 d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{ label: "All", value: "all" }}
                      options={filData}
                      onChange={subscriptionFilter1}
                    />
                  </div>
                </div>
              ) : (
                <div className="Filter-rem-2 d-flex align-items-center">
                  <div className="pe-2">Filter by :</div>
                  <div className="flex-1">
                    <Select
                      styles={customStyles}
                      defaultValue={{
                        label: filData1[0].label,
                        value: filData1[0].value,
                      }}
                      options={filData1}
                      onChange={subscriptionFilter1}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              className="ag-theme-alpine"
              style={{ height: "309px", width: "50.6rem" }}
            >
              <AgGridReact
                rowData={rowData1}
                columnDefs={columnDefs1}
                pagination={true}
                paginationPageSize={5}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px;">You currently have no reminders</span>'
                }
                defaultColDef={{
                  editable: true,
                  enableRowGroup: true,
                  enablePivot: true,
                  enableValue: true,
                  sortable: true,
                  resizable: true,
                }}
              >
              </AgGridReact>
            </div> */}
      </>

      {/* new code  */}
      <>
        <Container
          fluid
          style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
          className="d-flex flex-column"
        >
          <>
            <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
              <Col>
                <h1 className="lh-base mb-0">Reminders</h1>
              </Col>

              {/* filter  */}
              <Col
                xs="auto"
                className="position-relative"
                ref={sortRef} // Ref to track button for outside clicks
              >
                <button
                  className={`border-white btn btn-white d-md-flex align-items-md-center shadow position-relative ${
                    isSortDropdown ? "dropdown-arrow" : ""
                  }`}
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  onClick={toggleDropdownSort} // Toggle dropdown on click
                  aria-expanded={isSortDropdown}
                >
                  <i className="fa-solid fa-sm fa-filter lh-1 align-middle"></i>
                  <span className="text-dark ms-md-2 d-none d-md-block">
                    Filter by
                  </span>
                </button>
                {/* main dropdown  */}
                <ul
                  className={`dropdown-menu dropdown-parent mt-2 ${
                    isSortDropdown ? "show" : ""
                  }`}
                  ref={sortRef}
                  style={{
                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  }}
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    {filtAll.map((option) => (
                      <div
                        key={option.value}
                        className="dropdown-item"
                        style={{
                          cursor: "pointer",
                          color:
                            selectedSort && selectedSort.value === option.value
                              ? "#020617"
                              : "",
                          backgroundColor:
                            selectedSort && selectedSort.value === option.value
                              ? "#36f1cc"
                              : "",
                        }}
                        onClick={() => {
                          setSelectedSort(option);
                          taskSort(option.value);
                          closeDropdownSort();
                        }}
                      >
                        {option.label}
                      </div>
                    ))}
                  </li>
                </ul>
              </Col>
            </Row>
            {loadingspinner ? (
              <>
                <Loading />
              </>
            ) : (
              <section className="bg-white p-4 p-md-5 p-lg-6 rounded-4 shadow h-100">
                <Row className="gx-3 gy-2 gx-md-5 gx-lg-7 mb-8">
                  <Col xs="12" md="auto">
                    <div className="h1 mb-0">
                      {tabVal === "other"
                        ? "Other reminders"
                        : "To-do reminders"}
                    </div>
                  </Col>
                  <Col className="d-flex justify-content-end align-items-end">
                    <div
                      className={`btn btn-sm ${
                        activeButton === "other" ? "bg-cayn" : "btn-white"
                      }`}
                      onClick={() => handleButtonClick("other")}
                      style={{
                        borderRadius: "8px 0 0 8px",
                      }}
                    >
                      Other
                    </div>
                    <div
                      className={`btn btn-sm ${
                        activeButton === "todo" ? "bg-cayn" : "btn-white"
                      }`}
                      onClick={() => handleButtonClick("todo")}
                      style={{
                        borderRadius: "0 8px 8px 0",
                      }}
                    >
                      To-do
                    </div>
                  </Col>
                </Row>
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <Row className="gx-3 gy-0 gx-md-4 gx-lg-3 gx-xl-2 gx-xxl-3">
                      <Col xs="8" md="9" lg="8" xl="6">
                        <span className="small fw-semibold fs-17">
                          {tabVal === "other" ? "Supplier name" : "Who"}
                        </span>
                      </Col>
                      <Col
                        xs="8"
                        md="5"
                        lg="8"
                        xl="6"
                        className="d-lg-block d-none"
                      >
                        <span className="small fw-semibold fs-17">
                          {tabVal === "other" ? "Type" : "Extra Info"}
                        </span>
                      </Col>
                      <Col xs="8" md="9" lg="4" xl="6">
                        <span className="small fw-semibold fs-17">
                          {tabVal === "other" ? "Category" : "What"}
                        </span>
                      </Col>
                      {tabVal === "other" && (
                        <Col xs="8" xl="3" className="d-xl-block d-none">
                          <span className="small fw-semibold fs-17">
                            Amount
                          </span>
                        </Col>
                      )}
                      <Col xs="8" md="6" lg="4" xl="3">
                        <span className="small fw-semibold fs-17">
                          {tabVal === "other" ? "Due date" : "When"}
                        </span>
                      </Col>
                    </Row>
                    <hr className="mt-1 mb-3" />
                    {isEmpty ? (
                      <div className="text-center">
                        <p className="mt-4">
                          There is no reminders available on this query
                        </p>
                      </div>
                    ) : (
                      <>
                        <InfiniteScroll
                          dataLength={allData.length} // Set the length of the current data
                          next={fetchMoreData} // Trigger to load more data
                          hasMore={hasMore} // Check if more data is available
                          style={{
                            overflow: "visible",
                          }}
                          loader={
                            <div className="d-flex align-items-center gap-2 my-5 justify-content-center">
                              <span
                                className="spinner-border spinner-border-sm mr-2"
                                role="status"
                              ></span>
                              <span>Loading . . .</span>
                            </div>
                          }
                        >
                          {allData &&
                            allData.map((subData, index) => (
                              <React.Fragment key={subData.id}>
                                <Row className="align-items-center gx-3 gy-0 gx-md-4 gx-lg-3 gx-xl-2 gx-xxl-3">
                                  <Col xs="8" md="9" lg="8" xl="6">
                                    <div className="d-inline small  text-dark">
                                      {tabVal === "other"
                                        ? subData.supplier_name
                                        : subData.who}
                                    </div>
                                  </Col>
                                  <Col
                                    xs="8"
                                    md="5"
                                    lg="8"
                                    xl="6"
                                    className="d-lg-block d-none"
                                  >
                                    <div className="d-inline small  text-dark">
                                      {tabVal === "other"
                                        ? subData.type
                                        : subData.extra_info}
                                    </div>
                                  </Col>
                                  <Col xs="8" md="9" lg="4" xl="6">
                                    <div className="d-inline small  text-dark">
                                      {tabVal === "other"
                                        ? subData.category
                                        : subData.what}
                                    </div>
                                  </Col>
                                  {tabVal === "other" && (
                                    <Col
                                      xs="8"
                                      xl="3"
                                      className="d-xl-block d-none"
                                    >
                                      <div className="d-inline small  text-dark">
                                        £ {subData.amount}
                                      </div>
                                    </Col>
                                  )}
                                  <Col xs="8" md="6" lg="4" xl="3">
                                    <div className="d-inline small  text-dark">
                                      {tabVal === "other"
                                        ? subData.due_date
                                          ? moment(subData.due_date).format(
                                              "Do MMM, YYYY"
                                            )
                                          : "--"
                                        : subData.when
                                        ? moment(subData.when).format(
                                            "Do MMM, YYYY"
                                          )
                                        : "--"}
                                    </div>
                                  </Col>
                                </Row>
                                {index !== allData.length - 1 && (
                                  <hr className="my-3" />
                                )}
                              </React.Fragment>
                            ))}
                        </InfiniteScroll>
                      </>
                    )}
                  </>
                )}
              </section>
            )}
          </>
        </Container>
      </>
    </>
  );
};
export default Reminders;
