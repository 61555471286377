import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loading from "../../common/Loading";
import userService from "../../services/user.service";

const FamilyDashboardEvent = () => {
  let { uuid } = useParams();
  const UserDetails = JSON.parse(localStorage.getItem("UserDetails"));

  const [eventLogsURL, setEventLogsURL] = useState("");
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [familyNotificationList, setFamilyNotificationList] = useState([]);

  useEffect(() => {
    setEventLogsURL("/family_notification/" + uuid);
  }, []);

  useEffect(() => {
    userService
      .getUserSingleFamilyDetails(uuid, UserDetails.id)
      .then((response) => {
        let familyId = response.FamilyData.Family.uuid;
        let params = {
          fuuid: familyId,
          userId: "all",
          limit: 50,
          offset: 0,
          filterVal: "",
          sortVal: "DESC",
        };

        //getting first 5 family notifications
        userService.getUserFamilyNotification3(params).then(
          (res) => {
            // Sort the first 5 notifications based on the createdAt date
            const sortedNotifications = res.data
              .slice(0, 5)
              .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

            setFamilyNotificationList(sortedNotifications);

            setTimeout(() => {
              setLoadingspinner(false);
            }, 250);
          },
          () => {}
        );
      });
  }, []);

  return (
    <div>
      <div className="d-flex mb-7">
        <h2 className="me-auto mb-0">Events</h2>
        <Link
          className="btn btn-white btn-sm btn-circle flex-shrink-0"
          to={eventLogsURL}
        >
          <i className="fa-solid fa-sm fa-angle-right text-dark"></i>
        </Link>
      </div>
      {loadingspinner ? (
        <>
          <div className="loadind-page">
            <Loading />
          </div>
        </>
      ) : (
        <>
          <Row className="gx-3 gy-0 gx-md-4 gx-lg-3 gx-xl-2 gx-xxl-3">
            <Col xs="10" md="8" lg="6" xl="6" xxl="6">
              <span className="small fw-semibold fs-17">Event</span>
            </Col>
            <Col xs="14" md="12" lg="14" xl="14" xxl="12">
              <span className="small fw-semibold fs-17">Description</span>
            </Col>
            <Col
              xs="10"
              md="10"
              lg="4"
              xl="4"
              xxl="6"
              className="d-none d-lg-block"
            >
              <span className="small fw-semibold fs-17">Module</span>
            </Col>
          </Row>
          {familyNotificationList && familyNotificationList.length !== 0 ? (
            <>
              {familyNotificationList.map((subData, index) => (
                <>
                  <hr className={index === 0 ? "mb-3" : "my-3"} />
                  <Row
                    className="align-items-center gx-3 gy-0 gx-md-4 gx-xl-2"
                    style={{ cursor: "pointer" }}
                  >
                    <Col xs="10" md="8" lg="6" xl="6" xxl="6">
                      <div className="small  text-truncate">
                        {subData.title}
                      </div>
                    </Col>

                    <Col xs="14" md="12" lg="14" xl="14" xxl="12">
                      <div className="small text-truncate">
                        {subData.message}
                      </div>
                    </Col>
                    <Col
                      xs="10"
                      md="10"
                      lg="4"
                      xl="4"
                      xxl="6"
                      className="d-none d-lg-block"
                    >
                      <div className="d-inline small fw-medium text-dark">
                        {subData.module}
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <hr className="my-3" />
              <p className="small mb-0">
                You have no event logs to catch up in this family.
              </p>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FamilyDashboardEvent;
