import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { ChoosePlanCard } from "../../stories/Subscription.stories";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Modal from "react-bootstrap/Modal";
import UserService from "../../services/user.service";
import { Outline } from "../../stories/Button.stories";
import SavedCardCheckoutForm from "./SavedCardCheckoutForm";
import Loading from "../../common/Loading";

const Subscription = () => {
  const [loading, setLoading] = useState(true);
  const [loadingModal, setLoadingModal] = useState(false);

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [cusDetails, setCusDetails] = useState();
  const [priceDetails, setPriceDetails] = useState();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [paymentList, setPaymentList] = useState([]);
  const [planId, setPlanId] = useState();
  const [planFullData, setPlanFullData] = useState();

  const myRef = useRef(null);
  const initialValues = { familyName: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [accountPlanList, setAccountPlanList] = useState([]);
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    UserService.getUserAccountPlan(UserDetails.uuid).then(
      (response) => {
        var newData = [];
        for (var i = 0; i < response.usersFamilyData.length; i++) {
          if (response.usersFamilyData[i].isAo === true) {
            newData.push(response.usersFamilyData[i]);
          }
        }
        setAccountPlanList(newData);
      },
      () => {}
    );
    // api call to get all plan
    UserService.planList().then(
      (response) => {
        setSubscriptionPlans(response);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      },
      () => {
        setLoading(false);
      }
    );
  }, []);

  const [loadingIndex, setLoadingIndex] = useState(null);
  const handleShow = (planData, index) => {
    setLoadingIndex(index); // Set the loading index
    setLoadingModal(true);
    if (UserDetails.lastname === "null") {
      let result = "";
      const charactersLength = characters.length;

      for (let i = 0; i < charactersLength; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      let stringData = result.slice(0, 8);

      setFormValues({ ...formValues, familyName: stringData });
      modalShow(planData);
    } else {
      setFormValues({ ...formValues, familyName: UserDetails.lastname });
      modalShow(planData);
    }
    setPlanFullData(planData);
    setPlanId(planData.id);
    setPriceDetails(planData.stripePriceId);
  };

  const handlePaymentShow = (planData, paymentList) => {
    // setShowPayment(true);
    UserService.getCustomerListStripe().then((response) => {
      var result = in_array(response, UserDetails.email);
      if (result) {
        setCusDetails(result);
        navigateToBilling(result, planData, paymentList);
      } else {
        // create customer on stripe
        let userData = {
          name: UserDetails.firstname + " " + UserDetails.lastname,
          email: UserDetails.email,
        };

        UserService.createCustomerStripe(userData).then(
          (response) => {
            setCusDetails(response.id);
            navigateToBilling(response.id, planData, paymentList);
          },
          () => {}
        );
        // end
      }
    });
  };
  function in_array(array, email) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].email === email) {
        return array[i].id;
      }
      //return (array[i].email == email)
    }
    return false;
  }

  const navigateToBilling = (customerId, planData, paymentList) => {
    history.push(
      `/newcard_billing_details/${
        id === "undefined" || id === undefined || id === null || id === "null"
          ? "new-user"
          : id
      }`,
      {
        customerId,
        planData,
        paymentList,
        accountPlanList,
      }
    );
  };
  const [showChoosePayment, setShowChoosePayment] = useState(false);
  const handleChoosePaymentClose = () => setShowChoosePayment(false);
  const handleChoosePaymentShow = () => {
    //setShowChoosePayment(true);
    // call to create customer on stripe
    UserService.getCustomerListStripe().then(
      (response) => {
        var result = in_array(response, UserDetails.email);

        if (result) {
          setCusDetails(result);

          // get list of customer payment
          let cusdata = {
            type: "card",
          };
          UserService.getCustomerPaymentMethodsList(cusdata, result).then(
            (res) => {
              setPaymentList(res.data);
              setShowChoosePayment(true);
              setLoadingIndex(null); // Set the loading index
            }
          );
        } else {
          // create customer on stripe
          let userData = {
            name: UserDetails.firstname + " " + UserDetails.lastname,
            email: UserDetails.email,
          };

          UserService.createCustomerStripe(userData).then(
            (response) => {
              //setShowPayment(true);
              setCusDetails(response.id);
              // get list of customer payment
              let cusdata = {
                type: "card",
              };
              UserService.getCustomerPaymentMethodsList(
                cusdata,
                response.id
              ).then((res) => {
                setPaymentList(res.data);
                setShowChoosePayment(true);
                setLoadingIndex(null); // Set the loading index
              });
            },
            () => {}
          );
          // end
        }
      },
      () => {}
    );
  };

  const modalShow = (planData) => {
    UserService.getCustomerListStripe().then(
      (response) => {
        var result = in_array(response, UserDetails.email);

        if (result) {
          setCusDetails(result);

          // get list of customer payment
          let cusdata = {
            type: "card",
          };
          UserService.getCustomerPaymentMethodsList(cusdata, result).then(
            (res) => {
              setPaymentList(res.data);
              //

              if (res.data.length !== 0) {
                handleChoosePaymentShow(); // saved card is there
              } else {
                handlePaymentShow(planData, res.data); // new card as no saved card
              }
            }
          );
        } else {
          // create customer on stripe
          let userData = {
            name: UserDetails.firstname + " " + UserDetails.lastname,
            email: UserDetails.email,
          };

          UserService.createCustomerStripe(userData).then(
            (response) => {
              //setShowPayment(true);
              setCusDetails(response.id);
              // get list of customer payment
              let cusdata = {
                type: "card",
              };
              UserService.getCustomerPaymentMethodsList(
                cusdata,
                response.id
              ).then((res) => {
                setPaymentList(res.data);

                if (res.data.length !== 0) {
                  handleChoosePaymentShow(); // saved card is there
                } else {
                  handlePaymentShow(planData, res.data); // saved card is not there. New card will show
                }
              });
            },
            () => {}
          );
          // end
        }

        setTimeout(() => {
          setLoadingModal(false);
        }, 250);
      },
      () => {}
    );
  };

  return (
    <div className="page-content subscription-pg tab-btn">
      <Container
        fluid
        ref={myRef}
        style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
        className="d-flex flex-column"
      >
        {/* {details} */}
        {loading ? (
          <Loading />
        ) : (
          <>
            <Row className="justify-content-center gy-0 mb-7">
              <Col lg="24" xl="22" xxl="20">
                <h3 className="">Elderly Life Account Plans</h3>
                <p className="sub-title">
                  Choose from the available account plans to create your Group.
                  You can always change the plan later.
                </p>

                <section className="bg-white p-4 p-md-5 p-lg-6 shadow rounded-4 tab-view">
                  <Tabs
                    defaultActiveKey="annual"
                    id="uncontrolled-tab-example"
                    className="tab-container"
                  >
                    <Tab
                      eventKey="annual"
                      title="Annual"
                      className="tab-annual"
                    >
                      <Row className="subscriptionPlan-card">
                        <div className="bold-colour fs-18 my-3 fw-medium">
                          All the below plans will have access to all the
                          modules of the Elderly Life Platform
                        </div>
                        {subscriptionPlans.map((plan, index) =>
                          plan.renewalType === "yearly" ? (
                            <Col lg="8" key={index}>
                              <ChoosePlanCard
                                planName={plan.name}
                                planPrice={plan.price}
                                priceSymbol={plan.Country.symbol}
                                planDuration={plan.renewalType}
                                benefits={plan.availableSeats}
                                planImage={plan.image}
                                buttonClick={() => handleShow(plan, index)}
                                planType={accountPlanList}
                                buttonShow={true}
                                loadingModal={loadingModal}
                                index={index}
                                fullData={plan}
                                isLoading={loadingIndex === index} // Check if this card is loading
                              />
                            </Col>
                          ) : (
                            ""
                          )
                        )}
                      </Row>
                    </Tab>
                    <Tab
                      eventKey="monthly"
                      title="Monthly"
                      className="tab-monthly"
                    >
                      <Row className="subscriptionPlan-card">
                        <div className="bold-colour fs-18 my-3 fw-medium">
                          All the below plans will have access to all the
                          modules of the Elderly Life Platform
                        </div>

                        {subscriptionPlans.map((plan, index) =>
                          plan.renewalType === "monthly" ? (
                            <Col lg="8" key={index}>
                              <ChoosePlanCard
                                planName={plan.name}
                                planPrice={plan.price}
                                priceSymbol={plan.Country.symbol}
                                planDuration={plan.renewalType}
                                benefits={plan.availableSeats}
                                planImage={plan.image}
                                buttonClick={() => handleShow(plan, index)}
                                planType={accountPlanList}
                                buttonShow={true}
                                loadingModal={loadingModal}
                                index={index}
                                fullData={plan}
                                isLoading={loadingIndex === index} // Check if this card is loading
                              />
                            </Col>
                          ) : (
                            ""
                          )
                        )}
                      </Row>
                    </Tab>
                  </Tabs>
                </section>
              </Col>
            </Row>

            {/* If I have already saved card  */}
            <Modal
              show={showChoosePayment}
              onHide={handleChoosePaymentClose}
              className="choose_paymentMethod_modal"
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  <h3>Choose a payment method</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: "70vh", overflowY: "auto" }}>
                <div className="mt-2">
                  <Outline
                    children="+ Add New card"
                    variant="outline"
                    size="mediumbtn"
                    onClick={() => {
                      handleChoosePaymentClose();
                      handlePaymentShow(planFullData, paymentList);
                    }}
                    className="btn btn-primary"
                  />
                </div>
                <hr className="my-3"></hr>
                {paymentList.length !== 0 ? (
                  <SavedCardCheckoutForm
                    customerId={cusDetails}
                    priceId={priceDetails}
                    planId={planId}
                    paymentList={paymentList}
                    familyName={formValues.familyName}
                    planType={accountPlanList}
                  />
                ) : (
                  <>
                    <p className="mt-4">Saved cards ( 0 )</p>
                    <div className="text-center saved-card-empty">
                      No saved cards
                    </div>
                  </>
                )}
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
};
export default Subscription;
