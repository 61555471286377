import React, { useState, useEffect } from "react";
import { useStripe, CardElement, useElements } from "@stripe/react-stripe-js";
import { Primary } from "../../stories/Button.stories";
import UserService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { Small } from "../../stories/Input.stories";
import Select from "react-select";

// select error style
const customStylesError = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid red",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

// select style
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    backgroundColor: "#ecf6ff 0% 0% no-repeat padding-box",
    fontSize: "16px",
    borderRadius: " 0.75rem",
    borderStyle: "none",
    cursor: "pointer",
    padding: "2px 0",
    border: "0.0625rem solid #aabccf",
    marginBottom: "4px",
    color: "#475569",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "0.75rem",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "4px 0",
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "0.5rem",
    fontSize: "16px",
    width: "100%",
    cursor: "pointer",
    color: "#475569",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#475569",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9ca3af",
    fontSize: "16px",
    fontStyle: "italic",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "#007bff" : "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "#dfe8f1",
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#9ca3af",
    "&:hover": {
      color: "#007bff",
    },
  }),
};

const CheckoutForm = (props) => {
  const cardStyle = {
    style: {
      base: {
        color: "#495057",
        fontSize: "16px",
        fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
        "::placeholder": {
          color: "#6c757d",
          opacity: 1,
        },
        padding: "10px",
        backgroundColor: "#fff",
        border: "1px solid #ced4da",
        borderRadius: "4px",
        height: "41.6px",
      },
      invalid: {
        color: "#dc3545",
        iconColor: "#dc3545",
      },
    },
  };
  var splitUrl = window.location.href.split("/");
  let history = useHistory();
  var isCardComplete = false;
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  var uuidDetails = localStorage.getItem("uuid");

  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [exist, setexist] = useState(false);
  const [DetailsSave, setDetailsSave] = useState({});
  const [cardNo, setCardNo] = useState([]);
  const [err, seterr] = useState(false);
  const [planDet, setplanDet] = useState();
  const [trialCheck, settrialCheck] = useState([]);
  const [trialCheck1, settrialCheck1] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState("");
  const countries = [
    { value: "us", label: "United States" },
    { value: "in", label: "India" },
    { value: "uk", label: "United Kingdom" },
    { value: "ca", label: "Canada" },
    { value: "au", label: "Australia" },
  ];

  useEffect(() => {
    var newData = [];
    for (var i = 0; i < props.paymentList.length; i++) {
      newData.push(props.paymentList[i].card.last4);
    }

    setCardNo(newData);
  }, [props.paymentList]);

  useEffect(() => {
    if (splitUrl[3] !== "subscription") {
      // get family details
      UserService.getUserSingleFamilyDetails(uuidDetails, UserDetails.id).then(
        (response) => {
          if (response && response.FamilyData) {
            UserService.getSubsciptionStripe(
              response.FamilyData.Family.Family_Subscription
                .stripeSubscriptionId
            ).then((res) => {
              settrialCheck(res);
              settrialCheck1(res.items.data[0]);
            });
          }
        },
        () => {}
      );
    }
  }, []);

  if (splitUrl[3] !== "downgradeSubscription") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      var newArr = [];
      for (var i = 0; i < props.planType.length; i++) {
        if (props.planType[i].isAo === true) {
          newArr.push(props.planType[i]);
        }
      }
      setplanDet(newArr);
    }, [props.planType]);
  }

  const initialValues = {
    name:
      UserDetails.firstname === "null"
        ? ""
        : UserDetails.firstname + " " + UserDetails.lastname,
    postcode: "",
    country: "GB",
    isCardValid: false,
    last4digit: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleCardChange = (e) => {
    if (e.complete) {
      isCardComplete = true;
      setFormValues({ ...formValues, isCardValid: isCardComplete });
    } else {
      setLoading(false);
      setFormValues({ ...formValues, isCardValid: false });
    }
  };

  const handleChange = (e, action) => {
    if (action.name === "postcode") {
      setSelectedCountry(e);
      setFormValues({ ...formValues, postcode: e.value });
    } else {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
    setLoading(true);
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.msg = "Please enter card details";
      errors.name = "Yes";
    }
    if (!values.postcode) {
      errors.msg = "Please enter valid postcode";
      errors.postcode = "Yes";
    }
    if (!values.isCardValid) {
      errors.msg = "Please enter card information";
      errors.isCardValid = "Yes";
    }
    setLoading(false);
    return errors;
  };

  // 7 days from today
  var date = new Date();
  date.setDate(date.getDate() + 7);
  const unixTimestamp = Math.floor(date.getTime() / 1000);

  useEffect(() => {
    // handle confirm submit
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (!stripe || !elements) {
        console.error(
          "Stripe.js has not loaded yet. Make sure it is initialized properly."
        );
        setLoading(false);
        return;
      }

      const cardElement = elements.getElement(CardElement);

      if (!cardElement) {
        console.error("CardElement is not mounted or could not be retrieved.");
        setLoading(false);
        return;
      }
      stripe
        .createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            name: formValues.name,
            address: {
              country: formValues.country,
              postal_code: formValues.postcode,
            },
          },
        })
        .then(function (result) {
          let attachData = {
            customer: props.customerId,
          };
          if (result.paymentMethod === undefined) {
            seterr(true);
            setLoading(false);
          } else {
            seterr(false);
            if (cardNo.includes(result.paymentMethod.card.last4)) {
              setexist(true);
              setLoading(false);
            } else {
              setexist(false);
              UserService.attachPaymentMethod(
                attachData,
                result.paymentMethod.id
              ).then(
                () => {
                  let updateData = {
                    "invoice_settings[default_payment_method]":
                      result.paymentMethod.id,
                  };
                  UserService.updateCustomerStripe(
                    updateData,
                    props.customerId
                  ).then(
                    () => {
                      if (splitUrl[3] === "downgradeSubscription") {
                        if (props.planId === 1 || props.planId === 4) {
                          if (
                            props.planDetails.availableS === 2 ||
                            props.planDetails.availableS === 5
                          ) {
                            history.push({
                              pathname: `/downgrade_Subscription/${splitUrl[4]}`,
                              state: {
                                planId: props,
                                stripeCusID: props.SubscriptionId,
                              },
                            });
                          } else {
                            UserService.upgradeSubscription(
                              splitUrl[4],
                              props.planId,
                              props.SubscriptionId
                            ).then(() => {
                              let subsciptionData = {
                                trial_end: "now",
                                proration_behavior: "always_invoice",
                                billing_cycle_anchor: "now",
                                "items[0][plan]": props.priceId,
                                "items[0][id]": trialCheck1.id,
                              };
                              UserService.updateSubsciptionStripe(
                                subsciptionData,
                                trialCheck.id
                              ).then((response) => {
                                if (
                                  response.status !== 400 &&
                                  response.status !== 404
                                ) {
                                  history.push({
                                    pathname: `/dashboard/${splitUrl[4]}`,
                                  });
                                }
                              });
                            });
                          }
                        } else if (props.planId === 2 || props.planId === 5) {
                          if (props.planDetails.availableS === 5) {
                            history.push({
                              pathname: `/downgrade_Subscription/${splitUrl[4]}`,
                              state: {
                                planId: props,
                                stripeCusID: props.SubscriptionId,
                              },
                            });
                          } else {
                            UserService.upgradeSubscription(
                              splitUrl[4],
                              props.planId,
                              props.SubscriptionId
                            ).then(() => {
                              let subsciptionData = {
                                trial_end: "now",
                                proration_behavior: "always_invoice",
                                billing_cycle_anchor: "now",
                                "items[0][plan]": props.priceId,
                                "items[0][id]": trialCheck1.id,
                              };
                              UserService.updateSubsciptionStripe(
                                subsciptionData,
                                trialCheck.id
                              ).then((response) => {
                                if (
                                  response.status !== 400 &&
                                  response.status !== 404
                                ) {
                                  history.push({
                                    pathname: `/dashboard/${splitUrl[4]}`,
                                  });
                                }
                              });
                            });
                          }
                        } else {
                          UserService.upgradeSubscription(
                            splitUrl[4],
                            props.planId,
                            props.SubscriptionId
                          ).then(() => {
                            let subsciptionData = {
                              trial_end: "now",
                              proration_behavior: "always_invoice",
                              billing_cycle_anchor: "now",
                              "items[0][plan]": props.priceId,
                              "items[0][id]": trialCheck1.id,
                            };
                            UserService.updateSubsciptionStripe(
                              subsciptionData,
                              trialCheck.id
                            ).then((response) => {
                              if (
                                response.status !== 400 &&
                                response.status !== 404
                              ) {
                                history.push({
                                  pathname: `/dashboard/${splitUrl[4]}`,
                                });
                              }
                            });
                          });
                        }
                      } else {
                        // create subscription
                        let subsciptionData = {
                          customer: props.customerId,
                          "items[0][price]": props.priceId,
                        };
                        if (planDet.length === 0) {
                          subsciptionData.trial_end = unixTimestamp;
                        }
                        UserService.createSubsciptionStripe(
                          subsciptionData
                        ).then(
                          (response) => {
                            if (response.status === "active") {
                              let createFamilyData = {
                                stripePaymentId: response.latest_invoice,
                                stripeSubscriptionId: response.id,
                                stripeCustomerId: response.customer,
                                familyName: props.familyName, //UserDetails.lastname,
                                planId: props.planId, // response.plan.product,
                                aoUser: UserDetails.uuid,
                              };

                              localStorage.setItem(
                                "UserA",
                                JSON.stringify(createFamilyData.planId)
                              );
                              UserService.createFamily(createFamilyData).then(
                                (res) => {
                                  setDetailsSave(res);
                                  localStorage.setItem(
                                    "UserDA",
                                    res.FamilyData.id
                                  );
                                  window.history.pushState(
                                    null,
                                    "",
                                    history.push({
                                      pathname: "/confirmation",
                                      familyUUID: res.FamilyData.uuid,
                                      fromPaymentSuccsess: true,
                                    })
                                  );
                                  window.onpopstate = function () {
                                    window.history.pushState(
                                      null,
                                      "",
                                      history.push({
                                        pathname: "/confirmation",
                                        familyUUID: res.FamilyData.uuid,
                                        fromPaymentSuccsess: true,
                                      })
                                    );
                                  };

                                  localStorage.setItem(
                                    "UserB",
                                    res.FamilyData.uuid
                                  );
                                }
                              );
                            } else if (response.status === "trialing") {
                              let createFamilyData = {
                                stripePaymentId: response.latest_invoice,
                                stripeSubscriptionId: response.id,
                                stripeCustomerId: response.customer,
                                familyName: props.familyName, //UserDetails.lastname,
                                planId: props.planId, // response.plan.product,
                                aoUser: UserDetails.uuid,
                              };

                              localStorage.setItem(
                                "UserA",
                                JSON.stringify(createFamilyData.planId)
                              );
                              UserService.createFamily(createFamilyData).then(
                                (res) => {
                                  setDetailsSave(res);
                                  localStorage.setItem(
                                    "UserDA",
                                    res.FamilyData.id
                                  );
                                  window.history.pushState(
                                    null,
                                    "",
                                    history.push({
                                      pathname: "/confirmation",
                                      familyUUID: res.FamilyData.uuid,
                                      fromPaymentSuccsess: true,
                                    })
                                  );
                                  window.onpopstate = function () {
                                    window.history.pushState(
                                      null,
                                      "",
                                      history.push({
                                        pathname: "/confirmation",
                                        familyUUID: res.FamilyData.uuid,
                                        fromPaymentSuccsess: true,
                                      })
                                    );
                                  };

                                  localStorage.setItem(
                                    "UserB",
                                    res.FamilyData.uuid
                                  );
                                }
                              );
                            }
                            //setLoading(false);
                          },
                          () => {
                            setLoading(false);
                          }
                        );
                      }
                    },
                    () => {
                      setLoading(false);
                    }
                  );
                },
                () => {
                  setLoading(false);
                }
              );
            }
          }
        })
        .catch((error) => {
          console.error("Stripe error:", error);
          setLoading(false);
          // Handle the error
        });
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [formErrors]);

  return (
    <>
      <form className="payment-form text-start w-100" onSubmit={handleSubmit}>
        <label
          className={
            formErrors.name ? "invalid-text form-label mb-1" : "form-label mb-1"
          }
        >
          Name on the card
        </label>
        <Small
          type="test"
          errorClass={formErrors.name ? "invalid-input" : ""}
          placeholder="John Mike"
          size="small"
          name="name"
          value={formValues.name}
          onChange={handleChange}
          className={`form-control ${
            formErrors.name ? `invalid-border` : "mb-3"
          }`}
        />
        {formErrors.name && (
          <p className="invalid-text fs-12 mt-1">
            Please enter valid card details
          </p>
        )}

        <label
          className={
            exist || formErrors.isCardValid
              ? "invalid-text form-label mb-1"
              : "form-label mb-1"
          }
        >
          Card Number
        </label>
        <CardElement
          options={cardStyle}
          onChange={handleCardChange}
          className={`form-control card-element ${
            exist || formErrors.isCardValid ? `invalid-border` : `mb-3`
          }`}
        />
        {exist && (
          <p className="invalid-text fs-12 mt-2">
            Card already exist. Please use different card
          </p>
        )}
        {formErrors.isCardValid && (
          <p className="invalid-text fs-12 mt-1">
            Please enter valid card information
          </p>
        )}
        <label
          className={
            formErrors.postcode
              ? "invalid-text form-label mb-1"
              : "form-label mb-1"
          }
        >
          Country
        </label>
        {/* <Small
          type="test"
          errorClass={formErrors.postcode ? "invalid-input" : ""}
          placeholder="SW1A 1AA"
          size="small"
          name="postcode"
          value={formValues.postcode}
          onChange={handleChange}
          className={`form-control ${
            formErrors.postcode ? `invalid-border` : "mb-3"
          }`}
        /> */}
        <Select
          styles={formErrors.postcode ? customStylesError : customStyles}
          options={countries}
          defaultValue={{
            label: "Select a category",
            value: "",
          }}
          value={selectedCountry}
          onChange={handleChange}
          name="postcode"
        />
        {formErrors.postcode && (
          <p className="invalid-text fs-12 mt-1 mb-3">
            Please select country from the dropdown
          </p>
        )}
        <div className="bold-colour mb-3 fs-12">
          By providing your card information, you allow Elderlylife to charge
          your card for future payments in accordance with their terms
        </div>
        <div className="text-center">
          <Primary
            children="Pay"
            variant="primary"
            size="mediumbtn"
            type="submit"
            className="btn btn-primary w-100"
            isLoading={loading ? "true" : "false"}
          />
          {message && <div id="payment-message">{message}</div>}
        </div>
        <div className="my-2 fs-12 d-flex align-items-center justify-content-center gap-1">
          <span style={{ color: "rgb(60, 66, 87)" }}>Powered by</span>{" "}
          <a
            href="https://stripe.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "rgb(84, 105, 212)" }}
          >
            Stripe
          </a>
        </div>
      </form>
    </>
  );
};

export default CheckoutForm;
