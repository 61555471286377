import React, { useState, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import moment from "moment";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Loading from "../../common/Loading";
import ToastBlockContainer from "../../common/ToastBlockContainer";

const GiftCards = (props) => {
  const myRef = useRef(null);
  let history = useHistory();
  let { uuid, userUUID } = useParams();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const executeScroll = () => myRef.current.scrollIntoView();
  const filterOption = [
    { value: "all", label: "All" },
    { value: "expired", label: "Expired" },
    { value: "active", label: "Active" },
  ];
  const sortOption = [
    { value: "createdAt", label: "Created" },
    { value: "updatedAt", label: "Updated" },
    { value: "expiryDate", label: "Expiry Date" },
    { value: "totalAmount", label: "Total Amount" },
  ];

  const [familyDetails, setFamilyDetails] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [loadingspinner, setLoadingspinner] = useState(true);
  const [title, setTitle] = useState("Vouchers & Gift cards");
  const [subTitle, setSubTitle] = useState(
    "You can track all your vouchers & gift Cards on Elderly Life platform here."
  );
  const [backText, setBackText] = useState("Back to Group Dashboard");
  const [backUrl, setBackUrl] = useState("");
  const [addGiftcardUrl, setAddGiftcardUrl] = useState("");
  const [parentUser, setParentUser] = useState();
  const [userGiftcardsList, setUserGiftcardsList] = useState([]);
  const [searchStr, setSearchStr] = useState("");
  const [showSearchText, setShowSearchText] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [giftId, setGiftId] = useState(false);
  const [cardType1, setCardType1] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState("createdAt");
  const [familyUserId, setFamilyUserId] = useState();
  const [GiftCount, setGiftCount] = useState("0");
  const [GiftCost, setGiftCost] = useState("£ 0");
  const [VoucherCount, setVoucherCount] = useState("0");
  const [VoucherCost, setVoucherCost] = useState("£ 0");
  const [TotalCount, setTotalCount] = useState("0");
  const [TotalCost, setTotalCost] = useState("£ 0");
  const [searchValue, setsearchValue] = useState("");
  const [filterValue, setfilterValue] = useState("all");
  const [sortValue, setsortValue] = useState("createdAt");
  const [orderValue, setorderValue] = useState("DESC");
  const [filterValueClick, setFilterValueClick] = useState("all");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState({
    value: "all",
    label: "All",
  }); // Selected option for first dropdown

  const [selectedSort, setSelectedSort] = useState({
    value: "createdAt",
    label: "Created",
  }); // Selected option for sort dropdown
  const [isSortDropdown, setIsSortDropdown] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Total items, needed to calculate total pages
  const [mounted, setMounted] = useState(false); // Tracks if the component has fully loaded

  const viewDetailsRoute = (giftId) => {
    if (userUUID) {
      history.push(
        `/view_user_vouchers_&_gift_cards/${uuid}/${giftId}/${userUUID}`
      );
    } else {
      history.push(`/view__vouchers_&_gift_cards/${uuid}/${giftId}`);
    }
  };
  const editGiftcardRoute = (giftId) => {
    if (userUUID) {
      history.push(
        `/edit_user_vouchers_&_gift_cards/${uuid}/${giftId}/${userUUID}`
      );
    } else {
      history.push(`/edit_vouchers_&_gift_cards/${uuid}/${giftId}`);
    }
  };
  useEffect(() => {
    // get family details
    if (userUUID) {
      UserService.userProfile(userUUID).then((response) => {
        getFamilyDetails(response.userData.id);
        let pUser = response.userData;
        setParentUser(response.userData);
        setAddGiftcardUrl(
          "/add_user_vouchers_&_gift_cards/" + uuid + "/" + userUUID
        );
        setBackUrl("/supporting_user_dashboard/" + uuid + "/" + userUUID);

        const abc =
          pUser.firstName === "null"
            ? pUser.email
            : pUser.firstName + " " + pUser.lastName === "null"
            ? ""
            : pUser.lastName;
        setTitle(`Vouchers & GiftCards`);
        setSubTitle(
          `You can track all Vouchers & GiftCards of ${abc}
            here`
        );
        setBackText(
          "Back to " + pUser.firstName + " " + pUser.lastName + "’s EL overview"
        );
      });
    } else {
      setAddGiftcardUrl("/add_vouchers_&_gift_cards/" + uuid);
      getFamilyDetails(UserDetails.id);
      setBackText("Back to Group Dashboard");
      setBackUrl("/dashboard/" + uuid);
    }
  }, []);

  // Get Family details
  const getFamilyDetails = (userId) => {
    UserService.getUserSingleFamilyDetails(uuid, userId).then(
      (response) => {
        setFamilyDetails(response);
        let famUserId = response.FamilyData.id;

        // get giftcards list
        let Max = {
          userId: famUserId,
          familyId: response.FamilyData.Family.id,
          searchStr: searchValue,
          sort: sortValue,
          order: orderValue,
          limit: 9007199254740991,
          offset: 0,
          filBy: filterValue,
        };
        UserService.getUserGiftcardListNew(Max).then(
          (response) => {
            setTotalItems(response.data); // Store the total number of items for pagination
          },
          (error) => {}
        );

        // get giftcards list
        let params = {
          userId: famUserId,
          familyId: response.FamilyData.Family.id,
          searchStr: searchValue,
          sort: sortValue,
          order: orderValue,
          limit: 50,
          offset: 0,
          filBy: filterValue,
        };
        UserService.getUserGiftcardListNew(params).then(
          (response) => {
            setUserGiftcardsList(response.data);
            response.data.length === 0 ? setIsEmpty(true) : setIsEmpty(false);
            setTimeout(() => {
              setLoadingspinner(false);
              setMounted(true); // Set mounted to true after the initial load
            }, 250);
          },
          (error) => {}
        );

        setFamilyUserId(famUserId);
        getGiftcardListCount(params);
      },
      (error) => {}
    );
  };

  // Get Giftcard List
  const getGiftcardList = (params) => {
    setLoadingspinner(true);
    let Max = { ...params }; // Clone params to avoid modifying it directly
    Max.limit = 9007199254740991; // Update the limit property

    UserService.getUserGiftcardListNew(Max).then(
      (response) => {
        setTotalItems(response.data);
      },
      (error) => {}
    );

    UserService.getUserGiftcardListNew(params).then(
      (response) => {
        setUserGiftcardsList(response.data);
        setLoadingspinner(false);
      },
      (error) => {}
    );
  };

  // Filter gift cards
  const giftcardFilter = (val) => {
    setfilterValue(val);
    setFilterValueClick(val);
    let params = {
      familyId: familyDetails.FamilyData.Family.id,
      userId: familyUserId,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: 50,
      offset: 0,
      filBy: val,
    };
    getGiftcardList(params);
  };

  // Sort gift cards
  const giftcardSort = (val) => {
    setLoading(true);
    setOffset(0);
    setLimit(50);

    setsortValue(val);
    setSortBy(val);
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: val,
      order: orderValue,
      limit: 50,
      offset: 0,
      filBy: filterValue,
    };
    getGiftcardList(params);
  };

  // Clear search
  const clearSearch = (e) => {
    e.preventDefault();
    setShowSearchText(false);
    setLoading(true);
    setSearchStr("");
    setsearchValue("");
    let params = {
      userId: familyUserId,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: "",
      sort: sortValue,
      order: orderValue,
      limit: 50,
      offset: 0,
      filBy: filterValue,
    };
    getGiftcardList(params);
  };

  //search box close
  useEffect(() => {
    if (mounted) {
      if (searchStr.length === 0) {
        // clearSearch();
        setShowSearchText(false);
        setLoading(true);
        setSearchStr("");
        setsearchValue("");
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: "",
          sort: sortValue,
          order: orderValue,
          limit: 50,
          offset: 0,
          filBy: filterValue,
        };
        getGiftcardList(params);
      }
    }
  }, [searchStr]);

  // Search gift cards
  const giftcardSearch = (e) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      setShowSearchText(true);
      setLoading(true);
      let srchStr = e.target.value;
      setSearchStr(e.target.value);
      setsearchValue(e.target.value);
      if (srchStr === "") {
        clearSearch(e);
      } else {
        setOffset(0);
        setLimit(50);
        let params = {
          userId: familyUserId,
          familyId: familyDetails.FamilyData.Family.id,
          searchStr: srchStr,
          sort: sortValue,
          order: orderValue,
          limit: 50,
          offset: 0,
          filBy: filterValue,
        };
        getGiftcardList(params);
      }
    }
  };

  // Close delete confirmation
  const handleShowDeleteClose = () => {
    setShowDelete(false);
  };

  // Delete giftcard
  const handleDeleteGiftCard = (giftId, cardType) => {
    setShowDelete(false);
    setCardType1(cardType);
    UserService.deleteGiftcard(giftId, cardType1).then(
      (response) => {
        let giftList = userGiftcardsList;
        const selectedSubIndex = giftList.findIndex(
          (x) => Number(x.id) === Number(giftId)
        );
        giftList.splice(selectedSubIndex, 1);
        setUserGiftcardsList(giftList);
        setLoadingspinner(false);
        setShowToast(true);
        setSuccessToast(true);
        executeScroll();
        window.scrollTo(0, 0);

        // setToastMessage("Giftcard deleted successfully");
        setToastMessage(
          cardType1 === "Gift Card"
            ? "Gift Card deleted successfully"
            : "Voucher deleted successfully"
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      },
      (error) => {}
    );
  };

  //giftcard count list
  const getGiftcardListCount = (params) => {
    setLoading(true);
    UserService.getUserGiftcardCount(params).then(
      (response) => {
        setGiftCount(response.GiftcardsCount);
        setGiftCost(parseFloat(response.GiftcardsTotalPrice).toFixed(2));
        setVoucherCount(response.VouchersCount);
        setVoucherCost(parseFloat(response.VouchersTotalPrice).toFixed(2));
        setTotalCount(response.GiftcardsCount + response.VouchersCount);
        setTotalCost(
          parseFloat(
            parseFloat(response.VouchersTotalPrice) +
              parseFloat(response.GiftcardsTotalPrice)
          ).toFixed(2)
        );
      },
      (error) => {}
    );
  };

  // Search modal
  const handleCloseSearch = () => setShowSearch(false);
  const handleShowSearch = () => setShowSearch(true);

  const [arrowRemaining, setArrowRemaining] = useState(true);
  const [arrowEndDate, setArrowEndDate] = useState(true);

  // remaining onclick function
  const handleRemaining = () => {
    setArrowRemaining(!arrowRemaining);

    if (arrowRemaining) {
      // Sort based on amount, from highest to lowest
      const sortedList = userGiftcardsList.sort(
        (a, b) => b.totalAmount - a.totalAmount
      );
      setUserGiftcardsList(sortedList);
    } else {
      // Sort based on amount, from lowest to highest
      const sortedList = userGiftcardsList.sort(
        (a, b) => a.totalAmount - b.totalAmount
      );
      setUserGiftcardsList(sortedList);
    }
  };

  // end date onclick function
  const handleEndDate = () => {
    setArrowEndDate(!arrowEndDate);

    if (arrowEndDate) {
      // Sort based on expiryDate, from highest (latest) to lowest (earliest)
      const sortedList = userGiftcardsList.sort(
        (a, b) => new Date(b.expiryDate) - new Date(a.expiryDate)
      );
      setUserGiftcardsList([...sortedList]); // Spread the array to trigger re-render
    } else {
      // Sort based on expiryDate, from lowest (earliest) to highest (latest)
      const sortedList = userGiftcardsList.sort(
        (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate)
      );
      setUserGiftcardsList([...sortedList]); // Spread the array to trigger re-render
    }
  };

  // validate end date
  const validateDate = (date) => {
    // Get today's date
    const today = new Date();

    // Create the target date from the ISO string
    const targetDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  const dropdownRef = useRef(null); // Ref for dropdown
  const sortRef = useRef(null); // Ref for the sort

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle the dropdown state
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false); // Close the main dropdown
  };

  const toggleDropdownSort = () => {
    setIsSortDropdown((prev) => !prev); // Toggle the dropdown state
  };

  const closeDropdownSort = () => {
    setIsSortDropdown(false);
  };

  useEffect(() => {
    // Handler to detect clicks outside of the dropdown
    const handleClickOutside = (event) => {
      // handle filter dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }

      // handle sort dropdown
      if (sortRef.current && !sortRef.current.contains(event.target)) {
        closeDropdownSort();
      }
    };

    // Bind the event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // more Family details based on Pagination
  const fetchTasks = (page, limitVal) => {
    setLoadingspinner(true);
    const offset = (page - 1) * limit;
    let params = {
      userId: familyDetails.FamilyData.id,
      familyId: familyDetails.FamilyData.Family.id,
      searchStr: searchValue,
      sort: sortValue,
      order: orderValue,
      limit: limitVal,
      offset: offset,
      filBy: filterValue,
    };

    UserService.getUserGiftcardListNew(params).then(
      (response) => {
        setUserGiftcardsList(response.data);
        setLoadingspinner(false);
      },
      (error) => {}
    );
  };

  const [pageSize, setPageSize] = useState(50); // Default value is 10

  const handlePageSizeChange = (event) => {
    const selectedValue = parseInt(event.target.value, 50); // Get the selected value as a number
    setPageSize(selectedValue); // Update the state
    setLimit(selectedValue);

    fetchTasks(currentPage, selectedValue);
    setCurrentPage(1); // Reset to page 1 after limit change
  };

  useEffect(() => {
    if (mounted) {
      fetchTasks(currentPage, limit);
    }
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems.length / limit);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Container
        fluid
        ref={myRef}
        style={{ minHeight: "calc(100vh - 17rem)", marginBottom: "6rem" }}
        className="d-flex flex-column"
      >
        <ToastBlockContainer
          successToast={successToast}
          showToast={showToast}
          setShowToast={setShowToast}
          toastMessage={toastMessage}
        />

        <>
          <Row className="row align-items-center justify-content-md-end gy-xl-0 gx-2 gx-xl-3 mb-4">
            <Col>
              <h1 className="lh-base mb-0">{title}</h1>
            </Col>

            {/* sort  */}
            <Col
              xs="auto"
              className="position-relative"
              ref={sortRef} // Ref to track button for outside clicks
            >
              <button
                className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                  isSortDropdown ? "dropdown-arrow" : ""
                }`}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                onClick={toggleDropdownSort} // Toggle dropdown on click
                aria-expanded={isSortDropdown}
              >
                <i className="fa-solid fa-sm fa-sort lh-1 align-middle"></i>
                <span className="text-dark ms-md-2 d-none d-xl-block">
                  Sort by
                </span>
              </button>
              {/* main dropdown  */}
              <ul
                className={`dropdown-menu dropdown-parent mt-2 ${
                  isSortDropdown ? "show" : ""
                }`}
                ref={dropdownRef}
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  {sortOption.map((option) => (
                    <div
                      key={option.value}
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        color:
                          selectedSort && selectedSort.value === option.value
                            ? "#020617"
                            : "",
                        backgroundColor:
                          selectedSort && selectedSort.value === option.value
                            ? "#36f1cc"
                            : "",
                      }}
                      onClick={() => {
                        setSelectedSort(option);
                        giftcardSort(option.value);
                        closeDropdownSort();
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </li>
              </ul>
            </Col>

            {/* filter  */}
            <Col
              xs="auto"
              className="position-relative"
              ref={dropdownRef} // Ref to track button for outside clicks
            >
              <button
                className={`border-white btn btn-white d-xl-flex align-items-xl-center shadow position-relative ${
                  isDropdownOpen ? "dropdown-arrow" : ""
                }`}
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                onClick={toggleDropdown} // Toggle dropdown on click
                aria-expanded={isDropdownOpen}
              >
                <i className="fa-solid fa-sm fa-sliders-h lh-1 align-middle"></i>
                <span className="text-dark ms-md-2 d-none d-xl-block">
                  Filters
                </span>
              </button>
              {/* main dropdown  */}
              <ul
                className={`dropdown-menu dropdown-parent mt-2 ${
                  isDropdownOpen ? "show" : ""
                }`}
                ref={dropdownRef}
                style={{
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                aria-labelledby="dropdownMenuButton"
              >
                <li>
                  {filterOption.map((option) => (
                    <div
                      key={option.value}
                      className="dropdown-item"
                      style={{
                        cursor: "pointer",
                        color:
                          selectedOption1 &&
                          selectedOption1.value === option.value
                            ? "#020617"
                            : "",
                        backgroundColor:
                          selectedOption1 &&
                          selectedOption1.value === option.value
                            ? "#36f1cc"
                            : "",
                      }}
                      onClick={() => {
                        setSelectedOption1(option);
                        closeDropdown(); // Close dropdown after selecting an option}
                        giftcardFilter(option.value);
                      }}
                    >
                      {option.label}
                    </div>
                  ))}
                </li>
              </ul>
            </Col>

            <Col xs="auto">
              <Button
                variant="white"
                className="shadow border-white d-xl-none"
                onClick={handleShowSearch}
              >
                <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
              </Button>
              <InputGroup className="rounded shadow d-none d-xl-flex">
                <Form.Control
                  type="text"
                  placeholder="Search vouchers & gift cards"
                  value={searchStr}
                  onChange={(e) => setSearchStr(e.target.value)}
                  onKeyDown={giftcardSearch}
                  className="border-white"
                />
                {!searchStr && (
                  <Button
                    variant="white"
                    type="submit"
                    className="border-white"
                  >
                    <i className="fa-solid fa-sm fa-magnifying-glass text-dark lh-1 align-middle"></i>
                  </Button>
                )}
                {searchStr && (
                  <Button
                    variant="white"
                    type="submit"
                    className="border-white"
                    onClick={clearSearch}
                  >
                    <i className="fa-solid fa-sm fa-circle-xmark text-dark lh-1 align-middle"></i>
                  </Button>
                )}
              </InputGroup>
            </Col>

            <Col xs="auto">
              <Link
                to={addGiftcardUrl}
                className="btn btn-primary d-md-flex align-items-md-center"
              >
                <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                <span className="ms-md-2 d-none d-md-block">Add new</span>
              </Link>
            </Col>
          </Row>
          {loadingspinner ? (
            <>
              <Loading />
            </>
          ) : (
            <section className="bg-white p-4 p-md-6 rounded-4 shadow h-100">
              <Row className="gx-3 gy-2 gx-md-5 gx-lg-7 mb-8">
                <Col xs="12" md="auto">
                  <div className="small fw-medium">Vouchers</div>
                  <div className="h1 mb-0">
                    £{" "}
                    {Number.isInteger(VoucherCost)
                      ? VoucherCost.toLocaleString("en-US")
                      : Math.trunc(VoucherCost).toLocaleString("en-US")}
                  </div>
                </Col>
                <Col xs="12" md="auto">
                  <div className="small fw-medium">Gift cards</div>
                  <div className="h1 mb-0">
                    £{" "}
                    {Number.isInteger(GiftCost)
                      ? GiftCost.toLocaleString("en-US")
                      : Math.trunc(GiftCost).toLocaleString("en-US")}
                  </div>
                </Col>
                <Col xs="12" md="auto">
                  <div className="small fw-medium">Savings</div>
                  <div className="h1 text-success mb-0">
                    £{" "}
                    {Number.isInteger(TotalCost)
                      ? TotalCost.toLocaleString("en-US")
                      : Math.trunc(TotalCost).toLocaleString("en-US")}
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center gx-3 gy-0 gx-md-4">
                <Col xs="7" md="6" lg="5" className="order-first">
                  <span className="small fw-semibold fs-17">Name</span>
                </Col>
                <Col xs="7" md="6" lg="5" className="order-first">
                  <span className="small fw-semibold fs-17">Supplier</span>
                </Col>
                <Col
                  xs="4"
                  md="3"
                  xl="3"
                  className="order-md-last order-xl-1 text-nowrap"
                  style={{ cursor: "pointer" }}
                  onClick={handleRemaining}
                >
                  <span className="small fw-semibold fs-17">Remaining</span>
                  <i
                    className={`fa-solid fa-xs ms-1 ${
                      arrowRemaining ? "fa-arrow-up " : "fa-arrow-down"
                    }`}
                  ></i>
                </Col>
                <Col xs="auto" className="order-md-last order-xl-3 ms-auto">
                  <i className="fa-solid fa-sm fa-angle-right invisible"></i>
                </Col>
                <Col
                  md="5"
                  lg="3"
                  className="d-none d-md-block"
                  style={{ cursor: "pointer" }}
                  onClick={handleEndDate}
                >
                  <span className="small fw-semibold fs-17">End date</span>
                  <i
                    className={`fa-solid fa-xs ms-1 ${
                      arrowEndDate ? "fa-arrow-up " : "fa-arrow-down"
                    }`}
                  ></i>
                </Col>
                <Col
                  md="3"
                  xl="2"
                  className="order-xxl-first d-none d-xxl-block"
                >
                  <span className="small fw-semibold fs-17">Type</span>
                </Col>
                <Col lg="3" xl="2" className="order-md-first d-none d-lg-block">
                  <span className="small fw-semibold fs-17">Total</span>
                </Col>
                <Col xl="3" xxl="2" className="order-xl-2 d-none d-xl-block">
                  <span className="small fw-semibold fs-17">
                    {/* maintain alignment */}
                  </span>
                </Col>
              </Row>
              <hr className="mt-1 mb-3" />
              {isEmpty ? (
                <div className="text-center">
                  <p className="mt-4">
                    You currently have no vouchers & gift cards set up!!
                  </p>
                  <Link
                    to={addGiftcardUrl}
                    className="btn btn-primary btn-lg d-inline-flex align-items-center"
                  >
                    <i className="fa-solid fa-sm fa-add lh-1 align-middle"></i>
                    <span className="ms-md-2 d-none d-md-block">Add new</span>
                  </Link>
                </div>
              ) : (
                <>
                  {userGiftcardsList.map((giftcard, index) => (
                    <>
                      <Row
                        className="align-items-center gx-3 gy-0 gx-md-4"
                        onClick={() => viewDetailsRoute(giftcard.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <Col xs="7" md="6" lg="5" className="order-first">
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250 }}
                            overlay={
                              <Tooltip id="">
                                <div className="">{giftcard.name}</div>
                              </Tooltip>
                            }
                          >
                            <div className="align-middle small text-dark text-truncate">
                              {giftcard.name}
                            </div>
                          </OverlayTrigger>
                        </Col>
                        <Col xs="7" md="6" lg="5" className="order-first">
                          <div className="d-flex flex-shrink-1 align-items-center align-middle">
                            <div className="img-wrapper img-wrapper-warranty-product flex-shrink-0 d-none d-md-flex">
                              <img
                                src={
                                  giftcard.Supplier.logo === null
                                    ? "https://el-backenddata.s3.eu-west-2.amazonaws.com/adminassets/Default_logo.jpg"
                                    : giftcard.Supplier.logo
                                }
                                alt="logo"
                                className="img-fluid w-100 h-100"
                              />
                            </div>
                            <div className="ms-2 ms-md-3 overflow-hidden">
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250 }}
                                overlay={
                                  <Tooltip id="">
                                    <div className="">
                                      {giftcard.Supplier &&
                                      giftcard.Supplier.name
                                        ? giftcard.Supplier.name === "Others"
                                          ? giftcard.otherSupplierName
                                          : giftcard.Supplier.name
                                        : "--"}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <div className="align-middle small text-dark text-truncate">
                                  {giftcard.Supplier && giftcard.Supplier.name
                                    ? giftcard.Supplier.name === "Others"
                                      ? giftcard.otherSupplierName
                                      : giftcard.Supplier.name
                                    : "--"}
                                </div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xs="5"
                          md="3"
                          xl="3"
                          className="order-md-last order-xl-1"
                        >
                          <div className="d-inline small text-dark">
                            {giftcard.cardType === "Voucher"
                              ? "£" +
                                parseFloat(giftcard.totalAmount).toFixed(2)
                              : "£" +
                                parseFloat(
                                  giftcard.totalAmount - giftcard.usedAmount
                                ).toFixed(2)}
                          </div>
                        </Col>
                        <Col
                          xs="auto"
                          className="order-md-last order-xl-3 ms-auto d-flex align-items-center gap-lg-2"
                        >
                          {giftcard.reminder && (
                            <>
                              <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250 }}
                                overlay={
                                  <Tooltip id="">
                                    <div className="">
                                      Reminder set for this {giftcard.cardType}
                                    </div>
                                  </Tooltip>
                                }
                              >
                                <span className="badge badge-xs d-inline-flex align-items-center badge-primary-light rounded-circle d-none d-xl-flex">
                                  <i className="fa-solid fa-md fa-bell"></i>
                                </span>
                              </OverlayTrigger>
                            </>
                          )}
                          {giftcard.documentLink ? (
                            <div className="d-none d-xl-flex">
                              <span className="badge badge-xs d-inline-flex align-items-center bg-warning d-flex align-items-center">
                                <i className="fa fa-file"></i>{" "}
                                <span
                                  className="ms-1 fs-12"
                                  style={{ marginTop: "3px", minWidth: "55px" }}
                                >
                                  {JSON.parse(giftcard.documentLink).length}{" "}
                                  {JSON.parse(giftcard.documentLink).length > 1
                                    ? "Files"
                                    : "File"}
                                </span>
                              </span>
                            </div>
                          ) : (
                            <div
                              className="d-none d-xl-flex"
                              style={{ width: "87px" }}
                            ></div>
                          )}
                          <Button
                            className="btn-sm btn-square btn-interactive d-none d-md-inline-block"
                            variant="link"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevents event from bubbling up
                              editGiftcardRoute(giftcard.id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-edit"></i>
                          </Button>
                          <Button
                            className="btn-sm btn-square btn-interactive"
                            variant="link"
                            onClick={() => viewDetailsRoute(giftcard.id)}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa-solid fa-chevron-right"></i>
                          </Button>
                        </Col>
                        <Col md="5" lg="3" className="d-none d-md-block">
                          <div
                            className={`small ${
                              validateDate(giftcard.expiryDate) <= 30
                                ? "text-danger"
                                : validateDate(giftcard.expiryDate) <= 60 &&
                                  validateDate(giftcard.expiryDate) > 30
                                ? "text-orange"
                                : validateDate(giftcard.expiryDate) <= 90 &&
                                  validateDate(giftcard.expiryDate) > 60
                                ? "text-lightgreen"
                                : "text-success"
                            } text-truncate`}
                          >
                            {moment(giftcard.expiryDate).format("Do MMM, YYYY")}
                          </div>
                        </Col>
                        <Col
                          md="3"
                          xl="2"
                          className="order-xxl-first d-none d-xxl-block"
                        >
                          <div className="d-inline small text-dark">
                            {giftcard.cardType ? giftcard.cardType : "-"}
                          </div>
                        </Col>
                        <Col
                          lg="3"
                          xl="2"
                          className="order-md-first d-none d-lg-block"
                        >
                          <div className="d-inline small text-dark">
                            {giftcard.totalAmount
                              ? "£" + giftcard.totalAmount
                              : "N/A"}
                          </div>
                        </Col>
                      </Row>
                      {index !== userGiftcardsList.length - 1 && (
                        <hr className="my-3" />
                      )}
                    </>
                  ))}
                </>
              )}
              {userGiftcardsList.length === 0 && !isEmpty && (
                <>
                  <p className="text-center mt-4">
                    There are no results for this query
                  </p>
                </>
              )}

              {/* pagination  */}
              <div className="d-sm-flex align-items-center gap-2 justify-content-end mt-7 fs-14 bold-colour pt-3">
                {/* Page Size Dropdown */}
                <div className="d-flex align-items-center gap-2 me-3  mb-3 mb-sm-0">
                  <label htmlFor="pageSize">Page Size:</label>
                  <select
                    id="pageSize"
                    value={pageSize} // Bind the state to the select element
                    onChange={handlePageSizeChange}
                    className="form-select p-2 me-2 fs-14"
                    style={{ width: "75px", display: "inline-block" }}
                  >
                    <option value={50}>50</option>
                    <option value={75}>75</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                  </select>
                  <div>
                    Showing <strong>{userGiftcardsList.length}</strong> of{" "}
                    <strong>{totalItems.length}</strong>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-2">
                  {/* Previous Button */}
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    <i class="fas fa-chevron-left "></i>
                  </button>
                  <div>
                    Page <strong>{totalPages === 0 ? 0 : currentPage}</strong>{" "}
                    of <strong>{totalPages}</strong>
                  </div>
                  {/* Next Button */}
                  <button
                    className="border-0 bg-transparent"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage * limit >= totalItems.length}
                  >
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </section>
          )}
        </>
        {/* // Delete Gift cards */}
        <Modal
          show={showDelete}
          onHide={handleShowDeleteClose}
          className="invite_au_modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h2 className="h3 mb-0">
                Delete {cardType1 === "Voucher" ? "Voucher" : "Gift Card"}
              </h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              All the {cardType1 === "Voucher" ? "Vouchers" : "Gift Cards"}{" "}
              related details will be deleted from the family
            </p>
            <p className="mb-5">Do you want to continue?</p>
            <Button variant="primary" onClick={handleShowDeleteClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => handleDeleteGiftCard(giftId)}
              className="ms-4"
            >
              Yes
            </Button>
          </Modal.Body>
        </Modal>

        {/* Search for mobile */}
        <Modal show={showSearch} onHide={handleCloseSearch}>
          <Modal.Header closeButton>
            <Modal.Title>Search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <InputGroup className="rounded shadow">
              <Form.Control
                type="text"
                placeholder="Search vouchers & gift cards"
                value={searchStr}
                onChange={(e) => setSearchStr(e.target.value)}
                onKeyDown={giftcardSearch}
                className="border-white"
              />
              {!searchStr && (
                <Button variant="white" type="submit" className="border-white">
                  <i className="fa-solid fa-sm fa-magnifying-glass text-dark"></i>
                </Button>
              )}
              {searchStr && (
                <Button
                  variant="white"
                  type="submit"
                  className="border-white"
                  onClick={clearSearch}
                >
                  <i className="fa-solid fa-sm fa-circle-xmark text-dark"></i>
                </Button>
              )}
            </InputGroup>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};
export default GiftCards;
