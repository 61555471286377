import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import ToastBlockContainer from "../../common/ToastBlockContainer";
import Loading from "../../common/Loading";
import Avatar from "../../assets/images/avatar.svg";

const ViewTaskDetails = (props) => {
  let history = useHistory();
  var UserDetails = JSON.parse(localStorage.getItem("UserDetails"));
  const [showDelete, setShowDelete] = useState(false);
  const [showConf, setShowConf] = useState(false);
  let { uuid, taskId, userUUID } = useParams();
  const [taskUrl, setTaskUrl] = useState("");
  const [taskEditUrl, setTaskEditUrl] = useState("");
  const [backText, setBackText] = useState("Back to My To Do's");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [successToast, setSuccessToast] = useState(false);
  const [allValue, setAllValue] = useState([]);
  const [clickVal, setClickVal] = useState({});
  const [newArrayVal, setnewArrayVal] = useState([]);
  const [highlightBox, setHighlightBox] = useState("");
  const abc = parseInt(localStorage.getItem("taskID"));
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    // get family details
    setLoader(true);
    if (userUUID) {
      setTaskUrl("/user_todos/" + uuid + "/" + userUUID);
      UserService.userProfile(userUUID).then((response) => {
        setBackText(
          response.userData.firstName === "null"
            ? response.userData.email
            : response.userData.firstName + " " + response.userData.lastName ===
              "null"
            ? ""
            : response.userData.firstName
        );
      });
    } else {
      setTaskUrl("/todos/" + uuid);
    }
    // get single warranty details by ID

    UserService.getUserSingleFamilyDetails(uuid, UserDetails.id).then(
      (response) => {
        if (userUUID) {
          UserService.getTaskDetailsById(taskId).then((response) => {
            UserService.getTaskDetailsByParentId(
              response.data.ParentId,
              response.data.FamilyUserId,
              response.data.FamilyId
            ).then((res) => {
              setAllValue(res.data);
              var newArray = [];
              for (var i = 0; i < res.data.length; i++) {
                const dateToday = new Date();
                const dateVal = new Date(Date.parse(res.data[i].eta));

                if (dateToday === dateVal) {
                  newArray.push(res.data[i]);
                } else if (dateToday > dateVal) {
                  newArray.push(res.data[i]);
                }
              }

              setnewArrayVal(newArray);
            });
            setHighlightBox("highlightBox");
            setClickVal(response.data);
            setTaskEditUrl(
              "/edit_user_todos/" + uuid + "/" + taskId + "/" + userUUID
            );
          });
        } else {
          UserService.getTaskDetailsByParentId(
            taskId,
            response.FamilyData.id,
            response.FamilyData.FamilyId
          ).then(
            (resp) => {
              setAllValue(resp.data);
              setClickVal(resp.data[0]);
              var newArray = [];
              for (var i = 0; i < resp.data.length; i++) {
                const dateToday = new Date();
                const dateVal = new Date(Date.parse(resp.data[i].eta));

                if (dateToday === dateVal) {
                  newArray.push(resp.data[i]);
                } else if (dateToday > dateVal) {
                  newArray.push(resp.data[i]);
                }
              }

              setnewArrayVal(newArray);
              setHighlightBox("highlightBox");

              if (userUUID) {
                setTaskEditUrl(
                  "/edit_user_todos/" + uuid + "/" + taskId + "/" + userUUID
                );
              } else {
                setTaskEditUrl(
                  "/edit_todos/" + uuid + "/" + taskId //+ response.data.rows[0].id
                );
              }
            },
            () => {}
          );
        }
        setTimeout(() => {
          setLoader(false);
        }, 3000);
      },
      () => {}
    );
  }, []);

  // Delete confirmation
  const handleShowDeleteShow = () => {
    setShowDelete(true);
    //setGiftId(id);
  };

  // Close delete confirmation
  const handleShowDeleteClose = () => {
    setShowDelete(false);
  };

  const noImgError = (e) => {
    e.target.setAttribute("src", Avatar);
  };

  // Delete task
  const handleDeleteTodo = (aaa) => {
    setShowDelete(false);

    UserService.deleteTask(aaa).then(
      () => {
        setShowToast(true);
        setSuccessToast(true);
        setToastMessage("To Do deleted successfully");
        window.scrollTo(0, 0);

        setTimeout(() => {
          history.push(`/todos/${uuid}`);
        }, 2500);
      },
      () => {}
    );
  };

  const [showMarkTodo, setShowMarkTodo] = useState(false);
  const [markTodoObj, setMarkTodoObj] = useState({});
  // show the mark to as completed modal
  const showTodo = (aaa) => {
    setMarkTodoObj(aaa);
    setShowMarkTodo(true);
  };

  // hide mark to do as completed modal
  const handleMarkTodoHide = () => {
    setShowMarkTodo(false);
  };

  // update task status to complete
  const updateTaskStatus = () => {
    setShowConf(false);

    let data = {
      status: "Completed",
    };
    UserService.updateTaskAsComplete(data, markTodoObj.id).then(
      (response) => {
        setShowMarkTodo(false);
        window.scrollTo(0, 0);

        if (response.status === 400) {
          setSuccessToast(false);
          setShowToast(true);
          setToastMessage(response.data.message);
        } else {
          setShowToast(true);
          setSuccessToast(true);
          setToastMessage("To Do mark as complete successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        }
      },
      () => {}
    );
  };

  const validateDate = (date) => {
    // Get today's date
    const today = new Date();

    // Create the target date from the ISO string
    const targetDate = new Date(date);

    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - today;

    // Convert the time difference from milliseconds to days
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  };

  return (
    <Container>
      <ToastBlockContainer
        successToast={successToast}
        showToast={showToast}
        setShowToast={setShowToast}
        toastMessage={toastMessage}
      />
      <Row className="justify-content-center gy-0 mb-6rem">
        <Col xl="21" xxl="18">
          <h1 className="lh-base mb-4">To-do</h1>
          <section className="bg-white p-4 p-md-6 p-lg-8 shadow rounded-4">
            {loader ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                <section>
                  <Row className="gy-3 gy-md-4 gy-lg-0 mb-3 mb-md-4">
                    <Col
                      xs
                      lg="6
                    "
                    >
                      <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250 }}
                        overlay={
                          <Tooltip id="">
                            <div className="">{clickVal && clickVal.name}</div>
                          </Tooltip>
                        }
                      >
                        <h2
                          className={`${
                            clickVal && clickVal.details
                              ? "mb-1 mb-md-2 text-truncate max-width-webkit w-fit cursor-pointer"
                              : "mb-0 text-truncate max-width-webkit w-fit cursor-pointer"
                          }`}
                        >
                          {clickVal && clickVal.name}
                        </h2>
                      </OverlayTrigger>
                      {clickVal && clickVal.details !== "" && (
                        <p className="mb-0">
                          {clickVal
                            ? clickVal.details
                              ? clickVal.details
                              : "--"
                            : ""}
                        </p>
                      )}
                    </Col>
                    <Col xs="auto" className="order-lg-last ms-auto">
                      {clickVal && !clickVal.taskRecurrence && (
                        <>
                          <Link
                            to={taskEditUrl}
                            className="btn btn-white btn-sm btn-square d-inline-flex d-md-none"
                          >
                            <i className="fa-solid fa-edit"></i>
                          </Link>
                          <Link
                            to={taskEditUrl}
                            className="btn btn-white btn-sm d-none d-md-inline-block"
                          >
                            <i className="fa-solid fa-edit me-2"></i>Edit
                          </Link>
                        </>
                      )}
                      {clickVal && clickVal.status !== "Completed" && (
                        <>
                          <button
                            onClick={() => showTodo(clickVal)}
                            className="btn btn-white btn-sm btn-square d-inline-flex d-md-none ms-2"
                          >
                            <i className="fa-solid fa-check"></i>
                          </button>
                          <button
                            onClick={() => showTodo(clickVal)}
                            className="btn btn-white btn-sm d-none d-md-inline-block ms-2"
                          >
                            <i className="fa-solid fa-check me-2"></i>Mark
                            complete
                          </button>
                        </>
                      )}

                      <Button
                        variant="danger"
                        size="sm"
                        className="btn-square d-inline-flex ms-2"
                        onClick={() =>
                          props.location.state
                            ? handleShowDeleteShow(props.location.state)
                            : handleShowDeleteShow(abc)
                        }
                      >
                        <i className="fa-solid fa-trash text-white"></i>
                      </Button>
                    </Col>
                    {/* unless lg breakpoint */}
                    <Col lg="auto" className="ms-auto d-lg-none">
                      <div className="small fw-medium">Date</div>
                      <p
                        className={`h2  ${
                          validateDate(clickVal && clickVal.eta) <= 30
                            ? "text-danger"
                            : validateDate(clickVal && clickVal.eta) <= 60 &&
                              validateDate(clickVal && clickVal.eta) > 30
                            ? "text-orange"
                            : validateDate(clickVal && clickVal.eta) <= 90 &&
                              validateDate(clickVal && clickVal.eta) > 60
                            ? "text-lightgreen"
                            : "text-success"
                        } fw-semibold text-truncate`}
                      >
                        {clickVal &&
                        clickVal.eta !== null &&
                        clickVal.eta !== undefined &&
                        moment(clickVal && clickVal.eta).isValid()
                          ? moment
                              .utc(clickVal && clickVal.eta)
                              .format("YYYY-MM-DD h:mm A")
                          : "--"}
                      </p>
                    </Col>
                    {/* if lg breakpoint */}
                    <Col
                      lg="auto"
                      className="ms-auto d-none d-lg-block"
                      style={{ height: "0" }}
                    >
                      <div className="small fw-medium">Date</div>
                      <p
                        className={`h2  ${
                          validateDate(clickVal && clickVal.eta) <= 30
                            ? "text-danger"
                            : validateDate(clickVal && clickVal.eta) <= 60 &&
                              validateDate(clickVal && clickVal.eta) > 30
                            ? "text-orange"
                            : validateDate(clickVal && clickVal.eta) <= 90 &&
                              validateDate(clickVal && clickVal.eta) > 60
                            ? "text-lightgreen"
                            : "text-success"
                        } fw-semibold text-truncate`}
                      >
                        {clickVal &&
                        clickVal.eta !== null &&
                        clickVal.eta !== undefined &&
                        moment(clickVal.eta).isValid()
                          ? moment.utc(clickVal.eta).format("YYYY-MM-DD h:mm A")
                          : "--"}
                      </p>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0 mb-3 mb-md-4">
                    <Col md="8">
                      <div className="small fw-medium">Status</div>
                      <div className="text-dark fw-semibold">
                        {clickVal && clickVal.status}
                      </div>
                    </Col>
                  </Row>
                  <Row className="gy-3 gy-md-0">
                    {clickVal && clickVal.taskRecurrence ? (
                      <>
                        <Col md="8">
                          <div className="small fw-medium">Frequency</div>
                          <p className="text-dark fw-medium mb-0">
                            {clickVal && clickVal.taskFrequency}
                          </p>
                        </Col>
                        <Col md="8">
                          <div className="small fw-medium">Occurrences</div>
                          <p className="text-dark fw-medium mb-0">
                            {allValue ? allValue.length : ""}
                          </p>
                        </Col>
                      </>
                    ) : (
                      ""
                    )}
                    <Col md="8">
                      <div className="small fw-medium">Assignee</div>
                      <div className="d-flex align-items-center">
                        <p className="text-dark fw-medium mb-0">
                          {clickVal && clickVal.assigneeId
                            ? clickVal.assigneeId.User.firstName === "null"
                              ? clickVal.assigneeId.User.email
                              : clickVal.assigneeId.User.firstName +
                                " " +
                                clickVal.assigneeId.User.lastName
                            : ""}
                        </p>
                        <div className="avatar avatar-sm flex-shrink-0 ms-3 ms-md-4">
                          <Image
                            src={clickVal && clickVal.assigneeId.User}
                            onError={noImgError}
                            alt="Profile Avatar"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </section>
                {clickVal && clickVal.taskRecurrence ? (
                  <>
                    <hr className="my-6 my-md-7" />
                    <section>
                      <h3 className="mb-1 mb-md-2">History</h3>
                      {/* <p class="mb-3 mb-md-4">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p> */}
                      <Row className="gy-3 gy-md-0 mb-3 mb-md-4">
                        <Col xs="16" md="8">
                          <div className="small fw-medium">
                            Previous completions
                          </div>
                          <p className="text-dark fw-semibold mb-0">
                            {
                              allValue.filter(
                                (task) => task.status === "Completed"
                              ).length
                            }
                          </p>
                        </Col>
                      </Row>
                      {allValue.map((allVal, index) => (
                        <>
                          <Row className="align-items-center gx-3 gy-0 gx-md-4">
                            <Col
                              xs="17"
                              md="10"
                              xl="10"
                              xxl="11"
                              className="me-auto"
                            >
                              <div
                                className={`d-flex align-items-center ${
                                  allVal.status === "Completed"
                                    ? "opacity-25"
                                    : ""
                                }`}
                              >
                                <div className="small fw-semibold text-dark d-md-none">
                                  {allVal.name}
                                </div>
                                <div className="fw-semibold text-dark d-none d-md-block">
                                  {allVal.name}
                                </div>
                              </div>
                              <div
                                className={`small text-truncate d-md-none ${
                                  allVal.status === "Completed"
                                    ? "opacity-25"
                                    : ""
                                }`}
                              >
                                {allVal.details}
                              </div>
                              <div
                                className={`small text-truncate d-none d-md-block ${
                                  allVal.status === "Completed"
                                    ? "opacity-25"
                                    : ""
                                }`}
                              >
                                {allVal.details}
                              </div>
                              <div
                                className={`small fw-semibold d-md-none ${
                                  allVal.status === "Completed"
                                    ? "opacity-25 mb-1"
                                    : ""
                                } ${
                                  allVal.status === "Expired"
                                    ? "text-danger mb-1"
                                    : "text-dark"
                                }`}
                              >
                                {moment
                                  .utc(allVal.eta)
                                  .format("YYYY-MM-DD h:mm A")}
                              </div>
                              <div
                                className={`fw-semibold ${
                                  allVal.status === "Completed"
                                    ? "text-success"
                                    : ""
                                } d-inline d-md-none small`}
                              >
                                {allVal.status}
                              </div>
                            </Col>
                            <Col
                              md="4"
                              lg="3"
                              className="d-none d-md-block"
                            ></Col>
                            <Col
                              className={`text-end d-none d-md-flex justify-content-end gap-4 ${
                                allVal.status === "Completed"
                                  ? "opacity-25"
                                  : ""
                              }`}
                            >
                              <div
                                className={`fw-semibold ${
                                  allVal.status === "Completed"
                                    ? "text-success"
                                    : ""
                                } d-inline small`}
                              >
                                {allVal.status}
                              </div>
                              <div
                                className={`small fw-semibold ${
                                  allVal.status === "Expired"
                                    ? "text-danger"
                                    : "text-dark"
                                }`}
                              >
                                {allVal.eta !== null &&
                                allVal.eta !== undefined &&
                                moment(allVal.eta).isValid()
                                  ? moment
                                      .utc(allVal.eta)
                                      .format("YYYY-MM-DD h:mm A")
                                  : "--"}
                              </div>
                            </Col>
                          </Row>
                          {index !== allValue.length - 1 && (
                            <hr className="my-3" />
                          )}
                        </>
                      ))}
                    </section>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </section>
        </Col>
      </Row>

      {/* // Delete Gift cards */}
      <Modal
        show={showDelete}
        onHide={handleShowDeleteClose}
        className="invite_au_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="h3 mb-0">Delete To Do</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>All the to do's related details will be deleted from the family</p>
          <p className="mb-5">Do you want to continue?</p>
          <Button variant="primary" onClick={handleShowDeleteClose}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() =>
              props.location.state
                ? handleDeleteTodo(props.location.state)
                : handleDeleteTodo(abc)
            }
            className="ms-4"
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>

      {/* // Mark Todo as completed */}
      <Modal show={showMarkTodo} onHide={handleMarkTodoHide} className="">
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="h3 mb-0">Mark Todo as completed</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Mark the Todo to be completed from the family</p>
          <p className="mb-5">Do you want to continue?</p>
          <Button variant="primary" onClick={handleMarkTodoHide}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => updateTaskStatus()}
            className="ms-4"
          >
            Yes
          </Button>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default ViewTaskDetails;
